import { ColumnsType } from 'antd/lib/table'
import EventOutlinedIcon from '@mui/icons-material/EventOutlined'
import { Link } from 'react-router-dom'
import urlJoin from 'url-join'
import { useTranslation } from 'react-i18next'
import { FC, memo } from 'react'
import moment, { Moment } from 'moment'
import { Table, Typography } from 'antd'

import { getTasks } from '../../store/selectors'
import { useTypedSelector } from '../../hooks'
import { CARD_WIDTH, DATE_FORMAT, EMPTY_STRING, TASKS_URL } from '../../constants'
import { CardTitle, StyledCard } from '../common'
import { Color, Spacing } from '../../styles'
import { EmptyTable, HistoryTableWrapper, TableCellContent } from '../tables'
import { ITask, TasksColumnsDataType } from '../../api'

const { Text } = Typography

const DAYS = 'days'

export const ClosestTasks: FC = memo(() => {
  const { tasks } = useTypedSelector(getTasks)

  const { t } = useTranslation()

  const getRange = (startDate: Moment, endDate: Moment, type: 'days') => {
    const fromDate = moment(startDate)
    const toDate = moment(endDate)

    const diff = toDate.diff(fromDate, type)
    const range = []

    /* eslint-disable */
    for (let i = 0; i < diff; i++) {
      range.push(moment(startDate).add(i, type).format(moment.HTML5_FMT.DATE))
    }

    return range
  }

  const getFiltredTasks = () => {
    const today = moment()
    const sevenDaysBefore = moment().add(7, DAYS)

    const dates = getRange(today, sevenDaysBefore, DAYS)

    if (tasks)
      return tasks
        .filter((i) => i.dueDatetime)
        .filter((item) => dates.includes(item.dueDatetime.split('T')[0]))

    return []
  }

  const columns: ColumnsType<TasksColumnsDataType> = [
    {
      title: EMPTY_STRING,
      dataIndex: 'title',
      key: 'title',
      ellipsis: true,
      render: (text, { key, dueDatetime }) => {
        const tooltipText = dueDatetime
          ? `${moment(dueDatetime).format(DATE_FORMAT)} ${'\u00A0'} ${text}`
          : text

        return (
          <>
            <Text>{dueDatetime && moment(dueDatetime).format(DATE_FORMAT)}</Text>

            <Link to={urlJoin(TASKS_URL, key?.toString())}>
              <TableCellContent
                isStrong
                isLink
                text={text}
                tooltipText={tooltipText}
                style={dueDatetime ? { marginLeft: '8px' } : undefined}
              />
            </Link>
          </>
        )
      },
    },
  ]

  const getData = () =>
    getFiltredTasks()
      .map<TasksColumnsDataType>(({ id, title, dueDatetime }: ITask) => ({
        key: id,
        title,
        dueDatetime,
      }))
      .sort((a: TasksColumnsDataType, b: TasksColumnsDataType) =>
        a.dueDatetime > b.dueDatetime ? 1 : -1,
      )

  return (
    <StyledCard
      title={
        <CardTitle $iconColor={Color.black} icon={<EventOutlinedIcon />}>
          {t('tasks-for-week')}
        </CardTitle>
      }
      $width={CARD_WIDTH.WIDTH_100_PER}
      $height="622px"
      $pr={Spacing.sm4}
      $pl={Spacing.sm4}
    >
      <HistoryTableWrapper>
        <Table
          columns={columns}
          dataSource={getData() || []}
          bordered={false}
          pagination={false}
          scroll={getData().length > 9 ? { y: 500 } : {}}
          style={{ width: '100%' }}
          showHeader={false}
          locale={{
            emptyText: <EmptyTable />,
          }}
        />
      </HistoryTableWrapper>
    </StyledCard>
  )
})
