import { BrowserRouter as Router } from 'react-router-dom'
import { FC, Suspense } from 'react'

import { Loader } from '../common'
import { Routes } from './Routes'

import 'antd/dist/antd.min.css'
import '../../index.css'

import '../../i18next'

export const Root: FC = () => {
  return (
    <Suspense fallback={<Loader />}>
      <Router>
        <Routes />
      </Router>
    </Suspense>
  )
}
