import urlJoin from 'url-join'
import { AxiosError, AxiosInstance, AxiosResponse } from 'axios'

import { AppDispatch } from '../../hooks'
import { handleLoadError } from './errors'
import { RootState } from '../reducers/rootReducer'
import { TasksActionTypes } from '../actionTypes/tasks'
import {
  AUTH,
  EQUIPMENT_HISTORY_ENDPOINT,
  HISTORY_ENDPOINT,
  HISTORY_MATERIALS_ENDPOINT,
  HISTORY_METERS_ENDPOINT,
  STATS_ENDPOINT,
  TASKS_HISTORY_ENDPOINT,
  USER_SERVICE_HISTORY_ENDPOINT,
} from '../../env'
import {
  ClientsActionTypes,
  EquipmentActionTypes,
  MaterialsActionTypes,
  MetersActionTypes,
  UserServicesActionTypes,
} from '../actionTypes'
import { EQUIPMENT_ERROR_TYPES, TASKS_ERROR_TYPES } from '../../api'
import { getEquipmentErrorActionType, getTasksErrorActionType, startDataLoading } from './utils'

type GetStateFunctionType = () => RootState

// Get Material Item  History   GET

export const createConfig = (id: string, startDate?: string, endDate?: string) => {
  if (startDate && endDate) {
    return {
      params: {
        id,
        startDate,
        endDate,
      },
    }
  }

  return {
    params: {
      id,
    },
  }
}

export const getMaterialItemHistory =
  (id: string, startDate?: string, endDate?: string) =>
  (dispatch: AppDispatch, getState: GetStateFunctionType, api: AxiosInstance) => {
    dispatch(startDataLoading(MaterialsActionTypes.START_MATERIALS_LOADING))

    api
      .get(
        urlJoin(HISTORY_ENDPOINT, HISTORY_MATERIALS_ENDPOINT),
        createConfig(id, startDate, endDate),
      )
      .then((res: AxiosResponse) => {
        if (res.status === 200) {
          dispatch({
            type: MaterialsActionTypes.LOAD_MATERIAL_HISTORY_SUCCESS,
            payload: res.data.data,
          })

          dispatch({
            type: MaterialsActionTypes.LOAD_MATERIAL_META_HISTORY_SUCCESS,
            payload: res.data.meta.summary,
          })
        }
      })
      .catch((err: AxiosError) =>
        dispatch(handleLoadError(err, MaterialsActionTypes.LOAD_MATERIALS_ERROR)),
      )
  }

// Get Meter Item  History   GET

export const getMeterItemHistory =
  (id: string, startDate?: string, endDate?: string) =>
  (dispatch: AppDispatch, getState: GetStateFunctionType, api: AxiosInstance) => {
    dispatch(startDataLoading(MetersActionTypes.START_METERS_LOADING))

    api
      .get(urlJoin(HISTORY_ENDPOINT, HISTORY_METERS_ENDPOINT), createConfig(id, startDate, endDate))
      .then((res: AxiosResponse) => {
        if (res.status === 200) {
          dispatch({
            type: MetersActionTypes.LOAD_METER_HISTORY_SUCCESS,
            payload: res.data.data,
          })

          dispatch({
            type: MetersActionTypes.LOAD_METER_META_HISTORY_SUCCESS,
            payload: res.data.meta.summary,
          })
        }
      })
      .catch((err: AxiosError) =>
        dispatch(handleLoadError(err, MetersActionTypes.LOAD_METERS_ERROR)),
      )
  }

// Get Equipment Item  History   GET

export const getEquipmentItemHistory =
  (id: string, startDate?: string, endDate?: string) =>
  (dispatch: AppDispatch, getState: GetStateFunctionType, api: AxiosInstance) => {
    dispatch(startDataLoading(EquipmentActionTypes.START_EQUIPMENT_HISTORY_LOADING))

    api
      .get(
        urlJoin(HISTORY_ENDPOINT, EQUIPMENT_HISTORY_ENDPOINT),
        createConfig(id, startDate, endDate),
      )
      .then((res: AxiosResponse) => {
        if (res.status === 200) {
          dispatch({
            type: EquipmentActionTypes.LOAD_EQUIPMENT_HISTORY_SUCCESS,
            payload: res.data.data,
          })
        }
      })
      .catch((err: AxiosError) =>
        dispatch(handleLoadError(err, getEquipmentErrorActionType(EQUIPMENT_ERROR_TYPES.HISTORY))),
      )
  }

// Get Equipment Item  History stats   GET

export const getEquipmentItemHistoryStats =
  (id: string, startDate?: string, endDate?: string) =>
  (dispatch: AppDispatch, getState: GetStateFunctionType, api: AxiosInstance) => {
    dispatch(startDataLoading(EquipmentActionTypes.START_EQUIPMENT_HISTORY_LOADING))

    api
      .get(
        urlJoin(HISTORY_ENDPOINT, EQUIPMENT_HISTORY_ENDPOINT, STATS_ENDPOINT),
        createConfig(id, startDate, endDate),
      )
      .then((res: AxiosResponse) => {
        if (res.status === 200) {
          dispatch({
            type: EquipmentActionTypes.LOAD_EQUIPMENT_HISTORY_STATS_SUCCESS,
            payload: res.data.data,
          })
        }
      })
      .catch((err: AxiosError) =>
        dispatch(handleLoadError(err, getEquipmentErrorActionType(EQUIPMENT_ERROR_TYPES.HISTORY))),
      )
  }

// Get User Service  History   GET

export const getUserServiceHistory =
  (id: string, startDate?: string, endDate?: string) =>
  (dispatch: AppDispatch, getState: GetStateFunctionType, api: AxiosInstance) => {
    dispatch(startDataLoading(UserServicesActionTypes.START_SERVICES_LOADING))

    api
      .get(
        urlJoin(HISTORY_ENDPOINT, USER_SERVICE_HISTORY_ENDPOINT),
        createConfig(id, startDate, endDate),
      )
      .then((res: AxiosResponse) => {
        if (res.status === 200) {
          dispatch({
            type: UserServicesActionTypes.LOAD_SERVICE_HISTORY_SUCCESS,
            payload: res.data.data,
          })
        }
      })
      .catch((err: AxiosError) =>
        dispatch(handleLoadError(err, UserServicesActionTypes.LOAD_SERVICES_ERROR)),
      )
  }

// // Get Task Item  History   GET

export const getTaskItemHistory =
  (id: string, startDate?: string, endDate?: string) =>
  (dispatch: AppDispatch, getState: GetStateFunctionType, api: AxiosInstance) => {
    dispatch(startDataLoading(TasksActionTypes.START_TASK_HISTORY_LOADING))

    api
      .get(urlJoin(HISTORY_ENDPOINT, TASKS_HISTORY_ENDPOINT), createConfig(id, startDate, endDate))
      .then((res: AxiosResponse) => {
        if (res.status === 200) {
          dispatch({
            type: TasksActionTypes.LOAD_TASK_HISTORY_SUCCESS,
            payload: res.data.data,
          })
        }
      })
      .catch((err: AxiosError) =>
        dispatch(handleLoadError(err, getTasksErrorActionType(TASKS_ERROR_TYPES.HISTORY))),
      )
  }

// Get Client Item  History   GET

export const getClientHistory =
  (id: string, startDate?: string, endDate?: string) =>
  (dispatch: AppDispatch, getState: GetStateFunctionType, api: AxiosInstance) => {
    dispatch(startDataLoading(ClientsActionTypes.START_CLIENTS_LOADING))

    api
      .get(urlJoin(HISTORY_ENDPOINT, AUTH), createConfig(id, startDate, endDate))
      .then((res: AxiosResponse) => {
        if (res.status === 200) {
          dispatch({
            type: ClientsActionTypes.LOAD_CLIENT_HISTORY_SUCCESS,
            payload: res.data.data,
          })
        }
      })
      .catch((err: AxiosError) =>
        dispatch(handleLoadError(err, ClientsActionTypes.LOAD_CLIENTS_ERROR)),
      )
  }

// Get Client Item  History Statistics   GET

export const getClientHistoryStatistics =
  (id: string) => (dispatch: AppDispatch, getState: GetStateFunctionType, api: AxiosInstance) => {
    dispatch(startDataLoading(ClientsActionTypes.START_CLIENTS_LOADING))

    api
      .get(urlJoin(HISTORY_ENDPOINT, AUTH, STATS_ENDPOINT), createConfig(id))
      .then((res: AxiosResponse) => {
        if (res.status === 200) {
          dispatch({
            type: ClientsActionTypes.LOAD_CLIENT_HISTORY_STATS_SUCCESS,
            payload: res.data.data,
          })
        }
      })
      .catch((err: AxiosError) =>
        dispatch(handleLoadError(err, ClientsActionTypes.LOAD_CLIENTS_ERROR)),
      )
  }
