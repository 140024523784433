import { Pie } from '@ant-design/plots'
import { t as translate } from 'i18next'
import { useTranslation } from 'react-i18next'
import { FC, memo, useEffect } from 'react'

import { Color } from '../../styles'
import { PieDataType } from '../../api'

import { EQUIPMENT_STATUSES_KODES, INIT } from '../../constants'
import { EquipmentStatusesType, STATUSES_COLOR_MAP } from '../common'
import { getAllEquipment, getEquipmentStatuses } from '../../store/actions'
import {
  getCommonStatisticsPieConfig,
  getInteractions,
  getStatisticsPieContentOptions,
  getStatisticsPieTitleOptions,
  renderStatistics,
} from '../../statisticsPieCommonOptions'
import { getEquipment, getWorkspaces } from '../../store/selectors'
import { useTypedDispatch, useTypedSelector } from '../../hooks'

const getDefaultColors = () => [Color.green, Color.pink, Color.blue, Color.orange, Color.darkPurple]

const getEmptyStatistics = () => [
  { type: translate(EQUIPMENT_STATUSES_KODES.ON_READY), value: 0 },
  { type: translate(EQUIPMENT_STATUSES_KODES.DEFECTIVE), value: 0 },
  { type: translate(EQUIPMENT_STATUSES_KODES.IN_SERVICE), value: 0 },
  { type: translate(EQUIPMENT_STATUSES_KODES.ON_REPAIR), value: 0 },
  { type: translate(EQUIPMENT_STATUSES_KODES.DOWNTIME), value: 0 },
]

export type EquipmentStatusesPiePropsType = {
  multiplier: number
}

export const EquipmentStatusesPie: FC<EquipmentStatusesPiePropsType> = memo(({ multiplier }) => {
  const { equipment, statuses } = useTypedSelector(getEquipment)
  const { currentWorkspace, defaultWorkspaceId } = useTypedSelector(getWorkspaces)

  const { t } = useTranslation()

  const dispatch = useTypedDispatch()

  const colors: string[] = statuses?.length && equipment?.length ? [] : getDefaultColors()

  const getEquipmentAmount = (statusId: number) =>
    equipment ? equipment.filter((item) => item.statusId === statusId) : []

  const getStatusesData = (): PieDataType[] => {
    if (statuses?.length && equipment?.length) {
      return statuses.map(({ id, code }) => {
        if (code)
          colors.push(STATUSES_COLOR_MAP.equipment[code as keyof EquipmentStatusesType].brightColor)

        return {
          type: t(code),
          value: getEquipmentAmount(id).length || null,
        }
      })
    }

    return []
  }

  const config = {
    ...getCommonStatisticsPieConfig(t('short-items'), '{value}'),
    data: getStatusesData().length ? getStatusesData() : getEmptyStatistics(),
    legend: { type: 'continue', showFullLabel: true },
    color: colors,
    width: 220 * multiplier,
    height: 220,

    statistic: {
      title: {
        ...getStatisticsPieTitleOptions('16px', -4),
        customHtml: (container: HTMLElement, view: any, datum: any) => {
          const { width, height } = container.getBoundingClientRect()

          const d = Math.sqrt((width / 1.25) ** 2 + (height / 1.25) ** 2)

          const text = datum ? datum.type : t('all-items')

          return renderStatistics(d, text, {
            fontSize: 16,
          })
        },
      },

      content: {
        ...getStatisticsPieContentOptions('20px', 4),
        customHtml: (container: HTMLElement, view: any, datum: any, items: any) => {
          const { width } = container.getBoundingClientRect()

          const text = datum
            ? `${datum.value} ${t('short-items')}`
            : `${items.reduce((r: number, d: PieDataType) => r + d.value, 0)} ${t('short-items')}`

          return renderStatistics(width, text, {
            fontSize: 20,
          })
        },
      },
    },

    interactions: getInteractions(),
  }

  useEffect(() => {
    if (currentWorkspace || defaultWorkspaceId) {
      dispatch(getEquipmentStatuses())
      dispatch(getAllEquipment(INIT))
    }
  }, [dispatch, currentWorkspace, defaultWorkspaceId])
  /* eslint-disable */

  return <Pie {...config} />
})
