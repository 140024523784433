import { AxiosError } from 'axios'

import { API_ERROR_DETAILS, EMPTY_STRING, STATUS_CODES } from '../constants'
import { DEVELOPMENT_ORIGIN, isDevelopment, isProduction } from '../env'

export const setItemToLocalStorage = (key: string, value: string) =>
  localStorage.setItem(key, value)

export const getItemFromLocalStorage = (key: string) => localStorage.getItem(key)

export const setItemToSessionStorage = (key: string, value: string) =>
  sessionStorage.setItem(key, value)

export const getItemFromSessionStorage = (key: string) => sessionStorage.getItem(key)

export const removeItemFromSessionStorage = (key: string) => sessionStorage.removeItem(key)

export const removeItemFromLocalStorage = (key: string) => localStorage.removeItem(key)

export const getErrorStatus = (error: AxiosError) => error?.response?.status

export const getErrorStatusText = (error: AxiosError) => error?.response?.statusText

export const getErrorMessageText = (error: AxiosError) => error?.response?.data?.message

export const getErrorDetails = (error: AxiosError) => error?.response?.data?.detail

export const getResponseUrl = (error: AxiosError) => error?.response?.request?.responseURL

export const isNotFoundError = (error: AxiosError) =>
  getErrorStatus(error) === STATUS_CODES.CODE_404 || false

export const isAuthError = (error: AxiosError) =>
  getErrorStatus(error) === STATUS_CODES.CODE_401 || false

export const isLimitsError = (error: AxiosError) =>
  getErrorStatus(error) === STATUS_CODES.CODE_429 ||
  getErrorStatus(error) === STATUS_CODES.CODE_424 ||
  false

export const isFileSizeError = (error: AxiosError) =>
  getErrorStatus(error) === STATUS_CODES.CODE_413 || false

export const isAccessError = (error: AxiosError) =>
  getErrorStatus(error) === STATUS_CODES.CODE_403 || false

export const isIncorrectDataError = (error: AxiosError) =>
  getErrorStatus(error) === STATUS_CODES.CODE_400 || false

export const isServerError = (error: AxiosError) => {
  const status = getErrorStatus(error)

  if (status === 500 || status === 502 || status === 503 || status === 504) return true

  return false
}

export const isValidationError = (error: AxiosError) =>
  getErrorStatus(error) === STATUS_CODES.CODE_422 || false

export const isJwtVerificationFailed = (error: AxiosError) =>
  (getErrorStatus(error) === STATUS_CODES.CODE_401 &&
    (getErrorDetails(error) === API_ERROR_DETAILS.JWT_VERIFICATION_FAILED ||
      getErrorMessageText(error) === API_ERROR_DETAILS.JWT_VERIFICATION_FAILED ||
      getErrorStatusText(error) === API_ERROR_DETAILS.UNAUTHORIZED_USER_ERROR ||
      API_ERROR_DETAILS.INVALID_AUTORIZATION_HEADER)) ||
  getErrorDetails(error) === API_ERROR_DETAILS.INVALID_TOKEN ||
  getErrorDetails(error) === API_ERROR_DETAILS.MISSING_TOKEN

export const isTimeoutError = (err: AxiosError) =>
  err?.code && err.code === STATUS_CODES.ECONNABORTED

export const isUnknownError = (error: AxiosError) => !getErrorStatus(error) || false

export const isDevelopmentOrigin = () => {
  return window?.location?.origin ? window.location.origin === DEVELOPMENT_ORIGIN : false
}

export const isDevelopmentMode = () => isDevelopment(process.env) || isDevelopmentOrigin() || false

export const isProductionMode = () => isProduction(process.env)

export const isNeedSendNotification = (error: AxiosError) => {
  const status = getErrorStatus(error)

  if (isDevelopmentMode() && status !== 401) return true

  if (
    status === 500 ||
    status === 502 ||
    status === 503 ||
    status === 504 ||
    status === 404 ||
    status === 401 ||
    status === 403
  )
    return false

  return true
}

export const logError = (err: AxiosError) => {
  if (isDevelopmentMode()) {
    console.error(err)

    if (err && err.response) console.log(`!!! ERROR ===>`, err.response)
  }
}

export const log = (message: string) => {
  if (isDevelopmentMode()) console.info(message)
}

export const isNeedLogin = (error: AxiosError) => {
  const errorDetails = getErrorDetails(error)

  if (
    (error && errorDetails === API_ERROR_DETAILS.BAD_REFRESH_TOKEN) ||
    (error && errorDetails === API_ERROR_DETAILS.BAD_SESSION_ID) ||
    (error && errorDetails === API_ERROR_DETAILS.SESSION_DOES_NOT_EXIST) ||
    (error && isServerError(error))
  )
    return true

  return false
}

export const isNeedRefresh = (authError: AxiosError, accessToken: string) => {
  if (isServerError(authError) || (authError && !getErrorStatus(authError))) return false

  if ((!accessToken && !authError) || isJwtVerificationFailed(authError)) return true

  return false
}

export const getQueryParam = (name: string, value?: string) =>
  value ? `?${name}=${value}` : EMPTY_STRING
