import styled from 'styled-components'
import { Col, Layout, Row } from 'antd'

import { EMPTY_STRING } from '../../constants'
import { BoxShadow, Color, FontSize, FontWeight, Spacing } from '../../styles'

const { Header } = Layout

type StyledHeaderPropsType = {
  $boxShadow?: boolean
}

type StyledButtonWrapperPropsType = {
  $width?: string
}

type TitleWrapperPropsType = {
  $cursor?: boolean
  $mb?: string
}

export const StyledHeaderTitle = styled(Row)`
  color: ${Color.darkBlue};
  font-size: ${FontSize.lg};
  font-weight: ${FontWeight.bold};

  .extraIcon {
    margin: 0 4px 0 0 !important;
  }
`

export const StyledButtonWrapper = styled(Col)<StyledButtonWrapperPropsType>`
  button {
    width: ${(props) => (props.$width ? props.$width : '180px')} !important;
  }
`

export const TitleWrapper = styled(Col)<TitleWrapperPropsType>`
  display: flex;
  align-items: center;

  svg {
    margin-right: ${Spacing.sm16};
    margin-bottom: ${(props) => (props.$mb ? props.$mb : Spacing.sm4)};
    cursor: ${(props) => (props.$cursor ? 'pointer' : EMPTY_STRING)};
  }
`

export const StyledPageTitle = styled.span`
  margin-right: ${Spacing.sm16};
`

export const StyledHeader = styled(Header)<StyledHeaderPropsType>`
  background-color: ${Color.white};
  box-shadow: ${(props) => (props.$boxShadow ? BoxShadow.header : EMPTY_STRING)};
  height: 100px;
  padding: ${Spacing.md20};
`

export const StyledText = styled.span`
  top: -10px;
  color: ${Color.gray};
  font-size: ${FontSize.md};
`
