import { AxiosError } from 'axios'
import { t } from 'i18next'

import { AppDispatch } from '../../hooks'
import { getErrorMessage } from '../../api/notificationService'
import { RootState } from '../reducers/rootReducer'
import {
  AuthActionTypes,
  ErrorActionTypes,
  FilesActionTypes,
  ImagesActionTypes,
  WorkspacesActionTypes,
} from '../actionTypes'
import {
  CLIENTS_SISTEM_NOTIFICATIONS,
  COMMON_SISTEM_NOTIFICATIONS,
  DATA_TYPE,
  ERROR,
  FILES_SISTEM_NOTIFICATIONS,
  MESSAGE_TYPES,
  REFRESH_TOKEN,
  SESSION_ID,
  STATUS_CODES,
  USER_SISTEM_NOTIFICATIONS,
  WORKSPACES_SISTEM_NOTIFICATIONS,
} from '../../constants'
import {
  ErrorTypes,
  getErrorDetails,
  getErrorMessageText,
  getErrorStatus,
  isAccessError,
  isAuthError,
  isFileSizeError,
  isIncorrectDataError,
  isJwtVerificationFailed,
  isLimitsError,
  isNeedSendNotification,
  isServerError,
  isValidationError,
  logError,
} from '../../api'
import { getBaseLocation, getObjectslimit } from '../../functions'
import { sendLimitsErrorNotification, sendNotification } from '../../components/common/notification'

// import { refreshToken } from './auth'

export type GetErrorStateFunctionType = () => RootState

export const sendErrorNotification = (err: AxiosError) => {
  if (isNeedSendNotification(err) && !isLimitsError(err))
    sendNotification(
      MESSAGE_TYPES.ERROR,
      err.toString(),
      `Message: ${getErrorMessageText(err)}
       Details: ${getErrorDetails(err)}` || '',
    )
}

export const setError =
  (error: AxiosError | boolean | null, dataType: ErrorTypes) => (dispatch: AppDispatch) =>
    dispatch({
      type: ErrorActionTypes.SET_ERROR,
      payload: { dataType, error },
    })

// Delete error

export const deleteError = (actionType: string) => (dispatch: AppDispatch) =>
  dispatch({
    type: actionType,
    payload: null,
  })

// Limit error

export const handleLimitError =
  (type?: string) => (dispatch: AppDispatch, getState: GetErrorStateFunctionType) => {
    const maxLimit = getObjectslimit(getState().user.limits, DATA_TYPE.USER_SERVICES)
    const isFilesError =
      type === FilesActionTypes.LOAD_FILES_ERROR || type === ImagesActionTypes.LOAD_IMAGES_ERROR

    if (isFilesError)
      sendLimitsErrorNotification(t(USER_SISTEM_NOTIFICATIONS.USER_FILES_LIMIT_ERROR))

    if (!isFilesError)
      sendLimitsErrorNotification(t(USER_SISTEM_NOTIFICATIONS.USER_LIMIT_ERROR), maxLimit || 0)
  }

// Auth error

export const handleAuthError =
  (err: AxiosError) => (dispatch: AppDispatch, getState: GetErrorStateFunctionType) => {
    logError(err)

    const status = getErrorStatus(err)

    dispatch({
      type: AuthActionTypes.SET_AUTH_ERROR,
      payload: err,
    })

    if (isServerError(err)) dispatch(setError(err, ERROR.SERVER_ERR))

    //  * for HTTP *

    if (status === 401 && !isJwtVerificationFailed(err)) {
      setTimeout(() => {
        localStorage.removeItem(SESSION_ID)
        localStorage.removeItem(REFRESH_TOKEN)
      }, 1000)
    }

    setTimeout(() => {
      dispatch(deleteError(AuthActionTypes.SET_AUTH_ERROR))
    }, 3000)

    setTimeout(() => {
      dispatch(setError(null, ERROR.SERVER_ERR))
    }, 10000)
  }

// Loading Error

export const handleLoadError = (error: AxiosError, type: string) => (dispatch: AppDispatch) => {
  const isFilesError =
    type === FilesActionTypes.LOAD_FILES_ERROR || type === ImagesActionTypes.LOAD_IMAGES_ERROR

  const baseLocation = getBaseLocation(window.location.pathname)

  logError(error)

  if (isValidationError(error) && type !== AuthActionTypes.SET_TELEGRAM_BOT_ERROR) {
    sendNotification(MESSAGE_TYPES.ERROR, t(COMMON_SISTEM_NOTIFICATIONS.VALIDATION_ERROR))
    sendErrorNotification(error)

    setTimeout(() => dispatch(deleteError(type)), 1000)
  }

  if (isAuthError(error)) {
    if (isFilesError) dispatch({ type, payload: null })
    if (!isFilesError) {
      dispatch(handleAuthError(error))
      // test
      dispatch({ type, payload: null })
    }
  }

  if (isAccessError(error)) {
    const message =
      baseLocation === 'clients'
        ? t(CLIENTS_SISTEM_NOTIFICATIONS.ADD_CLIENT_ERROR)
        : t(COMMON_SISTEM_NOTIFICATIONS.ACCESS_DATA_ERROR)

    sendNotification(MESSAGE_TYPES.ERROR, message)
    sendErrorNotification(error)

    setTimeout(() => dispatch(deleteError(type)), 1000)
  }

  if (isIncorrectDataError(error)) {
    sendNotification(MESSAGE_TYPES.ERROR, t(COMMON_SISTEM_NOTIFICATIONS.INCORRECT_DATA_ERROR))
  }

  if (isLimitsError(error)) {
    dispatch(handleLimitError(type))
    dispatch(deleteError(type))
  }

  if (isFileSizeError(error)) {
    sendLimitsErrorNotification(t(FILES_SISTEM_NOTIFICATIONS.LOAD_LARGE_FILE_ERROR))
    dispatch(deleteError(type))
  }

  if (
    !isAuthError(error) &&
    !isAccessError(error) &&
    !isIncorrectDataError(error) &&
    !isLimitsError(error) &&
    !isFileSizeError(error) &&
    !isValidationError(error)
  ) {
    dispatch({ type, payload: error })
    sendErrorNotification(error)
    setTimeout(() => dispatch(deleteError(type)), 10000)
  }
}

export const dataExportError = (error: AxiosError) => (dispatch: AppDispatch) => {
  logError(error)

  if (isAuthError(error)) {
    dispatch(handleAuthError(error))
  } else {
    sendNotification(MESSAGE_TYPES.ERROR, t(COMMON_SISTEM_NOTIFICATIONS.EXPORT_DATA_ERROR))
  }
}

// Files Error // DELETE

export const filesError = (err: AxiosError, type: string) => (dispatch: AppDispatch) => {
  logError(err)

  if (isAuthError(err)) {
    dispatch({
      type,
      payload: null,
    })
  } else {
    dispatch({
      type,
      payload: err,
    })

    if (isLimitsError(err)) {
      sendLimitsErrorNotification(t(USER_SISTEM_NOTIFICATIONS.USER_FILES_LIMIT_ERROR))
      dispatch(deleteError(type))
    }

    if (isFileSizeError(err)) {
      sendLimitsErrorNotification(t(FILES_SISTEM_NOTIFICATIONS.LOAD_LARGE_FILE_ERROR))
      dispatch(deleteError(type))
    }

    if (!isLimitsError(err) && !isFileSizeError(err))
      sendNotification(MESSAGE_TYPES.ERROR, getErrorMessage(err))

    setTimeout(() => dispatch(deleteError(type)), 10000)
  }
}

export const handleInviteUsersError =
  (error: AxiosError, type: WorkspacesActionTypes) => (dispatch: AppDispatch) => {
    const errorStatus = getErrorStatus(error)

    if (errorStatus === STATUS_CODES.CODE_404) {
      sendNotification(
        MESSAGE_TYPES.ERROR,
        t(WORKSPACES_SISTEM_NOTIFICATIONS.MEMBER_NOT_FOUND_ERROR),
      )
    }

    if (errorStatus === STATUS_CODES.CODE_409) {
      sendNotification(MESSAGE_TYPES.ERROR, t(WORKSPACES_SISTEM_NOTIFICATIONS.MEMBER_EXIST_ERROR))
    }

    if (errorStatus === STATUS_CODES.CODE_404) {
      sendNotification(
        MESSAGE_TYPES.ERROR,
        t(WORKSPACES_SISTEM_NOTIFICATIONS.MEMBER_NOT_FOUND_ERROR),
      )
      dispatch(handleLoadError(error, type))
    }
  }

export const handleNotExistingUserError = (error: AxiosError) => (dispatch: AppDispatch) => {
  const errorStatus = getErrorStatus(error)

  if (errorStatus === STATUS_CODES.CODE_409) {
    const errorDetails = getErrorDetails(error)

    if (errorDetails === 'ALREADY_EXISTS')
      sendNotification(
        MESSAGE_TYPES.ERROR,
        t(CLIENTS_SISTEM_NOTIFICATIONS.CLIENT_ALREADY_EXISTS_ERROR),
      )
  }

  if (errorStatus === STATUS_CODES.CODE_404)
    sendNotification(MESSAGE_TYPES.ERROR, t(CLIENTS_SISTEM_NOTIFICATIONS.NO_CLIENT_ERROR))
}
