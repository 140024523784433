import { transformKeysToCamelCase } from '../api/converters'
import {
  AccessTokenDecodedObjectType,
  getItemFromLocalStorage,
  ICompany,
  IWorkspace,
  UserLimitsTariffPlanType,
  UserType,
} from '../api'
import {
  CONTENT_TYPE_APPLICATION_JSON,
  DEFAULT_USER_SCOPE_ID_KEY,
  SCOPE_ID,
  USER_SCOPE_ID_KEY,
} from '../constants'

export const getAuthorizationHeaders = (token: string | null, contentType?: string) => {
  return {
    headers: {
      'Content-Type': contentType,
    },
  }
}

export const getJsonHeaders = () => ({
  headers: {
    'Content-Type': CONTENT_TYPE_APPLICATION_JSON,
  },
})

export const parseJwt = (token: string): AccessTokenDecodedObjectType | undefined => {
  if (token) {
    const base64Url = token.split('.')[1]
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')

    const jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split('')
        .map((c) => {
          /* eslint-disable-next-line */
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
        })
        .join(''),
    )

    return transformKeysToCamelCase(JSON.parse(jsonPayload))
  }

  return undefined
}

export const getUserTariffPlan = (user: UserType): UserLimitsTariffPlanType =>
  user?.subscriptionPlan
    ? (user.subscriptionPlan.toUpperCase() as UserLimitsTariffPlanType)
    : 'DEFAULT'

export const getWorkspaceTariffPlan = (
  token: string,
  currentScope: IWorkspace,
): UserLimitsTariffPlanType => {
  if (currentScope && token) {
    return getTokenInfo(token).relations[
      currentScope.ownerId
    ]?.subscriptionPlan?.toUpperCase() as UserLimitsTariffPlanType
  }

  return 'FREE'
}

export const getAccessTokenLifeTime = (accessToken: string) => {
  if (accessToken) {
    const tokenData = parseJwt(accessToken)
    const tokenLifeTime = new Date().getTime() - new Date(tokenData.exp * 1e3).getTime()

    return Math.abs(Math.floor(tokenLifeTime)) - 40000
  }

  return 80000
}

export const getWorkspaceId = (workspaceItem: IWorkspace, accessToken: string) => {
  if (workspaceItem) return workspaceItem.id

  if (accessToken) {
    const userInfo = parseJwt(accessToken)

    if (userInfo) {
      const scopes = userInfo?.relations[userInfo.id]?.scopes

      return scopes ? Number(Object.keys(scopes)[0]) : null
    }
  }

  return null
}

export const getTokenInfo = (accessToken: string) => {
  const userInfo = parseJwt(accessToken)

  return userInfo || null
}

export const getUserWorkspaceStorageKey = (userId: number) => `${USER_SCOPE_ID_KEY}-${userId}`

export const getUserWorkspaceDefaultStorageKey = (userId: number) =>
  `${DEFAULT_USER_SCOPE_ID_KEY}-${userId}`

export const getWorkspaceIdFromStorage = (userId: number) => {
  const userWorkspaceId = getItemFromLocalStorage(getUserWorkspaceStorageKey(userId))

  const defaultWorkspaceId = getItemFromLocalStorage(getUserWorkspaceDefaultStorageKey(userId))

  return userWorkspaceId || defaultWorkspaceId
}

export const isOwner = (ownerId: number, user: UserType) => ownerId === user?.id

export const getCompanyId = (scopeId: number, workspaces: IWorkspace[]) => {
  const [scope] = workspaces ? workspaces.filter((i) => i.id === scopeId) : []

  return scope?.companyId
}

export const getCompanyLogoUrl = (companyItem: ICompany) => companyItem?.logoUrl

export const getCompanyName = (id: number, companies: ICompany[]) => {
  const [companyItem] = id && companies ? companies.filter((i) => i.id === id) : []

  return companyItem?.name || 'Моя компания'
}

export const getCompanyLogo = (id: number, companies: ICompany[]) => {
  const [companyItem] = id && companies ? companies.filter((i) => i.id === id) : []

  return companyItem?.logoUrl || ''
}

export const getScopeIdQuery = (scopeId: number | string | null) => {
  if (!scopeId) return ''

  return `?${SCOPE_ID}=${scopeId}`
}

export const buildUrl = (baseUrl: string, parameter: string) =>
  baseUrl && parameter ? `/${baseUrl}${parameter}` : baseUrl

export const getUserWorkspaceFromToken = (token: string) => {
  const userInfo = parseJwt(token)

  if (userInfo) {
    const scopes = userInfo.relations[userInfo.id]?.scopes

    if (scopes && Object.keys(scopes)?.length) {
      const [key] = Object.keys(scopes)

      return key
    }
  }

  return null
}

export const getCurrentWorkspaceId = (userId: number, idFromState?: number) => {
  return idFromState?.toString() || getWorkspaceIdFromStorage(userId) || ''
}

export const isDistributor = (accessToken: string) => {
  if (accessToken) {
    const token = getTokenInfo(accessToken)

    const [userId] = Object.keys(token.relations).filter((i: any) => Number(i) !== token.id)

    if (userId) {
      const [scope] = Object.keys(token.relations[userId]?.scopes)

      if (scope) return token.relations[userId]?.scopes[scope]?.role === 'distributor'
    }
  }

  return false
}
