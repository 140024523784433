import urlJoin from 'url-join'
import { useTranslation } from 'react-i18next'
import { FC, useCallback, useEffect, useState } from 'react'
import { Radio, RadioChangeEvent, Row, Table } from 'antd'
import { useLocation, useParams } from 'react-router-dom'

import { EmptyTable } from './commonComponents/EmptyTable'
import { ExpandedIcon } from './commonComponents/ExpandedIcon'
import { getItemsSelectTableColumns } from './columns/itemSelectTableColumns'
import { getRowClassName } from './helpers'
import { PlacesColumnsDataType } from '../../api'
import { prepareSearchValue } from './helpers/sorter'
import { SearchForm } from '../forms'
import { sendNotification } from '../common/notification'
import { Spacing } from '../../styles'
import { StyledTableDivider } from '../common'
import { TableWrapper } from './commonComponents/styled'
import {
  CREATE,
  CURRENT_LOCATION,
  DATA_TYPE,
  MESSAGE_TYPES,
  PLACES_SISTEM_NOTIFICATIONS,
} from '../../constants'
import { getFilters, getPlaces, getTemporaryData } from '../../store/selectors'
import { setTemporaryDataIds, setTemporaryPlaceAddress } from '../../store/actions'
import { useTypedDispatch, useTypedSelector } from '../../hooks'

export type PlaceSelectTablePropsType = {
  data?: PlacesColumnsDataType[]
  selectedPlaceId?: number
}

export const PlaceSelectTable: FC<PlaceSelectTablePropsType> = ({ data, selectedPlaceId }) => {
  const [checked, setChecked] = useState(false)
  const [selectedKey, setSelectedKey] = useState(null)

  const { places } = useTypedSelector(getPlaces)
  const { searchValue } = useTypedSelector(getFilters)
  const { tempIds } = useTypedSelector(getTemporaryData)

  const { locationId } = useParams<{ locationId?: string }>()

  const { pathname } = useLocation()

  const { t } = useTranslation()

  const isCreatePage = pathname.split('/')[2] === CREATE

  const currentLocation = pathname.split('/')[1]

  const dispatch = useTypedDispatch()

  const getPlaceItem = (key: number) => {
    const [place] = places ? places.filter((item) => item.id === key) : []

    return place
  }

  const handleSelectRow = (key: number) => {
    if (locationId && Number(locationId) === key) {
      sendNotification(MESSAGE_TYPES.ERROR, t(PLACES_SISTEM_NOTIFICATIONS.PLACE_SELECTION_ERROR))
    } else {
      const placeParentItem = getPlaceItem(key)

      setChecked(false)
      setSelectedKey(key)

      dispatch(setTemporaryPlaceAddress(placeParentItem ? placeParentItem.address : null))

      dispatch(setTemporaryDataIds(DATA_TYPE.PLACES, [key]))
    }
  }

  const resetState = () => {
    setSelectedKey(null)
    setChecked(true)
  }

  const handleOnChange = (e: RadioChangeEvent) => {
    setChecked(e.target.checked)
    setSelectedKey(null)

    dispatch(setTemporaryPlaceAddress(undefined))

    dispatch(setTemporaryDataIds(DATA_TYPE.PLACES, null))
  }

  const getInitialPlaceSelectItem = useCallback(() => {
    if ((isCreatePage && !tempIds.places) || (!isCreatePage && !selectedPlaceId && !tempIds.places))
      resetState()

    if (!isCreatePage && selectedPlaceId && tempIds.places === undefined)
      setSelectedKey(selectedPlaceId)

    if (isCreatePage && tempIds.places?.length) setSelectedKey(tempIds.places[0])
  }, [isCreatePage, tempIds, selectedPlaceId])

  const handleSearchRow = (key: number) =>
    getRowClassName(places, key, prepareSearchValue(searchValue.places))

  useEffect(() => getInitialPlaceSelectItem(), [getInitialPlaceSelectItem])

  return (
    <TableWrapper>
      <Row align="middle">
        <Radio checked={checked} onChange={handleOnChange} style={{ marginLeft: Spacing.sm8 }}>
          {t('no-selections')}
        </Radio>
        <StyledTableDivider orientation="center" $mt={Spacing.sm12} $mb={Spacing.sm8} />
      </Row>

      <Row align="middle">
        <SearchForm $isModal dataType={DATA_TYPE.PLACES} />
        <StyledTableDivider orientation="center" $mt={Spacing.sm8} $mb={Spacing.sm12} />
      </Row>

      <Table
        columns={getItemsSelectTableColumns([selectedKey])}
        dataSource={data || []}
        bordered={false}
        pagination={false}
        scroll={
          data.length > 6 && !searchValue
            ? { y: '350px' }
            : places.length > 6 && searchValue
            ? { y: '350px' }
            : {}
        }
        showHeader={false}
        rowClassName={(record) => handleSearchRow(record.key)}
        onRow={(record) => ({
          onClick: () => handleSelectRow(record.key),
        })}
        locale={{
          emptyText: (
            <EmptyTable
              prompt={t('no-items')}
              hasButton={Boolean(
                places && places.length && currentLocation !== CURRENT_LOCATION.LOCATIONS,
              )}
              navigationPath={urlJoin(DATA_TYPE.PLACES, CREATE)}
            />
          ),
        }}
        expandable={{
          defaultExpandAllRows: true,
          indentSize: 20,
          expandIcon: ({ expanded, onExpand, record }) => (
            <ExpandedIcon expanded={expanded} record={record} onExpand={onExpand} />
          ),
        }}
        style={{ minWidth: '100%', margin: '0' }}
      />
    </TableWrapper>
  )
}
