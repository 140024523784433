import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { FC, useCallback, useEffect, useState } from 'react'
import { Row, Table } from 'antd'

import { EmptyTable } from './commonComponents/EmptyTable'
import { ExpandedIcon } from './commonComponents/ExpandedIcon'
import { getItemsSelectTableColumns } from './columns/itemSelectTableColumns'
import { getRowClassName } from './helpers'
import { MaterialsColumnsDataType } from '../../api'
import { prepareSearchValue } from './helpers/sorter'
import { SearchForm } from '../forms'
import { setTemporaryDataIds } from '../../store/actions'
import { Spacing } from '../../styles'
import { StyledTableDivider } from '../common'
import { TableWrapper } from './commonComponents/styled'
import { CREATE, DATA_TYPE } from '../../constants'
import { getFilters, getMaterials, getTasks, getTemporaryData } from '../../store/selectors'
import { useTypedDispatch, useTypedSelector } from '../../hooks'

export type MaterialsSelectTablePropsType = {
  data?: MaterialsColumnsDataType[]
}

export const MaterialsSelectTable: FC<MaterialsSelectTablePropsType> = ({ data }) => {
  const [selectedKeys, setSelectedKeys] = useState([])

  const { materials } = useTypedSelector(getMaterials)
  const { taskItem } = useTypedSelector(getTasks)

  const { tempIds } = useTypedSelector(getTemporaryData)
  const { searchValue } = useTypedSelector(getFilters)

  const dispatch = useTypedDispatch()

  const { t } = useTranslation()

  const { equipmentId } = useParams<{ equipmentId?: string }>()
  const { taskId } = useParams<{ taskId?: string }>()

  const getLinkedEquipmentMaterialIds = useCallback(
    () =>
      materials
        .map((item) => {
          if (item.equipments.length) {
            const [eq] = item.equipments.filter((i) => i.id === Number(equipmentId))
            return eq ? item.id : null
          }
          return null
        })
        .filter((i) => i),
    [equipmentId, materials],
  )

  const getLinkedTaskMaterialIds = useCallback(() => {
    if (taskItem && taskItem.materials && taskItem.materials.length)
      return taskItem.materials.map((i) => i.id)

    return []
  }, [taskItem])

  const handleSelectItems = (sKey: number) => {
    if (equipmentId && getLinkedEquipmentMaterialIds().includes(sKey)) return
    if (taskId && getLinkedTaskMaterialIds().includes(sKey)) return

    if (selectedKeys.includes(sKey)) {
      setSelectedKeys(selectedKeys.filter((i) => i !== sKey))
    } else {
      setSelectedKeys([...selectedKeys, sKey])
    }
  }

  const handleDispatchSelectedItems = (sKey: number) => {
    if (equipmentId && getLinkedEquipmentMaterialIds().includes(sKey)) return
    if (taskId && getLinkedTaskMaterialIds().includes(sKey)) return

    if (tempIds.materials && tempIds.materials.length && tempIds.materials.includes(sKey))
      dispatch(
        setTemporaryDataIds(
          DATA_TYPE.MATERIALS,
          tempIds.materials.filter((i) => i !== sKey),
        ),
      )

    if (tempIds.materials && tempIds.materials.length && !tempIds.materials.includes(sKey))
      dispatch(setTemporaryDataIds(DATA_TYPE.MATERIALS, [...tempIds.materials, sKey]))

    if (!tempIds.materials || !tempIds.materials.length)
      dispatch(setTemporaryDataIds(DATA_TYPE.MATERIALS, [sKey]))
  }

  const handleSelectItem = (sKey: number) => {
    handleSelectItems(sKey)
    handleDispatchSelectedItems(sKey)
  }

  const getInitialSelectItems = useCallback(() => {
    if (materials && materials.length && !tempIds.materials) {
      if (equipmentId) setSelectedKeys(getLinkedEquipmentMaterialIds().map((i) => i))

      if (taskId) setSelectedKeys(getLinkedTaskMaterialIds().map((i) => i))
    }
  }, [
    getLinkedEquipmentMaterialIds,
    getLinkedTaskMaterialIds,
    materials,
    tempIds.materials,
    equipmentId,
    taskId,
  ])

  const handleSearchRow = (key: number) => {
    return getRowClassName(materials, key, prepareSearchValue(searchValue.materials))
  }

  useEffect(() => getInitialSelectItems(), [getInitialSelectItems])

  return (
    <TableWrapper>
      <Row align="middle">
        <SearchForm $isModal dataType={DATA_TYPE.MATERIALS} />
        <StyledTableDivider orientation="center" $mt={Spacing.sm8} $mb={Spacing.sm12} />
      </Row>

      <Table
        columns={getItemsSelectTableColumns(selectedKeys)}
        dataSource={data || []}
        bordered={false}
        pagination={false}
        scroll={data.length > 6 ? { y: '350px' } : {}}
        showHeader={false}
        rowClassName={(record) => handleSearchRow(record.key)}
        onRow={(record) => ({
          onClick: () => handleSelectItem(record.key),
        })}
        locale={{
          emptyText: (
            <EmptyTable
              prompt={t('no-items')}
              hasButton
              navigationPath={`/${DATA_TYPE.MATERIALS}/${CREATE}`}
            />
          ),
        }}
        expandable={{
          defaultExpandAllRows: true,
          indentSize: 20,
          expandIcon: ({ expanded, onExpand, record }) => (
            <ExpandedIcon expanded={expanded} record={record} onExpand={onExpand} />
          ),
        }}
        style={{ minWidth: '100%', margin: '0' }}
      />
    </TableWrapper>
  )
}
