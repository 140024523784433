import { t } from 'i18next'

import { IAdmission, IStatus, PrioritiesType } from '../types'

export const getStatus = (id: number, statuses: IStatus[]) => {
  if (statuses) {
    const [status] = statuses.filter((i) => i.id === id)

    return status
  }

  return null
}

export const sortEquipmentStatuses = (statuses: IStatus[]) => {
  if (statuses) return statuses.sort((a, b) => (a.importanceLevel > b.importanceLevel ? 1 : -1))

  return []
}

export const sortTaskStatuses = (statuses: IStatus[]) => {
  if (statuses) return statuses.sort((a, b) => (a.importanceLevel > b.importanceLevel ? 1 : -1))

  return []
}

export const sortUserServiceStatuses = (statuses: IStatus[]) => {
  if (statuses) return statuses.sort((a, b) => (a.importanceLevel > b.importanceLevel ? 1 : -1))

  return []
}

export const sortUserServiceAdmissions = (admissions: IAdmission[]) => {
  if (admissions)
    return admissions
      .map((item) => ({
        ...item,
        name: t(item.nameEn),
      }))
      .sort((a, b) => (a.importanceLevel > b.importanceLevel ? 1 : -1))

  return []
}

export const convertTaskPriorities = (priorities: PrioritiesType[]) => {
  if (priorities)
    return priorities
      .map((item) => ({
        ...item,
        name: t(item.nameEn),
      }))
      .sort((a, b) => (a.importanceLevel > b.importanceLevel ? 1 : -1))

  return []
}
