import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Form, Formik } from 'formik'
import { Row, Typography } from 'antd'

import { EMAIL_VALIDATION_SCHEMA } from '../../components/forms'
import { EmailType } from '../../api/authTypes'
import { getAuth } from '../../store/selectors'
import { Page } from '../page'
import { SuccessPage } from '../service'
import { VkSetEmail } from '../../store/actions/auth'
import { EMPTY_STRING, ERROR_STRING, LOGIN, STATUS_CODES, TOKEN } from '../../constants'
import {
  FormWrapper,
  Logo,
  LogoWrapper,
  NotificationPannel,
  PlannyInput,
  PlannyLabel,
  RequiredFieldStarLabel,
  StyledButton,
  StyledCol,
  StyledFieldError,
} from '../../components/common'
import { useQuery, useTypedDispatch, useTypedSelector } from '../../hooks'

const { Title } = Typography

export const VkAuthenticationPage: FC = () => {
  const { status, authError } = useTypedSelector(getAuth)

  const query = useQuery()

  const token = query.get(TOKEN)

  const { t } = useTranslation()

  const dispatch = useTypedDispatch()

  const getInitialValues = (): EmailType => {
    return {
      email: EMPTY_STRING,
    }
  }

  const handleSendEmail = ({ email }: EmailType) => {
    if (token) dispatch(VkSetEmail({ email: email.trim(), token }))
  }

  if (status && status === STATUS_CODES.CODE_200) {
    return (
      <SuccessPage
        title={t('successful-registration-message')}
        subTitle={t('successful-registration-prompt')}
        redirectLink={LOGIN}
        redirectTime={7000}
      />
    )
  }

  return (
    <Page pageTitle={t('send-email-page-title')} authLayout>
      <>
        <LogoWrapper justify="center">
          <Logo $isSider={false} />
        </LogoWrapper>
        <>
          {authError && <NotificationPannel error={authError} />}

          <Formik
            validationSchema={EMAIL_VALIDATION_SCHEMA}
            validateOnBlur
            validateOnChange
            initialValues={getInitialValues()}
            onSubmit={(values) => handleSendEmail(values)}
          >
            {({ values, setFieldValue, errors, touched, handleBlur, isValid }) => (
              <FormWrapper justify="center">
                <Form>
                  <Row justify="center">
                    <StyledCol>
                      <Title level={3}> {t('send-email-page-title')}</Title>
                    </StyledCol>
                  </Row>

                  <StyledCol>
                    <PlannyLabel>
                      {t('input-label-email-prompt')}
                      <RequiredFieldStarLabel> * </RequiredFieldStarLabel>
                    </PlannyLabel>

                    <PlannyInput
                      name="email"
                      value={values.email}
                      onChange={(e) => setFieldValue('email', e.target.value)}
                      onBlur={(e) => handleBlur(e)}
                      style={{ marginBottom: '8px' }}
                      status={errors.email && touched.email && ERROR_STRING}
                    />

                    {errors.email && touched.email ? (
                      <StyledFieldError>{errors.email}</StyledFieldError>
                    ) : null}
                  </StyledCol>

                  <StyledCol>
                    <StyledButton type="primary" block htmlType="submit" disabled={!isValid}>
                      {t('registration-button')}
                    </StyledButton>
                  </StyledCol>
                </Form>
              </FormWrapper>
            )}
          </Formik>
        </>
      </>
    </Page>
  )
}
