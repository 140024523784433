import { FC, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { getAuth } from '../../store/selectors'
import { Loader } from '../../components/common'
import { refreshToken } from '../../store/actions'
import {
  CHANNELS,
  HOME_URL,
  REFRESH_TOKEN,
  SESSION_ID,
  SW_REGISTRATION_ERROR,
} from '../../constants'
import {
  getErrorDetails,
  getItemFromLocalStorage,
  setItemToLocalStorage,
} from '../../api/functions'
import { useQuery, useTypedDispatch, useTypedSelector } from '../../hooks'

export const SsoAuthorizationPage: FC = () => {
  const { accessToken, authError } = useTypedSelector(getAuth)

  const ssoLoginChannel = new BroadcastChannel(CHANNELS.SSO_LOGIN_CHANNEL)
  const sendPathChannel = new BroadcastChannel(CHANNELS.SEND_PATH_CHANNEL)

  const isServiceWorkerError = JSON.parse(getItemFromLocalStorage(SW_REGISTRATION_ERROR))

  const dispatch = useTypedDispatch()

  const navigate = useNavigate()

  const query = useQuery()

  const { pathname } = useLocation()

  const path = pathname.split('/')[1]

  const ssoRefToken = query.get(REFRESH_TOKEN)
  const ssoSessionId = query.get(SESSION_ID)

  const errorDetails = getErrorDetails(authError)

  useEffect(() => {
    sendPathChannel.postMessage(path)

    if (ssoRefToken && ssoSessionId && !isServiceWorkerError) {
      sendPathChannel.postMessage(path)

      ssoLoginChannel.postMessage({ sessionId: ssoSessionId, refToken: ssoRefToken })
    }

    // * for HTTP *
    if (ssoRefToken && ssoSessionId && isServiceWorkerError) {
      setItemToLocalStorage(REFRESH_TOKEN, ssoRefToken)
      setItemToLocalStorage(SESSION_ID, ssoSessionId)

      dispatch(refreshToken())
    }

    // * for HTTP *

    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (accessToken && !authError && !errorDetails) {
      navigate(HOME_URL)
    }
  }, [navigate, accessToken, errorDetails, authError])

  return <Loader />
}
