import { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Button, Result } from 'antd'

import { HOME_URL } from '../../constants'
import { Page } from '../page'
import { PlannyLogo } from '../../components/common'

export const NotFound: FC = () => {
  const navigate = useNavigate()

  const { t } = useTranslation()

  const handleGoBack = () => navigate(HOME_URL)

  return (
    <Page pageTitle={t('not-found-pageTitle')} authLayout>
      <Result
        title="404"
        subTitle={t('not-found-page')}
        icon={
          <PlannyLogo>
            <img src="/images/p24_err.svg" alt="err" />
          </PlannyLogo>
        }
        extra={
          <Button type="primary" onClick={handleGoBack}>
            {t('go-back-button')}
          </Button>
        }
      />
    </Page>
  )
}
