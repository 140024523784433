import styled from 'styled-components'
import { Typography } from 'antd'
import { useTranslation } from 'react-i18next'
import { CSSProperties, FC, memo, ReactNode, useCallback, useEffect } from 'react'

import { Color } from '../../../styles'
import { StyledTag } from '../../common'
import { TASK_STATUSES_CODES } from '../../../constants'

import 'moment/dist/locale/ru'
import 'moment/dist/locale/en-ca'

const TOOLTIP_CONTENT = 'tooltip-content'

const { Text } = Typography

const StyledText = styled.span`
  display: none;
`

const StyledBlock = styled.div`
  display: none;
`

export type TableCellContentPropsType = {
  text?: string
  tooltipText?: string
  editIcon?: ReactNode
  isLink?: boolean
  isTag?: boolean
  isStatus?: boolean
  tagColor?: string
  isStrong?: boolean
  $className?: string
  style?: CSSProperties
}

export const TableCellContent: FC<TableCellContentPropsType> = memo(
  ({
    text,
    isLink = false,
    isTag = false,
    editIcon,
    isStatus,
    tagColor,
    isStrong,
    $className,
    tooltipText,
    style,
  }) => {
    const { t } = useTranslation()

    const isLongTag = text && text.length > 10

    const getClassName = (clName: string, additionClass?: string) => {
      if (clName && additionClass) return `${additionClass} ${$className}`

      return $className ? `${$className}` : undefined
    }

    const createTooltip = useCallback(
      (clsName: string) => {
        const cellElements = Array.from(document.querySelectorAll(`.${clsName}`))

        if (cellElements && cellElements.length) {
          cellElements.forEach((elem) => {
            const closestTdElement = elem.closest(isTag ? 'span' : 'td')

            if (closestTdElement) closestTdElement.setAttribute('title', elem.textContent)
          })
        }
      },
      [isTag],
    )

    useEffect(() => {
      const className = tooltipText ? TOOLTIP_CONTENT : $className

      createTooltip(className)
    }, [createTooltip, tooltipText, $className])

    if (isLink)
      return (
        <Text
          strong={isStrong}
          className={getClassName($className || TOOLTIP_CONTENT, 'planny-link')}
          style={style}
        >
          {text}

          {tooltipText ? <StyledText className={TOOLTIP_CONTENT}>{tooltipText}</StyledText> : null}
        </Text>
      )

    if (isStatus)
      return (
        <StyledTag
          color={tagColor}
          style={{
            color: Color.black,
            border: text === t(TASK_STATUSES_CODES.ARСHIVE) ? '1px solid #E1E1E1' : 'none',
          }}
          className={getClassName($className)}
        >
          {text}
        </StyledTag>
      )

    if (isTag) {
      return (
        <StyledTag
          $isTable
          color={tagColor}
          className={getClassName($className || TOOLTIP_CONTENT)}
        >
          {isLongTag ? `${text.slice(0, 10)}...` : text}

          {tooltipText ? <StyledBlock className={TOOLTIP_CONTENT}>{text}</StyledBlock> : null}
        </StyledTag>
      )
    }

    if (editIcon && text) {
      return (
        <>
          <Text strong={isStrong} className={getClassName($className)} style={style}>
            {text}

            {tooltipText ? (
              <StyledText className={TOOLTIP_CONTENT}>{tooltipText}</StyledText>
            ) : null}
          </Text>
          {'   '} {editIcon}
        </>
      )
    }

    return (
      <Text strong={isStrong} className={getClassName($className)} style={style}>
        {text}

        {tooltipText ? <StyledText className={TOOLTIP_CONTENT}>{tooltipText}</StyledText> : null}
      </Text>
    )
  },
)
