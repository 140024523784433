import { LOCAL_STORAGE_CACHE_KEYS } from '../components/forms'
import { CURRENCY_ABBR, CURRENCY_LABELS, DASH, EMPTY_STRING } from '../constants'
import {
  DataTypes,
  IdObject,
  IEquipment,
  IMaterialCurrencies,
  IMeasurementUnits,
  IPlaces,
  removeItemFromLocalStorage,
  TemporaryIdsType,
} from '../api'

export const parseFloatNumber = (value: string) => {
  if (value && value !== '0')
    return Number(value.toString().replace(/\s+/g, '').replace(/^0+/, '').replace(/,/, '.'))

  return 0
}

export const parseNumber = (value: number) => {
  if (value && value !== 0) return Number(value.toString().replace(/\s+/g, ''))

  return 0
}

export const getMeasurementUnit = (id: number, measurements: IMeasurementUnits[]) => {
  if (id && measurements) {
    const [measurementUnit] = measurements.filter((unit) => unit.id === id)

    if (measurementUnit && measurementUnit.id === 7) return measurementUnit.symbol || DASH

    return measurementUnit && measurementUnit.shortName
      ? measurementUnit.shortName
      : measurementUnit.name
  }

  return EMPTY_STRING
}

export const getUnitPriceSelectOptions = (currencies: IMaterialCurrencies[]) => {
  if (currencies) {
    return currencies.map(({ id, code }) => {
      if (code === CURRENCY_ABBR.RUB)
        return { id, label: CURRENCY_LABELS.RUB, optionValue: CURRENCY_LABELS.RUB }
      if (code === CURRENCY_ABBR.USD)
        return { id, label: CURRENCY_LABELS.USD, optionValue: CURRENCY_LABELS.USD }

      return { id, label: CURRENCY_LABELS.POUND, optionValue: CURRENCY_LABELS.POUND }
    })
  }
  return [{ id: 1, label: CURRENCY_LABELS.RUB, optionValue: CURRENCY_LABELS.RUB }]
}

export const getUnitCountSelectOptions = (measurementUnits: IMeasurementUnits[]) => {
  if (measurementUnits) {
    return measurementUnits.map(({ id, name }) => ({ id, label: name, optionValue: name }))
  }

  return [{ id: 1, label: 'шт', optionValue: 'шт' }]
}

export const getOperationValueSelectOptions = (measurementUnits: IMeasurementUnits[]) => {
  if (measurementUnits) {
    return measurementUnits.map(({ id, name, shortName }) => ({
      id,
      label: shortName || name,
      optionValue: shortName || name,
    }))
  }

  return [{ id: 1, label: 'м³', optionValue: 'м³' }]
}

export const getReadingPeriodValueSelectOptions = (readindPeriods: IMeasurementUnits[]) => {
  if (readindPeriods) {
    return readindPeriods.map(({ id, name }) => ({ id, label: name, optionValue: name }))
  }

  return [{ id: 1, label: 'день', optionValue: 'день' }]
}

export const parseDate = (date: string) => {
  const partDate = date ? date.split(DASH) : []

  return partDate.length > 0 ? `${partDate[2]}.${partDate[1]}.${partDate[0]}` : EMPTY_STRING
}

// clean storage from deleted data

export const cleanFormsCache = (dataArray: (IPlaces | IEquipment)[], localStorageKey: string) => {
  const formKeys = Object.keys(localStorage)
    .filter((i) => i.startsWith(localStorageKey))
    .map((i) => Number(i.split(':')[1]))

  const existingDataIds = dataArray ? dataArray.map((i) => i.id) : []

  formKeys.forEach((item) => {
    if (!existingDataIds.includes(item)) removeItemFromLocalStorage(`${localStorageKey}:${item}`)
  })
}

// clean local storage from cached data

export const cleanCache = () => {
  LOCAL_STORAGE_CACHE_KEYS.forEach((key) => {
    const formKeys = Object.keys(localStorage).filter((i) => i.startsWith(key))

    formKeys.forEach((formKey) => {
      removeItemFromLocalStorage(formKey)
    })
  })
}

export const isEmptyObject = (obj: any) => {
  if (!obj || (obj && !Object.values(obj).filter((i) => i).length)) return true

  return false
}

export const getTemporaryId = (key: DataTypes, tempIds: TemporaryIdsType) => {
  if (tempIds[key] === null) return null

  return tempIds[key] && tempIds[key].length ? tempIds[key][0] : undefined
}

export const getTemporaryIdArray = (key: DataTypes, tempIds: TemporaryIdsType) => {
  if (tempIds.equipment === null) return null

  return tempIds[key] && tempIds[key].length ? tempIds[key] : undefined
}

export const getArraysDifference = (initialArray: IdObject[], modifiedArray: number[]) => {
  if (initialArray && modifiedArray) {
    const deleted = initialArray
      .map((item) => (!modifiedArray.includes(item.id) ? item.id : undefined))
      .filter((i) => i)

    return deleted.length ? deleted : null
  }

  return null
}
