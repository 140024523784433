import { FC } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Row, Timeline } from 'antd'

import { getBaseLocation } from '../../../functions'
import { IPlaces } from '../../../api'
import { resetTemporaryPlaceIds } from '../../../store/actions'
import { Spacing } from '../../../styles'
import { StyledCardText } from '../../common'
import { getPlaces, getTemporaryData } from '../../../store/selectors'
import { useTypedDispatch, useTypedSelector } from '../../../hooks'

export type PlacesStructurePropsType = {
  prompt: string
}

export const PlacesStructure: FC<PlacesStructurePropsType> = ({ prompt }) => {
  const { places, placeItem } = useTypedSelector(getPlaces)
  const { tempIds } = useTypedSelector(getTemporaryData)

  const { pathname } = useLocation()

  const currentLocation = getBaseLocation(pathname)

  const dispatch = useTypedDispatch()

  const getParents = () => {
    let parents: IPlaces[] = []

    const getParent = (id: number) => places.filter((item: IPlaces) => item.id === id)

    const findParents = (obj: IPlaces) => {
      if (obj && obj.parentId) {
        const [parent] = getParent(obj.parentId)
        parents = [parent, ...parents]

        findParents(parent)
      }
    }

    if (places.length && !tempIds.places) {
      findParents(placeItem)

      return parents.length ? parents : []
    }

    const [parent] = places.filter((item: IPlaces) => item.id === tempIds.places[0])

    findParents(parent)

    if (!parents.length && parent) return [parent]

    return parents.length && parent ? [...parents, parent] : []
  }

  const handleClick = () => dispatch(resetTemporaryPlaceIds())

  return getParents().length && tempIds.places !== null ? (
    <Row style={{ textAlign: 'left', paddingRight: Spacing.sm8, paddingLeft: Spacing.sm4 }}>
      <Timeline>
        {getParents().map((item: IPlaces) => (
          <Timeline.Item key={item.id} style={{ paddingBottom: Spacing.sm6 }}>
            <StyledCardText>
              <Link to={`/${currentLocation}/${item.id}`} onClick={handleClick}>
                {item.name}
              </Link>
            </StyledCardText>
          </Timeline.Item>
        ))}
      </Timeline>
    </Row>
  ) : (
    <StyledCardText>{prompt}</StyledCardText>
  )
}
