import { Layout as AntLayout } from 'antd'
import { ErrorBoundary } from 'react-error-boundary'
import styled from 'styled-components'
import { FC, memo, ReactNode, useCallback, useEffect, useState } from 'react'

import { AppHelmet } from './helmet'
import { CommonActions } from '../../common'
import { EMPTY_STRING } from '../../constants'
import { ErrorFallback } from '../errorBoundary'
import { PlannySider } from './sider'
import { StyledLayout } from '../common/styled'
import { useTypedSelector } from '../../hooks'
import { Color, Spacing } from '../../styles'
import { getUser, getWorkspaces } from '../../store/selectors'

const { Content } = AntLayout

type StyledContentPropsType = {
  $isTablePage?: boolean
}

const StyledContent = styled(Content)<StyledContentPropsType>`
  padding: ${(props) => (props.$isTablePage ? 0 : `${Spacing.md24}`)};
  min-height: 280px;
  background-color: #f9f9f9;
`

export type LayoutPropsType = {
  children: ReactNode
  header?: ReactNode
  isTablePage?: boolean
  pageTitle?: string
}

export const Layout: FC<LayoutPropsType> = memo(
  ({ children, header, isTablePage = false, pageTitle = EMPTY_STRING }) => {
    const [width, setWidth] = useState('width=device-width, initial-scale=1')

    const { user } = useTypedSelector(getUser)
    const { defaultWorkspaceId } = useTypedSelector(getWorkspaces)

    const setContentWidth = useCallback(() => {
      if (window.innerWidth <= 1280) {
        setWidth('width=1280, user-scalable=yes')
      } else {
        setWidth('width=device-width, initial-scale=1')
      }
    }, [])

    useEffect(() => {
      setContentWidth()
    }, [setContentWidth])

    return (
      <StyledLayout hasSider>
        <AppHelmet pageTitle={pageTitle} contentWidth={width} />

        {user && defaultWorkspaceId ? (
          <>
            <PlannySider commonActions={<CommonActions />} />

            <AntLayout style={{ backgroundColor: Color.white }}>
              {header}

              <ErrorBoundary FallbackComponent={ErrorFallback}>
                <StyledContent $isTablePage={isTablePage}>{children}</StyledContent>
              </ErrorBoundary>
            </AntLayout>
          </>
        ) : null}
      </StyledLayout>
    )
  },
)
