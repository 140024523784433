import urlJoin from 'url-join'
import moment, { Moment } from 'moment'

import { CREATE, EMPTY_STRING, I18NEXT, LANGUAGES } from '../constants'
import { FILE_SERVER_ENDPOINT, PLANNY_BASE_URL } from '../env'
import { IEquipment, IFile, IMaterial, IMeter, IPlaces, IUserServices } from '../api'

export const getBaseLocation = (url: string) => {
  if (url) return url.split('/')[1]

  return EMPTY_STRING
}

export const getCurrentTab = (url: string) => url?.split('/')[3] || undefined

export const isCreatePage = (url: string) => {
  if (url) return url.split('/')[2] === CREATE

  return false
}

export const filterEmptyValues = (obj: IPlaces | IEquipment | IMaterial | IMeter) => {
  return Object.entries(obj).reduce((a, [k, v]) => {
    if (
      (v === null && k === 'parentId') ||
      (v === null && k === 'placeId') ||
      (v === null && k === 'equipmentId') ||
      (v === 0 && k === 'unitCount') ||
      (v === 0 && k === 'unitPrice') ||
      (v === 0 && k === 'operationValue') ||
      (v === 0 && k === 'readingPeriodValue') ||
      (v === false && k === 'isNotify')
    ) {
      return { ...a, [k]: v }
    }
    return v ? { ...a, [k]: v } : a
  }, {})
}

export const nounPluralize = (number: number, one: string, two: string, five: string) => {
  let num = number
  num = Math.abs(num)
  num %= 100
  if (num >= 5 && num <= 20) return five

  num %= 10

  if (num === 1) return one

  if (num >= 2 && num <= 4) return two

  return five
}

export const getLanguage = () => {
  const language = localStorage ? localStorage.getItem(I18NEXT) : undefined

  if (language) return language

  return LANGUAGES.RU
}

export const getImagePath = (array: IFile[]) => {
  if (array) {
    const [previewImage] = array.filter((item) => item.isPreview)

    return previewImage ? previewImage.url : EMPTY_STRING
  }

  return EMPTY_STRING
}

export const getImageId = (array: IFile[]) => {
  if (array) {
    const [previewImageId] = array.filter((item) => item.isPreview)

    if (previewImageId) return previewImageId.id
  }
  return null
}

export const isMobilePlatform = () => {
  const mobileOSReg = /(iemobile|opera mini|blackberry|andriod)/i

  const agent = navigator.userAgent

  return !!(agent.match(mobileOSReg) || agent.search(/andriod/i) > 0)
}

export const urlConverter = (url: string, query: string) => {
  const oldUrlArray = url.split('https://storage.yandexcloud.net/')

  const newUrl = urlJoin(PLANNY_BASE_URL, FILE_SERVER_ENDPOINT, oldUrlArray[1], query)

  return newUrl
}

export const getNotFiredWorkers = (data: IUserServices[]) => {
  if (data) return data.filter((i) => i.statusId !== 3)

  return []
}

export const getUtcOffset = () => moment().format('Z')

export const containsInArray = (arr: string[], elem: string) => arr.indexOf(elem) !== -1

export const deleteOffset = (time: string) => time?.split(/[+-]/)[0] || ''

export const isOffset = (time: string) => time?.split(/[+-]/).length > 1

export const getTimeWithOffset = (time: string) =>
  time && isOffset(time) ? time : `${time}${getUtcOffset()}`

export const getCorrectedDate = (date: string, time: string) => {
  if (date && time) {
    const dateString = date.split('T')[0]
    const result = `${dateString}T${time}:00${getUtcOffset()}`

    return result
  }

  return date || ''
}

export const isUserOnline = () => {
  window.addEventListener('online', isOnline)
  window.addEventListener('offline', isOnline)

  function isOnline() {
    return navigator.onLine || false
  }

  return isOnline()
}

export const getDateWithUtcOffset = (date: Moment) => {
  if (date) {
    const offset = moment().utcOffset()
    const moskovTime = 3

    if (offset > 0) return date.utc(true).add(moskovTime, 'hour')
    if (offset < 0) return date.utc(true).subtract(moskovTime, 'hour')

    return date.utc(true)
  }

  return null
}

export const getFirstLetter = (word: string) => (word ? word.slice(0, 1).toUpperCase() : '')

export const convertFirstLetterToUpperCase = (word: string) =>
  word ? word.charAt(0).toUpperCase() + word.slice(1) : ''
