import { t } from 'i18next'
import urlJoin from 'url-join'
import { AxiosError, AxiosInstance, AxiosResponse } from 'axios'

import { AppDispatch } from '../../hooks'
import { handleLoadError } from './errors'
import { PpwGraphicsActionTypes } from '../actionTypes'
import { RootState } from '../reducers/rootReducer'
import { sendNotification } from '../../components/common/notification'
import { startDataLoading } from './utils'
import { CACHE_KEYS, INIT, MESSAGE_TYPES, PPW_GRAPHICS_SISTEM_NOTIFICATIONS } from '../../constants'
import { createObject, editObject } from '../../api/shortcuts'
import { deleteTemporaryTags, resetTemporaryIds } from './temporaryData'
import { IPpwGraphics, removeItemFromLocalStorage } from '../../api'
import { PPW_ENDPOINT, RULES_ENDPOINT } from '../../env'

export type GetPpwGraphicsStateFunctionType = () => RootState

// PpwGraphics

// Load PpwGraphics Item

export const loadPpwGraphicsItemSuccess = (data: IPpwGraphics) => (dispatch: AppDispatch) =>
  dispatch({
    type: PpwGraphicsActionTypes.GET_PPW_GRAPHICS_ITEM_SUCCESS,
    payload: data,
  })

export const setCreatedPpwGraphicsId = (value: string | undefined) => (dispatch: AppDispatch) => {
  dispatch({
    type: PpwGraphicsActionTypes.SET_CREATED_PPW_GRAPHICS_ID,
    payload: value,
  })
}

// Get  PpwGraphics  GET

export const getAllPpwGraphics =
  (init?: string) =>
  (dispatch: AppDispatch, getState: GetPpwGraphicsStateFunctionType, api: AxiosInstance) => {
    dispatch(startDataLoading(PpwGraphicsActionTypes.START_PPW_GRAPHICS_LOADING))

    if (init && init === INIT) {
      dispatch(resetTemporaryIds())
      dispatch(deleteTemporaryTags())
    }

    api
      .get<IPpwGraphics[]>(urlJoin(PPW_ENDPOINT, RULES_ENDPOINT))
      .then((res: AxiosResponse) => {
        if (res.status === 200)
          dispatch({
            type: PpwGraphicsActionTypes.GET_PPW_GRAPHICS_SUCCESS,
            payload: res.data.data,
          })
      })
      .catch((err: AxiosError) =>
        dispatch(handleLoadError(err, PpwGraphicsActionTypes.SET_LOAD_PPW_GRAPHICS_ERROR)),
      )
  }

// Get PpwGraphics Item   GET

export const getPpwGraphicsItem =
  (PpwGraphicsId: string | undefined) =>
  (dispatch: AppDispatch, getState: GetPpwGraphicsStateFunctionType, api: AxiosInstance) => {
    dispatch(startDataLoading(PpwGraphicsActionTypes.START_PPW_GRAPHICS_LOADING))

    api
      .get(urlJoin(PPW_ENDPOINT, RULES_ENDPOINT, PpwGraphicsId))
      .then((res: AxiosResponse) => {
        dispatch(loadPpwGraphicsItemSuccess(res.data.data[0]))
      })
      .catch((err: AxiosError) =>
        dispatch(handleLoadError(err, PpwGraphicsActionTypes.SET_LOAD_PPW_GRAPHICS_ERROR)),
      )
  }

//  Edit PpwGraphics Item    PUT

export const editPpwGraphicsItem =
  (PpwGraphicsId: string | undefined, data?: IPpwGraphics) =>
  (dispatch: AppDispatch, getState: GetPpwGraphicsStateFunctionType, api: AxiosInstance) => {
    dispatch(startDataLoading(PpwGraphicsActionTypes.START_PPW_GRAPHICS_LOADING))

    api
      .put(urlJoin(PPW_ENDPOINT, RULES_ENDPOINT, PpwGraphicsId), editObject(data))
      .then((res: AxiosResponse) => {
        if (res.status === 200) {
          dispatch(resetTemporaryIds())

          dispatch(deleteTemporaryTags())

          dispatch(loadPpwGraphicsItemSuccess(res.data.data[0]))

          sendNotification(
            MESSAGE_TYPES.SUCCESS,
            t(PPW_GRAPHICS_SISTEM_NOTIFICATIONS.UPDATE_PPW_GRAPHICS_DATA_SUCCESS),
          )
        }
      })
      .catch((err: AxiosError) =>
        dispatch(handleLoadError(err, PpwGraphicsActionTypes.SET_LOAD_PPW_GRAPHICS_ERROR)),
      )
  }

// Create PpwGraphics   POST

export const createPpwGraphicsItem =
  (data: IPpwGraphics) =>
  (dispatch: AppDispatch, getState: GetPpwGraphicsStateFunctionType, api: AxiosInstance) => {
    dispatch(startDataLoading(PpwGraphicsActionTypes.START_PPW_GRAPHICS_LOADING))

    api
      .post(urlJoin(PPW_ENDPOINT, RULES_ENDPOINT), createObject(data))
      .then((res: AxiosResponse) => {
        if (res.status === 201) {
          dispatch(setCreatedPpwGraphicsId(res.headers.location.split('/')[2]))

          dispatch(resetTemporaryIds())

          removeItemFromLocalStorage(CACHE_KEYS.CREATE_GRAPHICS_FORM)

          dispatch({
            type: PpwGraphicsActionTypes.CREATE_PPW_GRAPHICS_SUCCESS,
          })

          dispatch(loadPpwGraphicsItemSuccess(res.data.data[0]))

          sendNotification(
            MESSAGE_TYPES.SUCCESS,
            t(PPW_GRAPHICS_SISTEM_NOTIFICATIONS.CREATE_PPW_GRAPHICS_DATA_SUCCESS),
          )
        }
      })
      .catch((err: AxiosError) =>
        dispatch(handleLoadError(err, PpwGraphicsActionTypes.SET_LOAD_PPW_GRAPHICS_ERROR)),
      )
  }

// Get PpwGraphics Rules   GET

export const getPpwRules =
  (PpwGraphicsId: string | undefined) =>
  (dispatch: AppDispatch, getState: GetPpwGraphicsStateFunctionType, api: AxiosInstance) => {
    dispatch(startDataLoading(PpwGraphicsActionTypes.START_PPW_GRAPHICS_LOADING))

    api
      .get(urlJoin(PPW_ENDPOINT, 'tasks', `?ruleId=${PpwGraphicsId}`))
      .then((res: AxiosResponse) => {
        dispatch({
          type: PpwGraphicsActionTypes.GET_RULES_SUCCESS,
          payload: res.data,
        })
      })
      .catch((err: AxiosError) =>
        dispatch(handleLoadError(err, PpwGraphicsActionTypes.SET_LOAD_PPW_GRAPHICS_ERROR)),
      )
  }

// Delete PpwGraphics Item   DELETE

export const deletePpwGraphicsItem =
  (PpwGraphicsId: string | undefined) =>
  (dispatch: AppDispatch, getState: GetPpwGraphicsStateFunctionType, api: AxiosInstance) => {
    dispatch(startDataLoading(PpwGraphicsActionTypes.START_PPW_GRAPHICS_LOADING))

    api
      .delete(urlJoin(PPW_ENDPOINT, RULES_ENDPOINT, PpwGraphicsId))
      .then((res: AxiosResponse) => {
        if (res.status === 204) {
          dispatch(resetTemporaryIds())

          dispatch({
            type: PpwGraphicsActionTypes.DELETE_PPW_GRAPHICS_SUCCESS,
            payload: Number(PpwGraphicsId),
          })

          sendNotification(
            MESSAGE_TYPES.SUCCESS,
            t(PPW_GRAPHICS_SISTEM_NOTIFICATIONS.DELETE_PPW_GRAPHICS_ITEM_SUCCESS),
          )
        }
      })
      .catch((err: AxiosError) =>
        dispatch(handleLoadError(err, PpwGraphicsActionTypes.SET_LOAD_PPW_GRAPHICS_ERROR)),
      )
  }
