import { Card } from 'antd'
import styled from 'styled-components'

import { BoxShadow, LineHeight, Spacing } from '../../../styles'
import { CARD_HEIGHT, CARD_WIDTH, EMPTY_STRING } from '../../../constants'

// ********* Styled Cards ******** //

// * Styled Common Card * //

type StyledCardPropsType = {
  $width?: string
  $height?: string
  $boxShadow?: string
  $lineHeight?: string
  $mb?: string
  $mt?: string
  $ml?: string
  $mr?: string
  $pb?: string
  $pt?: string
  $pl?: string
  $pr?: string
}

export const StyledCard = styled(Card)<StyledCardPropsType>`
  box-shadow: ${(props) => (props.$boxShadow ? props.$boxShadow : BoxShadow.card)};
  line-height: ${(props) => (props.$lineHeight ? props.$lineHeight : EMPTY_STRING)};

  margin-bottom: ${(props) => (props.$mb ? props.$mb : Spacing.sm16)};
  margin-top: ${(props) => (props.$mt ? props.$mt : {})};
  margin-left: ${(props) => (props.$ml ? props.$ml : {})};
  margin-right: ${(props) => (props.$mr ? props.$mr : {})};

  min-height: ${(props) => (props.$height ? props.$height : '50px')};
  max-height: 740px;
  width: ${(props) => (props.$width ? props.$width : CARD_WIDTH.SMALL_CARD)};

  text-align: ${(props) =>
    !props.$width || props.$width === CARD_WIDTH.SMALL_CARD ? 'center' : 'left'};

  .ant-card-body {
    padding-right: ${(props) => (props.$pr ? props.$pr : Spacing.md20)};
    padding-left: ${(props) => (props.$pl ? props.$pl : Spacing.md20)};
    padding-top: ${(props) => (props.$pt ? props.$pt : Spacing.md20)};
    padding-bottom: ${(props) => (props.$pb ? props.$pb : Spacing.md20)};
  }
`

// * Styled Small Panel Card * //

type StyledSmallPanelCardPropsType = {
  $width?: string
  $height?: string
  $textAlign?: 'left' | 'right'
  $mb?: string
  $mt?: string
  $ml?: string
  $mr?: string
}

export const StyledSmallPanelCard = styled(Card)<StyledSmallPanelCardPropsType>`
  box-shadow: ${BoxShadow.card};
  line-height: ${LineHeight.xs};
  margin-bottom: ${(props) => (props.$mb ? props.$mb : Spacing.sm16)};
  margin-top: ${(props) => (props.$mt ? props.$mt : {})};
  margin-left: ${(props) => (props.$ml ? props.$ml : {})};
  margin-right: ${(props) => (props.$mr ? props.$mr : {})};
  min-height: ${(props) => (props.$height ? props.$height : '50px')};
  max-height: 740px;
  width: ${(props) => (props.$width ? props.$width : CARD_WIDTH.SMALL_CARD)};
  text-align: ${(props) => props.$textAlign || 'center'};

  .ant-card-body {
    padding: ${Spacing.sm12} ${Spacing.md24} !important;
  }
`

// * Styled Preview Card * //

type StyledPreviewCardPropsType = {
  $maskHeight?: number
  $pb?: string
  $pt?: string
  $pl?: string
  $pr?: string
  $width?: string
  $minHeight?: string
}

export const StyledPreviewCard = styled(Card)<StyledPreviewCardPropsType>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${(props) => (props.$width ? props.$width : CARD_WIDTH.SMALL_CARD)} !important;
  box-shadow: ${BoxShadow.card};
  border-radius: ${Spacing.sm12} !important;
  margin-bottom: ${Spacing.sm16};
  min-height: ${(props) => (props.$minHeight ? props.$minHeight : CARD_HEIGHT.HEIGHT_175)};
  max-height: 500px;

  .ant-card-body {
    padding-right: ${(props) => (props.$pr ? props.$pr : Spacing.md20)} !important;
    padding-left: ${(props) => (props.$pl ? props.$pl : Spacing.md20)} !important;
    padding-top: ${(props) => (props.$pt ? props.$pt : Spacing.md20)} !important;
    padding-bottom: ${(props) => (props.$pb ? props.$pb : Spacing.md20)} !important;
  }

  .img-mask {
    height: ${(props) => (props.$maskHeight ? `${props.$maskHeight}px` : '100%')} !important;
  }

  .ant-image {
    position: static !important;
  }
`

// ********* Styled Cards ******** //
