import { lazy } from 'react'
import { Navigate } from 'react-router-dom'
import urlJoin from 'url-join'

import {
  ACCOUNT_DELETION_SUCCESS_URL,
  AUTH,
  AUTHENTICATION_PAGE_URL,
  FORGOT_PASSWORD,
  LOGIN,
  NOT_FOUND_URL,
  REGISTER,
  RESET_PASSWORD,
  SUCCESS_VERIFICATION_EMAIL,
} from '../../constants'
import {
  AuthenticationPage,
  LoginPage,
  RegistrationPage,
  SsoAuthorizationPage,
  VkAuthenticationPage,
} from '../../pages/auth'

import { SSO_AUTHENTICATION_ENDPOINT, VK_AUTHENTICATION_ENDPOINT } from '../../env'

const SuccessDeletionAccountPage = lazy(
  () => import('../../pages/service/SuccessDeletionAccountPage'),
)
const ForgotPasswordPage = lazy(() => import('../../pages/service/ForgotPasswordPage'))
const ResetPasswordPage = lazy(() => import('../../pages/service/ResetPasswordPage'))
const SuccessVerificationPage = lazy(() => import('../../pages/service/SuccessVerificationPage'))

export const getPublicRoutes = () => [
  {
    path: AUTHENTICATION_PAGE_URL,
    element: <AuthenticationPage />,
  },
  {
    path: LOGIN,
    element: <LoginPage />,
  },
  {
    path: REGISTER,
    element: <RegistrationPage />,
  },
  {
    path: FORGOT_PASSWORD,
    element: <ForgotPasswordPage />,
  },
  {
    path: urlJoin(AUTH, RESET_PASSWORD),
    element: <ResetPasswordPage />,
  },
  {
    path: SSO_AUTHENTICATION_ENDPOINT,
    element: <SsoAuthorizationPage />,
  },
  {
    path: VK_AUTHENTICATION_ENDPOINT,
    element: <VkAuthenticationPage />,
  },
  {
    path: SUCCESS_VERIFICATION_EMAIL,
    element: <SuccessVerificationPage />,
  },
  {
    path: ACCOUNT_DELETION_SUCCESS_URL,
    element: <SuccessDeletionAccountPage />,
  },
  {
    path: '*',
    element: <Navigate replace to={NOT_FOUND_URL} />,
  },
]
