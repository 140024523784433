import { ITag } from '../../api'

export type TagsStateType = {
  tags: ITag[]
  tempTags: ITag[]
  deletedTagsId: number[]
  isTagsLoading: boolean
  tagsError: null | string
}

export enum TagsActionTypes {
  START_TAGS_LOADING = 'START_TAGS_LOADING',
  LOAD_TAGS_ERROR = 'LOAD_TAGS_ERROR',
  LOAD_TAGS_SUCCESS = 'LOAD_TAGS_SUCCESS',
  SAVE_TEMPORARY_TAGS = 'SAVE_TEMPORARY_TAGS',
  EDIT_TEMPORARY_TAG_COLOR = 'EDIT_TEMPORARY_TAG_COLOR',
  EDIT_TEMPORARY_TAG = 'EDIT_TEMPORARY_TAG',
  DELETE_TEMPORARY_TAG_ITEM = 'DELETE_TEMPORARY_TAG_ITEM',
  SAVE_DELETED_TAG_ID = 'SAVE_DELETED_TAG_ID',
  DELETE_TEMPORARY_TAGS = 'DELETE_TEMPORARY_TAGS',
}

export type StartLoadingTagsActionType = {
  type: TagsActionTypes.START_TAGS_LOADING
}

export type LoadingTagsErrorActionType = {
  type: TagsActionTypes.LOAD_TAGS_ERROR
  payload: string
}

export type LoadingTagsSuccessActionType = {
  type: TagsActionTypes.LOAD_TAGS_SUCCESS
  payload: ITag[]
}

export type SaveTemporaryTagsActionType = {
  type: TagsActionTypes.SAVE_TEMPORARY_TAGS
  payload: ITag
}

export type SaveDeletedTagIdActionType = {
  type: TagsActionTypes.SAVE_DELETED_TAG_ID
  payload: number
}

export type DeleteTemporaryTagsActionType = {
  type: TagsActionTypes.DELETE_TEMPORARY_TAGS
}

export type EditTemporaryTagColorActionType = {
  type: TagsActionTypes.EDIT_TEMPORARY_TAG_COLOR
  payload: {
    name: string
    color: string
  }
}

export type EditTemporaryTagActionType = {
  type: TagsActionTypes.EDIT_TEMPORARY_TAG
  payload: {
    tag: string
    newTag: string
  }
}

export type DeleteTemporaryTagActionType = {
  type: TagsActionTypes.DELETE_TEMPORARY_TAG_ITEM
  payload: string
}

export type TagsActionType =
  | StartLoadingTagsActionType
  | LoadingTagsErrorActionType
  | LoadingTagsSuccessActionType
  | SaveTemporaryTagsActionType
  | DeleteTemporaryTagsActionType
  | EditTemporaryTagColorActionType
  | EditTemporaryTagActionType
  | SaveDeletedTagIdActionType
  | DeleteTemporaryTagActionType
