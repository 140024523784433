/* eslint-disable */
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Row, Typography } from 'antd'

import { ListItem } from './ListItem'
import { StyledCard } from '../../common'
import { MANUAL_LINKS, SUPPORT_LINKS, TELEGRAM_BOT_LINKS } from './links'
import {
  StyledCellTitle,
  StyledContentContainer,
  StyledDashboardHelpTable,
  StyledImage,
  StyledImageContainer,
  StyledLeftTableCell,
  StyledList,
  StyledRightTableCell,
  StyledText,
  StyledTitle,
} from './styledComponents'

const { Text } = Typography

export const DashboardHelpBlock: FC = () => {
  const { t } = useTranslation()

  return (
    <StyledCard $width="1060px" $height="724px" $pt="40px" $pr="40px" $pl="40px">
      <StyledDashboardHelpTable>
        <tbody>
          <tr>
            <td colSpan={3} style={{ verticalAlign: 'center' }}>
              <StyledTitle level={2}>{t('main-title')}</StyledTitle>
            </td>
          </tr>

          <tr>
            <td colSpan={3} style={{ verticalAlign: 'center' }}>
              <StyledTitle level={4}>
                {t('main-subtitle-part1')} <br /> {t('main-subtitle-part2')}
              </StyledTitle>
            </td>
          </tr>

          <tr>
            <td colSpan={3} style={{ verticalAlign: 'center' }}>
              &nbsp;
            </td>
          </tr>

          <tr style={{ verticalAlign: 'top' }}>
            <StyledLeftTableCell width="48%">
              <StyledCellTitle level={4}>{t('manual-cell-title')}</StyledCellTitle>

              <Row>
                <StyledImageContainer>
                  <StyledImage src="/images/telegramBot/help_manual.svg" alt="help_support" />
                </StyledImageContainer>

                <StyledContentContainer>
                  <Text>{t('manual-cell-subtitle')}</Text>

                  <StyledList>
                    {MANUAL_LINKS.map(({ id, name, link }) => (
                      <ListItem link={link} key={id}>
                        {t(name)}
                      </ListItem>
                    ))}
                  </StyledList>
                </StyledContentContainer>
              </Row>
            </StyledLeftTableCell>

            <td width="2%" />

            <StyledRightTableCell width="48%">
              <StyledCellTitle level={4}>{t('plans-cell-title')}</StyledCellTitle>

              <Row>
                <StyledImageContainer>
                  <StyledImage src="/images/telegramBot/help_plans.svg" alt="help_support" />
                </StyledImageContainer>

                <StyledContentContainer>
                  <StyledText>{t('plans-cell-info1')}</StyledText>

                  <Text> {t('plans-cell-info2')} </Text>

                  <a href="mailto:support@planny24.ru">{t('mail-link-text')}</a>
                </StyledContentContainer>
              </Row>
            </StyledRightTableCell>
          </tr>

          <tr>
            <td colSpan={3} style={{ verticalAlign: 'center' }}>
              &nbsp;
            </td>
          </tr>

          <tr style={{ verticalAlign: 'top' }}>
            <StyledLeftTableCell width="48%">
              <StyledCellTitle level={4}>{t('telegram-bot-cell-title')}</StyledCellTitle>

              <Row>
                <StyledImageContainer>
                  <StyledImage src="/images/telegramBot/help_bot.svg" alt="help_support" />
                </StyledImageContainer>

                <StyledContentContainer>
                  <Text>{t('t-bot-cell-subtitle')}</Text>

                  <StyledList>
                    {TELEGRAM_BOT_LINKS.map(({ id, name, link }) => (
                      <ListItem link={link} key={id}>
                        {t(name)}
                      </ListItem>
                    ))}
                  </StyledList>
                </StyledContentContainer>
              </Row>
            </StyledLeftTableCell>

            <td width="2%" />

            <StyledRightTableCell width="48%">
              <StyledCellTitle level={4}>{t('support-cell-title')}</StyledCellTitle>

              <Row>
                <StyledImageContainer>
                  <StyledImage src="/images/telegramBot/help_support.svg" alt="help_support" />
                </StyledImageContainer>

                <StyledContentContainer>
                  <Text>{t('support-cell-subtitle')}</Text>

                  <StyledList>
                    {SUPPORT_LINKS.map(({ id, name, link }) => (
                      <ListItem link={link} key={id}>
                        {t(name)}
                      </ListItem>
                    ))}
                  </StyledList>
                </StyledContentContainer>
              </Row>
            </StyledRightTableCell>
          </tr>
        </tbody>
      </StyledDashboardHelpTable>
    </StyledCard>
  )
}
