import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Col, Row, Skeleton, Tooltip } from 'antd'
import { FC, ReactElement, ReactNode } from 'react'

import { getBaseLocation } from '../../functions'
import { useBreakpoint } from '../../hooks'
import { getGutter, getTitleLength, getUnitPageTitle } from './helpers'
import { StyledHeaderTitle, StyledPageTitle, StyledText, TitleWrapper } from './styled'

export type ItemPageHeaderPropsType = {
  pageTitle: string
  headerIcon: ReactElement
  actions: ReactNode
  extraIcon: ReactElement
  navigationTabs: ReactNode
}

export const ItemPageHeader: FC<ItemPageHeaderPropsType> = ({
  pageTitle,
  headerIcon,
  actions,
  extraIcon,
  navigationTabs,
}) => {
  const { t } = useTranslation()

  const { pathname } = useLocation()

  const breakpoint = useBreakpoint()

  const currentLocation = getBaseLocation(pathname)

  return (
    <Row align="middle">
      <StyledText style={{ position: 'absolute' }}>
        {t(getUnitPageTitle(currentLocation))}
      </StyledText>

      <Col style={{ minWidth: '360px' }}>
        <StyledHeaderTitle>
          {pageTitle ? (
            <TitleWrapper $cursor $mb={pageTitle.length > 25 && '0'}>
              <Tooltip title={pageTitle}>
                {headerIcon}

                <StyledPageTitle>
                  {pageTitle.slice(0, getTitleLength(breakpoint))}

                  {pageTitle.length > getTitleLength(breakpoint) ? '...' : null}
                </StyledPageTitle>

                {extraIcon && extraIcon}
              </Tooltip>
            </TitleWrapper>
          ) : (
            <Skeleton.Button active style={{ marginTop: '20px', width: '300px', height: '25px' }} />
          )}
        </StyledHeaderTitle>
      </Col>

      <Col style={{ minWidth: getGutter(breakpoint) }}> </Col>

      {navigationTabs && (
        <Col>
          <Row align="middle">
            {navigationTabs} {actions && actions}
          </Row>
        </Col>
      )}
    </Row>
  )
}
