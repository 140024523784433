import { DEV_MODE, PRODUCTION_MODE } from './constants'

export const PLANNY_BASE_URL = process.env.REACT_APP_PLANNY_BASE_URL
export const EQUIPMENT_ENDPOINT = process.env.REACT_APP_EQUIPMENT_ENDPOINT
export const PLACES_ENDPOINT = process.env.REACT_APP_PLACES_ENDPOINT
export const MATERIALS_ENDPOINT = process.env.REACT_APP_MATERIALS_ENDPOINT
export const METERS_ENDPOINT = process.env.REACT_APP_METERS_ENDPOINT
export const TASKS_ENDPOINT = process.env.REACT_APP_TASKS_ENDPOINT
export const SERVICES_ENDPOINT = process.env.REACT_APP_TASKS_SERVICES_ENDPOINT
export const HISTORY_ENDPOINT = process.env.REACT_APP_HISTORY_ENDPOINT
export const TASK_RULES_ENDPOINT = process.env.REACT_APP_TASK_RULES_ENDPOINT
export const PPW_ENDPOINT = process.env.REACT_APP_PPW_ENDPOINT
export const RULES_ENDPOINT = process.env.REACT_APP_RULES_ENDPOINT

export const FILE_SERVER_ENDPOINT = process.env.REACT_APP_FILE_SERVER_ENDPOINT
export const LIMITS_ENDPOINT = process.env.REACT_APP_LIMITS_ENDPOINT
export const DATA_EXPORT_ENDPOINT = process.env.REACT_APP_DATA_EXPORT_ENDPOINT
export const PRIORITIES_ENDPOINT = process.env.REACT_APP_TASKS_PRIORETIES_ENDPOINT

export const HISTORY_MATERIALS_ENDPOINT = process.env.REACT_APP_HISTORY_MATERIALS_ENDPOINT
export const HISTORY_METERS_ENDPOINT = process.env.REACT_APP_HISTORY_METERS_ENDPOINT
export const TASKS_HISTORY_ENDPOINT = process.env.REACT_APP_TASKS_HISTORY_ENDPOINT
export const EQUIPMENT_HISTORY_ENDPOINT = process.env.REACT_APP_HISTORY_EQUIPMENT_ENDPOINT
export const USER_SERVICE_HISTORY_ENDPOINT = process.env.REACT_APP_USER_SERVICE_HISTORY_ENDPOINT

export const WORKSPACES_ENDPOINT = process.env.REACT_APP_WORKSPACES_ENDPOINT
export const WORKSPACE_ENDPOINT = process.env.REACT_APP_WORKSPACE_ENDPOINT
export const EMPLOYEES_ENDPOINT = process.env.REACT_APP_EMPLOYEES_ENDPOINT
export const EMPLOYEE_ENDPOINT = process.env.REACT_APP_EMPLOYEE_ENDPOINT
export const EMPLOYEES_URL = process.env.REACT_APP_EMPLOYEE_URL
export const INVITATIONS_URL = process.env.REACT_APP_INVITATIONS_URL
export const COMPANY_ENDPOINT = process.env.REACT_APP_COMPANY_ENDPOINT
export const COMPANIES_ENDPOINT = process.env.REACT_APP_COMPANIES_ENDPOINT
export const NOTIFICATIONS_ENDPOINT = process.env.REACT_APP_NOTIFICATIONS_ENDPOINT
export const DISTRIBUTION_ENDPOINT = process.env.REACT_APP_DISTRIBUTION_ENDPOINT
export const CLIENTS_ENDPOINT = process.env.REACT_APP_CLIENTS_ENDPOINT
export const CLIENT_ENDPOINT = process.env.REACT_APP_CLIENT_ENDPOINT
export const SUBSCRIPTIONS_ENDPOINT = process.env.REACT_APP_SUBSCRIPTIONS_ENDPOINT
export const SUBSCRIPTION_ENDPOINT = process.env.REACT_APP_SUBSCRIPTION_ENDPOINT
export const PLANS_ENDPOINT = process.env.REACT_APP_PLANS_ENDPOINT

export const AUTH_ENDPOINT = process.env.REACT_APP_AUTH_ENDPOINT
export const USERS_ENDPOINT = process.env.REACT_APP_USERS_ENDPOINT
export const SSO_AUTHENTICATION_ENDPOINT = process.env.REACT_APP_SSO_AUTHENTICATION_ENDPOINT
export const VK_AUTHENTICATION_ENDPOINT = process.env.REACT_APP_VK_AUTHENTICATION_ENDPOINT
export const AUTH_SSO_YANDEX_ENDPOINT = process.env.REACT_APP_SSO_YANDEX_ENDPOINT
export const AUTH_SSO_VK_ENDPOINT = process.env.REACT_APP_SSO_VK_ENDPOINT
export const AUTH_SSO_GOOGLE_ENDPOINT = process.env.REACT_APP_SSO_GOOGLE_ENDPOINT
export const AUTH_SSO_MAIL_ENDPOINT = process.env.REACT_APP_SSO_MAIL_ENDPOINT
export const TELEGRAM_PARAMETER = process.env.REACT_APP_SSO_TELEGRAM_PARAMETER
export const AUTH = process.env.REACT_APP_AUTH
export const INTEGRATION_TELEGRAM = process.env.REACT_APP_INTEGRATION_TELEGRAM
export const TELEGRAM_BOT = process.env.REACT_APP_TELEGRAM_BOT
export const ACCOUNTS_TELEGRAM = process.env.REACT_APP_ACCOUNTS_TELEGRAM
export const ACCOUNT_TELEGRAM = process.env.REACT_APP_ACCOUNT_TELEGRAM

export const USER_LIMITS_ENDPOINT = process.env.REACT_APP_USER_LIMITS_ENDPOINT

export const LOGIN_ENDPOINT = process.env.REACT_APP_LOGIN_ENDPOINT
export const REGISTER_ENDPOINT = process.env.REACT_APP_REGISTER_ENDPOINT
export const LOGOUT_ENDPOINT = process.env.REACT_APP_LOGOUT_ENDPOINT
export const RESET_PASSWORD_ENDPOINT = process.env.REACT_APP_RESET_PASSWORD_ENDPOINT
export const FORGOT_PASSWORD_ENDPOINT = process.env.REACT_APP_FORGOT_PASSWORD_ENDPOINT
export const TOKENS_REFRESH_ENDPOINT = process.env.REACT_APP_TOKENS_REFRESH_ENDPOINT
export const SET_EMAIL_ENDPOINT = process.env.REACT_APP_SET_EMAIL_ENDPOINT

export const ME_ENDPOINT = process.env.REACT_APP_ME_ENDPOINT

export const TAGS_ENDPOINT = process.env.REACT_APP_TAGS_ENDPOINT
export const STATUSES_ENDPOINT = process.env.REACT_APP_EQUIPMENT_STATUSES_ENDPOINT
export const USER_SERVICE_ADMISSIONS_ENDPOINT =
  process.env.REACT_APP_USER_SERVICE_ADMISSIONS_ENDPOINT

export const CURRENCIES_ENDPOINT = process.env.REACT_APP_CURRENCIES_ENDPOINT
export const MEASUREMENT_UNITS_ENDPOINT = process.env.REACT_APP_MEASUREMENT_UNITS_ENDPOINT
export const READING_PERIODS_ENDPOINT = process.env.REACT_APP_READING_PERIODS_ENDPOINT
export const STATS_ENDPOINT = process.env.REACT_APP_STATS

export const DEVELOPMENT_ORIGIN = process.env.REACT_APP_DEVELOPMENT_ORIGIN
export const LOCALHOST_ORIGIN = process.env.REACT_APP_LOCALHOST_ORIGIN

export const HISTORY_UPDATE_TIMEOUT = process.env.REACT_APP_HISTORY_UPDATE_TIMEOUT

export const NOTIFICATIONS_UPDATE_TIMEOUT = process.env.REACT_APP_NOTIFICATIONS_UPDATE_TIMEOUT
export type Env = Record<string, string | undefined>

//  Return `process.env.NODE_ENV`

export const getMode = (env: Env): string => env.MODE ?? env.NODE_ENV!

// Function to check that current build operates in development mode.

export const isDevelopment = (env: Env): boolean => getMode(env) === DEV_MODE

// Function  to check that current build operates in production mode.

export const isProduction = (env: Env): boolean => getMode(env) === PRODUCTION_MODE
