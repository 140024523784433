import { t } from 'i18next'
import urlJoin from 'url-join'
import { AxiosError, AxiosInstance, AxiosResponse } from 'axios'

import { AppDispatch } from '../../hooks'
import { AuthActionTypes } from '../actionTypes'
import { loadUser } from './user'
import { RootState } from '../reducers/rootReducer'
import { sendNotification } from '../../components/common/notification'
import { startDataLoading } from './utils'
import {
  ACCOUNT_TELEGRAM,
  ACCOUNTS_TELEGRAM,
  AUTH_ENDPOINT,
  FORGOT_PASSWORD_ENDPOINT,
  LOGIN_ENDPOINT,
  LOGOUT_ENDPOINT,
  REGISTER_ENDPOINT,
  RESET_PASSWORD_ENDPOINT,
  SET_EMAIL_ENDPOINT,
  TELEGRAM_BOT,
  TOKENS_REFRESH_ENDPOINT,
} from '../../env'
import {
  CHANNELS,
  COMMON_SISTEM_NOTIFICATIONS,
  CONTENT_TYPE_APPLICATION_JSON,
  EMPTY_STRING,
  HEADERS,
  INTEGRATION_TELEGRAM_LINK,
  JSON_HEADERS,
  MESSAGE_TYPES,
  REFRESH_TOKEN,
  SESSION_ID,
  STATUS_CODES,
  SW_ACTIONS,
  SW_REGISTRATION_ERROR,
} from '../../constants'
import {
  cleanCache,
  getCurrentWorkspaceId,
  getUserWorkspaceFromToken,
  parseJwt,
} from '../../functions'
import { deleteOldWorkspaceData, getCurrentWorkspace } from './workspaces'
import {
  EmailType,
  getItemFromLocalStorage,
  ResetPasswordType,
  setItemToLocalStorage,
  UserLoginType,
  UserRegisterType,
  VKSetEmailType,
} from '../../api'
import { handleAuthError, handleLoadError } from './errors'

export type GetAuthStateFunctionType = () => RootState

// Handle update user

export const handleUpdateUser =
  (token: string) => (dispatch: AppDispatch, getState: GetAuthStateFunctionType) => {
    dispatch(loadUser())

    const currrentScope = getState().workspaces.currentWorkspace

    if (!currrentScope) {
      const userInfo = parseJwt(token)
      const scopeId = getUserWorkspaceFromToken(token)
      const storedScopeId = getCurrentWorkspaceId(userInfo.id)

      if (storedScopeId || scopeId)
        dispatch(getCurrentWorkspace(Number(storedScopeId) || Number(scopeId)))
    }
  }

// Starts refresh

export const startRefresh = () => (dispatch: AppDispatch) =>
  dispatch({
    type: AuthActionTypes.START_REFRESH,
  })

// Finish refresh

export const finishRefresh = () => (dispatch: AppDispatch) =>
  dispatch({
    type: AuthActionTypes.FINISH_REFRESH,
  })

// Save access token

export const refreshAccessTokenSuccess = (accessToken: string) => (dispatch: AppDispatch) => {
  dispatch({
    type: AuthActionTypes.REFRESH_SUCCESS,
    payload: {
      accessToken,
      status: 200,
    },
  })
}

// Set status

export const setStatus = (status: number | null) => (dispatch: AppDispatch) =>
  dispatch({
    type: AuthActionTypes.SET_STATUS,
    payload: status,
  })

// Set refresh token & sessionId to local storage * for HTTP *

const setCredentialsToStorage = (res: AxiosResponse) => {
  const refToken = res.data.refresh_token
  const sessionId = res.data.session_id

  if (refToken && sessionId) {
    setItemToLocalStorage(REFRESH_TOKEN, res.data.refresh_token)
    setItemToLocalStorage(SESSION_ID, res.data.session_id)
  }
}

// Registration POST

export const register =
  (payload: UserRegisterType) =>
  (dispatch: AppDispatch, getState: GetAuthStateFunctionType, api: AxiosInstance) => {
    dispatch(startDataLoading(AuthActionTypes.START_AUTH_LOADING))

    api
      .post(urlJoin(AUTH_ENDPOINT, REGISTER_ENDPOINT), payload, JSON_HEADERS)
      .then((res: AxiosResponse) => {
        dispatch({
          type: AuthActionTypes.REGISTER_SUCCESS,
          payload: {
            status: res.status,
          },
        })
      })
      .catch((err: AxiosError) => dispatch(handleAuthError(err)))
  }

// Login POST

export const login =
  (payload: UserLoginType, telegramToken?: string) =>
  (dispatch: AppDispatch, getState: GetAuthStateFunctionType, api: AxiosInstance) => {
    dispatch(startDataLoading(AuthActionTypes.START_AUTH_LOADING))

    const isServiceWorkerError = JSON.parse(getItemFromLocalStorage(SW_REGISTRATION_ERROR))

    const loginChannel = new BroadcastChannel(CHANNELS.LOGIN_CHANNEL)

    const formData = new FormData()

    const queryParams = telegramToken ? `?telegram_token=${telegramToken}` : EMPTY_STRING

    /* eslint-disable */

    Object.keys(payload).forEach((key) =>
      formData.append(key, payload[key as keyof typeof payload]),
    )

    api
      .post(urlJoin(AUTH_ENDPOINT, LOGIN_ENDPOINT, queryParams), formData, HEADERS)
      .then((res: AxiosResponse) => {
        if (res.status === 200) {
          const token = res.data?.access_token

          loginChannel.postMessage({
            sessionId: res.data.session_id,
            refToken: res.data.refresh_token,
            accessToken: res.data.access_token,
          })

          cleanCache()

          dispatch(refreshAccessTokenSuccess(token))

          dispatch(handleUpdateUser(token))

          // * for HTTP *

          if (isServiceWorkerError) setCredentialsToStorage(res)
        }
      })
      .catch((err: AxiosError) => dispatch(handleAuthError(err)))
  }

export const logout =
  () => (dispatch: AppDispatch, getState: GetAuthStateFunctionType, api: AxiosInstance) => {
    dispatch(startDataLoading(AuthActionTypes.START_AUTH_LOADING))

    const logoutChannel = new BroadcastChannel(CHANNELS.LOGOUT_CHANNEL)

    const headers = {
      headers: {
        'session-id': 'null',
      },
    }

    api
      .post(urlJoin(AUTH_ENDPOINT, LOGOUT_ENDPOINT), {}, headers)
      .then((res: AxiosResponse) => {
        logoutChannel.postMessage(SW_ACTIONS.LOGOUT_ACT)

        dispatch({
          type: AuthActionTypes.LOGOUT,
        })

        deleteOldWorkspaceData()

        cleanCache()

        // * Refresh token if SW is not active*
        localStorage.removeItem(SESSION_ID)
        localStorage.removeItem(REFRESH_TOKEN)
        localStorage?.removeItem(SW_REGISTRATION_ERROR)
      })
      .catch((err: AxiosError) => dispatch(handleAuthError(err)))
  }

// Reset password POST

export const resetPassword =
  (payload: ResetPasswordType) =>
  (dispatch: AppDispatch, getState: GetAuthStateFunctionType, api: AxiosInstance) => {
    dispatch(startDataLoading(AuthActionTypes.START_AUTH_LOADING))

    api
      .post(urlJoin(AUTH_ENDPOINT, RESET_PASSWORD_ENDPOINT), payload)
      .then((res: AxiosResponse) => {
        dispatch({
          type: AuthActionTypes.RESET_PASSWORD_SUCCESS,
          payload: {
            status: res.status,
          },
        })
      })
      .catch((err: AxiosError) => dispatch(handleAuthError(err)))
  }

// Forgot password POST

export const sendEmail =
  (payload: EmailType) =>
  (dispatch: AppDispatch, getState: GetAuthStateFunctionType, api: AxiosInstance) => {
    dispatch(startDataLoading(AuthActionTypes.START_AUTH_LOADING))

    api
      .post(urlJoin(AUTH_ENDPOINT, FORGOT_PASSWORD_ENDPOINT), payload)
      .then((res: AxiosResponse) => {
        dispatch({
          type: AuthActionTypes.SEND_EMAIL_SUCCESS,
          payload: {
            status: res.status,
          },
        })
      })
      .catch((err: AxiosError) => dispatch(handleAuthError(err)))
  }

// VK SET_EMAIL POST

export const VkSetEmail =
  (payload: VKSetEmailType) =>
  (dispatch: AppDispatch, getState: GetAuthStateFunctionType, api: AxiosInstance) => {
    dispatch(startDataLoading(AuthActionTypes.START_AUTH_LOADING))

    api
      .post(urlJoin(AUTH_ENDPOINT, SET_EMAIL_ENDPOINT), payload)
      .then((res: AxiosResponse) => {
        dispatch({
          type: AuthActionTypes.SEND_EMAIL_SUCCESS,
          payload: {
            status: res.status,
          },
        })
      })
      .catch((err: AxiosError) => dispatch(handleAuthError(err)))
  }

// Refresh token POST * for HTTP *

export const refreshToken =
  () => (dispatch: AppDispatch, getState: GetAuthStateFunctionType, api: AxiosInstance) => {
    dispatch(startDataLoading(AuthActionTypes.START_AUTH_LOADING))
    dispatch(startRefresh())

    const config = {
      headers: {
        'session-id': getItemFromLocalStorage(SESSION_ID),
        'refresh-token': getItemFromLocalStorage(REFRESH_TOKEN),
      },
    }

    api
      .post(urlJoin(AUTH_ENDPOINT, TOKENS_REFRESH_ENDPOINT), {}, config)
      .then((res: AxiosResponse) => {
        if (res.status === 200) {
          const token = res.data?.access_token

          setCredentialsToStorage(res)

          dispatch(refreshAccessTokenSuccess(token))

          if (token) dispatch(handleUpdateUser(token))
        }
      })
      .catch((err: AxiosError) => {
        dispatch(finishRefresh())

        dispatch(handleAuthError(err))
      })
      .finally(() => dispatch(finishRefresh()))
  }

export const linkTelegramBot =
  (token: string, navigateToDashboard: () => void) =>
  (dispatch: AppDispatch, getState: GetAuthStateFunctionType, api: AxiosInstance) => {
    dispatch(startDataLoading(AuthActionTypes.START_AUTH_LOADING))

    const headers = {
      headers: {
        'Content-Type': CONTENT_TYPE_APPLICATION_JSON,
      },
    }

    api
      .post(
        urlJoin(TELEGRAM_BOT, ACCOUNTS_TELEGRAM),
        {
          token,
        },
        headers,
      )
      .then((res: AxiosResponse) => {
        if (res.status === 201) {
          sessionStorage.removeItem(INTEGRATION_TELEGRAM_LINK)
          sendNotification(
            MESSAGE_TYPES.SUCCESS,
            t(COMMON_SISTEM_NOTIFICATIONS.SUCCESS_LINKED_TELEGRAM_BOT),
          )

          navigateToDashboard()
        }
      })
      .catch((err: AxiosError) => {
        if (err?.response?.status === STATUS_CODES.CODE_422) {
          sendNotification(MESSAGE_TYPES.ERROR, t(COMMON_SISTEM_NOTIFICATIONS.INVALID_TOKEN))
        }

        dispatch(handleLoadError(err, AuthActionTypes.SET_TELEGRAM_BOT_ERROR))
      })
  }

export const checkIsTelegramBotConnected =
  () => (dispatch: AppDispatch, getState: GetAuthStateFunctionType, api: AxiosInstance) => {
    dispatch(startDataLoading(AuthActionTypes.START_AUTH_LOADING))

    const headers = {
      headers: {
        'Content-Type': CONTENT_TYPE_APPLICATION_JSON,
      },
    }

    api
      .get(urlJoin(TELEGRAM_BOT, ACCOUNT_TELEGRAM), headers)
      .then((res: AxiosResponse) => {
        if (res.status === 200) {
          dispatch({
            type: AuthActionTypes.SET_IS_TELEGRAM_BOT_CONNECTED,
            payload: true,
          })
        }
      })
      .catch((err: AxiosError) => {
        dispatch({
          type: AuthActionTypes.SET_IS_TELEGRAM_BOT_CONNECTED,
          payload: false,
        })
      })
  }
