import { Avatar as AntdAvatar } from 'antd'
import { FC } from 'react'
import { UserOutlined } from '@ant-design/icons'

type AvatarPropsType = {
  $mr?: string
  src?: string
  onError?: () => boolean
}

export const Avatar: FC<AvatarPropsType> = ({ $mr, src, onError }) => {
  return src ? (
    <AntdAvatar src={src} size={35} style={{ marginRight: $mr }} onError={onError} />
  ) : (
    <AntdAvatar icon={<UserOutlined />} size={35} style={{ marginRight: $mr }} onError={onError} />
  )
}
