import { AxiosError } from 'axios'
import { CloseOutlined } from '@ant-design/icons'
import { Row } from 'antd'
import styled from 'styled-components'
import { FC, useEffect, useState } from 'react'

import { AuthActionTypes } from '../../store/actionTypes'
import { deleteError } from '../../store/actions/errors'
import { getErrorMessage } from '../../api/notificationService'
import { useTypedDispatch } from '../../hooks'
import { Color, FontSize, Spacing } from '../../styles'

const StyledPanel = styled(Row)`
  position: absolute;
  border-radius: ${Spacing.sm2};
  top: 40px;
  width: 600px;
  min-height: 60px;
  background-color: rgba(250, 211, 211, 0.5);
  padding: ${Spacing.sm8} ${Spacing.sm16};
`

const StyledText = styled.span`
  color: ${Color.black};
  font-size: ${FontSize.md};
  opacity: 0.7;
`

const StyledCloseIcon = styled(CloseOutlined)`
  position: absolute;
  top: ${Spacing.sm8};
  right: ${Spacing.sm8};
  cursor: pointer;
`

export type NotificationPannelPropsType = {
  error: AxiosError
}

export const NotificationPannel: FC<NotificationPannelPropsType> = ({ error }) => {
  const [isPanelVisible, setIsPanelVisible] = useState(true)

  const dispatch = useTypedDispatch()

  const prepareErrorMessage = () => {
    if (getErrorMessage(error)) {
      return getErrorMessage(error)
        .split('.')
        .filter((i) => i)
    }

    return null
  }

  useEffect(() => {
    setTimeout(() => dispatch(deleteError(AuthActionTypes.SET_AUTH_ERROR)), 5000)

    return () => {
      dispatch(deleteError(AuthActionTypes.SET_AUTH_ERROR))
    }
  }, [dispatch])

  const handleOnClick = () => setIsPanelVisible(false)

  return isPanelVisible && prepareErrorMessage() ? (
    <StyledPanel justify="center" align="middle">
      {prepareErrorMessage().length &&
        prepareErrorMessage().map((line) => <StyledText key={line}>{line}.</StyledText>)}
      <StyledCloseIcon onClick={handleOnClick} />
    </StyledPanel>
  ) : null
}
