import { PERIOD_TYPES } from '../../constants'
import { ERROR_TYPES, USER_ROLES, WORKSPACE_KINDS } from './enums'

export interface IdObject {
  id?: number
}

// Services types

export interface ITag extends IdObject {
  name?: string
  color?: string
  description?: string
}

export interface IFile extends IdObject {
  url?: string
  fileName?: string
  fileSize?: number
  isPreview?: boolean
}

export type PrioritiesType = {
  code?: number
  description?: string | null
  id?: number
  name?: string
  nameRu?: string
  nameEn?: string
  importanceLevel?: number
}

export interface IAdmission extends IdObject {
  name?: string
  nameRu?: string
  nameEn?: string
  importanceLevel?: number
}

export interface IStatus extends IdObject {
  name?: string
  nameRu?: string
  nameEng?: string
  description?: string
  color?: string
  tableDotColor?: string
  importanceLevel?: number
  durationHours?: number
  code?: string
}

export interface IMeasurementUnits extends IdObject {
  name?: string
  shortName?: string
  description?: string
  symbol?: string
}

export interface IPlaces extends IdObject {
  name?: string
  address?: string
  companyId?: number | null
  description?: string
  files?: IFile[]
  images?: IFile[]
  ownerId?: number | null
  ownerName?: string
  ownerPhone?: string
  ownerPost?: string
  parentId?: number
  scopeId?: number
  hierarchy?: number[]
  children?: IPlaces[]
  location?: string | null
  isFavorite?: boolean | null
}

export interface IUserServices extends IdObject {
  name?: string
  position?: string | null
  contacts?: string | null
  comment?: string | null
  operationComment?: string
  operationDate?: string
  admissionId?: number
  ownerId?: number
  statusId?: number
  parentId?: number | null
  scopeId?: number
  files?: IFile[]
  hierarchy?: number[]
  tags?: ITag[]
  images?: IFile[]
  materials?: IdObject[]
  meters?: IdObject[]
  places?: IdObject[]
  tasks?: IdObject[]
  isFavorite?: boolean | null
}

export interface IMaterial extends IdObject {
  name?: string
  partNumber?: string
  serialNumber?: string
  unitPrice?: number
  unitCount?: number
  operationCount?: number
  minUnitCount?: number
  isNotify?: boolean
  description?: string
  placeId?: number
  ownerId?: number
  companyId?: number
  currencyId?: number
  scopeId?: number
  responsibleId?: number
  equipments?: IdObject[]
  measurementUnitId?: number
  tags?: ITag[]
  images?: IFile[]
  files?: IFile[]
  isFavorite?: boolean | null
}

export interface IMeter extends IdObject {
  name?: string
  partNumber?: string
  serialNumber?: string
  description?: string
  placeId?: number
  ownerId?: number
  companyId?: number
  equipmentId?: number
  responsibleId?: number
  isNotify?: boolean
  scopeId?: number
  validityPeriodValue?: number
  readingPeriodValue?: number
  lastOperationValue?: number
  lastOperationDate?: string
  operationValue?: number
  inspectionDate?: string
  readingPeriodId?: number
  measurementUnitId?: number
  operationComment?: string
  tags?: ITag[]
  images?: IFile[]
  files?: IFile[]
  isFavorite?: boolean | null
}

export type MaterialsType = IdObject & {
  quantity?: number
}

export interface ITask extends IdObject {
  number?: number
  title?: string
  description?: string
  createdDatetime?: string
  updatedDatetime?: string
  dueDatetime?: string
  operationComment?: string
  equipments?: IdObject[]
  materials?: MaterialsType[]
  meters?: IdObject[]
  places?: IdObject[]
  scopeId?: number
  assignees?: IdObject[]
  images?: IFile[]
  files?: IFile[]
  tags?: ITag[]
  laborDays?: string
  isCalendarVisible?: boolean
  isInfinity?: boolean
  isPlanned?: boolean
  ownerId?: number
  statusId?: number
  priorityId?: number
  propertyName?: string
  isFavorite?: boolean | null
  downtimeDays?: number
  laborHours?: number
}

export interface IMaterialCurrencies extends IdObject {
  name: string
  code: string
  numCode: string
  description: string
}

export interface IEquipment extends IdObject {
  name?: string
  modelName?: string
  serialNumber?: string
  inventoryNumber?: string
  description?: string
  manufacturer?: string
  importanceLevel?: number
  images?: IFile[]
  tags?: ITag[]
  files?: IFile[]
  parentId?: number
  scopeId?: number
  placeId?: number
  statusId?: number
  companyId?: number
  responsibleId?: number
  hierarchy?: number[]
  ownerId?: number
  operationDate?: string
  lastOperationDate?: string
  operationComment?: string
  isFavorite?: boolean | null
}

export type Permissions = {
  description?: string
  name?: string
}

export type UserRole = {
  name?: USER_ROLES
  permissions?: any[]
  description?: string
}

export interface IMember extends IdObject {
  title?: string
  role?: UserRole
  scopeId?: number
  description?: string
  email?: string
}

export interface IOwner extends IdObject {
  email?: string
}
export interface IWorkspace extends IdObject {
  description?: string
  kind?: WORKSPACE_KINDS
  companyId?: number
  ownerId: number
}

export interface ICompany extends IdObject {
  description?: string
  name?: string
  ownerId?: number
  address?: string
  logoUrl?: string
}

export enum SOURSE_OBJECT_TYPE {
  AUTH = 1,
  EQUIPMENT = 2,
  PLACE = 3,
  MATERIAL = 4,
  METER = 5,
  TASK = 6,
  TRIGGER = 7,
}

export interface INotification extends IdObject {
  userId?: number
  type?: string
  level?: ERROR_TYPES
  isRead?: boolean
  message?: string
  createdAt?: string
  obj?: {
    name: string
    url?: string
  }
}

export interface IPpwGraphics extends IdObject {
  name?: string
  description?: string
  startDatetime?: string
  endDatetime?: string
  equipments?: IdObject[]
  tags?: ITag[]
}

export interface ITaskGraphicsData {
  taskName?: string
  taskDate?: string
  taskIsPlanned?: boolean
  equipmentDowntime?: number
  laborIntensity?: number
  equipment?: IdObject[]
  taskId?: number
}

export interface ITaskGraphicsMeta {
  downtimeTotalSum?: number
  laborTotalSum?: number
  periodDays?: number
  taskCount?: number
}

export interface ITaskGraphics {
  data?: ITaskGraphicsData[]
  meta?: {
    summary: ITaskGraphicsMeta
  }
}

export type ImageInfoFieldsType = {
  AWSAccessKeyId: string
  key: string
  policy: string
  signature: string
}

export interface ICompanyImageInfo extends IdObject {
  fields: ImageInfoFieldsType
  url: string
}

export type FormValuesInfoType = {
  isTimeDisabled?: boolean
  isMeterDisabled?: boolean
}

export type MeterEveryValueType = {
  value: number
  startedAtValue: number
}

export type MeterRuleType = FormValuesInfoType &
  IdObject & {
    relationType?: string
    relationValue?: number
    every?: MeterEveryValueType
  }

export type PeriodType = FormValuesInfoType & {
  type?: PERIOD_TYPES
  repeatAtType?: string
  startedAtValue?: number | undefined
  repeatAtValues?: number[]
  startedAtTime?: string
}

export type ServiceIventType = {
  meter?: MeterRuleType
}

export type TimeType = {
  period?: PeriodType
}

export type NotifyType = {
  startedAtType?: PERIOD_TYPES
  startedAtValue?: number
}

export type BasedOnType = {
  serviceEvent?: ServiceIventType
  time?: TimeType
  notify?: NotifyType
}

export type RuleDataType = IdObject &
  FormValuesInfoType & {
    startedTime?: string | null
    basedOn?: BasedOnType
  }

export type TaskRuleType = IdObject & {
  ruleData: RuleDataType
}

export type AllServicesType = IEquipment | IPlaces | IMaterial | IMeter
