import { FC } from 'react'
import { Link } from 'react-router-dom'
import { Row, Timeline } from 'antd'

import { CURRENT_LOCATION } from '../../../constants'
import { IEquipment } from '../../../api'
import { Spacing } from '../../../styles'
import { StyledCardText } from '../../common'
import { getEquipment, getTemporaryData } from '../../../store/selectors'
import { resetTemporaryEquipmentIds, resetTemporaryPlaceIds } from '../../../store/actions'
import { useTypedDispatch, useTypedSelector } from '../../../hooks'

export type EquipmentStructurePropsType = {
  prompt: string
}

export const EquipmentStructure: FC<EquipmentStructurePropsType> = ({ prompt }) => {
  const { equipment, equipmentItem } = useTypedSelector(getEquipment)
  const { tempIds } = useTypedSelector(getTemporaryData)

  const dispatch = useTypedDispatch()

  const getParents = () => {
    let parents: IEquipment[] = []

    const getParent = (id: number) => equipment.filter((item: IEquipment) => item.id === id)

    const findParents = (obj: IEquipment) => {
      if (obj && obj.parentId) {
        const [parent] = getParent(obj.parentId)
        parents = [parent, ...parents]

        findParents(parent)
      }
    }

    if (equipment.length && !tempIds.equipment) {
      findParents(equipmentItem)

      return parents.length ? parents : []
    }

    const [parent] = equipment.filter((item: IEquipment) => item.id === tempIds.equipment[0])

    findParents(parent)

    if (!parents.length && parent) return [parent]

    return parents.length && parent ? [...parents, parent] : []
  }

  const handleClick = () => {
    dispatch(resetTemporaryEquipmentIds())
    dispatch(resetTemporaryPlaceIds())
  }

  return equipment && getParents().filter((item) => item).length && tempIds.equipment !== null ? (
    <Row style={{ textAlign: 'left', paddingRight: Spacing.sm8, paddingLeft: Spacing.sm4 }}>
      <Timeline>
        {getParents()
          .filter((item) => item)
          .map((item: IEquipment) => (
            <Timeline.Item key={item.id} style={{ paddingBottom: Spacing.sm6 }}>
              <StyledCardText>
                <Link to={`/${CURRENT_LOCATION.EQUIPMENT}/${item.id}`} onClick={handleClick}>
                  {item.name}
                </Link>
              </StyledCardText>
            </Timeline.Item>
          ))}
      </Timeline>
    </Row>
  ) : (
    <StyledCardText>{prompt}</StyledCardText>
  )
}
