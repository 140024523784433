export enum EQUIPMENT_STATUSES_KODES {
  ON_READY = 'working',
  ON_REPAIR = 'in_repair',
  DEFECTIVE = 'defective',
  IN_SERVICE = 'maintenance',
  DOWNTIME = 'downtime',
}

export enum TASK_STATUSES_CODES {
  NEW = 'new',
  IN_PROCESS = 'in_progress',
  CLOSED = 'closed',
  ON_HOLD = 'on_hold',
  ARСHIVE = 'archive',
}

export enum USER_SERVICES_STATUSES {
  PRESENT = 'Присутствует',
  ABSENT = 'Отсутствует',
  FIRED = 'Уволен',
}

export enum USER_SERVICES_STATUSES_CODES {
  PRESENT = 'present',
  ABSENT = 'absent',
  FIRED = 'fired',
}

export enum USER_SERVICES_ADMISSIONS_STATUSES_CODES {
  YES = 'Yes',
  NO = 'No',
}

export enum TASK_PRIORITIES_I18N {
  HIGH = 'High',
  LOW = 'Low',
  NORMAL = 'Normal',
}
