import { BREAKPOINTS, CURRENT_LOCATION, EMPTY_STRING } from '../../constants'

export const getButtonWidth = (breakpoint: string) => {
  if (breakpoint === BREAKPOINTS.md) return '160px'
  if (breakpoint === BREAKPOINTS.xxl) return '260px'
  if (breakpoint === BREAKPOINTS.xxxl) return '300px'
  return '180px'
}

export const getGutter = (breakpoint: string) => (breakpoint === BREAKPOINTS.xl ? '80px' : '120px')

export const getTitleLength = (breakpoint: string) => {
  if (breakpoint === BREAKPOINTS.xl) return 20
  if (breakpoint === BREAKPOINTS.xxl) return 22
  if (breakpoint === BREAKPOINTS.xxxl) return 25

  return 15
}

export const getDashboardTitleWidth = (breakpoint: string) => {
  if (breakpoint === BREAKPOINTS.xl) return 7
  if (breakpoint === BREAKPOINTS.xxl) return 6

  return 4
}

export const getUnitPageTitle = (currentLocation: string) => {
  if (currentLocation === CURRENT_LOCATION.EQUIPMENT) return CURRENT_LOCATION.EQUIPMENT

  if (currentLocation === CURRENT_LOCATION.LOCATIONS) return CURRENT_LOCATION.LOCATIONS

  if (currentLocation === CURRENT_LOCATION.MATERIALS) return CURRENT_LOCATION.MATERIALS

  if (currentLocation === CURRENT_LOCATION.METERS) return CURRENT_LOCATION.METERS

  if (currentLocation === CURRENT_LOCATION.TASKS) return CURRENT_LOCATION.TASKS

  if (currentLocation === CURRENT_LOCATION.USER_SERVICES) return CURRENT_LOCATION.USER_SERVICES

  return EMPTY_STRING
}
