// Link names are I18Next variables * ref. translation.json

export const SUPPORT_LINKS = [
  { id: 1, name: 'mail-support-link-text', link: 'mailto:support@planny24.ru' },
  { id: 2, name: 'telegram-link-text', link: 'https://t.me/Planny24' },
  { id: 3, name: 'feedback-link-text', link: '/feedback' },
]

export const MANUAL_LINKS = [
  { id: 1, name: 'account-manual-link-text', link: 'https://planny24.ru/help#account' },
  { id: 2, name: 'equipment-manual-link-text', link: 'https://planny24.ru/help#equipment' },
  { id: 3, name: 'spta-manual-link-text', link: 'https://planny24.ru/help#spta' },
  { id: 4, name: 'employee-manual-link-text', link: 'https://planny24.ru/help#employee' },
  { id: 5, name: 'tasks-manual-link-text', link: 'https://planny24.ru/help#tasks' },
  { id: 6, name: 'export-manual-link-text', link: 'https://planny24.ru/help#export' },
]

export const TELEGRAM_BOT_LINKS = [
  { id: 1, name: 'bot-link-text', link: 'https://t.me/Planny24bot' },
  { id: 2, name: 'help-bot-link-text', link: 'https://planny24.ru/help#bot' },
]
