import urlJoin from 'url-join'
import { AxiosError, AxiosInstance, AxiosResponse } from 'axios'

import { AppDispatch } from '../../hooks'
import { handleLoadError } from './errors'
import { RootState } from '../reducers/rootReducer'
import { startDataLoading } from './utils'
import {
  CURRENCIES_ENDPOINT,
  MATERIALS_ENDPOINT,
  MEASUREMENT_UNITS_ENDPOINT,
  METERS_ENDPOINT,
  READING_PERIODS_ENDPOINT,
} from '../../env'
import { MaterialsActionTypes, MetersActionTypes } from '../actionTypes'

type GetStateFunctionType = () => RootState

export const getCurrencies =
  () => (dispatch: AppDispatch, getState: GetStateFunctionType, api: AxiosInstance) => {
    dispatch(startDataLoading(MaterialsActionTypes.START_MATERIALS_LOADING))

    api
      .get(urlJoin(MATERIALS_ENDPOINT, CURRENCIES_ENDPOINT))
      .then((res: AxiosResponse) => {
        dispatch({
          type: MaterialsActionTypes.LOAD_MATERIAL_CURRENCIES_SUCCESS,
          payload: res.data.data,
        })
      })
      .catch((err: AxiosError) =>
        dispatch(handleLoadError(err, MaterialsActionTypes.LOAD_MATERIALS_ERROR)),
      )
  }

export const getMeasurementUnits =
  () => (dispatch: AppDispatch, getState: GetStateFunctionType, api: AxiosInstance) => {
    dispatch(startDataLoading(MaterialsActionTypes.START_MATERIALS_LOADING))

    api
      .get(urlJoin(MATERIALS_ENDPOINT, MEASUREMENT_UNITS_ENDPOINT))
      .then((res: AxiosResponse) => {
        dispatch({
          type: MaterialsActionTypes.LOAD_MATERIAL_MEASUREMENT_UNITS_SUCCESS,
          payload: res.data.data,
        })
      })
      .catch((err: AxiosError) =>
        dispatch(handleLoadError(err, MaterialsActionTypes.LOAD_MATERIALS_ERROR)),
      )
  }

export const getMeterReadingPeriods =
  () => (dispatch: AppDispatch, getState: GetStateFunctionType, api: AxiosInstance) => {
    dispatch(startDataLoading(MetersActionTypes.START_METERS_LOADING))

    api
      .get(urlJoin(METERS_ENDPOINT, READING_PERIODS_ENDPOINT))
      .then((res: AxiosResponse) => {
        dispatch({
          type: MetersActionTypes.LOAD_METER_READING_PERIOD_SUCCESS,
          payload: res.data.data,
        })
      })
      .catch((err: AxiosError) =>
        dispatch(handleLoadError(err, MetersActionTypes.LOAD_METERS_ERROR)),
      )
  }

export const getMeterMeasurementUnits =
  () => (dispatch: AppDispatch, getState: GetStateFunctionType, api: AxiosInstance) => {
    dispatch(startDataLoading(MetersActionTypes.START_METERS_LOADING))

    api
      .get(urlJoin(METERS_ENDPOINT, MEASUREMENT_UNITS_ENDPOINT))
      .then((res: AxiosResponse) => {
        dispatch({
          type: MetersActionTypes.LOAD_METER_MEASUREMENT_UNITS_SUCCESS,
          payload: res.data.data,
        })
      })
      .catch((err: AxiosError) =>
        dispatch(handleLoadError(err, MetersActionTypes.LOAD_METERS_ERROR)),
      )
  }
