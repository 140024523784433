import { FC, memo, useCallback, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { NOTIFICATIONS_UPDATE_TIMEOUT } from '../env'
import { PUBLIC_ROUTES } from '../constants'
import {
  getAllCompanies,
  getAllNotifications,
  getAllWorkspaces,
  getCurrentCompany,
  getUserLimits,
  getWorkspaceOwner,
} from '../store/actions'
import { getAuth, getCompanies, getNotifications, getUser, getWorkspaces } from '../store/selectors'
import {
  getBaseLocation,
  getCurrentWorkspaceId,
  getScopeIdQuery,
  getUserWorkspaceFromToken,
  getWorkspaceTariffPlan,
} from '../functions'
import { useTypedDispatch, useTypedSelector } from '../hooks'

export const CommonActions: FC = memo(() => {
  const { accessToken, isRefreshStarts } = useTypedSelector(getAuth)
  const { user, limits } = useTypedSelector(getUser)
  const { workspaces, owner, currentWorkspace, defaultWorkspaceId, loadWorkspacesError } =
    useTypedSelector(getWorkspaces)
  const { notifications, loadNotificationsError } = useTypedSelector(getNotifications)
  const { currentCompany, companies, loadCompanyError } = useTypedSelector(getCompanies)

  const { pathname } = useLocation()

  const navigate = useNavigate()

  const dispatch = useTypedDispatch()

  const currentLocation = getBaseLocation(pathname)

  const getWorkspace = useCallback(() => {
    return (
      currentWorkspace?.id ||
      defaultWorkspaceId ||
      getCurrentWorkspaceId(user?.id) ||
      getUserWorkspaceFromToken(accessToken)
    )
  }, [accessToken, currentWorkspace, user, defaultWorkspaceId])

  const isQueryNeeded = useCallback(() => {
    if (PUBLIC_ROUTES.indexOf(currentLocation) === -1 && currentLocation !== 'integration')
      return true

    return false
  }, [currentLocation])

  useEffect(() => {
    // redirect to current user path with query string(current workspace)
    if (isQueryNeeded() && getWorkspace()) {
      navigate(`${pathname}${getScopeIdQuery(getWorkspace())}`)
    }
  }, [navigate, getWorkspace, isQueryNeeded, pathname])

  useEffect(() => {
    if (!workspaces && !loadWorkspacesError) dispatch(getAllWorkspaces())
  }, [dispatch, workspaces, loadWorkspacesError])

  useEffect(() => {
    if (!companies && !loadCompanyError) dispatch(getAllCompanies())
  }, [dispatch, companies, loadCompanyError])

  useEffect(() => {
    if (currentWorkspace && !owner && !loadWorkspacesError)
      dispatch(getWorkspaceOwner(currentWorkspace?.id))
  }, [dispatch, currentWorkspace, owner, loadWorkspacesError])

  useEffect(() => {
    if (currentWorkspace && !currentCompany && !loadCompanyError)
      dispatch(getCurrentCompany(currentWorkspace?.companyId))
  }, [dispatch, currentWorkspace, currentCompany, loadCompanyError])

  useEffect(() => {
    if (!limits)
      dispatch(getUserLimits(getWorkspaceTariffPlan(accessToken, currentWorkspace), user?.id))
  }, [dispatch, currentWorkspace, accessToken, limits, user])

  useEffect(() => {
    if (!notifications && !loadNotificationsError && !isRefreshStarts)
      dispatch(getAllNotifications())
  }, [dispatch, notifications, loadNotificationsError, isRefreshStarts])

  useEffect(() => {
    const interval = setInterval(() => {
      if (!isRefreshStarts) dispatch(getAllNotifications())
    }, Number(NOTIFICATIONS_UPDATE_TIMEOUT))

    return () => clearInterval(interval)
  }, [dispatch, isRefreshStarts])

  return <div style={{ display: 'none' }}> </div>
})
