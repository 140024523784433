import { FC } from 'react'
import styled from 'styled-components'

const StyledNameWrapper = styled.span`
  display: block;
  line-height: 18px;
  font-size: 13px;
`

const StyledAccountNameWrapper = styled.span`
  display: block;
  line-height: 18px;
  font-size: 15px;
  font-weight: 700;
`

const StyledWrapper = styled.span`
  display: flex;
  flex-direction: column;
`

export type ChangeWorkspaceButtonPropsType = {
  accountName: string
  username: string
}

export const ChangeWorkspaceButton: FC<ChangeWorkspaceButtonPropsType> = ({
  accountName,
  username,
}) => {
  return (
    <StyledWrapper>
      <StyledAccountNameWrapper>{accountName}</StyledAccountNameWrapper>
      <StyledNameWrapper>{username}</StyledNameWrapper>
    </StyledWrapper>
  )
}
