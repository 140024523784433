export enum Spacing {
  sm2 = '2px',
  sm4 = '4px',
  sm6 = '6px',
  sm8 = '8px',
  sm10 = '10px',
  sm12 = '12px',
  sm16 = '16px',
  md20 = '20px',
  md24 = '24px',
  md32 = '32px',
  md40 = '40px',
  lg48 = '48px',
  lg56 = '56px',
}
