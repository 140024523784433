import { Col, Row, Skeleton } from 'antd'
import { FC, ReactElement, ReactNode } from 'react'

import { BREAKPOINTS } from '../../constants'
import { getButtonWidth } from './helpers'
import { useBreakpoint } from '../../hooks'
import { StyledButtonWrapper, StyledHeaderTitle, TitleWrapper } from './styled'

export type TablePageHeaderPropsType = {
  pageTitle: string
  headerIcon: ReactElement
  search: ReactNode
  actions: ReactNode
  button: ReactNode
  selectModal: ReactNode
}

export const TablePageHeader: FC<TablePageHeaderPropsType> = ({
  pageTitle,
  headerIcon,
  search,
  actions,
  button,
  selectModal,
}) => {
  const breakpoint = useBreakpoint()

  return (
    <Row justify="space-between" align="middle">
      <Col>
        <StyledHeaderTitle align="middle">
          {pageTitle ? (
            <TitleWrapper $cursor={false}>
              {headerIcon}
              {breakpoint === BREAKPOINTS.sm ? '' : pageTitle}
              {selectModal && selectModal}
            </TitleWrapper>
          ) : (
            <Skeleton.Button active style={{ marginTop: '20px', width: '300px', height: '25px' }} />
          )}
        </StyledHeaderTitle>
      </Col>

      {search && (
        <Row align="middle" justify="center" style={{ width: '340px' }}>
          {search}
        </Row>
      )}

      {button && (
        <Col>
          <Row align="middle">
            <StyledButtonWrapper $width={getButtonWidth(breakpoint)}>{button}</StyledButtonWrapper>

            {actions && actions}
          </Row>
        </Col>
      )}
    </Row>
  )
}
