import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Layout as AntLayout, Row, Typography } from 'antd'
import { FC, ReactNode } from 'react'

import { AuthHelmet } from './helmet'
import { Spacing } from '../../styles'
import { StyledAuthLayout } from '../common/styled'
import {
  EMPTY_STRING,
  PLANNY_HELP_LINK,
  PLANNY_SUPPORT_LINK,
  SEA_PROJECT_LINK,
} from '../../constants'

const { Content, Footer } = AntLayout
const { Text } = Typography

const StyledText = styled(Text)`
  margin-right: ${Spacing.sm12};
`

const StyledFooter = styled(Footer)`
  background-image: url('/images/p24_pattern.png');
`

const StyledLink = styled.a`
  margin-right: ${Spacing.sm12};
`

const StyledContent = styled(Content)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export type AuthLayoutPropsType = {
  children: ReactNode
  pageTitle?: string
}

export const AuthLayout: FC<AuthLayoutPropsType> = ({ children, pageTitle = EMPTY_STRING }) => {
  const { t } = useTranslation()

  return (
    <StyledAuthLayout>
      <AuthHelmet pageTitle={pageTitle} />

      <StyledContent>{children}</StyledContent>

      <StyledFooter>
        <Row justify="center" align="middle">
          <StyledText type="secondary">
            {t('license-text')}
            <span> </span>
            <a href={SEA_PROJECT_LINK} target="_blank" rel="noreferrer">
              {t('sea-project')}
            </a>
          </StyledText>

          <StyledLink href={PLANNY_HELP_LINK} target="_blank" rel="noreferrer">
            {t('help-link')}
          </StyledLink>

          <a href={PLANNY_SUPPORT_LINK} target="_blank" rel="noreferrer">
            {t('support-letter')}
          </a>
        </Row>

        <Row justify="center" align="middle">
          <span>{t('test-prompt')}.</span>
        </Row>
      </StyledFooter>
    </StyledAuthLayout>
  )
}
