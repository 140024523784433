import { FC } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Row, Timeline } from 'antd'

import { getBaseLocation } from '../../../functions'
import { IUserServices } from '../../../api'
import { resetTemporaryServiceIds } from '../../../store/actions'
import { Spacing } from '../../../styles'
import { StyledCardText } from '../../common'
import { getTemporaryData, getUserServices } from '../../../store/selectors'
import { useTypedDispatch, useTypedSelector } from '../../../hooks'

export type UserServiceStructurePropsType = {
  prompt: string
}

export const UserServiceStructure: FC<UserServiceStructurePropsType> = ({ prompt }) => {
  const { userServices, serviceItem } = useTypedSelector(getUserServices)
  const { tempIds } = useTypedSelector(getTemporaryData)

  const { pathname } = useLocation()

  const currentLocation = getBaseLocation(pathname)

  const dispatch = useTypedDispatch()

  const getParents = () => {
    let parents: IUserServices[] = []

    const getParent = (id: number) => userServices.filter((item: IUserServices) => item.id === id)

    const findParents = (obj: IUserServices) => {
      if (obj && obj.parentId) {
        const [parent] = getParent(obj.parentId)
        parents = [parent, ...parents]

        findParents(parent)
      }
    }

    if (userServices && userServices.length && !tempIds.userServices) {
      findParents(serviceItem)

      return parents.length ? parents : []
    }

    const [parent] = userServices
      ? userServices.filter((item: IUserServices) => item.id === tempIds.userServices[0])
      : []

    findParents(parent)

    if (!parents.length && parent) return [parent]

    return parents.length && parent ? [...parents, parent] : []
  }

  const handleClick = () => dispatch(resetTemporaryServiceIds())

  return getParents().length && tempIds.userServices !== null ? (
    <Row style={{ textAlign: 'left', paddingRight: Spacing.sm8, paddingLeft: Spacing.sm4 }}>
      <Timeline>
        {getParents().map((item: IUserServices) => (
          <Timeline.Item key={item.id} style={{ paddingBottom: Spacing.sm6 }}>
            <StyledCardText>
              <Link to={`/${currentLocation}/${item.id}`} onClick={handleClick}>
                {item.name}
              </Link>
            </StyledCardText>
          </Timeline.Item>
        ))}
      </Timeline>
    </Row>
  ) : (
    <StyledCardText>{prompt}</StyledCardText>
  )
}
