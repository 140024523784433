import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'

/* eslint-disable */

import { PLANNY_BASE_URL } from '../env'

const SERVICES = [
  'equipments',
  'places',
  'user-services',
  'materials',
  'meters',
  'tasks',
  'history',
  'triggers',
  'limits',
  'rules',
  'ppw',
]

let store: any

export const injectStore = (_store: any) => {
  store = _store
}

export const DEFAULT_AXIOS_CONFIG: AxiosRequestConfig = {
  timeout: 120000,
  baseURL: PLANNY_BASE_URL,
  responseType: 'json',
}

const createApi = (extra: AxiosRequestConfig = {}): AxiosInstance =>
  axios.create({
    ...DEFAULT_AXIOS_CONFIG,
    ...extra,
  })

const api = createApi()

api.interceptors.request.use(
  (config) => {
    const token = store.getState()?.auth?.accessToken
    const scopeId = store.getState()?.workspaces?.currentWorkspace?.id
    const defaultScopeId = store.getState()?.workspaces?.defaultWorkspaceId
    const isUserOnline = store.getState()?.errors?.isUserOnline
    const isNeedToken = !config.url?.match('refresh')
    const baseLocation = config.url ? config.url.split('/')[0] : ''

    if (isUserOnline) {
      if (token && isNeedToken) {
        config.headers['Authorization'] = 'Bearer ' + token
        config.headers['withCredentials'] = true

        if (config.headers['Content-Type'] === undefined) {
          config.headers['Content-Type'] = 'multipart/form-data'
        }
      }

      const currentLocation = window.location.pathname
      const currentTab = currentLocation?.split('/')[3] || ''

      if (SERVICES.indexOf(baseLocation) !== -1) {
        if (currentTab === 'subscriptions') {
          config.params = { ...config.params, scopeId: defaultScopeId }
        } else {
          config.params = { ...config.params, scopeId: scopeId || defaultScopeId }
        }
      }

      return config
    }

    return config
  },
  (error) => {
    return Promise.reject(error)
  },
)

export default api
