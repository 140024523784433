import { Pie } from '@ant-design/plots'
import { t as translate } from 'i18next'
import { useTranslation } from 'react-i18next'
import { FC, memo, useEffect } from 'react'

import { Color } from '../../styles'
import { PieDataType } from '../../api'
import { getAllTasks, getTaskStatuses } from '../../store/actions'
import {
  getCommonStatisticsPieConfig,
  getInteractions,
  getStatisticsPieContentOptions,
  getStatisticsPieTitleOptions,
  renderStatistics,
} from '../../statisticsPieCommonOptions'
import { getTasks, getWorkspaces } from '../../store/selectors'
import { INIT, TASK_STATUSES_CODES } from '../../constants'
import { STATUSES_COLOR_MAP, TasksStatusesType } from '../common'
import { useTypedDispatch, useTypedSelector } from '../../hooks'

const getDefaultColors = () => [Color.blue, Color.green, Color.darkPurple, Color.orange]

const getEmptyStatistics = () => [
  { type: translate(TASK_STATUSES_CODES.NEW), value: 0 },
  { type: translate(TASK_STATUSES_CODES.IN_PROCESS), value: 0 },
  { type: translate(TASK_STATUSES_CODES.CLOSED), value: 0 },
  { type: translate(TASK_STATUSES_CODES.ON_HOLD), value: 0 },
]

export type TasksStatusesPiePropsType = {
  multiplier: number
}

export const TasksStatusesPie: FC<TasksStatusesPiePropsType> = memo(({ multiplier }) => {
  const { tasks, statuses } = useTypedSelector(getTasks)
  const { currentWorkspace, defaultWorkspaceId } = useTypedSelector(getWorkspaces)

  const { t } = useTranslation()

  const dispatch = useTypedDispatch()

  const colors: string[] = statuses?.length && tasks?.length ? [] : getDefaultColors()

  const getTasksAmount = (statusId: number) =>
    tasks?.filter((item) => item.statusId === statusId) || []

  const getStatusesData = (): PieDataType[] => {
    if (statuses?.length && tasks?.length) {
      return statuses
        .filter((i) => i.id !== 5)
        .map(({ id, code }) => {
          if (code)
            colors.push(STATUSES_COLOR_MAP.tasks[code as keyof TasksStatusesType].brightColor)

          return {
            type: t(code),
            value: getTasksAmount(id).length || null,
          }
        })
    }

    return []
  }

  const config = {
    ...getCommonStatisticsPieConfig(t('short-items'), '{value}'),
    data: getStatusesData().length ? getStatusesData() : getEmptyStatistics(),
    color: colors,
    width: 220 * multiplier,
    height: 220,

    statistic: {
      title: {
        ...getStatisticsPieTitleOptions('16px', -4),
        customHtml: (container: HTMLElement, view: any, datum: any) => {
          const { width, height } = container.getBoundingClientRect()

          const d = Math.sqrt((width / 1.6) ** 2 + (height / 1.6) ** 2)

          const text = datum ? datum.type : t('all-items')

          return renderStatistics(d, text, {
            fontSize: 16,
          })
        },
      },
      content: {
        ...getStatisticsPieContentOptions('20px', 4),
        customHtml: (container: HTMLElement, view: any, datum: any, items: any) => {
          const { width } = container.getBoundingClientRect()

          const text = datum
            ? `${datum.value} ${t('short-items')}`
            : `${items.reduce((r: number, d: PieDataType) => r + d.value, 0)} ${t('short-items')}`

          return renderStatistics(width, text, {
            fontSize: 20,
          })
        },
      },
    },

    interactions: getInteractions(),
  }

  useEffect(() => {
    if (currentWorkspace || defaultWorkspaceId) {
      dispatch(getTaskStatuses())
      dispatch(getAllTasks(INIT))
    }
  }, [dispatch, currentWorkspace, defaultWorkspaceId])
  /* eslint-disable */

  return <Pie className="responsive-canvas" {...config} />
})
