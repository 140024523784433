import urlJoin from 'url-join'
import { useTranslation } from 'react-i18next'
import { FC, useCallback, useEffect, useState } from 'react'
import { Radio, RadioChangeEvent, Row, Table } from 'antd'
import { useLocation, useParams } from 'react-router-dom'

import { EmptyTable } from './commonComponents/EmptyTable'
import { ExpandedIcon } from './commonComponents/ExpandedIcon'
import { getItemsSelectTableColumns } from './columns/itemSelectTableColumns'
import { getRowClassName } from './helpers'
import { prepareSearchValue } from './helpers/sorter'
import { SearchForm } from '../forms'
import { sendNotification } from '../common/notification'
import { setTemporaryDataIds } from '../../store/actions'
import { Spacing } from '../../styles'
import { StyledTableDivider } from '../common'
import { TableWrapper } from './commonComponents/styled'
import { UserServicesColumnsDataType } from '../../api'
import {
  CREATE,
  CURRENT_LOCATION,
  DATA_TYPE,
  MESSAGE_TYPES,
  USER_SERVICE_SISTEM_NOTIFICATIONS,
} from '../../constants'
import { getBaseLocation, isCreatePage } from '../../functions'
import { getFilters, getTemporaryData, getUserServices } from '../../store/selectors'
import { useTypedDispatch, useTypedSelector } from '../../hooks'

export type UserServiceSelectTablePropsType = {
  data: UserServicesColumnsDataType[]
  selectedServiceId?: number
}

export const UserServiceSelectTable: FC<UserServiceSelectTablePropsType> = ({
  data,
  selectedServiceId,
}) => {
  const [checked, setChecked] = useState(false)
  const [selectedKey, setSelectedKey] = useState(null)

  const { userServices } = useTypedSelector(getUserServices)
  const { searchValue } = useTypedSelector(getFilters)
  const { tempIds } = useTypedSelector(getTemporaryData)

  const { serviceId } = useParams<{ serviceId?: string }>()

  const { pathname } = useLocation()

  const { t } = useTranslation()

  const currentLocation = getBaseLocation(pathname)

  const dispatch = useTypedDispatch()

  const handleSelectRow = (key: number) => {
    if (serviceId && Number(serviceId) === key) {
      sendNotification(
        MESSAGE_TYPES.ERROR,
        t(USER_SERVICE_SISTEM_NOTIFICATIONS.USER_SERVICE_SELECTION_ERROR),
      )
    } else {
      setChecked(false)
      setSelectedKey(key)

      dispatch(setTemporaryDataIds(DATA_TYPE.USER_SERVICES, [Number(key)]))
    }
  }

  const resetState = () => {
    setSelectedKey(null)
    setChecked(true)
  }

  const handleOnChange = (e: RadioChangeEvent) => {
    setChecked(e.target.checked)
    setSelectedKey(null)

    dispatch(setTemporaryDataIds(DATA_TYPE.USER_SERVICES, null))
  }

  const getInitialServiceSelectItem = useCallback(() => {
    if (
      (isCreatePage(pathname) && !tempIds.userServices) ||
      (!isCreatePage(pathname) && !selectedServiceId && !tempIds.userServices)
    )
      resetState()

    if (!isCreatePage(pathname) && selectedServiceId && tempIds.userServices === undefined)
      setSelectedKey(selectedServiceId)

    if (isCreatePage(pathname) && tempIds.userServices) setSelectedKey(tempIds.userServices)
  }, [tempIds, selectedServiceId, pathname])

  const handleSearchRow = (key: number) => {
    return getRowClassName(userServices, key, prepareSearchValue(searchValue.userServices))
  }

  useEffect(() => getInitialServiceSelectItem(), [getInitialServiceSelectItem])

  return (
    <TableWrapper>
      <Row align="middle">
        <Radio checked={checked} onChange={handleOnChange} style={{ marginLeft: Spacing.sm8 }}>
          {t('no-selections')}
        </Radio>
        <StyledTableDivider orientation="center" $mt={Spacing.sm12} $mb={Spacing.sm8} />
      </Row>

      <Row align="middle">
        <SearchForm $isModal dataType={DATA_TYPE.USER_SERVICES} />
        <StyledTableDivider orientation="center" $mt={Spacing.sm8} $mb={Spacing.sm12} />
      </Row>

      <Table
        columns={getItemsSelectTableColumns([selectedKey])}
        dataSource={data || []}
        bordered={false}
        pagination={false}
        scroll={
          data.length > 6 && !searchValue
            ? { y: '350px' }
            : userServices.length > 6 && searchValue
            ? { y: '350px' }
            : {}
        }
        showHeader={false}
        rowClassName={(record) => handleSearchRow(record.key)}
        onRow={(record) => ({
          onClick: () => handleSelectRow(record.key),
        })}
        locale={{
          emptyText: (
            <EmptyTable
              prompt={t('no-items')}
              hasButton={Boolean(
                userServices &&
                  userServices.length &&
                  currentLocation !== CURRENT_LOCATION.USER_SERVICES,
              )}
              navigationPath={urlJoin(DATA_TYPE.USER_SERVICES, CREATE)}
            />
          ),
        }}
        expandable={{
          defaultExpandAllRows: true,
          indentSize: 20,
          expandIcon: ({ expanded, onExpand, record }) => (
            <ExpandedIcon expanded={expanded} record={record} onExpand={onExpand} />
          ),
        }}
        style={{ minWidth: '100%', margin: '0' }}
      />
    </TableWrapper>
  )
}
