import { t } from 'i18next'
import urlJoin from 'url-join'
import { AxiosError, AxiosInstance, AxiosResponse } from 'axios'

import { AppDispatch } from '../../hooks'
import { deleteOldWorkspaceData } from './workspaces'
import { handleLoadError } from './errors'
import { RootState } from '../reducers/rootReducer'
import { startDataLoading } from './utils'
import { transformKeysToCamelCase } from '../../api/converters'
import { UserActionTypes } from '../actionTypes/user'
import {
  ACCOUNT_DELETION_SUCCESS_URL,
  CONTENT_TYPE_APPLICATION_JSON,
  MESSAGE_TYPES,
  REFRESH_TOKEN,
  SESSION_ID,
  SW_REGISTRATION_ERROR,
  USER_ID_KEY,
  USER_SISTEM_NOTIFICATIONS,
} from '../../constants'
import { cleanCache, convertUserLimits, isDistributor } from '../../functions'
import { EditUserCredentialsType, UserLimitsTariffPlanType } from '../../api'
import {
  FILE_SERVER_ENDPOINT,
  LIMITS_ENDPOINT,
  ME_ENDPOINT,
  USER_LIMITS_ENDPOINT,
  USERS_ENDPOINT,
} from '../../env'
import { sendInformationMessage, sendNotification } from '../../components/common/notification'

export type GetUserStateFunctionType = () => RootState

// Get user GET

export const loadUser =
  () => (dispatch: AppDispatch, getState: GetUserStateFunctionType, api: AxiosInstance) => {
    dispatch(startDataLoading(UserActionTypes.START_USER_LOADING))

    const token = getState().auth.accessToken

    api
      .get(urlJoin(USERS_ENDPOINT, ME_ENDPOINT))
      .then((res: AxiosResponse) => {
        dispatch({
          type: UserActionTypes.GET_USER_SUCCESS,
          payload: transformKeysToCamelCase({ ...res.data, isDistributor: isDistributor(token) }),
        })
      })
      .catch((err: AxiosError) =>
        dispatch(handleLoadError(err, UserActionTypes.SET_LOAD_USER_ERROR)),
      )
  }

// Edit user PATCH

export const editUser =
  (userCredentials: EditUserCredentialsType) =>
  (dispatch: AppDispatch, getState: GetUserStateFunctionType, api: AxiosInstance) => {
    dispatch(startDataLoading(UserActionTypes.START_USER_LOADING))

    const headers = {
      headers: {
        'Content-Type': CONTENT_TYPE_APPLICATION_JSON,
      },
    }

    api
      .patch(urlJoin(USERS_ENDPOINT, ME_ENDPOINT), userCredentials, headers)
      .then((res: AxiosResponse) => {
        if (res.status === 200) {
          dispatch({
            type: UserActionTypes.GET_USER_SUCCESS,
            payload: transformKeysToCamelCase(res.data),
          })

          if (userCredentials.email)
            sendInformationMessage(t(USER_SISTEM_NOTIFICATIONS.EDIT_EMAIL_SUCCESS))

          if (!userCredentials.email)
            sendNotification(MESSAGE_TYPES.SUCCESS, t(USER_SISTEM_NOTIFICATIONS.EDIT_USER_SUCCESS))
        }
      })
      .catch((err: AxiosError) =>
        dispatch(handleLoadError(err, UserActionTypes.SET_LOAD_USER_ERROR)),
      )
  }

// Delete user DELETE

export const deleteUserAccount =
  () => (dispatch: AppDispatch, getState: GetUserStateFunctionType, api: AxiosInstance) => {
    dispatch(startDataLoading(UserActionTypes.START_USER_LOADING))

    api
      .delete(urlJoin(USERS_ENDPOINT, ME_ENDPOINT))
      .then((res: AxiosResponse) => {
        if (res.status === 204) {
          localStorage?.removeItem(SESSION_ID)
          localStorage?.removeItem(REFRESH_TOKEN)
          localStorage?.removeItem(SW_REGISTRATION_ERROR)
          localStorage?.removeItem(USER_ID_KEY)

          deleteOldWorkspaceData()

          cleanCache()

          window.location.pathname = ACCOUNT_DELETION_SUCCESS_URL
        }
      })
      .catch((err: AxiosError) =>
        dispatch(handleLoadError(err, UserActionTypes.SET_LOAD_USER_ERROR)),
      )
  }

// Get user tariff limits  GET

export const getUserLimits =
  (tariffPlan: UserLimitsTariffPlanType, userId: number) =>
  (dispatch: AppDispatch, getState: GetUserStateFunctionType, api: AxiosInstance) => {
    dispatch(startDataLoading(UserActionTypes.START_USER_LOADING))

    api
      .get(urlJoin(USER_LIMITS_ENDPOINT))
      .then((res: AxiosResponse) => {
        api
          .get(urlJoin(FILE_SERVER_ENDPOINT, LIMITS_ENDPOINT, userId?.toString()))
          .then((resp: AxiosResponse) => {
            if (resp.status === 200) {
              dispatch({
                type: UserActionTypes.GET_USER_LIMITS_SUCCESS,
                payload: convertUserLimits(res.data, tariffPlan, resp.data),
              })
            }
          })
          .catch((err: AxiosError) =>
            dispatch(handleLoadError(err, UserActionTypes.SET_LOAD_USER_ERROR)),
          )
      })

      .catch((err: AxiosError) =>
        dispatch(handleLoadError(err, UserActionTypes.SET_LOAD_USER_ERROR)),
      )
  }
