export enum CHANNELS {
  LOGOUT_CHANNEL = 'logout-channel',
  REFRESH_CHANNEL = 'refresh-channel',
  LOGIN_CHANNEL = 'login-channel',
  SSO_LOGIN_CHANNEL = 'sso-login-channel',
  AUTH_ERROR_CHANNEL = 'auth-error-channel',
  SEND_PATH_CHANNEL = 'send-path-channel',
  SW_STATUS_CHANNEL = 'service-worker-status-channel',
}

export enum SW_ACTIONS {
  LOGOUT_ACT = 'LOGOUT',
  NEED_REFRESH_ACT = 'NEED_REFRESH',
  SW_REGISTER_ERROR = 'SW_REGISTER_ERROR',
  SW_REGISTER_SUCCESS = 'SW_REGISTER_SUCCESS',
  SW_REFRESH_STARTS = 'SW_REFRESH_STARTS',
  SW_REFRESH_FINISH = 'SW_REFRESH_FINISH',
}
