import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined'
import { Row } from 'antd'
import styled from 'styled-components'

import { BoxShadow, Color, FontSize, LineHeight, Spacing } from '../../../styles'

// * Styled Table * //

export const StyledTable = styled(Row)`
  margin-left: ${Spacing.sm16};
  margin-right: ${Spacing.sm8};

  .ant-table table {
    border-spacing: 0 ${Spacing.sm8};
  }

  .ant-table.ant-table-fixed-header {
    background-color: transparent;
  }

  .ant-table {
    background-color: transparent;
  }

  & .ant-table-cell-with-append {
    border-radius: ${Spacing.sm12} 0 0 ${Spacing.sm12} !important;
  }

  .ant-table-tbody > tr > td {
    border: none;
  }

  .ant-table-thead > tr > th {
    background-color: ${Color.white};
    color: ${Color.gray};
    font-size: ${FontSize.xs13};
    line-height: ${LineHeight.sm};
    font-weight: 400;
    border: none !important;
  }

  .ant-table-thead > tr {
    border: none;
    box-shadow: ${BoxShadow.header};
  }

  .searchedRows > td.ant-table-cell {
    background-color: ${Color.lightBlue} !important;
  }

  tr.ant-table-row.ant-table-row-level-0.searchedRows > td.ant-table-cell {
    background-color: ${Color.lightBlue} !important;
  }

  th.ant-table-cell.ant-table-cell-ellipsis.ant-table-column-has-sorters:hover {
    background-color: ${Color.white} !important;
  }

  & .ant-table-cell.ant-table-cell-ellipsis::before {
    background-color: ${Color.white} !important;
    border: none !important;
  }

  & .ant-table-cell::before {
    background-color: ${Color.white} !important;
    border: none !important;
  }

  tr.ant-table-row.ant-table-row-level-0 {
    background-color: ${Color.white};
  }

  tr.ant-table-row.ant-table-row-level-0 > td.ant-table-cell {
    background-color: ${Color.white} !important;
  }

  tr.ant-table-row.ant-table-row-level-0 > td.ant-table-cell.ant-table-cell-row-hover {
    background-color: #bce7fc !important;
  }

  tr.ant-table-row:hover > td {
    background-color: #bce7fc !important;
  }

  & .react-resizable {
    position: relative;
    background-clip: padding-box;
    user-select: none;
  }

  & .react-resizable-handle {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    width: 1px;
    height: 50%;
    cursor: col-resize;
    user-select: none;
    background-color: rgba(0, 0, 0, 0.06);
  }

  & .react-draggable-transparent-selection {
    user-select: none;
  }
`

// * Styled Table Wrapper* //

export type TableWrapperPropsType = {
  $isBorderRadius?: boolean
  $isPadding?: boolean
}

export const TableWrapper = styled(Row)<TableWrapperPropsType>`
  border: 1px solid ${Color.lightGray};
  padding: ${(props) => (props.$isPadding ? Spacing.sm12 : '20px 0 0 0')};
  border-radius: ${(props) => (props.$isBorderRadius ? Spacing.sm8 : 0)};

  .ant-table-wrapper {
    min-height: 250px;
  }

  .ant-table-tbody > tr > td {
    border: none;
  }

  .ant-table-thead > tr > th {
    background-color: ${Color.white} !important;
    border: none;
  }

  th.ant-table-cell.ant-table-cell-scrollbar {
    display: none;
  }

  .ant-table-cell::before {
    display: none;
  }

  .searchedRows > td.ant-table-cell {
    background-color: ${Color.lightBlue} !important;
    border-bottom: 1px solid ${Color.white} !important;
  }

  .searchedRows > .ant-table-cell-with-append {
    svg {
      color: ${Color.darkGray} !important;
    }
  }

  .searchedRows > .ant-table-cell-with-append:hover {
    svg {
      color: ${Color.white} !important;
    }
  }

  .ant-table-tbody > tr.ant-table-row.searchedRows:hover > td {
    background-color: ${Color.blue} !important;
    color: ${Color.white} !important;
  }

  .ant-table-tbody > tr.ant-table-row:hover > td {
    background-color: ${Color.blue};
    color: ${Color.white} !important;
  }

  .ant-table-tbody > tr.ant-table-row-selected > td {
    background-color: ${Color.white};
  }

  .ant-table-row > td > svg {
    color: ${Color.blue};
  }

  .ant-table-row:hover td > svg {
    color: ${Color.white};
  }
`

type HistoryTableWrapperPropsType = {
  $height?: string
  $padding?: string
}

export const HistoryTableWrapper = styled(Row)<HistoryTableWrapperPropsType>`
  padding: ${(props) => (props.$padding ? props.$padding : Spacing.sm12)};
  min-height: ${(props) => (props.$height ? props.$height : '200px')}!important;

  .ant-table-tbody > tr > td {
    border-top: 1px solid #f0f0f0 !important;
    border-bottom: none !important;
  }

  .ant-table-thead > tr > th {
    background-color: ${Color.white};
    color: ${Color.gray};
    font-size: ${FontSize.xs13};
    line-height: ${LineHeight.sm};
    font-weight: 400;
    border: none !important;

    th.ant-table-cell.ant-table-cell-scrollbar {
      border: none !important;
      box-shadow: none;
    }

    th.ant-table-cell.ant-table-cell-ellipsis:hover {
      background-color: ${Color.white} !important;
    }

    :before {
      background-color: ${Color.white} !important;
    }

    :after {
      background-color: ${Color.white} !important;
    }

    & .ant-table-cell::before {
      background-color: ${Color.white} !important;
      border: none !important;
    }
  }
`

export const StyledCheckOutlinedIcon = styled(CheckOutlinedIcon)`
  position: absolute;
  top: 16px;
  left: 12px;
`

export const StyledCheckIcon = styled(CheckOutlinedIcon)`
  position: absolute;
  top: 12px;
  right: 10px;
  margin-left: 12px;
`
