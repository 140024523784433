import type { NotificationPlacement } from 'antd/lib/notification'
import { t } from 'i18next'
import { message as antdMessage, notification } from 'antd'

import { Spacing } from '../../styles'
import { EMPTY_STRING, MESSAGE_TYPES } from '../../constants'

type NotificationType = 'success' | 'info' | 'warning' | 'error'

export const sendNotification = (
  type = 'info',
  message: string,
  description = EMPTY_STRING,
  placement: NotificationPlacement = 'bottomRight',
) => {
  setTimeout(() => {
    notification[type as NotificationType]({
      message,
      description,
      placement,
      style: { borderRadius: Spacing.sm8 },
    })
  }, 1000)
}

export const sendInformationMessage = (content: string) => {
  antdMessage.success({
    content,
    className: 'custom-class',
    style: {
      marginTop: '20vh',
    },
  })
}

export const sendLimitsErrorNotification = (message?: string, limit?: number) => {
  const limitString = limit ? ` ${limit} ${t('short-items')}` : EMPTY_STRING

  sendNotification(MESSAGE_TYPES.WARNING, `${message}${limitString}`)
}
