import { useTranslation } from 'react-i18next'
import { FC, useEffect, useState } from 'react'
import { Row, Table } from 'antd'

import { EmptyTable } from './commonComponents/EmptyTable'
import { ExpandedIcon } from './commonComponents/ExpandedIcon'
import { getItemsSelectTableColumns } from './columns/itemSelectTableColumns'
import { getRowClassName } from './helpers'
import { getStatus } from '../../api/converters'
import { prepareSearchValue } from './helpers/sorter'
import { SearchForm } from '../forms'
import { setTemporaryDataIds } from '../../store/actions'
import { TableWrapper } from './commonComponents/styled'
import { TasksColumnsDataType } from '../../api'
import { Color, Spacing } from '../../styles'
import { CREATE, DATA_TYPE } from '../../constants'
import { getFilters, getTasks, getTemporaryData } from '../../store/selectors'
import { STATUSES_COLOR_MAP, StyledTableDivider, TasksStatusesType } from '../common'
import { useTypedDispatch, useTypedSelector } from '../../hooks'

const IS_TASKS_DATA = true

export type TasksSelectTablePropsType = {
  data?: TasksColumnsDataType[]
  selectedIds?: number[]
}

export const TasksSelectTable: FC<TasksSelectTablePropsType> = ({ data, selectedIds }) => {
  const [selectedKeys, setSelectedKeys] = useState([])

  const { statuses } = useTypedSelector(getTasks)
  const { tempIds } = useTypedSelector(getTemporaryData)
  const { searchValue } = useTypedSelector(getFilters)

  const dispatch = useTypedDispatch()

  const { t } = useTranslation()

  const handleSelectItem = (sKey: number) => {
    if (selectedIds.includes(sKey)) return

    if (!selectedKeys.includes(sKey)) {
      setSelectedKeys([...selectedKeys, sKey])
      dispatch(setTemporaryDataIds(DATA_TYPE.TASKS, [...selectedKeys, sKey]))
    }

    if (selectedKeys.includes(sKey)) {
      setSelectedKeys(selectedKeys.filter((i) => i !== sKey))
      dispatch(
        setTemporaryDataIds(
          DATA_TYPE.TASKS,
          selectedKeys.filter((i) => i !== sKey),
        ),
      )
    }
  }

  const handleSearchRow = (key: number) =>
    getRowClassName(data, key, prepareSearchValue(searchValue.tasks))

  useEffect(() => {
    if (!tempIds.tasks) setSelectedKeys(selectedIds)
  }, [selectedIds, tempIds])

  return (
    <TableWrapper>
      <Row align="middle">
        <SearchForm $isModal dataType={DATA_TYPE.TASKS} />
        <StyledTableDivider orientation="center" $mt={Spacing.sm8} $mb={Spacing.sm12} />
      </Row>

      <Table
        columns={getItemsSelectTableColumns(selectedKeys, IS_TASKS_DATA)}
        dataSource={data || []}
        bordered={false}
        pagination={false}
        scroll={data.length > 6 ? { y: '350px' } : {}}
        showHeader={false}
        rowClassName={(record) => handleSearchRow(record.key)}
        onRow={(record) => ({
          onClick: () => handleSelectItem(record.key),
        })}
        locale={{
          emptyText: (
            <EmptyTable
              prompt={t('no-items')}
              hasButton
              navigationPath={`/${DATA_TYPE.TASKS}/${CREATE}`}
            />
          ),
        }}
        expandable={{
          defaultExpandAllRows: true,
          indentSize: 20,
          expandIcon: ({ expanded, onExpand, record }) => (
            <ExpandedIcon
              expanded={expanded}
              record={record}
              onExpand={onExpand}
              color={
                record
                  ? STATUSES_COLOR_MAP.tasks[
                      getStatus(record.statusId, statuses).code as keyof TasksStatusesType
                    ].brightColor
                  : Color.green
              }
            />
          ),
        }}
        style={{ minWidth: '100%', margin: '0' }}
      />
    </TableWrapper>
  )
}
