import { AxiosError } from 'axios'

import { IPpwGraphics, ITaskGraphics } from '../../api'

export type PpwGraphicsStateType = {
  isPpwGraphicsLoading: boolean
  ppwGraphics: IPpwGraphics[] | null
  ppwGraphicsItem: IPpwGraphics | null
  rules: ITaskGraphics
  createdId: string | undefined
  loadPpwGraphicsError: AxiosError | null
}

export enum PpwGraphicsActionTypes {
  START_PPW_GRAPHICS_LOADING = 'START_PPW_GRAPHICS_LOADING',
  GET_PPW_GRAPHICS_SUCCESS = 'GET_PPW_GRAPHICS_SUCCESS',
  GET_PPW_GRAPHICS_ITEM_SUCCESS = 'GET_PPW_GRAPHICS_ITEM_SUCCESS',
  GET_RULES_SUCCESS = 'GET_RULES_SUCCESS',
  EDIT_PPW_GRAPHICS_ITEM_SUCCESS = 'EDIT_PPW_GRAPHICS_ITEM_SUCCESS',
  CREATE_PPW_GRAPHICS_SUCCESS = 'CREATE_PPW_GRAPHICS_SUCCESS',
  DELETE_PPW_GRAPHICS_SUCCESS = 'DELETE_PPW_GRAPHICS_SUCCESS',
  SET_LOAD_PPW_GRAPHICS_ERROR = 'SET_LOAD_PPW_GRAPHICS_ERROR',
  SET_CREATED_PPW_GRAPHICS_ID = 'SET_CREATED_PPW_GRAPHICS_ID',
}

export type StartPpwGraphicsLoadingActionType = {
  type: PpwGraphicsActionTypes.START_PPW_GRAPHICS_LOADING
}

export type GetPpwGraphicsSuccessActionType = {
  type: PpwGraphicsActionTypes.GET_PPW_GRAPHICS_SUCCESS
  payload: IPpwGraphics[]
}

export type GetPpwGraphicsItemSuccessActionType = {
  type: PpwGraphicsActionTypes.GET_PPW_GRAPHICS_ITEM_SUCCESS
  payload: IPpwGraphics
}

export type GetRulesSuccessActionType = {
  type: PpwGraphicsActionTypes.GET_RULES_SUCCESS
  payload: ITaskGraphics
}

export type EditPpwGraphicsSuccessActionType = {
  type: PpwGraphicsActionTypes.EDIT_PPW_GRAPHICS_ITEM_SUCCESS
  payload: IPpwGraphics[]
}

export type CreatePpwGraphicsSuccessActionType = {
  type: PpwGraphicsActionTypes.CREATE_PPW_GRAPHICS_SUCCESS
  payload: IPpwGraphics
}

export type SetPpwGraphicsErrorSuccessActionType = {
  type: PpwGraphicsActionTypes.SET_LOAD_PPW_GRAPHICS_ERROR
  payload: AxiosError
}

export type SetCreatedPpwGraphicsIdActionType = {
  type: PpwGraphicsActionTypes.SET_CREATED_PPW_GRAPHICS_ID
  payload: string | undefined
}

export type DeletePpwGraphicsIdActionType = {
  type: PpwGraphicsActionTypes.DELETE_PPW_GRAPHICS_SUCCESS
  payload: number
}

export type PpwGraphicsActionType =
  | StartPpwGraphicsLoadingActionType
  | GetPpwGraphicsSuccessActionType
  | GetPpwGraphicsItemSuccessActionType
  | GetRulesSuccessActionType
  | EditPpwGraphicsSuccessActionType
  | CreatePpwGraphicsSuccessActionType
  | DeletePpwGraphicsIdActionType
  | SetPpwGraphicsErrorSuccessActionType
  | SetCreatedPpwGraphicsIdActionType
