import { t } from 'i18next'
import urlJoin from 'url-join'
import { AxiosError, AxiosInstance, AxiosResponse } from 'axios'

import { AppDispatch } from '../../hooks'
import { handleLoadError } from './errors'
import { ITag } from '../../api'
import { RootState } from '../reducers/rootReducer'
import { sendNotification } from '../../components/common/notification'
import { TAGS_ENDPOINT } from '../../env'
import { TagsActionTypes } from '../actionTypes'
import {
  COMMON_SISTEM_NOTIFICATIONS,
  MESSAGE_TYPES,
  STATUS_CODES,
  TAGS_SISTEM_NOTIFICATIONS,
} from '../../constants'
import { getBasePath, startDataLoading } from './utils'

type GetTagsStateFunctionType = () => RootState

// Get Tags     GET

export const loadTags =
  (tagsType: string) =>
  (dispatch: AppDispatch, getState: GetTagsStateFunctionType, api: AxiosInstance) => {
    dispatch(startDataLoading(TagsActionTypes.START_TAGS_LOADING))

    api
      .get<ITag[]>(urlJoin(getBasePath(tagsType), TAGS_ENDPOINT))
      .then((res: AxiosResponse) => {
        dispatch({
          type: TagsActionTypes.LOAD_TAGS_SUCCESS,
          payload: res.data.data,
        })
      })
      .catch((err: AxiosError) => dispatch(handleLoadError(err, TagsActionTypes.LOAD_TAGS_ERROR)))
  }

// Delete Tags     DELETE

export const deleteTags =
  (tagId: number, tagsType: string) =>
  (dispatch: AppDispatch, getState: GetTagsStateFunctionType, api: AxiosInstance) => {
    dispatch(startDataLoading(TagsActionTypes.START_TAGS_LOADING))

    api
      .delete<ITag[]>(urlJoin(getBasePath(tagsType), TAGS_ENDPOINT, tagId.toString()))
      .then((res: AxiosResponse) => {
        if (res.status === STATUS_CODES.CODE_204) {
          dispatch(loadTags(tagsType))

          sendNotification(MESSAGE_TYPES.SUCCESS, t(TAGS_SISTEM_NOTIFICATIONS.DELETE_TAG_SUCCESS))
        }
      })
      .catch((err: AxiosError) => dispatch(handleLoadError(err, TagsActionTypes.LOAD_TAGS_ERROR)))
  }

export const saveTags = (tag: ITag) => (dispatch: AppDispatch) => {
  dispatch({
    type: TagsActionTypes.SAVE_TEMPORARY_TAGS,
    payload: tag,
  })

  sendNotification(
    MESSAGE_TYPES.SUCCESS,
    t(COMMON_SISTEM_NOTIFICATIONS.SAVE_TEMPORARY_DATA_SUCCESS),
  )
}

export const editTagColor = (name: string, color: string) => (dispatch: AppDispatch) => {
  dispatch({
    type: TagsActionTypes.EDIT_TEMPORARY_TAG_COLOR,
    payload: { name, color },
  })
}

export const editTemporaryTagItem = (tag: string, newTag: string) => (dispatch: AppDispatch) => {
  dispatch({
    type: TagsActionTypes.EDIT_TEMPORARY_TAG,
    payload: { tag, newTag },
  })
}

export const deleteTemporaryTagItem = (name: string) => (dispatch: AppDispatch) => {
  dispatch({
    type: TagsActionTypes.DELETE_TEMPORARY_TAG_ITEM,
    payload: name,
  })
}

export const saveDeletedTagId = (id: number) => (dispatch: AppDispatch) => {
  dispatch({
    type: TagsActionTypes.SAVE_DELETED_TAG_ID,
    payload: id,
  })

  sendNotification(
    MESSAGE_TYPES.SUCCESS,
    t(COMMON_SISTEM_NOTIFICATIONS.SAVE_TEMPORARY_DATA_SUCCESS),
  )
}
