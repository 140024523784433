import localeEn from 'antd/es/date-picker/locale/en_US'
import localeRu from 'antd/es/date-picker/locale/ru_RU'
import moment from 'moment'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Col, DatePicker, Radio, RadioChangeEvent, Row, Typography } from 'antd'
import { FC, memo, ReactNode, useCallback, useEffect, useRef, useState } from 'react'

import 'moment/locale/ru'

import { getLanguage } from '../../functions'
import { HISTORY_RANGE_VALUES } from '../../api'
import { Spacing } from '../../styles'
import { StyledRangeDateCardWrapper } from '../common'
import { DATE_IN_MILLISECONDS, HISTORY_CREATE_DATE, LANGUAGES } from '../../constants'

const { RangePicker } = DatePicker

const { Text } = Typography

export const StyledRangePicker = styled(RangePicker)`
  border-radius: ${Spacing.sm8};
  width: 300px;
  margin-left: ${Spacing.sm12};
`

export const StyledRadio = styled(Radio)`
  margin-right: ${Spacing.md40};
`

export const ButtonWrapper = styled(Row)`
  margin: ${Spacing.sm8} 0;
`

export const StyledContainer = styled(Row)`
  height: 56px;

  .inner-block {
    margin-left: ${Spacing.sm8};
  }
`

export type RangeDateCardPropsType = {
  onRangeChange?: (startDate: string, endDate: string) => void
  onRangeRadioChange?: (value: HISTORY_RANGE_VALUES) => void
  isHistoryEmpty?: boolean
  actionButton?: ReactNode
}

export const RangeDateCard: FC<RangeDateCardPropsType> = memo(
  ({ onRangeChange, onRangeRadioChange, isHistoryEmpty, actionButton }) => {
    const [value, setValue] = useState(HISTORY_RANGE_VALUES.ALL)
    const [datesValue, setDatesValue] = useState(null)
    const [isPickerOpen, setIsPickerOpen] = useState(false)

    const picker = useRef(null)

    const { t } = useTranslation()

    const handleChangeRadioButton = useCallback(
      (buttonValue: HISTORY_RANGE_VALUES) => {
        if (onRangeRadioChange) onRangeRadioChange(buttonValue)
      },
      [onRangeRadioChange],
    )

    const getStartDateInMs = useCallback(
      (now: number) => {
        const createDate = Date.parse(new Date(HISTORY_CREATE_DATE).toISOString())

        if (value === HISTORY_RANGE_VALUES.MONTH) return now - DATE_IN_MILLISECONDS.month
        if (value === HISTORY_RANGE_VALUES.YEAR) return now - DATE_IN_MILLISECONDS.year
        if (value === HISTORY_RANGE_VALUES.ALL) return createDate

        return createDate
      },
      [value],
    )

    const getStartDate = useCallback(() => {
      const now = Date.parse(new Date().toISOString())
      const startDateInMs = getStartDateInMs(now)
      return new Date(startDateInMs).toISOString().split('T')[0]
    }, [getStartDateInMs])

    const getEndDate = useCallback(() => {
      const now = Date.parse(new Date().toISOString())
      return new Date(now).toISOString().split('T')[0]
    }, [])

    const handleOnChange = (e: RadioChangeEvent) => {
      setValue(e.target.value)
      handleChangeRadioButton(e.target.value)
      setDatesValue(null)
    }

    const handleOpenDatePicker = (isOpen: boolean) => {
      if (isOpen) {
        setValue(HISTORY_RANGE_VALUES.EMPTY)
        handleChangeRadioButton(HISTORY_RANGE_VALUES.EMPTY)
      }

      setIsPickerOpen(isOpen)
    }

    const handleSelectDates = (dates: string[]) => {
      setDatesValue(dates)

      if (dates.filter((i) => i).length) {
        setDatesValue(dates)

        onRangeChange(dates[0], dates[1])
      } else {
        setValue(HISTORY_RANGE_VALUES.ALL)
        setDatesValue(null)
        handleChangeRadioButton(HISTORY_RANGE_VALUES.ALL)
      }
    }

    const deleteFocus = () => {
      const pickerElement = picker.current

      pickerElement.blur()
    }

    const getInitialSelectValue = useCallback(() => {
      setValue(value)
      handleChangeRadioButton(value)

      if (value || !isPickerOpen) deleteFocus()

      if (!value && !datesValue && !isPickerOpen) {
        handleChangeRadioButton(value)
        setValue(HISTORY_RANGE_VALUES.ALL)
      }
    }, [value, datesValue, isPickerOpen, handleChangeRadioButton])

    useEffect(() => {
      if (!datesValue) onRangeChange(getStartDate(), getEndDate())

      getInitialSelectValue()
    }, [getInitialSelectValue, onRangeChange, getEndDate, getStartDate, datesValue])

    return (
      <StyledRangeDateCardWrapper xs={24} $ml="0px">
        <StyledContainer justify="space-between" align="middle">
          <Col className="inner-block">
            <Radio.Group
              value={value}
              onChange={handleOnChange}
              disabled={isHistoryEmpty && value === HISTORY_RANGE_VALUES.ALL}
            >
              <StyledRadio value={HISTORY_RANGE_VALUES.ALL}>{t('radio-value-all')}</StyledRadio>

              <StyledRadio value={HISTORY_RANGE_VALUES.MONTH}>{t('radio-value-month')}</StyledRadio>

              <StyledRadio value={HISTORY_RANGE_VALUES.YEAR}>{t('radio-value-year')}</StyledRadio>
            </Radio.Group>

            <Text>{t('radio-value-dates')}:</Text>

            <StyledRangePicker
              onChange={(mom, datesArray) => handleSelectDates(datesArray)}
              value={datesValue ? [moment(datesValue[1]), moment(datesValue[0])] : null}
              onOpenChange={(isOpen) => handleOpenDatePicker(isOpen)}
              locale={getLanguage() === LANGUAGES.RU ? localeRu : localeEn}
              allowEmpty={[false, false]}
              ref={picker}
              disabled={isHistoryEmpty && value === HISTORY_RANGE_VALUES.ALL}
            />
          </Col>

          <Col> {actionButton}</Col>
        </StyledContainer>
      </StyledRangeDateCardWrapper>
    )
  },
)
