import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { FC, memo, ReactNode, useState } from 'react'

import { getBaseLocation } from '../../functions'
import { SelectTableModal } from '../tables'
import { sendNotification } from '../common/notification'
import { setSearchValue } from '../../store/actions'
import {
  CARD_WIDTH,
  COMMON_SISTEM_NOTIFICATIONS,
  CURRENT_LOCATION,
  DATA_TYPE,
  EMPTY_STRING,
  MESSAGE_TYPES,
} from '../../constants'
import { CardTitle, PlannyButton, StyledSmallPanelCard } from '../common'
import { Color, Spacing } from '../../styles'
import { getTemporaryData, getUserServices } from '../../store/selectors'
import { useTypedDispatch, useTypedSelector } from '../../hooks'

type UserServicePanelPropsType = {
  modalPrompt: string
  buttonText: string
  table: ReactNode
  cardContent: ReactNode
  isSubmitButtonDisabled?: boolean
  $cardWidth?: string
}

export const UserServicePanel: FC<UserServicePanelPropsType> = memo(
  ({
    modalPrompt,
    buttonText,
    table,
    cardContent,
    isSubmitButtonDisabled = false,
    $cardWidth = CARD_WIDTH.SMALL_CARD,
  }) => {
    const [isModalVisible, setIsModalVisible] = useState(false)

    const { tempIds } = useTypedSelector(getTemporaryData)
    const { userServices } = useTypedSelector(getUserServices)

    const dispatch = useTypedDispatch()

    const { t } = useTranslation()

    const { pathname } = useLocation()

    const currentLocation = getBaseLocation(pathname)

    const showModal = () => setIsModalVisible(true)

    const getTitle = () => {
      if (currentLocation === CURRENT_LOCATION.USER_SERVICES) return t('user-services-card-title')

      return t('responsible-user-card-title')
    }

    const resetSearchValue = () => {
      dispatch(setSearchValue(DATA_TYPE.USER_SERVICES, EMPTY_STRING))
    }

    const handleCancel = () => {
      setIsModalVisible(false)
      resetSearchValue()
    }

    const handleSave = () => {
      setIsModalVisible(false)
      resetSearchValue()

      if (tempIds.userServices !== undefined)
        sendNotification(
          MESSAGE_TYPES.SUCCESS,
          t(COMMON_SISTEM_NOTIFICATIONS.SAVE_TEMPORARY_DATA_SUCCESS),
        )
    }
    return (
      <>
        <SelectTableModal
          title={getTitle()}
          subTitle={modalPrompt}
          dataType={DATA_TYPE.USER_SERVICES}
          onCancel={handleCancel}
          handleSave={handleSave}
          icon={<PeopleAltOutlinedIcon />}
          table={table}
          visible={isModalVisible}
          isSubmitButtonDisabled={isSubmitButtonDisabled}
        />
        <StyledSmallPanelCard
          title={
            <CardTitle
              icon={<PeopleAltOutlinedIcon />}
              $iconColor={Color.gray}
              $mbIcon={Spacing.sm6}
            >
              {getTitle()}
            </CardTitle>
          }
          actions={[
            <PlannyButton
              type="primary"
              ghost
              onClick={showModal}
              $marginBottom={Spacing.sm8}
              disabled={!userServices || (userServices && !userServices.length)}
            >
              {buttonText}
            </PlannyButton>,
          ]}
          $width={$cardWidth}
          bordered={false}
        >
          {cardContent || null}
        </StyledSmallPanelCard>
      </>
    )
  },
)
