import { FC } from 'react'
import HeadsetMicOutlinedIcon from '@mui/icons-material/HeadsetMicOutlined'
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined'
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest'
import { useTranslation } from 'react-i18next'
import { Col, Row } from 'antd'

import { ClosestTasks } from './ClosestTasks'
import { EquipmentStatusesPie } from './EquipmentStatusesPie'
import { MaterialsAmountPie } from './MaterialsAmountPie'
import { TasksStatusesPie } from './TasksStatusesPie'
import { useBreakpoint } from '../../hooks'
import { UserServicesStatusesPie } from './UserServicesStatusesPie'
import { BREAKPOINTS, CARD_WIDTH } from '../../constants'
import { CardTitle, NutIcon, StyledCard } from '../common'
import { Color, Spacing } from '../../styles'

export const DashboardMainBlock: FC = () => {
  const { t } = useTranslation()

  const breakpoint = useBreakpoint()

  const getMultiplier = () => {
    if (breakpoint === BREAKPOINTS.xxxl || breakpoint === BREAKPOINTS.xxl) return 1.8

    if (breakpoint === BREAKPOINTS.xl) return 1.7

    return 1.6
  }

  return (
    <Row gutter={[10, 10]}>
      <Col xs={8} md={8} lg={8} xl={6} xxl={6}>
        <ClosestTasks />
      </Col>

      <Col xs={8} md={8} lg={8} xl={9} xxl={7}>
        <StyledCard
          title={
            <CardTitle
              icon={<HeadsetMicOutlinedIcon />}
              $iconColor={Color.darkBlue}
              $pb={Spacing.sm2}
            >
              {t('tasks')}
            </CardTitle>
          }
          $width={CARD_WIDTH.WIDTH_100_PER}
          $pt={Spacing.sm2}
          $pl={Spacing.sm2}
          $pr={Spacing.sm2}
          $pb={Spacing.md20}
          $mr={Spacing.sm8}
        >
          <TasksStatusesPie multiplier={getMultiplier()} />
        </StyledCard>

        <StyledCard
          title={
            <CardTitle
              icon={<PeopleAltOutlinedIcon />}
              $iconColor={Color.darkBlue}
              $pb={Spacing.sm2}
            >
              {t('user-services')}
            </CardTitle>
          }
          $width={CARD_WIDTH.WIDTH_100_PER}
          $pt={Spacing.sm2}
          $pl={Spacing.sm2}
          $pr={Spacing.sm2}
          $pb={Spacing.md20}
          $mr={Spacing.sm8}
        >
          <UserServicesStatusesPie multiplier={getMultiplier()} />
        </StyledCard>
      </Col>

      <Col xs={8} md={8} lg={8} xl={9} xxl={7}>
        <StyledCard
          title={
            <CardTitle icon={<SettingsSuggestIcon />} $pb={Spacing.sm2} $iconColor={Color.darkBlue}>
              {t('equipment')}
            </CardTitle>
          }
          $width={CARD_WIDTH.WIDTH_100_PER}
          $pt={Spacing.sm2}
          $pl={Spacing.sm2}
          $pr={Spacing.sm2}
          $pb={Spacing.md20}
        >
          <EquipmentStatusesPie multiplier={getMultiplier()} />
        </StyledCard>

        <StyledCard
          title={
            <CardTitle icon={<NutIcon $headerIcon />} $pb={Spacing.sm2}>
              {t('materials')}
            </CardTitle>
          }
          $width={CARD_WIDTH.WIDTH_100_PER}
          $pt={Spacing.sm2}
          $pl={Spacing.sm2}
          $pr={Spacing.sm2}
          $pb={Spacing.md20}
        >
          <MaterialsAmountPie multiplier={getMultiplier()} />
        </StyledCard>
      </Col>
    </Row>
  )
}
