import { compare, getFilteredItemsByName, idCondition, uniqueObjectsArray } from './filter'
import {
  EquipmentColumnsDataType,
  IEquipment,
  IMaterial,
  IPlaces,
  ITask,
  IUserServices,
  PlacesColumnsDataType,
  StructureTableType,
  UserServicesColumnsDataType,
} from '../../../api'
import {
  getEquipmentTableHierarchy,
  getPlacesTableHierarchy,
  getStructureTableHierarchy,
  getUserServicesTableHierarchy,
} from './getTableRowStructure'
import {
  getSearchedEquipmentItems,
  getSearchedPlaceItems,
  getSearchedUserServicesItems,
  prepareSearchValue,
} from './sorter'

export const getParentItems = (data: StructureTableType[], preparedData: StructureTableType[]) => {
  if (preparedData && data) {
    const parentElements = preparedData.map((element) => {
      if (element.hierarchy.length && element.parentId) {
        return data.filter((item) => item.id === element.hierarchy[0])[0]
      }

      return element
    })

    return uniqueObjectsArray(parentElements, idCondition).filter((i) => i.parentId === null)
  }

  return []
}

// Rename
export const getDataHierarchy = (
  data: StructureTableType[],
  searchValue?: string,
): (EquipmentColumnsDataType | PlacesColumnsDataType | UserServicesColumnsDataType)[] => {
  const search = prepareSearchValue(searchValue)
  const preparedData = search ? getFilteredItemsByName(data, search) : data
  const parents = getParentItems(data, preparedData)
  const tData = getStructureTableHierarchy(parents, data)

  return tData.sort(compare)
}

export const getEquipmentTableData = (
  data: StructureTableType[],
  searchValue?: string,
  query?: string,
) => {
  const search = prepareSearchValue(searchValue)
  const preparedData = search ? getSearchedEquipmentItems(data, search) : data
  const parents = getParentItems(data, preparedData)

  const tData = getEquipmentTableHierarchy(parents, data, query)

  return tData.sort(compare)
}

export const getPlacesTableData = (
  data: StructureTableType[],
  searchValue?: string,
  query?: string,
) => {
  const search = prepareSearchValue(searchValue)
  const preparedData = search ? getSearchedPlaceItems(data, search) : data
  const parents = getParentItems(data, preparedData)

  const tData = getPlacesTableHierarchy(parents, data, query)

  return tData.sort(compare)
}

export const getUserServicesTableData = (
  data: UserServicesColumnsDataType[],
  searchValue?: string,
  query?: string,
) => {
  const search = prepareSearchValue(searchValue)
  const preparedData = search ? getSearchedUserServicesItems(data, search) : data
  const parents = getParentItems(data, preparedData)

  const tData = getUserServicesTableHierarchy(parents, data, query)

  return tData.sort(compare)
}

export const getTableData = (data: IMaterial[], searchValue?: string) => {
  const search = prepareSearchValue(searchValue)
  const filtredItems = search ? getFilteredItemsByName(data, searchValue) : data

  return filtredItems
    .map(({ id, name, partNumber }: IMaterial) => {
      return {
        id,
        key: id,
        name,
        partNumber,
      }
    })
    .sort(compare)
}

export const getHierarchicalSelectTableData = (
  data: (IEquipment | IPlaces | IUserServices)[],
  searchValue?: string,
) => (data && data.length ? getDataHierarchy(data, searchValue) : [])

export const getSelectTableData = (data: IMaterial[], searchValue?: string) =>
  data && data.length ? getTableData(data, searchValue) : []

export const findItemById = (
  data: (IEquipment | IPlaces | IUserServices | ITask)[],
  id: number,
) => {
  if (data) {
    const [dataItem] = data.filter(
      (item: IEquipment | IPlaces | IUserServices | ITask) => item.id === id,
    )

    return dataItem || undefined
  }

  return undefined
}
