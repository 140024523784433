import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Col, Row } from 'antd'
import { FC, ReactElement, ReactNode } from 'react'

import { getBaseLocation } from '../../functions'
import { getUnitPageTitle } from './helpers'
import { StyledHeaderTitle, StyledText, TitleWrapper } from './styled'

export type DashboardPageHeaderPropsType = {
  pageTitle: string
  headerIcon: ReactElement
  navigationTabs: ReactNode
  buttonGroup: ReactNode
}

export const DashboardPageHeader: FC<DashboardPageHeaderPropsType> = ({
  pageTitle,
  headerIcon,
  navigationTabs,
  buttonGroup,
}) => {
  const { t } = useTranslation()

  const { pathname } = useLocation()

  const currentLocation = getBaseLocation(pathname)

  return (
    <Row justify="space-between" align="middle" style={{ width: '100%' }}>
      <StyledText style={{ position: 'absolute' }}>
        {t(getUnitPageTitle(currentLocation))}
      </StyledText>

      <Col>
        <StyledHeaderTitle>
          <TitleWrapper $cursor={false}>
            {headerIcon} {pageTitle}
          </TitleWrapper>
        </StyledHeaderTitle>
      </Col>

      {navigationTabs && (
        <Col>
          <Row align="middle">{navigationTabs}</Row>
        </Col>
      )}

      {buttonGroup && <Col>{buttonGroup}</Col>}
    </Row>
  )
}
