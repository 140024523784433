import { FC } from 'react'
import styled from 'styled-components'

import { EMPTY_STRING } from '../../constants'
import { Color, Spacing } from '../../styles'

type StyledNutIconPropsType = {
  space?: string
  $isSiderCollapsed?: boolean
  $outlineIcon?: boolean
  $headerIcon?: boolean
}

const StyledNutIcon = styled.svg<StyledNutIconPropsType>`
  width: ${(props) => (props.$isSiderCollapsed ? Spacing.sm16 : Spacing.md24)};
  height: ${(props) => (props.$isSiderCollapsed ? Spacing.sm16 : Spacing.md24)};
  margin-left: ${(props) => (props.$isSiderCollapsed ? -Spacing.sm6 : 0)};
  color: ${Color.darkBlue} !important;
`

type StyledTableNutIconProps = {
  $cursor?: boolean
  $color?: string
}

export const StyledTableNutIcon = styled.svg<StyledTableNutIconProps>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  width: ${Spacing.md24};
  height: ${Spacing.md24};
  text-align: center;
  cursor: ${(props) => (props.$cursor ? 'pointer' : EMPTY_STRING)};

  :hover path {
    stroke: ${(props) =>
      props.$cursor ? Color.blue : props.$color ? props.$color : EMPTY_STRING} !important;
  }
`

type NutIconPropsType = {
  $isSiderCollapsed?: boolean
  $outlineIcon?: boolean
  $headerIcon?: boolean
  $tableIcon?: boolean
  $cursor?: boolean
  $color?: string
  className?: string
  onClick?: () => void
}

export const NutIcon: FC<NutIconPropsType> = ({
  $outlineIcon,
  $isSiderCollapsed,
  $headerIcon,
  $tableIcon,
  $cursor,
  $color,
  className,
  onClick,
}) => {
  const d =
    'M21,16.5C21,16.88 20.79,17.21 20.47,17.38L12.57,21.82C12.41,21.94 12.21,22 12,22C11.79,22 11.59,21.94 11.43,21.82L3.53,17.38C3.21,17.21 3,16.88 3,16.5V7.5C3,7.12 3.21,6.79 3.53,6.62L11.43,2.18C11.59,2.06 11.79,2 12,2C12.21,2 12.41,2.06 12.57,2.18L20.47,6.62C20.79,6.79 21,7.12 21,7.5V16.5M12,7C9.24,7 7,9.24 7,12C7,14.76 9.24,17 12,17C14.76,17 17,14.76 17,12C17,9.24 14.76,7 12,7Z'

  return !$tableIcon ? (
    <StyledNutIcon
      $outlineIcon={$outlineIcon}
      $isSiderCollapsed={$isSiderCollapsed}
      $headerIcon={$headerIcon}
      className="ant-menu-item-icon"
      viewBox="0 0 24 24"
      onClick={onClick}
    >
      <path
        d={d}
        fill={`${$outlineIcon ? 'none' : Color.darkBlue}`}
        strokeWidth={`${$outlineIcon ? 1.5 : EMPTY_STRING}`}
        stroke={`${$outlineIcon ? Color.darkBlue : EMPTY_STRING}`}
      />
    </StyledNutIcon>
  ) : (
    <StyledTableNutIcon
      className={className}
      viewBox="0 0 24 24"
      onClick={onClick}
      $cursor={$cursor}
      $color={$color}
    >
      <path
        d={d}
        fill={`${$outlineIcon ? 'none' : Color.gray}`}
        strokeWidth={`${1.5}`}
        stroke={$color || Color.gray}
      />
    </StyledTableNutIcon>
  )
}
