import { AxiosError } from 'axios'
import { IStatus, ITask, ITaskHistory, PrioritiesType, RuleDataType, TaskRuleType } from '../../api'

export type TasksStateType = {
  tasks: ITask[]
  statuses: IStatus[]
  taskItem: ITask | undefined
  history: ITaskHistory[]
  priorities: PrioritiesType[]
  createdId: string | undefined
  taskRule: RuleDataType

  isTasksLoading: boolean
  isTaskItemLoading: boolean
  isTaskHistoryLoading: boolean
  isTaskStatusesLoading: boolean
  isTaskPrioritiesLoading: boolean
  isTaskRulesLoading: boolean

  tasksError: null | AxiosError
  taskItemError: null | AxiosError
  taskStatusesError: null | AxiosError
  taskHistoryError: null | AxiosError
  taskPrioritiesError: null | AxiosError
  taskRulesError: null | AxiosError
}

export enum TasksActionTypes {
  START_TASKS_LOADING = 'START_TASKS_LOADING',
  START_TASK_ITEM_LOADING = 'START_TASK_ITEM_LOADING',
  START_TASK_STATUSES_LOADING = 'START_TASK_STATUSES_LOADING',
  START_TASK_HISTORY_LOADING = 'START_TASK_HISTORY_LOADING',
  START_TASK_PRIORITIES_LOADING = 'START_TASK_PRIORITIES_LOADING',
  START_TASK_RULES_LOADING = 'START_TASK_RULES_LOADING',

  LOAD_TASKS_SUCCESS = 'LOAD_TASKS_SUCCESS',
  LOAD_TASK_HISTORY_SUCCESS = 'LOAD_TASK_HISTORY_SUCCESS',
  LOAD_TASK_STATUSES_SUCCESS = 'LOAD_TASK_STATUSES_SUCCESS',
  LOAD_TASK_ITEM_SUCCESS = 'LOAD_TASK_ITEM_SUCCESS',
  LOAD_TASK_PRIORITIES_SUCCESS = 'LOAD_TASK_PRIORITIES_SUCCESS',
  LOAD_TASK_RULES_SUCCESS = 'LOAD_TASK_RULES_SUCCESS',

  CREATE_TASK_ITEM_SUCCESS = 'CREATE_TASK_ITEM_SUCCESS',
  SET_CREATED_TASK_ID = 'SET_CREATED_TASK_ID',
  DELETE_TASK_ITEM_SUCCESS = 'DELETE_TASK_ITEM_SUCCESS',
  DELETE_TASK_RULE_SUCCESS = 'DELETE_TASK_RULE_SUCCESS',
  UPDATE_TASK_RULES_SUCCESS = 'UPDATE_TASK_RULES_SUCCESS',

  EDIT_TASK_HISTORY_ITEM_SUCCESS = 'EDIT_TASK_HISTORY_ITEM_SUCCESS',
  DELETE_TASK_HISTORY_ITEM_SUCCESS = 'DELETE_TASK_HISTORY_ITEM_SUCCESS',

  DELETE_TASK_ITEM_FROM_STATE = 'DELETE_TASK_ITEM_FROM_STATE',

  LOAD_TASKS_ERROR = 'LOAD_TASKS_ERROR',
  LOAD_TASK_STATUSES_ERROR = 'LOAD_TASK_STATUSES_ERROR',
  LOAD_TASK_ITEM_ERROR = 'LOAD_TASK_ITEM_ERROR',
  LOAD_TASK_HISTORY_ERROR = 'LOAD_TASK_HISTORY_ERROR',
  LOAD_TASK_PRIORITIES_ERROR = 'LOAD_TASK_PRIORITIES_ERROR',
  LOAD_TASK_RULES_ERROR = 'LOAD_TASK_RULES_ERROR',
}

export type StartTasksLoadingActionType = {
  type: TasksActionTypes.START_TASKS_LOADING
}

export type StartTaskItemLoadingActionType = {
  type: TasksActionTypes.START_TASK_ITEM_LOADING
}

export type StartTaskStatusesLoadingActionType = {
  type: TasksActionTypes.START_TASK_STATUSES_LOADING
}

export type StartTaskHistoryLoadingActionType = {
  type: TasksActionTypes.START_TASK_HISTORY_LOADING
}

export type StartTaskPrioritiesLoadingActionType = {
  type: TasksActionTypes.START_TASK_PRIORITIES_LOADING
}

export type StartTasksRulesLoadingActionType = {
  type: TasksActionTypes.START_TASK_RULES_LOADING
}

export type LoadTasksSuccessActionType = {
  type: TasksActionTypes.LOAD_TASKS_SUCCESS
  payload: ITask[]
}

export type LoadTaskStatusesSuccessActionType = {
  type: TasksActionTypes.LOAD_TASK_STATUSES_SUCCESS
  payload: IStatus[]
}

export type LoadTaskHistorySuccessActionType = {
  type: TasksActionTypes.LOAD_TASK_HISTORY_SUCCESS
  payload: ITaskHistory[]
}

export type LoadTaskItemSuccessActionType = {
  type: TasksActionTypes.LOAD_TASK_ITEM_SUCCESS
  payload: ITask
}

export type LoadTaskPrioritiesSuccessActionType = {
  type: TasksActionTypes.LOAD_TASK_PRIORITIES_SUCCESS
  payload: PrioritiesType[]
}

export type LoadTaskRulesSuccessActionType = {
  type: TasksActionTypes.LOAD_TASK_RULES_SUCCESS
  payload: TaskRuleType
}

export type LoadingTasksErrorActionType = {
  type: TasksActionTypes.LOAD_TASKS_ERROR
  payload: AxiosError
}

export type LoadingTaskStatusesErrorActionType = {
  type: TasksActionTypes.LOAD_TASK_STATUSES_ERROR
  payload: AxiosError
}

export type LoadingTaskItemErrorActionType = {
  type: TasksActionTypes.LOAD_TASK_ITEM_ERROR
  payload: AxiosError
}

export type LoadingTaskHistoryErrorActionType = {
  type: TasksActionTypes.LOAD_TASK_HISTORY_ERROR
  payload: AxiosError
}

export type LoadingTaskPrioritiesErrorActionType = {
  type: TasksActionTypes.LOAD_TASK_PRIORITIES_ERROR
  payload: AxiosError
}

export type LoadingTaskRulesErrorActionType = {
  type: TasksActionTypes.LOAD_TASK_RULES_ERROR
  payload: AxiosError
}

export type CreateTaskItemSuccessActionType = {
  type: TasksActionTypes.CREATE_TASK_ITEM_SUCCESS
}

export type UpdateTaskRulesSuccessActionType = {
  type: TasksActionTypes.UPDATE_TASK_RULES_SUCCESS
  payload: RuleDataType
}

export type DeleteTaskItemSuccessActionType = {
  type: TasksActionTypes.DELETE_TASK_ITEM_SUCCESS
  payload: number
}

export type DeleteTaskItemFromStateActionType = {
  type: TasksActionTypes.DELETE_TASK_ITEM_FROM_STATE
}

export type DeleteTaskRuleActionType = {
  type: TasksActionTypes.DELETE_TASK_RULE_SUCCESS
}

export type SetCreatedTaskIdActionType = {
  type: TasksActionTypes.SET_CREATED_TASK_ID
  payload: string | undefined
}

export type EditTaskHistoryItemSuccessActionType = {
  type: TasksActionTypes.EDIT_TASK_HISTORY_ITEM_SUCCESS
}

export type DeleteTaskHistoryItemSuccessActionType = {
  type: TasksActionTypes.DELETE_TASK_HISTORY_ITEM_SUCCESS
}

export type TasksActionType =
  | StartTasksLoadingActionType
  | StartTaskItemLoadingActionType
  | StartTaskStatusesLoadingActionType
  | StartTaskHistoryLoadingActionType
  | StartTaskStatusesLoadingActionType
  | LoadTasksSuccessActionType
  | LoadTaskItemSuccessActionType
  | LoadTaskStatusesSuccessActionType
  | LoadTaskHistorySuccessActionType
  | LoadingTasksErrorActionType
  | LoadingTaskStatusesErrorActionType
  | CreateTaskItemSuccessActionType
  | DeleteTaskItemSuccessActionType
  | DeleteTaskItemFromStateActionType
  | DeleteTaskRuleActionType
  | SetCreatedTaskIdActionType
  | LoadingTaskItemErrorActionType
  | LoadingTaskHistoryErrorActionType
  | StartTaskPrioritiesLoadingActionType
  | LoadTaskPrioritiesSuccessActionType
  | LoadingTaskPrioritiesErrorActionType
  | StartTasksRulesLoadingActionType
  | LoadingTaskRulesErrorActionType
  | LoadTaskRulesSuccessActionType
  | UpdateTaskRulesSuccessActionType
  | DeleteTaskHistoryItemSuccessActionType
  | EditTaskHistoryItemSuccessActionType
