import { Color } from '../../styles'

export type ColorsType = {
  pastelColor?: string
  brightColor?: string
}

export type EquipmentStatusesType = {
  working: ColorsType
  in_repair: ColorsType
  downtime: ColorsType
  defective: ColorsType
  maintenance: ColorsType
}

export type TasksStatusesType = {
  new: ColorsType
  in_progress: ColorsType
  closed: ColorsType
  on_hold: ColorsType
  archive: ColorsType
}

export type UserServicesStatusesType = {
  present: ColorsType
  absent: ColorsType
  fired: ColorsType
}

export type ClientTariffsType = {
  free: ColorsType
  promo: ColorsType
  team: ColorsType
  business: ColorsType
  pro: ColorsType
  enterprise: ColorsType
  default: ColorsType
}

export type StatusesColorMapType = {
  equipment: EquipmentStatusesType
  tasks: TasksStatusesType
  userServices: UserServicesStatusesType
  distribution: ClientTariffsType
}

export const STATUSES_COLOR_MAP: StatusesColorMapType = {
  equipment: {
    working: { pastelColor: Color.lightGreen, brightColor: Color.green },
    in_repair: { pastelColor: Color.pastelOrange, brightColor: Color.orange },
    downtime: { pastelColor: Color.purple, brightColor: Color.darkPurple },
    defective: { pastelColor: Color.pastelPink, brightColor: Color.pink },
    maintenance: { pastelColor: Color.pastelBlue, brightColor: Color.blue },
  },
  tasks: {
    new: { pastelColor: Color.pastelBlue, brightColor: Color.blue },
    in_progress: { pastelColor: Color.lightGreen, brightColor: Color.green },
    closed: { pastelColor: Color.purple, brightColor: Color.darkPurple },
    on_hold: { pastelColor: Color.pastelOrange, brightColor: Color.orange },
    archive: { pastelColor: Color.white, brightColor: Color.lightGray },
  },
  userServices: {
    present: { pastelColor: Color.lightGreen, brightColor: Color.green },
    absent: { pastelColor: Color.pastelPink, brightColor: Color.pink },
    fired: { pastelColor: Color.lightGray, brightColor: Color.gray },
  },
  distribution: {
    free: { pastelColor: Color.lightGray, brightColor: Color.gray },
    team: { pastelColor: Color.pastelBlue, brightColor: Color.blue },
    promo: { pastelColor: Color.pastelPink, brightColor: Color.pink },
    business: { pastelColor: Color.pastelOrange, brightColor: Color.orange },
    pro: { pastelColor: Color.lightGreen, brightColor: Color.green },
    enterprise: { pastelColor: Color.lightGreen, brightColor: Color.green },
    default: { pastelColor: Color.lightGray, brightColor: Color.gray },
  },
}
