import urlJoin from 'url-join'
import { AxiosError, AxiosInstance, AxiosResponse } from 'axios'

import { AppDispatch } from '../../hooks'
import { CONTENT_TYPE_APPLICATION_JSON } from '../../constants'
import { handleLoadError } from './errors'
import { NOTIFICATIONS_ENDPOINT } from '../../env'
import { NotificationsActionTypes } from '../actionTypes'
import { RootState } from '../reducers/rootReducer'
import { startDataLoading } from './utils'
import { transformKeysToCamelCase, transformKeysToSnakeCase } from '../../api/converters'

export type GetNotificationsStateFunctionType = () => RootState

const createConfig = (startDate: string, endDate: string, unreadOnly: boolean) => {
  if (startDate && endDate) {
    return {
      params: {
        unread_only: unreadOnly || false,
        start_date: startDate,
        end_date: endDate,
      },
    }
  }

  return {
    params: {
      unread_only: unreadOnly || false,
    },
  }
}

// Get Notifications GET

export const getAllNotifications =
  (startDate?: string, endDate?: string, unreadOnly?: boolean) =>
  (dispatch: AppDispatch, getState: GetNotificationsStateFunctionType, api: AxiosInstance) => {
    dispatch(startDataLoading(NotificationsActionTypes.START_NOTIFICATIONS_LOADING))

    api
      .get(urlJoin(NOTIFICATIONS_ENDPOINT), createConfig(startDate, endDate, unreadOnly))
      .then((res: AxiosResponse) => {
        dispatch({
          type: NotificationsActionTypes.GET_NOTIFICATIONS_SUCCESS,
          payload: transformKeysToCamelCase(res.data),
        })
      })
      .catch((err: AxiosError) =>
        dispatch(handleLoadError(err, NotificationsActionTypes.SET_LOAD_NOTIFICATIONS_ERROR)),
      )
  }

// Edit Notification PATCH

export const editNotification =
  (data: { id: number; isRead: boolean }[]) =>
  (dispatch: AppDispatch, getState: GetNotificationsStateFunctionType, api: AxiosInstance) => {
    dispatch(startDataLoading(NotificationsActionTypes.START_NOTIFICATIONS_LOADING))

    const headers = {
      headers: {
        'Content-Type': CONTENT_TYPE_APPLICATION_JSON,
      },
    }

    api
      .patch(urlJoin(NOTIFICATIONS_ENDPOINT), transformKeysToSnakeCase(data), headers)
      .then((res: AxiosResponse) => {
        if (res.status === 200) dispatch(getAllNotifications())
      })
      .catch((err: AxiosError) =>
        dispatch(handleLoadError(err, NotificationsActionTypes.SET_LOAD_NOTIFICATIONS_ERROR)),
      )
  }

export const markAllNotificationsAsRead =
  (lastId: number) =>
  (dispatch: AppDispatch, getState: GetNotificationsStateFunctionType, api: AxiosInstance) => {
    dispatch(startDataLoading(NotificationsActionTypes.START_NOTIFICATIONS_LOADING))

    const headers = {
      headers: {
        'Content-Type': CONTENT_TYPE_APPLICATION_JSON,
      },
    }

    api
      .post(urlJoin(NOTIFICATIONS_ENDPOINT, 'read'), { last_id: lastId }, headers)
      .then((res: AxiosResponse) => {
        if (res.status === 200) dispatch(getAllNotifications())
      })
      .catch((err: AxiosError) =>
        dispatch(handleLoadError(err, NotificationsActionTypes.SET_LOAD_NOTIFICATIONS_ERROR)),
      )
  }
