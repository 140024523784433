import { t } from 'i18next'

import { containsInArray } from './common'
import { isEmptyObject } from './forms'
import {
  DEFAULT_OPTION_ID,
  DEFAULT_SELECT_VALUE,
  METER_PARAMETERS,
  PERIOD_TYPES,
  REPITITION_TYPES_OPTIONS,
} from '../constants'
import { MeterRuleType, PeriodType, RuleDataType } from '../api'
import { TIME_OPTIONS, TIME_RADIO_BUTTON_OPTIONS, WEEK_DAYS_OPTIONS } from '../components/forms'

export const getBasedOnTimeRule = (rule: RuleDataType) => rule?.basedOn?.time?.period

export const getBasedOnMeterRule = (rule: RuleDataType) => rule?.basedOn?.serviceEvent?.meter

export const getNotifyRule = (rule: RuleDataType) => rule?.basedOn?.notify

export const getStartedTime = (rule: RuleDataType) => rule?.startedTime

export const getMeterId = (meter: MeterRuleType) => meter?.id

export const getMeterRelationType = (meter: MeterRuleType) => meter?.relationType || ''

export const getMeterRelationValue = (meter: MeterRuleType) => meter?.relationValue

export const getMeterEveryValue = (meter: MeterRuleType) => meter?.every?.value

export const getMeterEveryStartValue = (meter: MeterRuleType) => meter?.every?.startedAtValue

export const isMeterRuleDisabled = (meter: MeterRuleType) => isEmptyObject(meter)

export const isTimeRuleDisabled = (period: PeriodType) => isEmptyObject(period)

export const getMeterParameterType = (meter: MeterRuleType) =>
  meter?.every?.startedAtValue ? METER_PARAMETERS.INTERVAL : METER_PARAMETERS.VALUE

export const getCheckedDays = (period: PeriodType) => {
  if (period) {
    const { type, repeatAtType, repeatAtValues } = period
    if (type === PERIOD_TYPES.WEEK && repeatAtType === PERIOD_TYPES.DAY && repeatAtValues)
      return repeatAtValues
  }

  return []
}

export const getRepeatAtValues = (period: PeriodType) => period?.repeatAtValues

export const getStartDayNumber = (period: PeriodType) =>
  period?.startedAtTime ? period?.repeatAtValues[0] : null

// export const getStartedAtTime = (period: PeriodType) =>
//   period?.startedAtTime || moment().toISOString()

export const getStartedAtTime = (period: PeriodType) => period?.startedAtTime

export const getMonthDayNumber = (period: PeriodType) =>
  period?.type === PERIOD_TYPES.MONTHLY ? period?.repeatAtValues[0] : null

export const getRepititionType = (period: PeriodType) => {
  if (period) {
    const { type, startedAtTime, repeatAtValues } = period

    if (type === PERIOD_TYPES.DAY && !repeatAtValues && !startedAtTime)
      return REPITITION_TYPES_OPTIONS[0].optionValue

    if (type === PERIOD_TYPES.WEEK && repeatAtValues && !startedAtTime)
      return REPITITION_TYPES_OPTIONS[1].optionValue

    if (type === PERIOD_TYPES.MONTHLY) return REPITITION_TYPES_OPTIONS[2].optionValue

    if (repeatAtValues && startedAtTime) return REPITITION_TYPES_OPTIONS[3].optionValue

    return null
  }

  return REPITITION_TYPES_OPTIONS[0].optionValue
}

export const isDaylyType = (type: string) => REPITITION_TYPES_OPTIONS[0].optionValue === type
export const isWeeklyType = (type: string) => REPITITION_TYPES_OPTIONS[1].optionValue === type
export const isMonthlyType = (type: string) => REPITITION_TYPES_OPTIONS[2].optionValue === type
export const isCustomType = (type: string) => REPITITION_TYPES_OPTIONS[3].optionValue === type

export const isDayly = (rule: RuleDataType) =>
  isDaylyType(getRepititionType(getBasedOnTimeRule(rule)))
export const isWeekly = (rule: RuleDataType) =>
  isWeeklyType(getRepititionType(getBasedOnTimeRule(rule)))
export const isMonthly = (rule: RuleDataType) =>
  isMonthlyType(getRepititionType(getBasedOnTimeRule(rule)))
export const isCustom = (rule: RuleDataType) =>
  isCustomType(getRepititionType(getBasedOnTimeRule(rule)))

export const getTimeSelectOptions = () => {
  const times = [] // time array
  const result = ['', '']
  const x = 30 // minutes interval
  let tt = 0 // start time

  for (let i = 0; tt < 24 * 60; i += 1) {
    const hh = Math.floor(tt / 60) // getting hours of day
    const mm = tt % 60 // getting minutes of the hour

    /* eslint-disable */

    times[i] = `${('0' + hh).slice(-2)}:${('0' + mm).slice(-2)}${result[hh] || ''}`

    tt = tt + x
  }

  return times.map((time, idx) => ({
    id: idx + 1,
    label: time,
    optionValue: time,
  }))
}

export const getTimeButtonValue = (time: string) => {
  if (time) {
    const [timeOption] = TIME_RADIO_BUTTON_OPTIONS.filter((i) => i.value === time)

    return timeOption?.value || ''
  }

  return ''
}

export const getTimeSelectOption = (time: string) => {
  if (time) {
    const isRadioOption = containsInArray(TIME_OPTIONS, time)

    if (!isRadioOption) return DEFAULT_SELECT_VALUE

    const [option] = getTimeSelectOptions().filter((i) => i.optionValue === time)

    return option?.optionValue || ''
  }

  return ''
}

export const getDefaultSelectTimeOptionId = (time: string) => {
  if (time) {
    const isRadioOption = containsInArray(TIME_OPTIONS, time)

    if (isRadioOption) return DEFAULT_OPTION_ID

    const [option] = getTimeSelectOptions().filter((i) => i.optionValue === time)

    return option?.id || DEFAULT_OPTION_ID
  }

  return DEFAULT_OPTION_ID
}

export const getTranslatedDays = (days: number[]) => {
  if (days) {
    const filteredDays = WEEK_DAYS_OPTIONS.map((i) => (days.includes(i.value) ? i : null)).filter(
      (i) => i,
    )

    return filteredDays.map((i) => `${t(i.label)}`)
  }

  return null
}
