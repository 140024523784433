import { RcFile } from 'antd/lib/upload'
import { t } from 'i18next'
import urlJoin from 'url-join'
import { AxiosError, AxiosInstance, AxiosResponse } from 'axios'

import { AppDispatch } from '../../hooks'
import { deleteTemporaryImages } from './temporaryData'
import { getBaseLocation } from '../../functions'
import { handleLoadError } from './errors'
import { PLACES_ENDPOINT } from '../../env'
import { RootState } from '../reducers/rootReducer'
import { sendNotification } from '../../components/common/notification'
import { startDataLoading } from './utils'
import {
  CACHE_KEYS,
  CURRENT_LOCATION,
  DATA_TYPE,
  INIT,
  MESSAGE_TYPES,
  PLACES_SISTEM_NOTIFICATIONS,
} from '../../constants'
import { createObject, editObject } from '../../api/shortcuts'
import { IPlaces, removeItemFromLocalStorage } from '../../api'
import { PlacesActionTypes, TemporaryDataActionTypes } from '../actionTypes'

export type GetPlacesStateFunctionType = () => RootState

// Places

// Load place item success

export const loadPlaceItemSuccess = (data: IPlaces) => (dispatch: AppDispatch) =>
  dispatch({
    type: PlacesActionTypes.LOAD_PLACE_ITEM_SUCCESS,
    payload: data,
  })

// Delete temporary ids

export const resetTemporaryPlaceIds = () => (dispatch: AppDispatch) =>
  dispatch({
    type: TemporaryDataActionTypes.SET_TEMPORARY_IDS,
    payload: {
      dataType: DATA_TYPE.PLACES,
      tempIds: undefined,
    },
  })

// Delete Place item from state

export const deletePlaceItemFromState = () => (dispatch: AppDispatch) =>
  dispatch({
    type: PlacesActionTypes.DELETE_PLACE_ITEM_FROM_STATE,
  })

export const setCreatedPlaceId = (value: string | undefined) => (dispatch: AppDispatch) => {
  dispatch({
    type: PlacesActionTypes.SET_CREATED_PLACE_ID,
    payload: value,
  })
}

// Get Places     GET

export const getAllPlaces =
  (init?: string) =>
  (dispatch: AppDispatch, getState: GetPlacesStateFunctionType, api: AxiosInstance) => {
    dispatch(startDataLoading(PlacesActionTypes.START_PLACES_LOADING))

    if (init && init === INIT) {
      dispatch(deleteTemporaryImages())

      dispatch(deletePlaceItemFromState())

      dispatch(resetTemporaryPlaceIds())
    }

    api
      .get(urlJoin(PLACES_ENDPOINT))
      .then((res: AxiosResponse) => {
        if (res.status === 200) {
          dispatch({
            type: PlacesActionTypes.LOAD_PLACES_SUCCESS,
            payload: res.data.data,
          })
        }
      })
      .catch((err: AxiosError) =>
        dispatch(handleLoadError(err, PlacesActionTypes.LOAD_PLACES_ERROR)),
      )
  }

// Get Places     GET

export const getPlaceItem =
  (placeId: string | undefined) =>
  (dispatch: AppDispatch, getState: GetPlacesStateFunctionType, api: AxiosInstance) => {
    dispatch(startDataLoading(PlacesActionTypes.START_PLACES_LOADING))

    api
      .get(urlJoin(PLACES_ENDPOINT, placeId))
      .then((res: AxiosResponse) => {
        if (res.status === 200) {
          dispatch(loadPlaceItemSuccess(res.data.data[0]))
        }
      })
      .catch((err: AxiosError) =>
        dispatch(handleLoadError(err, PlacesActionTypes.LOAD_PLACES_ERROR)),
      )
  }

// Create place     POST

export const createPlaceItem =
  (data: IPlaces, files?: RcFile[]) =>
  (dispatch: AppDispatch, getState: GetPlacesStateFunctionType, api: AxiosInstance) => {
    dispatch(startDataLoading(PlacesActionTypes.START_PLACES_LOADING))

    api
      .post(urlJoin(PLACES_ENDPOINT), createObject(data, files))
      .then((res: AxiosResponse) => {
        if (res.status === 201) {
          const createdId = res.headers.location.split('/')[2]

          dispatch(setCreatedPlaceId(createdId))

          dispatch(resetTemporaryPlaceIds())

          removeItemFromLocalStorage(CACHE_KEYS.CREATE_PLACE_FORM)
          removeItemFromLocalStorage(CACHE_KEYS.EDIT_COORDINATES)

          dispatch(loadPlaceItemSuccess(res.data.data[0]))

          sendNotification(
            MESSAGE_TYPES.SUCCESS,
            t(PLACES_SISTEM_NOTIFICATIONS.CREATE_LOCATION_SUCCESS),
          )
        }
      })
      .catch((err: AxiosError) =>
        dispatch(handleLoadError(err, PlacesActionTypes.LOAD_PLACES_ERROR)),
      )
  }

// Edit place    PUT

export const editPlaceItem =
  (placeId: string | undefined, data: IPlaces, isDeleteCache = true) =>
  (dispatch: AppDispatch, getState: GetPlacesStateFunctionType, api: AxiosInstance) => {
    dispatch(startDataLoading(PlacesActionTypes.START_PLACES_LOADING))

    const baseLocation = getBaseLocation(window.location.pathname)

    api
      .put(urlJoin(PLACES_ENDPOINT, placeId), editObject(data))
      .then((res: AxiosResponse) => {
        if (res.status === 200) {
          dispatch(resetTemporaryPlaceIds())

          if (isDeleteCache) removeItemFromLocalStorage(`${CACHE_KEYS.EDIT_PLACE_FORM}:${placeId}`)

          dispatch(loadPlaceItemSuccess(res.data.data[0]))

          if (baseLocation === CURRENT_LOCATION.LOCATIONS) dispatch(getAllPlaces())

          sendNotification(
            MESSAGE_TYPES.SUCCESS,
            t(PLACES_SISTEM_NOTIFICATIONS.UPDATE_LOCATION_SUCCESS),
          )
        }
      })
      .catch((err: AxiosError) =>
        dispatch(handleLoadError(err, PlacesActionTypes.LOAD_PLACES_ERROR)),
      )
  }

// Delete place Item   DELETE

export const deletePlaceItem =
  (placeId: string | undefined) =>
  (dispatch: AppDispatch, getState: GetPlacesStateFunctionType, api: AxiosInstance) => {
    dispatch(startDataLoading(PlacesActionTypes.START_PLACES_LOADING))

    api
      .delete(urlJoin(PLACES_ENDPOINT, placeId))
      .then((res: AxiosResponse) => {
        if (res.status === 204) {
          dispatch({
            type: PlacesActionTypes.DELETE_PLACE_ITEM_SUCCESS,
            payload: Number(placeId),
          })

          dispatch(resetTemporaryPlaceIds())

          sendNotification(
            MESSAGE_TYPES.SUCCESS,
            t(PLACES_SISTEM_NOTIFICATIONS.DELETE_LOCATION_SUCCESS),
          )
        }
      })
      .catch((err: AxiosError) =>
        dispatch(handleLoadError(err, PlacesActionTypes.LOAD_PLACES_ERROR)),
      )
  }
