import { t } from 'i18next'
import urlJoin from 'url-join'
import { AxiosError, AxiosInstance, AxiosResponse } from 'axios'

import { AppDispatch } from '../../hooks'
// import { getJsonHeaders } from '../../functions'
import { RootState } from '../reducers/rootReducer'
import { sendNotification } from '../../components/common/notification'
import { startDataLoading } from './utils'
import { transformKeysToCamelCase } from '../../api/converters'
import { WorkspacesActionTypes } from '../actionTypes'
import { EmailType, removeItemFromLocalStorage, setItemToLocalStorage, USER_ROLES } from '../../api'
import {
  EMPLOYEE_ENDPOINT,
  EMPLOYEES_URL,
  WORKSPACE_ENDPOINT,
  WORKSPACES_ENDPOINT,
} from '../../env'
import {
  getUserWorkspaceDefaultStorageKey,
  getUserWorkspaceFromToken,
  parseJwt,
} from '../../functions'
import { handleInviteUsersError, handleLoadError } from './errors'
import {
  JSON_HEADERS,
  MESSAGE_TYPES,
  OWNER,
  STATUS_CODES,
  WORKSPACES_SISTEM_NOTIFICATIONS,
} from '../../constants'

export type GetWorkspacesStateFunctionType = () => RootState

export const deleteOldWorkspaceData = () => {
  const keys = ['DEFAULT_USER_SCOPE_ID_KEY', 'USER_SCOPE_ID_KEY']

  keys.forEach((key) => {
    const formKeys = Object.keys(localStorage).filter((i) => i.startsWith(key))

    formKeys.forEach((formKey) => removeItemFromLocalStorage(formKey))
  })
}

export const setDefaultWorkspaceIdToStore = (id: number) => (dispatch: AppDispatch) => {
  dispatch({
    type: WorkspacesActionTypes.SET_DEFAULT_WORKSPACE_ID,
    payload: id,
  })
}

export const setWorkspaceId = (token: string) => (dispatch: AppDispatch) => {
  if (token) {
    const userInfo = parseJwt(token)

    dispatch(setDefaultWorkspaceIdToStore(Number(getUserWorkspaceFromToken(token))))

    setItemToLocalStorage(
      getUserWorkspaceDefaultStorageKey(userInfo.id),
      getUserWorkspaceFromToken(token),
    )
  }
}

// Get Workspace Members GET

export const getWorkspaceMembers =
  (workspaceId: number) =>
  (dispatch: AppDispatch, getState: GetWorkspacesStateFunctionType, api: AxiosInstance) => {
    dispatch(startDataLoading(WorkspacesActionTypes.START_WORKSPACES_LOADING))

    api
      .get(urlJoin(WORKSPACE_ENDPOINT, workspaceId?.toString(), EMPLOYEES_URL))
      .then((res: AxiosResponse) => {
        dispatch({
          type: WorkspacesActionTypes.GET_WORKSPACE_MEMBERS_SUCCESS,
          payload: transformKeysToCamelCase(res.data),
        })
      })
      .catch((err: AxiosError) =>
        dispatch(handleLoadError(err, WorkspacesActionTypes.SET_LOAD_WORKSPACES_ERROR)),
      )
  }

// Get All Workspaces GET

export const getCurrentWorkspace =
  (workspaceId: number) =>
  (dispatch: AppDispatch, getState: GetWorkspacesStateFunctionType, api: AxiosInstance) => {
    dispatch(startDataLoading(WorkspacesActionTypes.START_WORKSPACES_LOADING))

    const token = getState().auth.accessToken

    api
      .get(urlJoin(WORKSPACE_ENDPOINT, workspaceId?.toString()))
      .then((res: AxiosResponse) => {
        if (res.status === STATUS_CODES.CODE_200) {
          if (res.data) {
            dispatch({
              type: WorkspacesActionTypes.GET_CURRENT_WORKSPACE_SUCCESS,
              payload: transformKeysToCamelCase(res.data),
            })

            if (token) dispatch(setWorkspaceId(token))
          }
        }
      })
      .catch((err: AxiosError) => {
        if (token) dispatch(setWorkspaceId(token))

        dispatch(handleLoadError(err, WorkspacesActionTypes.SET_LOAD_WORKSPACES_ERROR))
      })
  }

// Get Workspace Owner GET

export const getWorkspaceOwner =
  (workspaceId: number) =>
  (dispatch: AppDispatch, getState: GetWorkspacesStateFunctionType, api: AxiosInstance) => {
    dispatch(startDataLoading(WorkspacesActionTypes.START_WORKSPACES_LOADING))

    api
      .get(urlJoin(WORKSPACE_ENDPOINT, workspaceId?.toString(), OWNER))
      .then((res: AxiosResponse) => {
        if (res.status === STATUS_CODES.CODE_200) {
          if (res.data) {
            dispatch({
              type: WorkspacesActionTypes.GET_WORKSPACE_OWNER_SUCCESS,
              payload: transformKeysToCamelCase(res.data),
            })
          }
        }
      })
      .catch((err: AxiosError) =>
        dispatch(handleLoadError(err, WorkspacesActionTypes.SET_LOAD_WORKSPACES_ERROR)),
      )
  }

// Get All Workspaces GET

export const getAllWorkspaces =
  () => (dispatch: AppDispatch, getState: GetWorkspacesStateFunctionType, api: AxiosInstance) => {
    dispatch(startDataLoading(WorkspacesActionTypes.START_WORKSPACES_LOADING))

    api
      .get(urlJoin(WORKSPACES_ENDPOINT))
      .then((res: AxiosResponse) => {
        if (res.status === STATUS_CODES.CODE_200) {
          dispatch({
            type: WorkspacesActionTypes.GET_WORKSPACES_SUCCESS,
            payload: transformKeysToCamelCase(res.data),
          })
        }
      })
      .catch((err: AxiosError) =>
        dispatch(handleLoadError(err, WorkspacesActionTypes.SET_LOAD_WORKSPACES_ERROR)),
      )
  }

// Post Invite Workspace Member POST

export const createInvitation =
  (workspaceId: number, data: EmailType) =>
  (dispatch: AppDispatch, getState: GetWorkspacesStateFunctionType, api: AxiosInstance) => {
    dispatch(startDataLoading(WorkspacesActionTypes.START_WORKSPACES_LOADING))

    api
      .post(
        urlJoin(WORKSPACE_ENDPOINT, workspaceId?.toString(), 'test-invitations'),
        data,
        JSON_HEADERS,
      )
      .then((res: AxiosResponse) => {
        if (res.status === STATUS_CODES.CODE_200) {
          dispatch(getWorkspaceMembers(workspaceId))

          sendNotification(
            MESSAGE_TYPES.SUCCESS,
            t(WORKSPACES_SISTEM_NOTIFICATIONS.INVITE_MEMBER_SUCCESS),
          )
        }
      })
      .catch((err: AxiosError) =>
        dispatch(handleInviteUsersError(err, WorkspacesActionTypes.SET_LOAD_WORKSPACES_ERROR)),
      )
  }

// PATCH Edit Workspace Member

export const editWorkspaceMember =
  (memberId: string, data: { role: USER_ROLES }) =>
  (dispatch: AppDispatch, getState: GetWorkspacesStateFunctionType, api: AxiosInstance) => {
    api
      .patch(urlJoin(EMPLOYEE_ENDPOINT, memberId), data, JSON_HEADERS)
      .then((res: AxiosResponse) => {
        dispatch({
          type: WorkspacesActionTypes.EDIT_WORKSPACE_MEMBER_SUCCESS,
          payload: transformKeysToCamelCase(res.data),
        })

        sendNotification(
          MESSAGE_TYPES.SUCCESS,
          t(WORKSPACES_SISTEM_NOTIFICATIONS.EDIT_MEMBER_SUCCESS),
        )
      })
      .catch((err: AxiosError) =>
        dispatch(handleLoadError(err, WorkspacesActionTypes.SET_LOAD_WORKSPACES_ERROR)),
      )
  }

// DELETE delete Workspace Member

export const deleteWorkspaceMember =
  (memberId: string, workspaceId: number) =>
  (dispatch: AppDispatch, getState: GetWorkspacesStateFunctionType, api: AxiosInstance) => {
    api
      .delete(urlJoin(EMPLOYEE_ENDPOINT, memberId))
      .then((res: AxiosResponse) => {
        if (STATUS_CODES.CODE_204) {
          dispatch(getWorkspaceMembers(workspaceId))

          sendNotification(
            MESSAGE_TYPES.SUCCESS,
            t(WORKSPACES_SISTEM_NOTIFICATIONS.DELETE_MEMBER_SUCCESS),
          )
        }
      })
      .catch((err: AxiosError) =>
        dispatch(handleLoadError(err, WorkspacesActionTypes.SET_LOAD_WORKSPACES_ERROR)),
      )
  }
