import { t } from 'i18next'
import urlJoin from 'url-join'
import { AxiosError, AxiosInstance, AxiosResponse } from 'axios'

import { AppDispatch } from '../../hooks'
import { editObject } from '../../api/shortcuts'
import { getTaskItem } from './tasks'
import { getTasksErrorActionType } from './utils'
import { handleLoadError } from './errors'
import { RootState } from '../reducers/rootReducer'
import { sendNotification } from '../../components/common/notification'
import { TASK_RULES_ENDPOINT } from '../../env'
import { TasksActionTypes } from '../actionTypes'
import { BasedOnType, TASKS_ERROR_TYPES } from '../../api'
import { MESSAGE_TYPES, STATUS_CODES, TASKS_SISTEM_NOTIFICATIONS } from '../../constants'
import { transformKeysToCamelCase, transformKeysToSnakeCase } from '../../api/converters'

export type GetTaskRulesStateFunctionType = () => RootState

type RulesDataType = {
  id: number
  ruleData: any
}

const getTransformedRulesData = (data: RulesDataType) => {
  if (data) return { id: data.id, ...transformKeysToCamelCase(JSON.parse(data.ruleData)) }

  return null
}

// Tasks rules

export const startRulesLoading = () => (dispatch: AppDispatch) =>
  dispatch({
    type: TasksActionTypes.START_TASK_RULES_LOADING,
  })

// Tasks Rules  GET

export const getTaskRules =
  (taskId: string) =>
  (dispatch: AppDispatch, getState: GetTaskRulesStateFunctionType, api: AxiosInstance) => {
    dispatch(startRulesLoading())

    api
      .get(urlJoin(TASK_RULES_ENDPOINT, `?taskId=${taskId}`))
      .then((res: AxiosResponse) => {
        if (res.status === STATUS_CODES.CODE_200) {
          const data = res.data?.data[0]

          dispatch({
            type: TasksActionTypes.LOAD_TASK_RULES_SUCCESS,
            payload: data ? getTransformedRulesData(data) : null,
          })
        }
      })
      .catch((err: AxiosError) =>
        dispatch(handleLoadError(err, getTasksErrorActionType(TASKS_ERROR_TYPES.RULES))),
      )
  }

// Create Task Rules POST

export const createTaskRule =
  (
    taskId: string,
    rule: {
      startedTime?: string
      basedOn?: BasedOnType
    },
  ) =>
  (dispatch: AppDispatch, getState: GetTaskRulesStateFunctionType, api: AxiosInstance) => {
    dispatch(startRulesLoading())

    const values = editObject({
      taskId: Number(taskId),
      ruleData: JSON.stringify(transformKeysToSnakeCase(rule)),
    })

    api
      .post(urlJoin(TASK_RULES_ENDPOINT), values)
      .then((res: AxiosResponse) => {
        if (res.status === STATUS_CODES.CODE_201) {
          const data = res.data.data[0]

          dispatch({
            type: TasksActionTypes.UPDATE_TASK_RULES_SUCCESS,
            payload: getTransformedRulesData(data),
          })

          dispatch(getTaskItem(taskId))

          sendNotification(
            MESSAGE_TYPES.SUCCESS,
            t(TASKS_SISTEM_NOTIFICATIONS.CREATE_TASK_RULES_SUCCESS),
          )
        }
      })
      .catch((err: AxiosError) =>
        dispatch(handleLoadError(err, getTasksErrorActionType(TASKS_ERROR_TYPES.RULES))),
      )
  }

// Create Task Rules POST

export const editTaskRule =
  (
    ruleId: number,
    taskId: string,
    rule: {
      startedTime?: string
      basedOn?: BasedOnType
    },
  ) =>
  (dispatch: AppDispatch, getState: GetTaskRulesStateFunctionType, api: AxiosInstance) => {
    dispatch(startRulesLoading())

    const values = editObject({
      taskId: Number(taskId),
      ruleData: JSON.stringify(transformKeysToSnakeCase(rule)),
    })

    api
      .put(urlJoin(TASK_RULES_ENDPOINT, ruleId.toString()), values)
      .then((res: AxiosResponse) => {
        if (res.status === STATUS_CODES.CODE_200) {
          const data = res.data.data[0]

          dispatch({
            type: TasksActionTypes.UPDATE_TASK_RULES_SUCCESS,
            payload: getTransformedRulesData(data),
          })

          dispatch(getTaskItem(taskId))

          sendNotification(
            MESSAGE_TYPES.SUCCESS,
            t(TASKS_SISTEM_NOTIFICATIONS.UPDATE_TASK_RULES_SUCCESS),
          )
        }
      })
      .catch((err: AxiosError) =>
        dispatch(handleLoadError(err, getTasksErrorActionType(TASKS_ERROR_TYPES.RULES))),
      )
  }

// Delete Task Rules DELETE

export const deleteTaskRule =
  (ruleId: number) =>
  (dispatch: AppDispatch, getState: GetTaskRulesStateFunctionType, api: AxiosInstance) => {
    dispatch(startRulesLoading())

    api
      .delete(urlJoin(TASK_RULES_ENDPOINT, ruleId.toString()))
      .then((res: AxiosResponse) => {
        if (res) {
          dispatch({
            type: TasksActionTypes.DELETE_TASK_RULE_SUCCESS,
          })

          sendNotification(
            MESSAGE_TYPES.SUCCESS,
            t(TASKS_SISTEM_NOTIFICATIONS.DELETE_TASK_RULE_SUCCESS),
          )
        }
      })
      .catch((err: AxiosError) =>
        dispatch(handleLoadError(err, getTasksErrorActionType(TASKS_ERROR_TYPES.RULES))),
      )
  }
