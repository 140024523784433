import { ImagesActionType, ImagesActionTypes, ImagesStateType } from '../actionTypes'

const initialState: ImagesStateType = {
  tempImagesFiles: [],
  imagesError: null,
  isImagesLoading: false,
}

export const images = (state = initialState, action: ImagesActionType): ImagesStateType => {
  if (action) {
    switch (action.type) {
      case ImagesActionTypes.START_IMAGES_LOADING:
        return { ...state, isImagesLoading: true }

      case ImagesActionTypes.CREATE_IMAGES_SUCCESS:
        return { ...state, tempImagesFiles: [], isImagesLoading: false }

      case ImagesActionTypes.LOAD_IMAGES_ERROR:
        return {
          ...state,
          isImagesLoading: false,
          imagesError: action.payload,
          tempImagesFiles: [],
        }

      case ImagesActionTypes.SAVE_TEMPORARY_IMAGES:
        return { ...state, tempImagesFiles: [...state.tempImagesFiles, action.payload] }

      case ImagesActionTypes.DELETE_IMAGE_SUCCESS:
        return { ...state, isImagesLoading: false }

      case ImagesActionTypes.DELETE_TEMPORARY_IMAGES:
        return { ...state, tempImagesFiles: [] }

      case ImagesActionTypes.DELETE_TEMPORARY_IMAGE_ITEM:
        return {
          ...state,
          tempImagesFiles: state.tempImagesFiles.filter((file) => file.uid !== action.payload),
        }

      default:
        return state
    }
  } else {
    return state
  }
}
