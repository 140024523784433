import { PlacesActionType, PlacesActionTypes, PlacesStateType } from '../actionTypes'

const initialState: PlacesStateType = {
  places: [],
  placeItem: undefined,
  createdId: undefined,
  tempPlaceId: undefined,
  placesError: null,
  isPlacesLoading: false,
}

export const places = (state = initialState, action: PlacesActionType): PlacesStateType => {
  if (action) {
    switch (action.type) {
      case PlacesActionTypes.START_PLACES_LOADING:
        return { ...state, isPlacesLoading: true }

      case PlacesActionTypes.LOAD_PLACES_SUCCESS:
        return { ...state, isPlacesLoading: false, placesError: null, places: action.payload }

      case PlacesActionTypes.LOAD_PLACE_ITEM_SUCCESS:
        return { ...state, isPlacesLoading: false, placesError: null, placeItem: action.payload }

      case PlacesActionTypes.DELETE_PLACE_ITEM_FROM_STATE:
        return { ...state, placeItem: undefined }

      case PlacesActionTypes.DELETE_PLACE_ITEM_SUCCESS:
        return { ...state, places: state.places.filter((i) => i.id !== action.payload) }

      case PlacesActionTypes.SAVE_TEMPORARY_PLACE_ID:
        return { ...state, tempPlaceId: action.payload }

      case PlacesActionTypes.DELETE_TEMPORARY_PLACE_ID:
        return { ...state, tempPlaceId: undefined }

      case PlacesActionTypes.SET_CREATED_PLACE_ID:
        return {
          ...state,
          createdId: action.payload,
        }

      case PlacesActionTypes.CREATE_PLACE_SUCCESS:
        return { ...state, isPlacesLoading: false, placesError: null }

      case PlacesActionTypes.LOAD_PLACES_ERROR:
        return {
          ...state,
          isPlacesLoading: false,
          placesError: action.payload,
        }

      default:
        return state
    }
  } else {
    return state
  }
}
