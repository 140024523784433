import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Col, Result, Row, Typography } from 'antd'
import { Link, useNavigate } from 'react-router-dom'

import { PlannyLogo } from '../../components/common'

export type ErrorPagePropsType = {
  title?: string
  subTitle?: string
  onResetError?: () => void
}

const { Text } = Typography

export const ErrorPage: FC<ErrorPagePropsType> = ({ title, subTitle, onResetError }) => {
  const navigate = useNavigate()

  const handleGoBack = () => {
    navigate('/')
  }

  const getResetButton = () => {
    if (onResetError)
      return [
        <Button key={2} type="primary" ghost onClick={onResetError}>
          {t('reset-error-button')}
        </Button>,
      ]

    return []
  }

  const { t } = useTranslation()

  return (
    <Row justify="center" align="middle" style={{ width: '100%', height: '500px' }}>
      <Col>
        <Result
          title={title || t('error-page-title')}
          icon={
            <PlannyLogo>
              <img src="/images/p24_err.svg" alt="err" />
            </PlannyLogo>
          }
          subTitle={
            subTitle || (
              <>
                <Text type="secondary">{t('error-page-subTitle')}</Text> <br />
                <Text type="secondary">
                  {t('error-prompt')} <Link to="/feedback">{t('feedback-link')}</Link>
                </Text>
              </>
            )
          }
          extra={[
            <Button key={1} type="primary" onClick={handleGoBack}>
              {t('go-back-button')}
            </Button>,
            ...getResetButton(),
          ]}
        />
      </Col>
    </Row>
  )
}
