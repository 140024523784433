import { ColumnType } from 'antd/lib/table'
import { ReactNode } from 'react'
import { SortOrder } from 'antd/lib/table/interface'

import { IFile } from '../../../api'
import { TARIFF_PLANS } from '../../../constants'
import {
  ClientsColumnsDataType,
  EquipmentColumnsDataType,
  IUserServices,
  IUuid,
  KeyType,
  MaterialsColumnsDataType,
  MetersColumnsDataType,
  PlacesColumnsDataType,
} from '../../../api/types'

export type TaskHistoryColumnsType = KeyType &
  IUuid & {
    operationComment?: string
    operationDate: string
    statusName: string
    title: string
    color?: string
    responsibleUser?: IUserServices
    editButton?: ReactNode
    statusId?: number
  }

export type UserServiceHistoryColumnsType = KeyType &
  IUuid & {
    operationComment: string
    operationDate: string
    statusName: string
    admission: string
    dotColor?: string
    editButton?: ReactNode
    statusId?: number
  }

export type MeterHistoryColumnsType = KeyType &
  IUuid & {
    name?: string
    operationValue?: number
    operationValueDiff?: number
    operationDate?: string
    operationDateDiff?: number
    operationType?: string
    operationComment?: string
    editButton?: ReactNode
    lastOperationValue?: number
  }

export type MaterialHistoryColumnsType = KeyType &
  IUuid & {
    name?: string
    operationCount?: number
    operationDate?: string
    operationDateDiff?: number
    operationType?: string
    partNumber?: string
    serialNumber?: string
    unitCount?: number
    unitPrice?: string
    operationComment?: string
    operationSerialNumber?: string
    editButton?: ReactNode
  }

export type EquipmentHistoryColumnsDataType = EquipmentColumnsDataType &
  IUuid & {
    statusName?: string
    editButton?: ReactNode
  }

export type ClientHistoryColumnsDataType = ClientsColumnsDataType &
  IUuid & {
    subscriptionPlan?: TARIFF_PLANS
    editButton?: ReactNode
    dotColor?: string
  }

export type FilesTableColumnsType = KeyType & {
  icon?: ReactNode
  fileName?: string | ReactNode
  fileSize?: string
  url?: string
}

export type SelectTablesColumnsType = KeyType & {
  name?: string
  partNumber?: string
  serialNumber?: string
  inventoryNumber?: string
  address?: string
  statusId?: number
  dueDatetime?: string
}

export type WorkspaceMembersTableDataType = KeyType & {
  status?: string
  email?: string
  permissions?: ReactNode
  isUserOwner?: boolean
  role?: string
  inviteActions?: ReactNode
}

export type ActualTasksTableDataType = KeyType & {
  id?: number
  isPlanned?: boolean
  type?: string
  dueDatetime?: ReactNode
  dotColor?: string
  assignee?: IUserServices
}

// Titles translation functions types

export type MeterColumnTitlesType = {
  name: string
  equipment?: string
  lastOperationDate?: string
  lastOperationValue?: string
  readingPeriod?: string
  measurementUnit?: string
  description?: string
  images?: IFile[]
  tags?: string
  location?: string
  measUnit?: string
  responsibleUser: string
}

export type EquipmentColumnsTitlesType = {
  name: string
  modelName: string
  serialNumber: string
  description: string
  inventoryNumber: string
  importanceLevel: string
  tags: string
  place: string
  status: string
  responsibleUser?: string
}

export type MaterialColumnTitlesType = {
  name: string
  equipmentArray?: string
  unitCount?: string
  description?: string
  partNumber?: string
  unitPrice?: string
  tags?: string
  place?: string
  responsibleUser?: string
  count?: string
}

export type TasksColumnsTitlesType = {
  number?: string
  title?: string
  description?: string
  createDatetime?: string
  dueDatetime?: string
  operationComment?: string
  equipments?: string
  location?: string
  equipment?: string
  tags?: string
  laborDays?: string
  status?: string
  owner?: string
  priority?: string
  responsibleUser?: string
  type?: string
}

export type PlaceColumnTitlesType = {
  name: string
  address: string
  ownerName: string
  description: string
  phone: string
}

export type MaterialHistoryTitlesType = {
  operationDate: string
  operationType: string
  operationCount: string
  materialNumber: string
  comments: string
  unitCount: string
  loss: string
  profit: string
  task: string
}

export type MetersHistoryTitlesType = {
  operationDate: string
  lastOperationValue: string
  measurementUnit: string
  operationValueDiff: string
  comments: string
  operationDateDiff: string
  task: string
}

export type EquipmentHistoryColumnTitlesType = {
  period: string
  operationDate: string
  statusName: string
  operationComment: string
  task: string
  durationHours: string
}

export type ClientHistoryColumnTitlesType = {
  subscriptionPlanName: TARIFF_PLANS
  operationDate: string
  durationHours: string
  operationComment: string
  subscriptionPlanStart: string
  subscriptionPlanTerm: string
}

export type TaskHistoryColumnTitlesType = {
  owner: string
  operationDate: string
  operationTime: string
  operationDue: string
  statusName: string
  operationComment: string
  responsibleUser: string
}

export type ServicesColumnTitlesType = {
  name: string
  status: string
  admission: string
  position: string
  description: string
  contacts: string
  tags: string
}

export type PpwGraphicsColumnTitlesType = {
  name: string
  equipmentArray?: string
  description?: string
  startDatetime: string
  endDatetime: string
  tags?: string
}

export type ServicesHistoryColumnTitlesType = {
  operationDate: string
  statusName: string
  operationComment: string
  admission: string
  task: string
}

export type UsersRolesColumnTitlesType = {
  status: string
  email: string
  userPermissions: string
  role: string
  inviteAction: string
}

export type NotificationsHistoryColumnTitlesType = {
  createdAt: string
  eventMsg: string
  isMsgRead: string
  eventTypeName: string
}

export type TaskGraphicsColumnTitlesType = {
  taskName: string
  taskDate: string
  equipment: string
  equipmentDowntime: string
  task: string
  laborIntensity: string
}

export type ClientsColumnTitlesType = {
  name: string
  email: string
  description: string
  tariffPlan: string
  startsAt: string
  term: string
  expiresAt: string
}

export type TableColumnsType = EquipmentColumnsDataType | PlacesColumnsDataType

export type AllColumnsType =
  | EquipmentColumnsDataType
  | MaterialsColumnsDataType
  | PlacesColumnsDataType
  | MetersColumnsDataType

export type SorterColumnsType = {
  column: ColumnType<AllColumnsType>
  order: SortOrder
}

export type SortColumnsKeysType = 'location' | 'images' | 'isFavorite' | 'materials' | 'isPlanned'

export type FiltersType = {
  text: string
  value: string
}

export enum PLACES_TABLE_DATA_KEY {
  NAME = 'name',
  ADDRESS = 'address',
  OWNER_NAME = 'ownerName',
  DESCRIPTION = 'description',
  OWNER_PHONE = 'ownerPhone',
  IS_FAVORITE = 'isFavorite',
  IMAGES = 'images',
  LOCATION = 'location',
  IMAGE = 'image',
}

export enum EQUIPMENT_TABLE_DATA_KEY {
  NAME = 'name',
  MODEL_NAME = 'modelName',
  SERIAL_NUMBER = 'serialNumber',
  INVENTORY_NUMBER = 'inventoryNumber',
  DESCRIPTION = 'description',
  RESPONSIBLE_USER = 'responsibleUser',
  IS_FAVORITE = 'isFavorite',
  IMAGES = 'images',
  PLACE = 'place',
  MATERIALS = 'materials',
  STATUS = 'status',
  IMPORTANCE_LEVEL = 'importanceLevel',
  TAGS = 'tags',
}

export enum MATERIALS_TABLE_DATA_KEY {
  NAME = 'name',
  PLACE = 'place',
  TAGS = 'tags',
  IMAGES = 'images',
  DESCRIPTION = 'description',
  PART_NUMBER = 'partNumber',
  UNIT_COUNT = 'unitCount',
  EQUIPMENT_ARRAY = 'equipmentArray',
  UNIT_PRICE_VALUE = 'unitPriceValue',
  RESPONSIBLE_USER = 'responsibleUser',
  IS_FAVORITE = 'isFavorite',
}

export enum METER_TABLE_DATA_KEY {
  NAME = 'name',
  DESCRIPTION = 'description',
  MEAS_UNIT = 'measurementUnit',
  PERIOD = 'readingPeriod',
  PLACE = 'place',
  TAGS = 'tags',
  IMAGES = 'images',
  EQUIPMENT = 'equipment',
  OPERATION_DATE = 'lastOperationDate',
  OPERATION_VALUE = 'lastOperationValue',
  RESPONSIBLE_USER = 'responsibleUser',
  IS_FAVORITE = 'isFavorite',
}

export enum USERS_TABLE_DATA_KEY {
  NAME = 'name',
  POSITION = 'position',
  CONTACTS = 'contacts',
  COMMENT = 'comment',
  IS_FAVORITE = 'isFavorite',
  IMAGES = 'images',
  TAGS = 'tags',
  STATUS = 'status',
  ADMISSION = 'admission',
}

export enum TASKS_TABLE_DATA_KEY {
  DESCRIPTION = 'description',
  OWNER = 'owner',
  CREATED_DATE_TIME = 'createdDatetime',
  DUE_DATE_TIME = 'dueDatetime',
  EQUIPMENT_ARRAY = 'equipmentArray',
  NUMBER = 'number',
  TITLE = 'title',
  NAME = 'name',
  STATUS = 'status',
  IMAGES = 'images',
  PLACE = 'place',
  TAGS = 'tags',
  PRIORITIES = 'priorityName',
  PRIORITY = 'priority',
  IS_FAVORITE = 'isFavorite',
  NUMBER_SIGN = '№',
  ASSIGNEE = 'assignee',
  IS_PLANNED = 'isPlanned',
}

export enum USERS_ROLES_TABLE_DATA_KEY {
  E_MAIL = 'email',
  ROLE = 'role',
  STATUS = 'status',
  USER_PERMISSIONS = 'userPermissions',
  INVITE_ACTION = 'inviteAction',
}

export enum PPW_TABLE_DATA_KEY {
  NAME = 'name',
  DESCRIPTION = 'description',
  EQUIPMENT_ARRAY = 'equipmentArray',
  START_DATE = 'startDatetime',
  END_DATE = 'endDatetime',
  TAGS = 'tags',
}

export enum TASKS_GRAPHICS_DATA_KEY {
  TASK = 'task',
  LABOR_INTENSITY = 'laborIntensity',
  TASK_DATE = 'taskDate',
  EQUIPMENT_DOWNTIME = 'equipmentDowntime',
  EQUIPMENT = 'equipment',
  IS_PLANNED = 'taskIsPlanned',
}

export enum CLIENTS_TABLE_DATA_KEY {
  NAME = 'name',
  EMAIL = 'email',
  TARIFF = 'tariff',
  DESCRIPTION = 'description',
  STARTS_AT = 'startsAt',
  TERM = 'term',
  EXPIRES_AT = 'endsAt',
}
