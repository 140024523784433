import { WorkspacesActionType, WorkspacesActionTypes, WorkspacesStateType } from '../actionTypes'

const initialState: WorkspacesStateType = {
  isWorkspacesLoading: false,
  workspaces: undefined,
  currentWorkspace: undefined,
  defaultWorkspaceId: null,
  members: undefined,
  owner: undefined,
  loadWorkspacesError: null,
}

export const workspaces = (
  state = initialState,
  action: WorkspacesActionType,
): WorkspacesStateType => {
  if (action) {
    switch (action.type) {
      case WorkspacesActionTypes.START_WORKSPACES_LOADING:
        return {
          ...state,
          isWorkspacesLoading: true,
        }

      case WorkspacesActionTypes.GET_WORKSPACES_SUCCESS:
        return {
          ...state,
          isWorkspacesLoading: false,
          workspaces: action.payload,
          loadWorkspacesError: null,
        }

      case WorkspacesActionTypes.GET_CURRENT_WORKSPACE_SUCCESS:
        return {
          ...state,
          isWorkspacesLoading: false,
          currentWorkspace: action.payload,
          loadWorkspacesError: null,
        }

      case WorkspacesActionTypes.GET_WORKSPACE_MEMBERS_SUCCESS:
        return {
          ...state,
          isWorkspacesLoading: false,
          members: action.payload,
          loadWorkspacesError: null,
        }

      case WorkspacesActionTypes.GET_WORKSPACE_OWNER_SUCCESS:
        return {
          ...state,
          isWorkspacesLoading: false,
          owner: action.payload,
          loadWorkspacesError: null,
        }

      case WorkspacesActionTypes.EDIT_WORKSPACE_MEMBER_SUCCESS:
        return {
          ...state,
          isWorkspacesLoading: false,
          members: [...state.members.filter((i) => i.id !== action.payload.id), action.payload],
          loadWorkspacesError: null,
        }

      case WorkspacesActionTypes.SET_LOAD_WORKSPACES_ERROR:
        return {
          ...state,
          isWorkspacesLoading: false,
          loadWorkspacesError: action.payload,
        }

      case WorkspacesActionTypes.SET_DEFAULT_WORKSPACE_ID:
        return {
          ...state,
          isWorkspacesLoading: false,
          defaultWorkspaceId: action.payload,
        }

      default:
        return state
    }
  } else {
    return state
  }
}
