import { AppDispatch } from '../../../hooks'
import { startDataLoading } from './loading'
import { CONTENT_TYPE_APPLICATION_JSON, DATA_TYPE, EMPTY_STRING } from '../../../constants'
import {
  EQUIPMENT_ENDPOINT,
  MATERIALS_ENDPOINT,
  METERS_ENDPOINT,
  PLACES_ENDPOINT,
  PPW_ENDPOINT,
  SERVICES_ENDPOINT,
  TASKS_ENDPOINT,
} from '../../../env'
import {
  EquipmentActionTypes,
  MaterialsActionTypes,
  MetersActionTypes,
  PlacesActionTypes,
  TasksActionTypes,
  UserServicesActionTypes,
} from '../../actionTypes'

export const getBasePath = (dataType: string) => {
  if (dataType === DATA_TYPE.EQUIPMENT) return EQUIPMENT_ENDPOINT
  if (dataType === DATA_TYPE.PLACES) return PLACES_ENDPOINT
  if (dataType === DATA_TYPE.MATERIALS) return MATERIALS_ENDPOINT
  if (dataType === DATA_TYPE.METERS) return METERS_ENDPOINT
  if (dataType === DATA_TYPE.TASKS) return TASKS_ENDPOINT
  if (dataType === DATA_TYPE.USER_SERVICES) return SERVICES_ENDPOINT
  if (dataType === DATA_TYPE.PPW_GRAPHICS) return PPW_ENDPOINT

  return EMPTY_STRING
}

export const handleItemLoading = (dataType: string) => (dispatch: AppDispatch) => {
  if (dataType === DATA_TYPE.EQUIPMENT)
    dispatch(startDataLoading(EquipmentActionTypes.START_EQUIPMENT_LOADING))
  if (dataType === DATA_TYPE.PLACES)
    dispatch(startDataLoading(PlacesActionTypes.START_PLACES_LOADING))
  if (dataType === DATA_TYPE.MATERIALS)
    dispatch(startDataLoading(MaterialsActionTypes.START_MATERIALS_LOADING))
  if (dataType === DATA_TYPE.METERS)
    dispatch(startDataLoading(MetersActionTypes.START_METERS_LOADING))
  if (dataType === DATA_TYPE.TASKS) dispatch(startDataLoading(TasksActionTypes.START_TASKS_LOADING))
  if (dataType === DATA_TYPE.USER_SERVICES)
    dispatch(startDataLoading(UserServicesActionTypes.START_SERVICES_LOADING))
}

export const buildWorkspaceQueryString = (id: string | number) => {
  return id ? `?scopeId=${id}` : ''
}

export const getJsonHeader = () => ({
  headers: {
    'Content-Type': CONTENT_TYPE_APPLICATION_JSON,
  },
})
