import LogoutIcon from '@mui/icons-material/Logout'
import { useTranslation } from 'react-i18next'

import { logout } from '../../../store/actions'
import { useTypedDispatch } from '../../../hooks'

export const useLogoutAction = () => {
  const { t } = useTranslation()

  const dispatch = useTypedDispatch()

  const handleLogout = () => dispatch(logout())

  return {
    key: `logout`,
    icon: <LogoutIcon />,
    text: t(`logout-button`),
    onClick: () => handleLogout(),
  }
}
