import { CheckboxValueType } from 'antd/es/checkbox/Group'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { RedoOutlined } from '@ant-design/icons'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import ViewWeekOutlinedIcon from '@mui/icons-material/ViewWeekOutlined'
import { Button, Checkbox, Col, Popover, Row, Typography } from 'antd'
import { FC, memo, ReactNode, useCallback, useEffect, useState } from 'react'

import { sendNotification } from '../../common/notification'
import { Color, Spacing } from '../../../styles'
import {
  COMMON_SISTEM_NOTIFICATIONS,
  MESSAGE_TYPES,
  NOT_DEFAULT_CHECKED_VALUES,
} from '../../../constants'

const { Text } = Typography

const NAME = 'name'
const TITLE = 'title'

const Wrapper = styled(Row)`
  background-color: ${Color.white};
  height: 60px;
  padding: 0 ${Spacing.md20} ${Spacing.sm4} ${Spacing.md20};
`

const StyledCheckBoxGroup = styled(Col)`
  & .ant-checkbox-wrapper {
    height: 40px;
    padding: ${Spacing.sm12};
  }

  & .title {
    font-weight: 700;
    padding-left: ${Spacing.sm12};
  }

  & .ant-checkbox-group {
    display: flex;
    flex-direction: column;
  }

  & .ant-btn {
    color: ${Color.blue};
  }
`

const StyledCol = styled(Col)`
  display: flex;
  align-items: flex-end;
  color: ${Color.blue};
  margin-right: ${Spacing.md24};
  cursor: pointer;

  & .columns {
    margin-left: ${Spacing.sm8};
  }

  span {
    color: ${Color.blue};
  }
`

const StyledRow = styled(Row)`
  padding: ${Spacing.sm10} 0;
`

type TableFunctionalBlockPropsType = {
  itemsAmount: number
  onExpandAllRows?: (value: boolean) => void
  onCollapseAllRows?: (value: boolean) => void
  onColumnsChange?: (value: CheckboxValueType[]) => void
  hideFiredServiceGroup?: ReactNode
  hideArchiveTasksGroup?: ReactNode
  expandButtonText?: string
  options?: { label: string; value: string }[]
  resetRows?: boolean
  isExtandable?: boolean
  columnsType?: string
}

export const TableFunctionalBlock: FC<TableFunctionalBlockPropsType> = memo(
  ({
    itemsAmount,
    onExpandAllRows,
    onCollapseAllRows,
    onColumnsChange,
    hideFiredServiceGroup,
    hideArchiveTasksGroup,
    expandButtonText,
    options,
    resetRows,
    isExtandable = false,
    columnsType,
  }) => {
    const [isAllRowsExpanded, setIsAllRowsExpanded] = useState(false)
    const [isAllRowsCollapsed, setIsAllRowsCollapsed] = useState(false)
    const [rows, setRows] = useState(null)

    const [values, setValues] = useState([])

    const { t } = useTranslation()

    const handleExpandAllRows = () => {
      setIsAllRowsExpanded(true)
      setIsAllRowsCollapsed(false)
      onExpandAllRows(isAllRowsExpanded)
    }

    const handleCollapseAllRows = () => {
      setIsAllRowsExpanded(false)
      setIsAllRowsCollapsed(true)
      onCollapseAllRows(isAllRowsCollapsed)
    }

    const setColumnsToSessionStorage = (columns: CheckboxValueType[]) => {
      sessionStorage.setItem(columnsType, JSON.stringify(columns))
    }

    const resetColumnsInSessionStorage = () => {
      sessionStorage.removeItem(columnsType)
    }

    const getColumnsFromSessionStorage = useCallback(() => {
      return JSON.parse(sessionStorage.getItem(columnsType))
    }, [columnsType])

    const getDefaultOptions = (opts: { label: string; value: string }[]) => {
      return opts
        .map((option) => {
          return !NOT_DEFAULT_CHECKED_VALUES.includes(option.value) ? option.value : ''
        })
        .filter((i) => i)
    }

    const handleResetColumns = () => {
      setValues([])
      onColumnsChange(options ? getDefaultOptions(options) : [])

      resetColumnsInSessionStorage()
    }

    const onChange = (checkedValues: CheckboxValueType[]) => {
      const checkedName = checkedValues.filter((value) => value === NAME || value === TITLE)

      if (!checkedName.length) {
        setValues([...checkedValues, NAME, TITLE])
        setColumnsToSessionStorage([...checkedValues, NAME, TITLE])

        onColumnsChange([...checkedValues, NAME, TITLE])

        sendNotification(MESSAGE_TYPES.WARNING, t(COMMON_SISTEM_NOTIFICATIONS.HIDE_COLUMN_WARNING))
      }

      if (checkedName.length) {
        setColumnsToSessionStorage(checkedValues)
        setValues(checkedValues)
        onColumnsChange(checkedValues)
      }
    }

    const initialCheckedOptions = useCallback(() => {
      if (getColumnsFromSessionStorage() && !values.length) {
        setValues(getColumnsFromSessionStorage())
        onColumnsChange(getColumnsFromSessionStorage())
      }

      if (!getColumnsFromSessionStorage()) {
        setValues(options && !values.length ? getDefaultOptions(options) : values)
      }
    }, [options, values, onColumnsChange, getColumnsFromSessionStorage])

    useEffect(() => {
      if (resetRows && isExtandable) {
        setIsAllRowsExpanded(false)
        setIsAllRowsCollapsed(false)
      }

      setRows(document.querySelectorAll('tr.ant-table-row').length)

      if (isExtandable) {
        onExpandAllRows(isAllRowsExpanded)
        onCollapseAllRows(isAllRowsCollapsed)
      }

      initialCheckedOptions()
    }, [
      onExpandAllRows,
      onCollapseAllRows,
      isAllRowsExpanded,
      resetRows,
      isAllRowsCollapsed,
      initialCheckedOptions,
      isExtandable,
    ])

    return (
      <Wrapper align="bottom" className="tableFunctionalBlock">
        <Col xs={8}>
          <Text type="secondary">
            {t('items-amount-text')}: {itemsAmount || rows}
          </Text>
        </Col>

        <Col xs={16}>
          <Row justify="end">
            {hideFiredServiceGroup || null}

            {isExtandable && (
              <StyledCol
                onClick={
                  expandButtonText === t('spread-all') ? handleExpandAllRows : handleCollapseAllRows
                }
              >
                <MoreVertIcon />
                <Text>{expandButtonText}</Text>
              </StyledCol>
            )}

            {hideArchiveTasksGroup || null}

            <Popover
              trigger="click"
              placement="bottomLeft"
              content={
                <StyledCheckBoxGroup>
                  <Col className="title">{t('columns')}</Col>
                  <Checkbox.Group options={options && options} value={values} onChange={onChange} />

                  <StyledRow justify="center" align="middle">
                    <Button icon={<RedoOutlined />} type="text" onClick={handleResetColumns}>
                      {t('reset-all')}
                    </Button>
                  </StyledRow>
                </StyledCheckBoxGroup>
              }
            >
              <StyledCol>
                <ViewWeekOutlinedIcon />
                <Text className="columns">{t('columns')}</Text>
              </StyledCol>
            </Popover>
          </Row>
        </Col>
      </Wrapper>
    )
  },
)
