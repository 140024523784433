import { TagsActionType, TagsActionTypes, TagsStateType } from '../actionTypes'

const initialState: TagsStateType = {
  tags: [],
  tempTags: [],
  deletedTagsId: [],
  tagsError: null,
  isTagsLoading: false,
}

export const tags = (state = initialState, action: TagsActionType): TagsStateType => {
  if (action) {
    switch (action.type) {
      case TagsActionTypes.START_TAGS_LOADING:
        return { ...state, isTagsLoading: true }

      case TagsActionTypes.LOAD_TAGS_ERROR:
        return { ...state, isTagsLoading: false, tagsError: action.payload }

      case TagsActionTypes.LOAD_TAGS_SUCCESS:
        return { ...state, isTagsLoading: false, tags: action.payload }

      case TagsActionTypes.SAVE_TEMPORARY_TAGS:
        return { ...state, tempTags: [...state.tempTags, action.payload] }

      case TagsActionTypes.EDIT_TEMPORARY_TAG_COLOR: {
        return {
          ...state,
          tempTags: [
            ...state.tempTags.map((item) => {
              if (item.name === action.payload.name) {
                return { name: action.payload.name, color: action.payload.color }
              }
              return item
            }),
          ],
        }
      }

      case TagsActionTypes.EDIT_TEMPORARY_TAG:
        return {
          ...state,
          tempTags: [
            ...state.tempTags.map((item) =>
              item.name === action.payload.tag ? { ...item, name: action.payload.newTag } : item,
            ),
          ],
        }

      case TagsActionTypes.SAVE_DELETED_TAG_ID:
        return {
          ...state,
          deletedTagsId: [...state.deletedTagsId, action.payload],
        }

      case TagsActionTypes.DELETE_TEMPORARY_TAG_ITEM: {
        return {
          ...state,
          tempTags: [...state.tempTags.filter((item) => item.name !== action.payload)],
        }
      }

      case TagsActionTypes.DELETE_TEMPORARY_TAGS:
        return { ...state, tempTags: [], deletedTagsId: [] }

      default:
        return state
    }
  } else {
    return state
  }
}
