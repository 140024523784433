import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { FC, useState } from 'react'

import { Avatar } from '../../common'
import { ChangeWorkspaceButton } from './ChangeWorkspaceButton'
import { setItemToLocalStorage } from '../../../api'
import { StyledCheckIcon } from '../../tables'
import { useLogoutAction } from './useLogoutAction'
import { UserActionPopup } from './UserActionPopup'
import { useUserAccountRedirectAction } from './useUserAccountRedirectAction'
import { ActionButtonPropsType, IWorkspace } from '../../../api/types'
import {
  buildUrl,
  getCompanyId,
  getCompanyLogo,
  getCompanyName,
  getScopeIdQuery,
  getUserWorkspaceStorageKey,
  getWorkspaceTariffPlan,
  isOwner,
} from '../../../functions'
import {
  getAllCompanies,
  getAllWorkspaces,
  getCurrentCompany,
  getCurrentWorkspace,
  getUserLimits,
  getWorkspaceMembers,
  getWorkspaceOwner,
} from '../../../store/actions'
import { getAuth, getCompanies, getUser, getWorkspaces } from '../../../store/selectors'
import { useTypedDispatch, useTypedSelector } from '../../../hooks'

export const UserActionsPopover: FC = () => {
  const [isLoadImageError, setIsLoadImageError] = useState(false)

  const { user } = useTypedSelector(getUser)
  const { accessToken } = useTypedSelector(getAuth)

  const { workspaces, defaultWorkspaceId, currentWorkspace } = useTypedSelector(getWorkspaces)
  const { companies } = useTypedSelector(getCompanies)

  const userAccountRedirectAction = useUserAccountRedirectAction()
  const logoutAction = useLogoutAction()

  const navigate = useNavigate()

  const { t } = useTranslation()

  const dispatch = useTypedDispatch()

  const handleImageLoadError = () => {
    setIsLoadImageError(true)

    return true
  }

  const getWorkspaceById = (id: number) => {
    if (workspaces) {
      const [workspace] = workspaces.filter((i) => i.id === id)

      return workspace
    }

    return null
  }

  const handleChangeWorkspace = (id: number, companyId: number) => {
    if (id && user) {
      if (user.isDistributor) return

      const curWorkspaceObject = getWorkspaceById(id)

      setItemToLocalStorage(getUserWorkspaceStorageKey(user.id), id.toString())

      if (curWorkspaceObject)
        dispatch(getUserLimits(getWorkspaceTariffPlan(accessToken, curWorkspaceObject), user.id))

      dispatch(getAllWorkspaces())
      dispatch(getAllCompanies())
      dispatch(getCurrentWorkspace(id))
      dispatch(getCurrentCompany(companyId))
      dispatch(getWorkspaceOwner(id))
      dispatch(getWorkspaceMembers(id))

      navigate(buildUrl('dashboard', getScopeIdQuery(id || defaultWorkspaceId)))
    }
  }

  const cutLongName = (name: string) => {
    if (name) {
      const islong = name.length > 30

      return islong ? `${name?.slice(0, 30)}...` : name
    }

    return ''
  }

  const filterWorkspaces = (scopes: IWorkspace[]) => {
    const isUserDistributor = user?.isDistributor

    if (isUserDistributor) return scopes.filter((i) => i.ownerId === user.id)

    return scopes
  }

  const getWorkspaceButtons = (): ActionButtonPropsType[] => {
    const username = cutLongName(user?.nickname)
    const email = cutLongName(user?.email)

    if (workspaces) {
      return filterWorkspaces(workspaces).map(({ id, ownerId, companyId }) => {
        const companyName = cutLongName(getCompanyName(getCompanyId(id, workspaces), companies))
        const isUserOwner = isOwner(ownerId, user)
        const logoUrl = getCompanyLogo(getCompanyId(id, workspaces), companies)

        return {
          key: `workspace${id}`,
          icon: (
            <Avatar src={!isLoadImageError && logoUrl} $mr="10px" onError={handleImageLoadError} />
          ),
          extraIcon: currentWorkspace?.id === id ? <StyledCheckIcon /> : null,
          text: (
            <ChangeWorkspaceButton
              accountName={isUserOwner ? t('personal-account') : t('company-account')}
              username={isUserOwner ? username || email : companyName}
            />
          ),
          onClick: () => handleChangeWorkspace(id, companyId),
        }
      })
    }

    return []
  }

  const userAccountRedirectButton = [userAccountRedirectAction]
  const logoutButton = [logoutAction]
  const workspaceButtons = getWorkspaceButtons()

  const actions = [...userAccountRedirectButton, ...logoutButton]
  const extraActions = [...workspaceButtons]

  return <UserActionPopup actions={actions} extraActions={extraActions} />
}
