import { AxiosError } from 'axios'
import { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Button, Result, Typography } from 'antd'

import { getErrorStatus } from '../../api'
import { PlannyLogo, StyledAuthLayout } from '../../components/common'

const { Text } = Typography

type CommonErrorPagePropsType = {
  error?: AxiosError
  isOffline?: boolean
}

export const CommonErrorPage: FC<CommonErrorPagePropsType> = ({ error, isOffline }) => {
  const navigate = useNavigate()

  const { t } = useTranslation()

  const handleGoBack = () => navigate(-1)

  return (
    <StyledAuthLayout>
      <Result
        title={(isOffline && 'Нет сети') || getErrorStatus(error) || 500}
        subTitle={
          isOffline ? (
            'Похоже, у Вас проблемы с интернетом'
          ) : (
            <>
              <Text type="secondary">{t('error-page-title')}</Text> <br />
              <Text type="secondary">{t('error-page-subTitle')}</Text> <br />
            </>
          )
        }
        icon={
          <PlannyLogo>
            <img src="/images/p24_err.svg" alt="err" />
          </PlannyLogo>
        }
        extra={
          <Button type="primary" onClick={handleGoBack}>
            {t('go-back-button')}
          </Button>
        }
      />
    </StyledAuthLayout>
  )
}
