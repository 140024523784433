import { AxiosError } from 'axios'

import { IAdmission, IStatus, IUserServiceHistory, IUserServices } from '../../api'

export type UserServicesStateType = {
  userServices: IUserServices[]
  serviceItem: IUserServices | undefined
  statuses: IStatus[]
  admissions: IAdmission[]
  history: IUserServiceHistory[]
  createdId: string | undefined
  tempServiceId: number | undefined | null
  isServicesLoading: boolean
  servicesError: null | AxiosError
}

export enum UserServicesActionTypes {
  START_SERVICES_LOADING = 'START_SERVICES_LOADING',
  LOAD_SERVICES_SUCCESS = 'LOAD_SERVICES_SUCCESS',
  CREATE_SERVICE_SUCCESS = 'CREATE_SERVICE_SUCCESS',
  LOAD_SERVICE_ITEM_SUCCESS = 'LOAD_SERVICE_ITEM_SUCCESS',
  LOAD_SERVICE_STATUSES_SUCCESS = 'LOAD_SERVICE_STATUSES_SUCCESS',
  LOAD_SERVICE_HISTORY_SUCCESS = 'LOAD_SERVICE_HISTORY_SUCCESS',
  LOAD_SERVICE_ADMISSIONS_SUCCESS = 'LOAD_SERVICE_ADMISSIONS_SUCCESS',
  EDIT_SERVICE_HISTORY_ITEM_SUCCESS = 'EDIT_SERVICE_HISTORY_ITEM_SUCCESS',
  DELETE_SERVICE_HISTORY_ITEM_SUCCESS = 'DELETE_SERVICE_HISTORY_ITEM_SUCCESS',
  SAVE_TEMPORARY_SERVICE_ID = 'SAVE_TEMPORARY_SERVICE_ID',
  DELETE_TEMPORARY_SERVICE_ID = 'DELETE_TEMPORARY_SERVICE_ID',
  DELETE_SERVICE_ITEM_SUCCESS = 'DELETE_SERVICE_ITEM_SUCCESS',
  DELETE_SERVICE_ITEM_FROM_STATE = 'DELETE_SERVICE_ITEM_FROM_STATE',
  LOAD_SERVICES_ERROR = 'LOAD_SERVICES_ERROR',
  SET_CREATED_SERVICE_ID = 'SET_CREATED_SERVICE_ID',
}

export type StartLoadingServicesActionType = {
  type: UserServicesActionTypes.START_SERVICES_LOADING
}

export type LoadServicesSuccessActionType = {
  type: UserServicesActionTypes.LOAD_SERVICES_SUCCESS
  payload: IUserServices[]
}

export type LoadServiceItemSuccessActionType = {
  type: UserServicesActionTypes.LOAD_SERVICE_ITEM_SUCCESS
  payload: IUserServices
}

export type LoadServiceStatusesSuccessActionType = {
  type: UserServicesActionTypes.LOAD_SERVICE_STATUSES_SUCCESS
  payload: IStatus[]
}

export type LoadServiceHistorySuccessActionType = {
  type: UserServicesActionTypes.LOAD_SERVICE_HISTORY_SUCCESS
  payload: IUserServiceHistory[]
}

export type LoadServiceAdmissionsSuccessActionType = {
  type: UserServicesActionTypes.LOAD_SERVICE_ADMISSIONS_SUCCESS
  payload: IAdmission[]
}

export type CreateServiceSuccessActionType = {
  type: UserServicesActionTypes.CREATE_SERVICE_SUCCESS
}

export type SaveServiceParentIdActionType = {
  type: UserServicesActionTypes.SAVE_TEMPORARY_SERVICE_ID
  payload: number | null
}

export type DeleteServiceParentIdActionType = {
  type: UserServicesActionTypes.DELETE_TEMPORARY_SERVICE_ID
  payload: number
}

export type DeleteServiceItemFromStateActionType = {
  type: UserServicesActionTypes.DELETE_SERVICE_ITEM_FROM_STATE
}

export type DeleteServiceItemActionType = {
  type: UserServicesActionTypes.DELETE_SERVICE_ITEM_SUCCESS
  payload: number
}

export type LoadingErrorServicesActionType = {
  type: UserServicesActionTypes.LOAD_SERVICES_ERROR
  payload: AxiosError
}

export type SetCreatedServiceIdActionType = {
  type: UserServicesActionTypes.SET_CREATED_SERVICE_ID
  payload: string | undefined
}

export type EditHistoryServiceItemActionType = {
  type: UserServicesActionTypes.EDIT_SERVICE_HISTORY_ITEM_SUCCESS
}

export type DeleteHistoryServiceItemActionType = {
  type: UserServicesActionTypes.DELETE_SERVICE_HISTORY_ITEM_SUCCESS
}

export type UserServicesActionType =
  | StartLoadingServicesActionType
  | LoadingErrorServicesActionType
  | LoadServicesSuccessActionType
  | LoadServiceStatusesSuccessActionType
  | LoadServiceHistorySuccessActionType
  | LoadServiceAdmissionsSuccessActionType
  | CreateServiceSuccessActionType
  | LoadServiceItemSuccessActionType
  | SaveServiceParentIdActionType
  | DeleteServiceParentIdActionType
  | DeleteServiceItemFromStateActionType
  | SetCreatedServiceIdActionType
  | DeleteServiceItemActionType
  | EditHistoryServiceItemActionType
  | DeleteHistoryServiceItemActionType
