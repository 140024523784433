import {
  UserServicesActionType,
  UserServicesActionTypes,
  UserServicesStateType,
} from '../actionTypes'

const initialState: UserServicesStateType = {
  userServices: undefined,
  serviceItem: undefined,
  createdId: undefined,
  statuses: undefined,
  admissions: undefined,
  history: undefined,
  tempServiceId: undefined,
  servicesError: null,
  isServicesLoading: false,
}

export const userServices = (
  state = initialState,
  action: UserServicesActionType,
): UserServicesStateType => {
  if (action) {
    switch (action.type) {
      case UserServicesActionTypes.START_SERVICES_LOADING:
        return { ...state, isServicesLoading: true }

      case UserServicesActionTypes.LOAD_SERVICES_SUCCESS:
        return {
          ...state,
          isServicesLoading: false,
          servicesError: null,
          userServices: action.payload,
        }

      case UserServicesActionTypes.LOAD_SERVICE_ITEM_SUCCESS:
        return {
          ...state,
          isServicesLoading: false,
          servicesError: null,
          serviceItem: action.payload,
        }

      case UserServicesActionTypes.LOAD_SERVICE_STATUSES_SUCCESS:
        return {
          ...state,
          isServicesLoading: false,
          servicesError: null,
          statuses: action.payload,
        }

      case UserServicesActionTypes.LOAD_SERVICE_HISTORY_SUCCESS:
        return {
          ...state,
          isServicesLoading: false,
          servicesError: null,
          history: action.payload,
        }

      case UserServicesActionTypes.LOAD_SERVICE_ADMISSIONS_SUCCESS:
        return {
          ...state,
          isServicesLoading: false,
          servicesError: null,
          admissions: action.payload,
        }

      case UserServicesActionTypes.DELETE_SERVICE_ITEM_FROM_STATE:
        return { ...state, serviceItem: undefined }

      case UserServicesActionTypes.DELETE_SERVICE_ITEM_SUCCESS:
        return { ...state, userServices: state.userServices.filter((i) => i.id !== action.payload) }

      case UserServicesActionTypes.SAVE_TEMPORARY_SERVICE_ID:
        return { ...state, tempServiceId: action.payload }

      case UserServicesActionTypes.DELETE_TEMPORARY_SERVICE_ID:
        return { ...state, tempServiceId: undefined }

      case UserServicesActionTypes.SET_CREATED_SERVICE_ID:
        return {
          ...state,
          createdId: action.payload,
        }

      case UserServicesActionTypes.CREATE_SERVICE_SUCCESS:
        return { ...state, isServicesLoading: false, servicesError: null }

      case UserServicesActionTypes.LOAD_SERVICES_ERROR:
        return {
          ...state,
          isServicesLoading: false,
          servicesError: action.payload,
        }

      default:
        return state
    }
  } else {
    return state
  }
}
