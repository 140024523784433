import styled from 'styled-components'
import { Typography } from 'antd'

import { Spacing } from '../../../styles'

const { Title, Text } = Typography

// *** Styles ***

export const StyledImageContainer = styled.div`
  padding-right: ${Spacing.md20};
`

export const StyledContentContainer = styled.div`
  padding-right: ${Spacing.md20};
  width: 240px;
`

export const StyledDashboardHelpTable = styled.table`
  width: 100%;
`

export const StyledTitle = styled(Title)`
  text-align: center;
  margin-bottom: ${Spacing.md20};
`

export const StyledCellTitle = styled(Title)`
  margin-bottom: ${Spacing.md20} !important;
`

export const StyledText = styled(Text)`
  display: inline-block;
  margin-bottom: ${Spacing.md20} !important;
`
export const StyledImage = styled.img`
  width: 120px;
  height: 120px;
`

export const StyledLeftTableCell = styled.td`
  padding: 0 ${Spacing.sm10} 0 68px;
`

export const StyledRightTableCell = styled.td`
  padding: 0 ${Spacing.md20} 0 ${Spacing.sm10};
`

export const StyledList = styled.ul`
  padding-left: 26px;
`

export const StyledLink = styled.a`
  white-space: nowrap;
`

// *** Styles ***
