import { FC } from 'react'
import styled from 'styled-components'
import { Row, Spin } from 'antd'

const StyledSpin = styled(Row)`
  display: flex;
  align-items: center;
  justify-content: center;
`
export type LoaderPropsType = {
  isCardLoader?: boolean
}

export const Loader: FC<LoaderPropsType> = ({ isCardLoader = false }) => (
  <StyledSpin style={isCardLoader ? { height: '100%' } : { height: '100vh' }}>
    <Spin />
  </StyledSpin>
)
