import { Modal } from 'antd'
import styled from 'styled-components'

import { EMPTY_STRING } from '../../../constants'
import { Color, Spacing } from '../../../styles'

// * Styled Modals * //

type StyledModalProps = {
  $width?: string
  $height?: string
  $mb?: string
  $itemsAmount?: number
}

export const StyledModal = styled(Modal)<StyledModalProps>`
  width: ${(props) => (props.$width ? props.$width : EMPTY_STRING)} !important;

  .ant-modal-content {
    border-radius: ${Spacing.sm12};
  }

  .ant-modal-body {
    min-height: ${(props) => (props.$height ? props.$height : '250px')} !important;
    margin-bottom: ${(props) => (props.$mb ? props.$mb : Spacing.sm12)};
    padding: ${Spacing.sm12} ${Spacing.md24};
  }

  .ant-modal-header {
    border-radius: ${Spacing.sm12};
    padding-bottom: 0;
    border: none;
  }

  & .ant-upload-list {
    height: ${(props) => (props.$itemsAmount > 4 ? '300px' : undefined)};
    margin-top: 12px;
    overflow-y: ${(props) => (props.$itemsAmount > 4 ? 'auto' : undefined)};
  }

  & .ant-upload-list-picture-container {
    padding-right: 12px;
  }

  .ant-upload.ant-upload-drag {
    min-height: 200px;
    border-radius: ${Spacing.sm12};
    color: ${Color.lightGray};
    background-color: ${Color.white};
  }

  .ant-modal-footer {
    display: flex;
    justify-content: center;
    border: none;
    padding: ${Spacing.sm8} ${Spacing.sm16} ${Spacing.md20} ${Spacing.sm16};
  }
`

export const StyledExportObjectsModal = styled(Modal)`
  .ant-modal-content {
    border-radius: ${Spacing.sm12};
  }

  .ant-modal-body {
    min-height: 120px !important;
    padding: ${Spacing.sm12} ${Spacing.md24};

    .ant-row {
      min-height: 120px;

      .export-csv-btn {
        margin-left: ${Spacing.md24};
      }
    }
  }

  .ant-modal-header {
    border-radius: ${Spacing.sm12};
    padding-bottom: 0;
    border: none;
  }

  .ant-modal-footer {
    border: none;
  }
`

export const ModalWithoutFooter = styled(StyledModal)`
  .ant-modal-footer {
    display: none !important;
  }
`

// * Styled Modals * //
