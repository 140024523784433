import styled from 'styled-components'
import { DatePicker, Input, InputNumber, TimePicker } from 'antd'

import { FontSize, FontWeight, Spacing } from '../../../styles'

const { TextArea } = Input

// ******** Inputs ******** //

type PlannyInputPropsType = {
  search?: string
  $width?: string
}

export const PlannyInput = styled(Input)<PlannyInputPropsType>`
  height: 40px;
  border-radius: ${Spacing.sm4};
  margin-bottom: ${(props) => (props.search ? 0 : Spacing.sm16)};
  width: ${(props) => (props.$width ? props.$width : undefined)} !important;
  font-weight: ${FontWeight.normal};
  font-size: ${FontSize.sm};
`

// * Styled TextArea * //

export const StyledTextArea = styled(TextArea)`
  border-radius: ${Spacing.sm4};
`

// * Styled Search * //

export const StyledSearch = styled(Input)`
  font-weight: ${FontWeight.normal};
  font-size: ${FontSize.sm};
  width: 100%;
`

// * Styled  Password Input * //

export const StyledPasswordInput = styled(Input.Password)`
  margin-bottom: ${Spacing.sm8};
  height: 40px;
  border-radius: ${Spacing.sm4};
`

// * Styled Date Picker * //

type StyledDatePickerProps = {
  $mb?: string
  $width?: string
  $height?: string
}

export const StyledDatePicker = styled(DatePicker)<StyledDatePickerProps>`
  height: ${(props) => (props.$height ? props.$height : '40px')};
  width: ${(props) => (props.$width ? props.$width : '100%')};
  border-radius: ${Spacing.sm4};
  margin-bottom: ${(props) => (props.$mb ? props.$mb : Spacing.sm16)};
`

// * Styled Time Picker * //

type StyledTimePickerProps = {
  $mb?: string
}

export const StyledTimePicker = styled(TimePicker)<StyledTimePickerProps>`
  height: 40px;
  width: 100%;
  border-radius: ${Spacing.sm4};
  margin-bottom: ${(props) => (props.$mb ? props.$mb : Spacing.sm16)};
`

// * Styled InputNumber * //

type StyledInputNumberPropsType = {
  $width?: string
  $height?: string
  $ml?: string
  $mr?: string
}

export const StyledInputNumber = styled(InputNumber)<StyledInputNumberPropsType>`
  width: ${(props) => (props.$width ? props.$width : '60px')};
  height: ${(props) => (props.$height ? props.$height : undefined)};
  border-radius: ${Spacing.sm4};
  margin-right: ${(props) => (props.$mr ? props.$mr : '8px')};
  margin-left: ${(props) => (props.$ml ? props.$ml : undefined)};

  input {
    height: ${(props) => (props.$height ? props.$height : undefined)};
  }
`

// ******** Inputs ******** //
