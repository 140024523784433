import { AxiosError } from 'axios'
import { IEquipment, IEquipmentHistory, IHistoryStats, IStatus } from '../../api'

export type EquipmentStateType = {
  equipment: IEquipment[] | undefined
  equipmentItem: IEquipment | undefined

  createdId: string | undefined
  statuses: IStatus[]
  history: IEquipmentHistory[] | null
  historyStats: IHistoryStats[] | null

  isEquipmentLoading: boolean
  isEquipmentItemLoading: boolean
  isStatusesLoading: boolean
  isEquipmentHistoryLoading: boolean

  equipmentError: null | AxiosError
  equipmentItemError: null | AxiosError
  equipmentStatusesError: null | AxiosError
  equipmentHistoryError: null | AxiosError
}

export enum EquipmentActionTypes {
  START_EQUIPMENT_LOADING = 'START_EQUIPMENT_LOADING',
  START_STATUSES_LOADING = 'START_STATUSES_LOADING',
  START_EQUIPMENT_HISTORY_LOADING = 'START_EQUIPMENT_HISTORY_LOADING',
  START_EQUIPMENT_ITEM_LOADING = 'START_EQUIPMENT_ITEM_LOADING',

  LOAD_EQUIPMENT_SUCCESS = 'LOAD_EQUIPMENT_SUCCESS',
  LOAD_EQUIPMENT_ITEM_SUCCESS = 'LOAD_EQUIPMENT_ITEM_SUCCESS',
  LOAD_EQUIPMENT_HISTORY_SUCCESS = 'LOAD_EQUIPMENT_HISTORY_SUCCESS',
  LOAD_EQUIPMENT_HISTORY_STATS_SUCCESS = 'LOAD_EQUIPMENT_HISTORY_STATS_SUCCESS',
  LOAD_EQUIPMENT_STATUSES_SUCCESS = 'LOAD_EQUIPMENT_STATUSES_SUCCESS',

  CREATE_EQUIPMENT_ITEM_SUCCESS = 'CREATE_EQUIPMENT_ITEM_SUCCESS',

  EDIT_EQUIPMENT_HISTORY_ITEM = 'EDIT_EQUIPMENT_HISTORY_ITEM',
  DELETE_EQUIPMENT_HISTORY_ITEM = 'DELETE_EQUIPMENT_HISTORY_ITEM',

  DELETE_EQUIPMENT_ITEM_SUCCESS = 'DELETE_EQUIPMENT_ITEM_SUCCESS',
  DELETE_EQUIPMENT_ITEM_FROM_STATE = 'DELETE_EQUIPMENT_ITEM_FROM_STATE',
  DELETE_EQUIPMENT_FROM_STATE = 'DELETE_EQUIPMENT_FROM_STATE',

  SET_CREATED_EQUIPMENT_ID = 'SET_CREATED_EQUIPMENT_ID',

  LOAD_ERROR = 'LOAD_ERROR',
  LOAD_STATUSES_ERROR = 'LOAD_STATUSES_ERROR',
  LOAD_EQUIPMENT_HISTORY_ERROR = 'LOAD_EQUIPMENT_HISTORY_ERROR',
  LOAD_EQUIPMENT_ITEM_ERROR = 'LOAD_EQUIPMENT_ITEM_ERROR',
}

export type StartLoadingActionType = {
  type: EquipmentActionTypes.START_EQUIPMENT_LOADING
}

export type StartEquipmentItemLoadingActionType = {
  type: EquipmentActionTypes.START_EQUIPMENT_ITEM_LOADING
}

export type StartStatusesLoadingActionType = {
  type: EquipmentActionTypes.START_STATUSES_LOADING
}

export type StartEquipmentHistoryLoadingActionType = {
  type: EquipmentActionTypes.START_EQUIPMENT_HISTORY_LOADING
}

export type LoadEquipmentSuccessActionType = {
  type: EquipmentActionTypes.LOAD_EQUIPMENT_SUCCESS
  payload: IEquipment[]
}

export type EditEquipmentHistoryItemSuccessActionType = {
  type: EquipmentActionTypes.EDIT_EQUIPMENT_HISTORY_ITEM
}

export type DeleteEquipmentHistoryItemSuccessActionType = {
  type: EquipmentActionTypes.DELETE_EQUIPMENT_HISTORY_ITEM
}

export type LoadEquipmentItemSuccessActionType = {
  type: EquipmentActionTypes.LOAD_EQUIPMENT_ITEM_SUCCESS
  payload: IEquipment
}

export type LoadEquipmentStatusesSuccessActionType = {
  type: EquipmentActionTypes.LOAD_EQUIPMENT_STATUSES_SUCCESS
  payload: IStatus[]
}

export type LoadEquipmentStatsSuccessActionType = {
  type: EquipmentActionTypes.LOAD_EQUIPMENT_HISTORY_STATS_SUCCESS
  payload: IHistoryStats[]
}

export type LoadingErrorActionType = {
  type: EquipmentActionTypes.LOAD_ERROR
  payload: AxiosError
}

export type EquipmentItemLoadingErrorActionType = {
  type: EquipmentActionTypes.LOAD_EQUIPMENT_ITEM_ERROR
  payload: AxiosError
}

export type StatusesLoadingErrorActionType = {
  type: EquipmentActionTypes.LOAD_STATUSES_ERROR
  payload: AxiosError
}

export type EquipmentHistoryLoadingErrorActionType = {
  type: EquipmentActionTypes.LOAD_EQUIPMENT_HISTORY_ERROR
  payload: AxiosError
}

export type CreateEquipmentItemSuccessActionType = {
  type: EquipmentActionTypes.CREATE_EQUIPMENT_ITEM_SUCCESS
}

export type DeleteEquipmentItemSuccessActionType = {
  type: EquipmentActionTypes.DELETE_EQUIPMENT_ITEM_SUCCESS
  payload: number
}

export type DeleteEquipmentItemFromStateActionType = {
  type: EquipmentActionTypes.DELETE_EQUIPMENT_ITEM_FROM_STATE
}

export type DeleteEquipmentFromStateActionType = {
  type: EquipmentActionTypes.DELETE_EQUIPMENT_FROM_STATE
}

export type SetCreatedEquipmentIdActionType = {
  type: EquipmentActionTypes.SET_CREATED_EQUIPMENT_ID
  payload: string | undefined
}

export type LoadEquipmentHistorySuccessActionType = {
  type: EquipmentActionTypes.LOAD_EQUIPMENT_HISTORY_SUCCESS
  payload: IEquipmentHistory[]
}

export type EquipmentActionType =
  | StartLoadingActionType
  | StartEquipmentItemLoadingActionType
  | StartStatusesLoadingActionType
  | StartEquipmentHistoryLoadingActionType
  | LoadEquipmentSuccessActionType
  | LoadEquipmentStatusesSuccessActionType
  | LoadEquipmentStatsSuccessActionType
  | CreateEquipmentItemSuccessActionType
  | DeleteEquipmentItemSuccessActionType
  | LoadEquipmentItemSuccessActionType
  | DeleteEquipmentItemFromStateActionType
  | SetCreatedEquipmentIdActionType
  | LoadEquipmentHistorySuccessActionType
  | DeleteEquipmentFromStateActionType
  | LoadingErrorActionType
  | EquipmentItemLoadingErrorActionType
  | StatusesLoadingErrorActionType
  | EquipmentHistoryLoadingErrorActionType
  | EditEquipmentHistoryItemSuccessActionType
  | DeleteEquipmentHistoryItemSuccessActionType
