import { Moment } from 'moment'

import { AppDispatch } from '../../hooks'
import { DATA_TYPE } from '../../constants'
import {
  ImagesActionTypes,
  TagsActionTypes,
  TasksActionTypes,
  TemporaryDataActionTypes,
} from '../actionTypes'

export const setTemporaryDataIds =
  (dataType: string, tempIds: number | number[] | null) => (dispatch: AppDispatch) => {
    dispatch({
      type: TemporaryDataActionTypes.SET_TEMPORARY_IDS,
      payload: {
        dataType,
        tempIds,
      },
    })
  }

export const setTemporaryPlaceAddress = (address: string | null) => (dispatch: AppDispatch) => {
  dispatch({
    type: TemporaryDataActionTypes.SET_ADDRESS,
    payload: address,
  })
}

export const resetTemporaryIds = () => (dispatch: AppDispatch) => {
  dispatch(setTemporaryDataIds(DATA_TYPE.EQUIPMENT, undefined))

  dispatch(setTemporaryDataIds(DATA_TYPE.PLACES, undefined))

  dispatch(setTemporaryDataIds(DATA_TYPE.USER_SERVICES, undefined))
}

export const resetTemporaryMaterialIds = () => (dispatch: AppDispatch) => {
  dispatch({
    type: TemporaryDataActionTypes.SET_TEMPORARY_IDS,
    payload: {
      dataType: DATA_TYPE.MATERIALS,
      tempIds: undefined,
    },
  })
}

export const setTemporaryDate = (date: Moment | null) => (dispatch: AppDispatch) => {
  dispatch({
    type: TemporaryDataActionTypes.SET_TEMPORARY_DATE,
    payload: date,
  })
}

export const deleteTemporaryTags = () => (dispatch: AppDispatch) =>
  dispatch({
    type: TagsActionTypes.DELETE_TEMPORARY_TAGS,
  })

export const deleteTemporaryImages = () => (dispatch: AppDispatch) =>
  dispatch({
    type: ImagesActionTypes.DELETE_TEMPORARY_IMAGES,
  })

export const resetAllTemporaryData = () => (dispatch: AppDispatch) => {
  dispatch(resetTemporaryIds())
  dispatch(deleteTemporaryTags())
}

export const deleteRuleFromState = () => (dispatch: AppDispatch) => {
  dispatch({
    type: TasksActionTypes.LOAD_TASK_RULES_SUCCESS,
    payload: null,
  })
}
