import { Col } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { ChangeEvent, FC, useEffect } from 'react'

import { DataTypes } from '../../api'
import { EMPTY_STRING } from '../../constants'
import { getFilters } from '../../store/selectors'
import { setSearchValue } from '../../store/actions/filters'
import { Color, FontSize, Spacing } from '../../styles'
import { PlannyInput, StyledSearch } from '../common/styled'
import { useTypedDispatch, useTypedSelector } from '../../hooks'

const StyledSearchOutlined = styled(SearchOutlined)`
  font-size: ${FontSize.sm};
  color: ${Color.lightGray};
  margin-right: ${Spacing.sm8};
`

type SearchFormPropsType = {
  $isModal?: boolean
  dataType?: DataTypes
}

const SEARCH = 'searchField'

export const SearchForm: FC<SearchFormPropsType> = ({ $isModal, dataType }) => {
  const { searchValue } = useTypedSelector(getFilters)

  const dispatch = useTypedDispatch()

  const { t } = useTranslation()

  const getValue = () => searchValue[dataType]

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const search = e.target.value

    dispatch(setSearchValue(dataType, search))
  }

  useEffect(() => {
    return () => dispatch(setSearchValue(dataType, EMPTY_STRING))
  }, [dispatch, dataType])

  return $isModal ? (
    <Col xs={24}>
      <StyledSearch
        id="searchValue"
        name="searchValue"
        placeholder={t('search-input-placeholder')}
        allowClear
        prefix={<StyledSearchOutlined />}
        bordered={false}
        value={getValue()}
        onChange={handleChange}
      />
    </Col>
  ) : (
    <Col xs={20}>
      <PlannyInput
        id="searchValue"
        name="searchValue"
        search={SEARCH}
        placeholder={t('search-input-placeholder')}
        allowClear
        prefix={<StyledSearchOutlined />}
        value={getValue()}
        onChange={handleChange}
      />
    </Col>
  )
}
