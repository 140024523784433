import { ColumnsType } from 'antd/es/table'
import { Link } from 'react-router-dom'
import moment from 'moment'
import urlJoin from 'url-join'

import { getLanguage } from '../../../functions'
import { NotificationsDataType } from '../../../api'
import { DATE_FORMAT, FULL_DATE_FORMAT, LANGUAGES } from '../../../constants'
import { getNotificationsHistoryColumnTitles, TableCellContent } from '../../tables'

import 'moment/dist/locale/ru'
import 'moment/dist/locale/en-gb'

export const getNotificationsHistoryTableColumns = () => {
  moment.locale(getLanguage() === LANGUAGES.RU ? LANGUAGES.RU : LANGUAGES.EN_CA)

  const titles = getNotificationsHistoryColumnTitles()

  const columns: ColumnsType<NotificationsDataType> = [
    {
      title: titles.createdAt,
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (date, record) => {
        return date ? (
          <TableCellContent
            text={moment(date).format(DATE_FORMAT)}
            tooltipText={moment(date).format(FULL_DATE_FORMAT)}
            isStrong={!record.isRead}
          />
        ) : null
      },
      ellipsis: true,
      width: '14%',
    },

    {
      title: titles.eventMsg,
      dataIndex: 'message',
      key: 'message',
      render: (message, record) => {
        return message ? (
          <TableCellContent text={message} $className="message" isStrong={!record.isRead} />
        ) : null
      },
      ellipsis: true,
    },

    {
      title: titles.eventTypeName,
      dataIndex: 'type',
      key: 'type',
      render: (type, record) => {
        return record?.obj?.url ? (
          <Link to={urlJoin(record.obj.url)}>
            <TableCellContent
              text={record.obj?.name || type}
              isLink
              $className="type"
              isStrong={!record?.isRead}
            />
          </Link>
        ) : (
          <TableCellContent
            text={record?.obj?.name || type}
            $className="type"
            isStrong={!record?.isRead}
          />
        )
      },
      ellipsis: true,
      width: '25%',
    },

    {
      title: titles.isMsgRead,
      dataIndex: 'isMsgRead',
      key: 'isMsgRead',
      width: '12%',
      ellipsis: true,
    },
  ]

  return columns
}
