import { PpwGraphicsActionType, PpwGraphicsActionTypes, PpwGraphicsStateType } from '../actionTypes'

const initialState: PpwGraphicsStateType = {
  isPpwGraphicsLoading: false,
  ppwGraphics: null,
  ppwGraphicsItem: null,
  rules: null,
  createdId: undefined,
  loadPpwGraphicsError: null,
}

export const ppwGraphics = (
  state = initialState,
  action: PpwGraphicsActionType,
): PpwGraphicsStateType => {
  if (action) {
    switch (action.type) {
      case PpwGraphicsActionTypes.START_PPW_GRAPHICS_LOADING:
        return {
          ...state,
          isPpwGraphicsLoading: true,
        }

      case PpwGraphicsActionTypes.GET_PPW_GRAPHICS_SUCCESS:
        return {
          ...state,
          ppwGraphics: action.payload,
          isPpwGraphicsLoading: false,
          loadPpwGraphicsError: null,
        }

      case PpwGraphicsActionTypes.GET_PPW_GRAPHICS_ITEM_SUCCESS:
        return {
          ...state,
          ppwGraphicsItem: action.payload,
          isPpwGraphicsLoading: false,
          loadPpwGraphicsError: null,
        }

      case PpwGraphicsActionTypes.GET_RULES_SUCCESS:
        return {
          ...state,
          rules: action.payload,
          isPpwGraphicsLoading: false,
          loadPpwGraphicsError: null,
        }

      case PpwGraphicsActionTypes.CREATE_PPW_GRAPHICS_SUCCESS:
        return {
          ...state,
          ppwGraphicsItem: action.payload,
          isPpwGraphicsLoading: false,
        }

      case PpwGraphicsActionTypes.EDIT_PPW_GRAPHICS_ITEM_SUCCESS:
        return {
          ...state,
          ppwGraphics: action.payload,
          isPpwGraphicsLoading: false,
        }

      case PpwGraphicsActionTypes.SET_LOAD_PPW_GRAPHICS_ERROR:
        return {
          ...state,
          isPpwGraphicsLoading: false,
          loadPpwGraphicsError: action.payload,
        }

      case PpwGraphicsActionTypes.SET_CREATED_PPW_GRAPHICS_ID:
        return {
          ...state,
          createdId: action.payload,
        }

      case PpwGraphicsActionTypes.DELETE_PPW_GRAPHICS_SUCCESS:
        return { ...state, ppwGraphics: state.ppwGraphics.filter((i) => i.id !== action.payload) }
      default:
        return state
    }
  } else {
    return state
  }
}
