import { AppDispatch } from '../../../hooks'
import { DATA_TYPE } from '../../../constants'
import { getAllEquipment, loadEquipmentItemSuccess } from '../equipments'
import { getAllMaterials, loadMaterialItemSuccess } from '../materials'
import { getAllMeters, loadMeterItemSuccess } from '../meters'
import { getAllPlaces, loadPlaceItemSuccess } from '../places'
import { getAllServices, loadServiceItemSuccess } from '../userServices'
import { getAllTasks, loadTaskItemSuccess } from '../tasks'
import { IEquipment, IMaterial, IMeter, IPlaces, ITask } from '../../../api/types'

export const handleUpdateItem =
  (data: IEquipment | IPlaces | IMeter | IMaterial | ITask, fileType: string) =>
  (dispatch: AppDispatch) => {
    if (fileType === DATA_TYPE.EQUIPMENT) {
      dispatch(loadEquipmentItemSuccess(data))
      dispatch(getAllEquipment())
    }

    if (fileType === DATA_TYPE.PLACES) {
      dispatch(loadPlaceItemSuccess(data))
      dispatch(getAllPlaces())
    }

    if (fileType === DATA_TYPE.MATERIALS) {
      dispatch(loadMaterialItemSuccess(data))
      dispatch(getAllMaterials())
    }

    if (fileType === DATA_TYPE.METERS) {
      dispatch(loadMeterItemSuccess(data))
      dispatch(getAllMeters())
    }

    if (fileType === DATA_TYPE.TASKS) {
      dispatch(loadTaskItemSuccess(data))
      dispatch(getAllTasks())
    }

    if (fileType === DATA_TYPE.USER_SERVICES) {
      dispatch(loadServiceItemSuccess(data))
      dispatch(getAllServices())
    }
  }
