import * as Yup from 'yup'
import { METER_PARAMETERS, REPITITION_TYPES } from '../../constants'

export const REQUIRED_STRING_FIELD = Yup.string().min(1).required('Заполните поле')

export const NUMBER_FIELD = Yup.number().typeError(
  'Введите число. Как разделитель используйте точку.',
)

export const REQUIRED_NUMBER_FIELD = Yup.number()
  .min(0, 'Число должно быть больше нуля')
  .nullable(true)
  .typeError('Введите число. Как разделитель используйте точку.')
  .required('Заполните поле')

export const INTEGER_FIELD = Yup.number()
  .min(0, 'Число должно быть больше нуля')
  .max(2147483647, 'Слишком большое число')
  .nullable(true)
  .typeError('Введите целое число')
  .integer('Введите целое число')

export const REQUIRED_INTEGER_FIELD = Yup.number()
  .min(0, 'Число должно быть больше нуля')
  .max(2147483647, 'Слишком большое число')
  .typeError('Введите целое число')
  .integer('Введите целое число')
  .required('Заполните поле')

const validateMeterId = (type: string, conditionKey: string, extraKey?: string) =>
  Yup.number()
    .transform((value) => (isNaN(value) || value === null || value === undefined ? 0 : value))
    .when(type, {
      is: conditionKey || extraKey,
      then: Yup.number()
        .transform((value) => (isNaN(value) || value === null || value === undefined ? 0 : value))
        .required('Необходимо выбрать счетчик'),
      otherwise: Yup.number().notRequired(),
    })

const floatNumberFieldValidate = (type: string, conditionKey: string) =>
  Yup.number().when(type, {
    is: conditionKey,
    then: Yup.number()
      .min(1, 'Число должно быть больше нуля')
      .typeError('Введите число. Как разделитель используйте точку.')
      .required('Заполните поле'),
    otherwise: Yup.number().nullable(true).notRequired(),
  })

const numberFieldValidate = (type: string, conditionKey: string) =>
  Yup.number().when(type, {
    is: conditionKey,
    then: Yup.number()
      .min(1, 'Число должно быть больше нуля')
      .transform((value) => (isNaN(value) || value === null || value === undefined ? 0 : value))
      .typeError('Введите число. Как разделитель используйте точку.')
      .required('Заполните поле'),

    otherwise: Yup.number().nullable(true).notRequired(),
  })

const dateValidate = (type: string, conditionKey: string) =>
  Yup.string().when(type, {
    is: conditionKey,
    then: Yup.string().required('Выберите дату'),
    otherwise: Yup.string().nullable(true).notRequired(),
  })

const arrayValidate = (type: string, conditionKey: string) =>
  Yup.array()
    .transform((value) => (isNaN(value) || value === null || value === undefined ? [] : value))
    .of(Yup.number())
    .when(type, {
      is: conditionKey,
      then: Yup.array().required('Необходимо выбрать день недели'),
      otherwise: Yup.array().nullable(true).notRequired(),
    })

export const SEARCH_FORM_VALIDATION_SCHEMA = Yup.object().shape({
  searchValue: REQUIRED_STRING_FIELD,
})

export const CREATE_EQUIPMENT_FORM_VALIDATION_SCHEMA = Yup.object().shape({
  name: REQUIRED_STRING_FIELD,
})

export const EDIT_EQUIPMENT_FORM_VALIDATION_SCHEMA = Yup.object().shape({
  name: REQUIRED_STRING_FIELD,
})

export const CREATE_PLACE_FORM_VALIDATION_SCHEMA = Yup.object().shape({
  name: REQUIRED_STRING_FIELD,
})

export const EDIT_PLACE_FORM_VALIDATION_SCHEMA = Yup.object().shape({
  name: REQUIRED_STRING_FIELD,
})

export const CREATE_MATERIALS_FORM_VALIDATION_SCHEMA = Yup.object().shape({
  name: REQUIRED_STRING_FIELD,
  unitCount: INTEGER_FIELD,
  unitPrice: NUMBER_FIELD,
})

export const EDIT_MATERIALS_FORM_VALIDATION_SCHEMA = Yup.object().shape({
  name: REQUIRED_STRING_FIELD,
  unitPrice: NUMBER_FIELD,
})

export const EDIT_MATERIALS_COUNT_FORM_VALIDATION_SCHEMA = Yup.object().shape({
  unitCount: INTEGER_FIELD,
})

export const EDIT_MATERIALS_NIN_COUNT_FORM_VALIDATION_SCHEMA = Yup.object().shape({
  minUnitCount: REQUIRED_INTEGER_FIELD,
})

export const CREATE_METER_FORM_VALIDATION_SCHEMA = Yup.object().shape({
  name: REQUIRED_STRING_FIELD,
  readingPeriodValue: INTEGER_FIELD,
  operationValue: Yup.number().typeError('Введите число. Как разделитель используйте точку.'),
})

export const EDIT_METER_FORM_VALIDATION_SCHEMA = Yup.object().shape({
  name: REQUIRED_STRING_FIELD,
  readingPeriodValue: INTEGER_FIELD,
  validityPeriodValue: INTEGER_FIELD,
})

export const EDIT_METER_VALUE_FORM_VALIDATION_SCHEMA = Yup.object().shape({
  operationValue: REQUIRED_NUMBER_FIELD,
})

export const REGISTRATION_VALIDATION_SCHEMA = Yup.object().shape({
  password: Yup.string()
    .required('Пожалуйста, введите пароль')
    .min(8, 'Пароль должен содержать не менее 8 символов'),

  passwordConfirmation: Yup.string()
    .required('Пожалуйста, подтвердите пароль')
    .oneOf([Yup.ref('password'), null], 'Пароли не совпадают'),

  email: Yup.string()
    .min(5, 'Email должен содержать не менее 5 символов')
    .required('Пожалуйста, введите Email')
    .trim()
    .email('Невалидный Email')
    .max(255),
})

export const LOGIN_VALIDATION_SCHEMA = Yup.object().shape({
  username: Yup.string().required('Пожалуйста, введите Email'),
  password: Yup.string().required('Пожалуйста, введите пароль'),
})

export const EMAIL_VALIDATION_SCHEMA = Yup.object().shape({
  email: Yup.string()
    .nullable(true)
    .min(5, 'Email должен содержать не менее 5 знаков')
    .trim()
    .required('Пожалуйста, введите Email')
    .matches(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      'Невалидный Email',
    ),
})

export const RESET_PASSWORD_VALIDATION_SCHEMA = Yup.object().shape({
  password: Yup.string()
    .required('Пожалуйста, введите пароль')
    .min(8, 'Пароль должен содержать не менее 8 символов'),

  passwordConfirmation: Yup.string()
    .required('Пожалуйста, подтвердите пароль')
    .oneOf([Yup.ref('password'), null], 'Пароли не совпадают'),
})

export const EDIT_USER_VALIDATION_SCHEMA = Yup.object().shape({
  email: Yup.string()
    .min(5, 'Email должен содержать не менее 5 знаков')
    .trim()
    .required('Пожалуйста, введите Email')
    .matches(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      'Невалидный Email',
    ),
})

export const COORDINATES_VALIDATION_SCHEMA = Yup.object().shape({
  location: Yup.string()
    .trim()
    .matches(
      /^[-]?[0-9]{1,3}.[0-9]{2,},\s{0,}?[-]?[0-9]{1,3}.[0-9]{2,}$/,
      'Введите координаты через запятую. Например: "65.874376, -174.707715".',
    ),
})

export const EDIT_TASK_FORM_VALIDATION_SCHEMA = Yup.object().shape({
  title: REQUIRED_STRING_FIELD,
})

export const EDIT_USER_SERVICE_FORM_VALIDATION_SCHEMA = Yup.object().shape({
  name: REQUIRED_STRING_FIELD,
})

export const CREATE_USER_SERVICE_FORM_VALIDATION_SCHEMA = Yup.object().shape({
  name: REQUIRED_STRING_FIELD,
})

export const CREATE_RULES_FORM_VALIDATION_SCHEMA = Yup.object().shape({
  meterParametersType: Yup.string().nullable(),
  relationType: Yup.string(),
  repititionType: Yup.string().nullable(),
  relationValue: floatNumberFieldValidate('meterParametersType', METER_PARAMETERS.VALUE),
  value: floatNumberFieldValidate('meterParametersType', METER_PARAMETERS.INTERVAL),
  startAtMeterValue: floatNumberFieldValidate('meterParametersType', METER_PARAMETERS.INTERVAL),
  meterId: validateMeterId('repititionType', METER_PARAMETERS.INTERVAL, METER_PARAMETERS.VALUE),
  monthDayNumber: numberFieldValidate('repititionType', REPITITION_TYPES.MONTHLY),
  checkedDays: arrayValidate('repititionType', REPITITION_TYPES.WEEKY),
  startedAtValue: numberFieldValidate('repititionType', REPITITION_TYPES.CUSTOM),
  startedAtTime: dateValidate('repititionType', REPITITION_TYPES.CUSTOM),
})

export const EDIT_TASK_PARAMETERS_FORM_VALIDATION_SCHEMA = Yup.object().shape({
  laborHours: Yup.number().nullable(true).typeError('Введите число.'),
  downtimeDays: Yup.number().nullable(true).typeError('Введите число.'),
})

export const PPW_FORM_VALIDATION_SCHEMA = Yup.object().shape({
  name: REQUIRED_STRING_FIELD,
  startDatetime: REQUIRED_STRING_FIELD,
  endDatetime: REQUIRED_STRING_FIELD,
})

export const CLIENT_FORM_VALIDATION_SCHEMA = Yup.object().shape({
  email: REQUIRED_STRING_FIELD,
})
