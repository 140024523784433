import styled from 'styled-components'
import { Col, Divider, Layout, Row, Tag, Typography } from 'antd'

import { BoxShadow, Color, FontSize, LineHeight, Spacing } from '../../../styles'

const { Text, Title } = Typography

export const StyledCardText = styled(Text)`
  font-size: ${FontSize.sm};
  line-height: 18px;
`

export const StyledLinkText = styled(Text)`
  :hover {
    color: ${Color.blue};
  }
`

export const StyledPanelTitle = styled(Title)`
  font-size: ${FontSize.md} !important;
  margin-bottom: 1em !important;

  svg {
    margin: 0 ${Spacing.sm12} -${Spacing.sm4} 0;
  }
`

export const PlannyLabel = styled(Text)`
  font-size: ${FontSize.sm};
  line-height: ${LineHeight.sm};
  color: ${Color.gray};
`

export const RequiredFieldStarLabel = styled.span`
  color: ${Color.red};
`

// ******** Text ******** //

export const StyledFieldError = styled(Row)`
  color: ${Color.red};
  margin: -${Spacing.sm8} 0 ${Spacing.sm2} 0;
`

type StyledAbsoluteFieldErrorPropsType = {
  $top?: string
  $left?: string
}

export const StyledAbsoluteFieldError = styled(Row)<StyledAbsoluteFieldErrorPropsType>`
  position: absolute;
  color: ${Color.red};
  top: ${(props) => (props.$top ? props.$top : Spacing.sm4)};
  left: ${(props) => (props.$left ? props.$left : Spacing.sm4)};
`

// * StyledTag * //

type StyledTagPropsType = {
  $isTable?: boolean
}

export const StyledTag = styled(Tag)<StyledTagPropsType>`
  margin-bottom: ${(props) => (props.$isTable ? Spacing.sm2 : Spacing.sm4)};
  border-radius: 8px;
`

// * Styled Divider * //

type StyledTableDividerProps = {
  $mt?: string
  $mb?: string
}

export const StyledTableDivider = styled(Divider)<StyledTableDividerProps>`
  width: 350px;
  margin-top: ${(props) => (props.$mt ? props.$mt : Spacing.sm12)};
  margin-bottom: ${(props) => (props.$mb ? props.$mb : Spacing.sm12)};
`

type StyledImageContainerProps = {
  $danger?: boolean
}

export const StyledImageContainer = styled(Col)<StyledImageContainerProps>`
  border: 1px solid ${(props) => (props.$danger ? Color.red : Color.lightGray)};
  border-radius: ${Spacing.sm12};
  margin: ${Spacing.sm6};
  width: 150px !important;
  height: 150px;
  overflow: hidden;
  padding: 0 !important;
`

export const StyledCol = styled(Col)`
  margin-bottom: ${Spacing.sm16};
`
// Auth form wrapper *Temporary component*

export const FormWrapper = styled(Row)`
  width: 440px;
  background-color: ${Color.white};
  padding: ${Spacing.md40} ${Spacing.md40};
  border-radius: ${Spacing.sm8};
  margin: ${Spacing.md40} 0;
  box-shadow: ${BoxShadow.card};
  form {
    width: 280px;
  }
`

export const StyledRow = styled(Row)`
  margin-bottom: ${Spacing.sm16};
`

type StyledMarginWrapperProps = {
  $mt?: string
  $mb?: string
  $ml?: string
  $mr?: string
}

export const StyledMarginWrapper = styled(Row)<StyledMarginWrapperProps>`
  margin-bottom: ${(props) => (props.$mb ? props.$mb : Spacing.sm16)};
  margin-top: ${(props) => (props.$mt ? props.$mt : {})};
  margin-left: ${(props) => (props.$ml ? props.$ml : {})};
  margin-right: ${(props) => (props.$mr ? props.$mr : {})};
`

export const StyledLabelWrapper = styled(Col)`
  margin-bottom: ${Spacing.sm4};
`

export const LogoWrapper = styled(Row)`
  padding-top: ${Spacing.md20};
`

export const StyledButtonsPanel = styled(Row)`
  padding: ${Spacing.md20};
  background-color: ${Color.white};
  border-radius: ${Spacing.sm8};
  margin: 0 0 ${Spacing.md20} 0 !important;
  box-shadow: ${BoxShadow.card};
`

type StyledRangeDateCardWrapperPropsType = {
  $ml?: string
}

export const StyledRangeDateCardWrapper = styled(Col)<StyledRangeDateCardWrapperPropsType>`
  background-color: ${Color.white};
  box-shadow: ${BoxShadow.card};
  height: 56px;
  border-radius: ${Spacing.sm8};
`

type StyledHistoryPromptWrapperPropsType = {
  $height: string
}

export const StyledHistoryPromptWrapper = styled(Row)<StyledHistoryPromptWrapperPropsType>`
  width: 100%;
  height: ${(props) => (props.$height ? props.$height : '400px')};

  .ant-typography {
    color: ${Color.lightGray};
  }
`

export const StyledCalendarCellWrapper = styled(Row)`
  position: absolute;
  top: 0;
  padding: 0;
  margin: 0;
  width: 100%;
  height: 30%;
`

export const StyledCalendarCellTextWrapper = styled(Col)`
  margin-bottom: 6px;

  .ant-badge-status-text:hover {
    color: ${Color.blue};
  }
`

export const EmailWrapper = styled(Col)`
  display: flex;
  align-items: center;
  margin-right: ${Spacing.md40};

  a {
    line-height: 18px;
  }

  a > span.ant-typography {
    display: block;
    line-height: 18px;
    font-size: ${FontSize.md};
  }

  a > span.ant-typography.email {
    font-size: 14px !important;
    color: ${Color.blue};
  }

  svg {
    margin-right: ${Spacing.sm8};
    cursor: pointer;
  }
`

export const StyledDivider = styled(Divider)`
  margin: 12px 0;
`

export const StyledInfoPanel = styled(Row)`
  width: 100%;
  height: 50px;
  background-color: #e8edf2;
  border-radius: 4px;
  padding: 4px 20px;
  margin-bottom: 8px;
`
export const StyledAuthLayout = styled(Layout)`
  min-height: 100vh;
  font-family: 'Roboto', sans-serif;
  background-image: url('/images/p24_pattern.png');
`

export const StyledLayout = styled(Layout)`
  min-height: 100vh;
  font-family: 'Roboto', sans-serif;
`

export const ZeroPaddingCol = styled(Col)`
  padding: 0;
`

type CalcWidthColProps = {
  $baseWidth?: number
  $sbtWidth?: number
}

export const CalcWidthCol = styled(Col)<CalcWidthColProps>`
  width: ${(props) =>
    props.$baseWidth && props.$sbtWidth
      ? `calc(${props.$baseWidth}px - ${props.$sbtWidth}px)`
      : `calc(100% - ${props.$sbtWidth}px)`};
`

type HistoryBlockContainerProps = {
  $width?: string
}

export const HistoryBlockContainer = styled(Col)<HistoryBlockContainerProps>`
  width: ${(props) => props.$width || '1200px'};
`

type HistoryCardWrapperProps = {
  $width?: string
}

export const HistoryCardWrapper = styled(Col)<HistoryCardWrapperProps>`
  width: ${(props) => props.$width || '260px'};
`
