import { AxiosError } from 'axios'

import { IMember, IOwner, IWorkspace } from '../../api'

export type WorkspacesStateType = {
  isWorkspacesLoading: boolean
  workspaces: IWorkspace[]
  currentWorkspace: IWorkspace
  defaultWorkspaceId: number | null
  members: IMember[]
  owner: IOwner
  loadWorkspacesError: AxiosError | null
}

export enum WorkspacesActionTypes {
  START_WORKSPACES_LOADING = 'START_WORKSPACES_LOADING',

  CREATE_WORKSPACES_SUCCESS = 'CREATE_WORKSPACES_SUCCESS',

  GET_WORKSPACES_SUCCESS = 'GET_WORKSPACES_SUCCESS',
  GET_CURRENT_WORKSPACE_SUCCESS = 'GET_CURRENT_WORKSPACE_SUCCESS',
  GET_WORKSPACE_MEMBERS_SUCCESS = 'GET_WORKSPACE_MEMBERS_SUCCESS',
  GET_WORKSPACE_OWNER_SUCCESS = 'GET_WORKSPACE_OWNER_SUCCESS',

  EDIT_WORKSPACE_MEMBER_SUCCESS = 'EDIT_WORKSPACE_MEMBER_SUCCESS',

  DELETE_WORKSPACE_ITEM_SUCCESS = 'DELETE_WORKSPACE_ITEM_SUCCESS',

  SET_DEFAULT_WORKSPACE_ID = 'SET_DEFAULT_WORKSPACE_ID',

  SET_LOAD_WORKSPACES_ERROR = 'SET_LOAD_WORKSPACES_ERROR',
}

export type StartWorkspacesLoadingActionType = {
  type: WorkspacesActionTypes.START_WORKSPACES_LOADING
}

export type GetWorkspacesSuccessActionType = {
  type: WorkspacesActionTypes.GET_WORKSPACES_SUCCESS
  payload: IWorkspace[]
}

export type GetCurrentWorkspaceSuccessActionType = {
  type: WorkspacesActionTypes.GET_CURRENT_WORKSPACE_SUCCESS
  payload: IWorkspace
}

export type GetWorkspaceMembersSuccessActionType = {
  type: WorkspacesActionTypes.GET_WORKSPACE_MEMBERS_SUCCESS
  payload: IMember[]
}

export type GetWorkspaceOwnerSuccessActionType = {
  type: WorkspacesActionTypes.GET_WORKSPACE_OWNER_SUCCESS
  payload: IOwner
}

export type EditWorkspaceMemberSuccessActionType = {
  type: WorkspacesActionTypes.EDIT_WORKSPACE_MEMBER_SUCCESS
  payload: IMember
}

export type CreateWorkspacesSuccessActionType = {
  type: WorkspacesActionTypes.CREATE_WORKSPACES_SUCCESS
  payload: IWorkspace[]
}

export type SetLoadWorkspacesErrorActionType = {
  type: WorkspacesActionTypes.SET_LOAD_WORKSPACES_ERROR
  payload: AxiosError | null
}

export type SetDefaultWorkspaceIdActionType = {
  type: WorkspacesActionTypes.SET_DEFAULT_WORKSPACE_ID
  payload: number | null
}

export type DeleteWorkspaceItemActionType = {
  type: WorkspacesActionTypes.DELETE_WORKSPACE_ITEM_SUCCESS
}

export type WorkspacesActionType =
  | StartWorkspacesLoadingActionType
  | GetWorkspacesSuccessActionType
  | GetCurrentWorkspaceSuccessActionType
  | GetWorkspaceMembersSuccessActionType
  | GetWorkspaceOwnerSuccessActionType
  | EditWorkspaceMemberSuccessActionType
  | CreateWorkspacesSuccessActionType
  | DeleteWorkspaceItemActionType
  | SetDefaultWorkspaceIdActionType
  | SetLoadWorkspacesErrorActionType
