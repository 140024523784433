import styled from 'styled-components'
import { FC, ReactNode } from 'react'
import { Row, Typography } from 'antd'

const { Text } = Typography

const BadgeWrapper = styled(Row)`
  min-width: 40px;
  max-width: 60px;
  height: 26px;
  background-color: #c2ddff;
  border-radius: 8px;
  margin-left: 12px;
  overflow-x: hidden;
`

const StyledText = styled(Text)`
  display: inline-block;
  line-height: 14px;
`

type NotificationsBadgePropsType = {
  children: ReactNode
}

export const NotificationsBadge: FC<NotificationsBadgePropsType> = ({ children }) => {
  return (
    <BadgeWrapper align="middle" justify="center">
      <StyledText>{children}</StyledText>
    </BadgeWrapper>
  )
}
