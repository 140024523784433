import { RcFile } from 'antd/lib/upload'

export type FilesStateType = {
  tempFiles: RcFile[]
  isFilesLoading: boolean
  isFilesUploading: boolean
  filesError: null | string
}

export enum FilesActionTypes {
  START_FILES_LOADING = 'START_FILES_LOADING',
  START_FILES_UPLOAD = 'START_FILES_UPLOAD',
  LOAD_FILES_ERROR = 'LOAD_FILES_ERROR',
  CREATE_FILES_SUCCESS = 'CREATE_FILES_SUCCESS',
  SAVE_TEMPORARY_FILES = 'SAVE_TEMPORARY_FILES',
  DELETE_TEMPORARY_FILES = 'DELETE_TEMPORARY_FILES',
  DELETE_TEMPORARY_FILE_ITEM = 'DELETE_TEMPORARY_FILE_ITEM',
}

export type StartLoadingFilesActionType = {
  type: FilesActionTypes.START_FILES_LOADING
}

export type StartUploadingFilesActionType = {
  type: FilesActionTypes.START_FILES_UPLOAD
}

export type LoadingFilesErrorActionType = {
  type: FilesActionTypes.LOAD_FILES_ERROR
  payload: string
}

export type SaveTemporaryFilesActionType = {
  type: FilesActionTypes.SAVE_TEMPORARY_FILES
  payload: any
}

export type CreateFilesActionType = {
  type: FilesActionTypes.CREATE_FILES_SUCCESS
}

export type DeleteTemporaryFilesActionType = {
  type: FilesActionTypes.DELETE_TEMPORARY_FILES
}

export type DeleteTemporaryFileItemActionType = {
  type: FilesActionTypes.DELETE_TEMPORARY_FILE_ITEM
  payload: string
}

export type FilesActionType =
  | StartLoadingFilesActionType
  | LoadingFilesErrorActionType
  | SaveTemporaryFilesActionType
  | DeleteTemporaryFilesActionType
  | DeleteTemporaryFileItemActionType
  | CreateFilesActionType
  | StartUploadingFilesActionType
