import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { useTranslation } from 'react-i18next'
import { FC, memo, ReactNode, useCallback, useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Menu, Row, Typography } from 'antd'

import { EMPTY_STRING } from '../../../constants'
import { isArray } from '../../../api/shortcuts'
import { NotificationsBadge } from '../../notifications/history'
import { SiderHeader } from '../SiderHeader'
import { useTypedSelector } from '../../../hooks'
import { Color, Spacing } from '../../../styles'
import { getBaseLocation, isOwner } from '../../../functions'
import { getIsSiderCollapsed, getSiderMenuItems } from './siderMenuItems'
import { getNotifications, getUser, getWorkspaces } from '../../../store/selectors'
import { StyledMenuItem, StyledSider } from '../../common/styled'

const { Text } = Typography

const ARROW = 'arrow'
const DASHBOARD = 'dashboard'

type EventType = {
  key: string
}

export type PlannySiderPropsType = {
  commonActions: ReactNode
}

export const PlannySider: FC<PlannySiderPropsType> = memo(({ commonActions }) => {
  const [selectedItem, setSelectedItem] = useState([''])
  const [collapsed, setCollapsed] = useState(getIsSiderCollapsed())

  const { user } = useTypedSelector(getUser)
  const { currentWorkspace } = useTypedSelector(getWorkspaces)
  const { notifications } = useTypedSelector(getNotifications)

  const { t } = useTranslation()

  const { pathname } = useLocation()

  const currentLocation = getBaseLocation(pathname)

  const toggleSider = () => {
    setCollapsed(!collapsed)
    localStorage.setItem('isCollapsed', JSON.stringify(!collapsed))
  }

  const getNotificationsAmount = () =>
    notifications && isArray(notifications) ? notifications.filter((i) => !i.isRead)?.length : null

  const handleSelect = (e: EventType) => {
    if (e.key === ARROW) {
      setSelectedItem([currentLocation])
    } else {
      setSelectedItem([e.key])
    }
  }

  const getSiderColor = () => {
    const isUserOwner = isOwner(currentWorkspace?.ownerId, user)

    if (isUserOwner) return Color.lightBlue

    return Color.siderLightBlue
  }

  const getCurrentPath = useCallback(() => {
    if (currentLocation !== null) setSelectedItem([currentLocation])
    if (!currentLocation) setSelectedItem([DASHBOARD])
  }, [currentLocation])

  useEffect(() => getCurrentPath(), [getCurrentPath])

  return (
    <StyledSider trigger={null} collapsible collapsed={collapsed} $bcColor={getSiderColor()}>
      {commonActions}
      <Menu mode="inline" selectedKeys={selectedItem ?? selectedItem} onClick={handleSelect}>
        <SiderHeader collapsed={collapsed} />

        {user
          ? getSiderMenuItems(user.isDistributor).map(
              ({ key, icon, linkProps, marginBottom, isDisabled, isHidden }) => (
                <StyledMenuItem
                  key={key}
                  icon={icon}
                  space={marginBottom ? Spacing.md32 : EMPTY_STRING}
                  disabled={isDisabled}
                  $isDisabled={isDisabled}
                  $isHidden={isHidden}
                  style={collapsed ? { marginLeft: `-${Spacing.sm6}` } : {}}
                >
                  {isDisabled ? (
                    <Text>{t(`${linkProps.children}`)}</Text>
                  ) : (
                    <Link to={linkProps.to}>
                      <Row align="middle">
                        <Text>{t(`${linkProps.children}`)}</Text>

                        {linkProps.children === 'notifications' && getNotificationsAmount() ? (
                          <NotificationsBadge>{getNotificationsAmount()}</NotificationsBadge>
                        ) : null}
                      </Row>
                    </Link>
                  )}
                </StyledMenuItem>
              ),
            )
          : null}

        <StyledMenuItem
          key={ARROW}
          icon={collapsed ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          space={Spacing.md20}
          $isAbsolute
          style={collapsed ? { marginLeft: `-${Spacing.sm6}` } : {}}
          onClick={toggleSider}
        >
          <Text>{collapsed ? t(`uncollapse-menu`) : t(`collapse-menu`)}</Text>
        </StyledMenuItem>
      </Menu>
    </StyledSider>
  )
})
