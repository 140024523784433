import { RcFile } from 'antd/lib/upload'
import { t } from 'i18next'
import urlJoin from 'url-join'
import { AxiosError, AxiosInstance, AxiosResponse } from 'axios'

import { AppDispatch } from '../../hooks'
import { getUserServiceHistory } from './history'
import { handleLoadError } from './errors'
import { RootState } from '../reducers/rootReducer'
import { sendNotification } from '../../components/common/notification'
import {
  CACHE_KEYS,
  CURRENT_LOCATION,
  DATA_TYPE,
  HISTORY_SISTEM_NOTIFICATIONS,
  INIT,
  MESSAGE_TYPES,
  TAB_PATHS,
  USER_SERVICE_SISTEM_NOTIFICATIONS,
} from '../../constants'
import { createObject, editObject } from '../../api/shortcuts'
import { deleteTemporaryImages, deleteTemporaryTags } from './temporaryData'
import { getAuthorizationHeaders, getBaseLocation, getCurrentTab } from '../../functions'
import { getJsonHeader, startDataLoading } from './utils'
import {
  HISTORY_ENDPOINT,
  HISTORY_UPDATE_TIMEOUT,
  SERVICES_ENDPOINT,
  STATUSES_ENDPOINT,
  USER_SERVICE_ADMISSIONS_ENDPOINT,
  USER_SERVICE_HISTORY_ENDPOINT,
} from '../../env'
import { IUserServiceHistory, IUserServices, removeItemFromLocalStorage } from '../../api'
import { sortUserServiceAdmissions, sortUserServiceStatuses } from '../../api/converters'
import { TemporaryDataActionTypes, UserServicesActionTypes } from '../actionTypes'

export type GetServicesStateFunctionType = () => RootState

// User Services

// Load service item success

export const loadServiceItemSuccess = (data: IUserServices) => (dispatch: AppDispatch) =>
  dispatch({
    type: UserServicesActionTypes.LOAD_SERVICE_ITEM_SUCCESS,
    payload: data,
  })

// Delete temporary ids

export const resetTemporaryServiceIds = () => (dispatch: AppDispatch) =>
  dispatch({
    type: TemporaryDataActionTypes.SET_TEMPORARY_IDS,
    payload: {
      dataType: DATA_TYPE.USER_SERVICES,
      tempIds: undefined,
    },
  })

// Delete Service item from state

export const deleteServiceItemFromState = () => (dispatch: AppDispatch) =>
  dispatch({
    type: UserServicesActionTypes.DELETE_SERVICE_ITEM_FROM_STATE,
  })

export const setCreatedServiceId = (value: string | undefined) => (dispatch: AppDispatch) => {
  dispatch({
    type: UserServicesActionTypes.SET_CREATED_SERVICE_ID,
    payload: value,
  })
}

// Get Service Status  GET

export const getServiceStatuses =
  () => (dispatch: AppDispatch, getState: GetServicesStateFunctionType, api: AxiosInstance) => {
    dispatch({
      type: UserServicesActionTypes.START_SERVICES_LOADING,
    })

    api
      .get(urlJoin(SERVICES_ENDPOINT, STATUSES_ENDPOINT))
      .then((res: AxiosResponse) => {
        dispatch({
          type: UserServicesActionTypes.LOAD_SERVICE_STATUSES_SUCCESS,
          payload: sortUserServiceStatuses(res.data.data),
        })
      })
      .catch((err: AxiosError) =>
        dispatch(handleLoadError(err, UserServicesActionTypes.LOAD_SERVICES_ERROR)),
      )
  }

// Get Service Admissions  GET

export const getServiceAdmissions =
  () => (dispatch: AppDispatch, getState: GetServicesStateFunctionType, api: AxiosInstance) => {
    dispatch({
      type: UserServicesActionTypes.START_SERVICES_LOADING,
    })

    api
      .get(urlJoin(SERVICES_ENDPOINT, USER_SERVICE_ADMISSIONS_ENDPOINT))
      .then((res: AxiosResponse) => {
        dispatch({
          type: UserServicesActionTypes.LOAD_SERVICE_ADMISSIONS_SUCCESS,
          payload: sortUserServiceAdmissions(res.data.data),
        })
      })
      .catch((err: AxiosError) =>
        dispatch(handleLoadError(err, UserServicesActionTypes.LOAD_SERVICES_ERROR)),
      )
  }

// Get Services     GET

export const getAllServices =
  (init?: string) =>
  (dispatch: AppDispatch, getState: GetServicesStateFunctionType, api: AxiosInstance) => {
    dispatch(startDataLoading(UserServicesActionTypes.START_SERVICES_LOADING))

    if (init && init === INIT) {
      dispatch(deleteTemporaryImages())

      dispatch(deleteTemporaryTags())

      dispatch(deleteServiceItemFromState())

      dispatch(resetTemporaryServiceIds())
    }

    api
      .get(urlJoin(SERVICES_ENDPOINT), getAuthorizationHeaders(getState().auth.accessToken))
      .then((res: AxiosResponse) => {
        if (res.status === 200) {
          dispatch({
            type: UserServicesActionTypes.LOAD_SERVICES_SUCCESS,
            payload: res.data.data,
          })
        }
      })
      .catch((err: AxiosError) =>
        dispatch(handleLoadError(err, UserServicesActionTypes.LOAD_SERVICES_ERROR)),
      )
  }

// Get Services     GET

export const getServiceItem =
  (serviceId: string | undefined) =>
  (dispatch: AppDispatch, getState: GetServicesStateFunctionType, api: AxiosInstance) => {
    dispatch(startDataLoading(UserServicesActionTypes.START_SERVICES_LOADING))

    api
      .get(urlJoin(SERVICES_ENDPOINT, serviceId))
      .then((res: AxiosResponse) => {
        if (res.status === 200) {
          dispatch(loadServiceItemSuccess(res.data.data[0]))
        }
      })
      .catch((err: AxiosError) =>
        dispatch(handleLoadError(err, UserServicesActionTypes.LOAD_SERVICES_ERROR)),
      )
  }

// Create Service     POST

export const createServiceItem =
  (data: IUserServices, files?: RcFile[]) =>
  (dispatch: AppDispatch, getState: GetServicesStateFunctionType, api: AxiosInstance) => {
    dispatch(startDataLoading(UserServicesActionTypes.START_SERVICES_LOADING))

    api
      .post(urlJoin(SERVICES_ENDPOINT), createObject(data, files))
      .then((res: AxiosResponse) => {
        if (res.status === 201) {
          const createdId = res.headers.location.split('/')[2]

          dispatch(setCreatedServiceId(createdId))

          dispatch(resetTemporaryServiceIds())

          removeItemFromLocalStorage(CACHE_KEYS.CREATE_SERVICE_FORM)

          dispatch(loadServiceItemSuccess(res.data.data[0]))

          sendNotification(
            MESSAGE_TYPES.SUCCESS,
            t(USER_SERVICE_SISTEM_NOTIFICATIONS.CREATE_USER_SERVICE_SUCCESS),
          )
        }
      })
      .catch((err: AxiosError) =>
        dispatch(handleLoadError(err, UserServicesActionTypes.LOAD_SERVICES_ERROR)),
      )
  }

// Edit Service  Item   PUT

export const editServiceItem =
  (serviceId: string | undefined, data: IUserServices) =>
  (dispatch: AppDispatch, getState: GetServicesStateFunctionType, api: AxiosInstance) => {
    dispatch(startDataLoading(UserServicesActionTypes.START_SERVICES_LOADING))

    const currentLocation = getCurrentTab(window.location.pathname)
    const baseLocation = getBaseLocation(window.location.pathname)

    api
      .put(urlJoin(SERVICES_ENDPOINT, serviceId), editObject(data))
      .then((res: AxiosResponse) => {
        if (res.status === 200) {
          dispatch(resetTemporaryServiceIds())

          removeItemFromLocalStorage(`${CACHE_KEYS.EDIT_SERVICE_FORM}:${serviceId}`)

          dispatch(loadServiceItemSuccess(res.data.data[0]))

          dispatch(deleteTemporaryTags())

          if (currentLocation === TAB_PATHS.HISTORY) {
            dispatch(getUserServiceHistory(serviceId))
            dispatch(getServiceStatuses())
          }

          if (baseLocation === CURRENT_LOCATION.USER_SERVICES) dispatch(getAllServices())

          sendNotification(
            MESSAGE_TYPES.SUCCESS,
            t(USER_SERVICE_SISTEM_NOTIFICATIONS.EDIT_USER_SERVICE_SUCCESS),
          )
        }
      })
      .catch((err: AxiosError) =>
        dispatch(handleLoadError(err, UserServicesActionTypes.LOAD_SERVICES_ERROR)),
      )
      .finally(() => {
        if (currentLocation === TAB_PATHS.HISTORY) {
          if (HISTORY_UPDATE_TIMEOUT && Number(HISTORY_UPDATE_TIMEOUT) >= 0) {
            setTimeout(() => {
              dispatch(getUserServiceHistory(serviceId))
            }, Number(HISTORY_UPDATE_TIMEOUT))
          }
        }
      })
  }

// Delete Service Item   DELETE

export const deleteServiceItem =
  (serviceId: string | undefined) =>
  (dispatch: AppDispatch, getState: GetServicesStateFunctionType, api: AxiosInstance) => {
    dispatch(startDataLoading(UserServicesActionTypes.START_SERVICES_LOADING))

    api
      .delete(urlJoin(SERVICES_ENDPOINT, serviceId))
      .then((res: AxiosResponse) => {
        if (res.status === 204) {
          dispatch({
            type: UserServicesActionTypes.DELETE_SERVICE_ITEM_FROM_STATE,
            payload: Number(serviceId),
          })

          dispatch(resetTemporaryServiceIds())

          sendNotification(
            MESSAGE_TYPES.SUCCESS,
            t(USER_SERVICE_SISTEM_NOTIFICATIONS.DELETE_USER_SERVICE_SUCCESS),
          )

          dispatch(getAllServices(INIT))
        }
      })
      .catch((err: AxiosError) =>
        dispatch(handleLoadError(err, UserServicesActionTypes.LOAD_SERVICES_ERROR)),
      )
  }

// Edit Service History Item   PUT

export const editServiceHistoryItemItem =
  (serviceId: string, uuid: string, data: IUserServiceHistory) =>
  (dispatch: AppDispatch, getState: GetServicesStateFunctionType, api: AxiosInstance) => {
    dispatch(startDataLoading(UserServicesActionTypes.START_SERVICES_LOADING))

    api
      .put(urlJoin(HISTORY_ENDPOINT, USER_SERVICE_HISTORY_ENDPOINT, uuid), data, getJsonHeader())
      .then((res: AxiosResponse) => {
        if (res.status === 200) {
          dispatch({
            type: UserServicesActionTypes.EDIT_SERVICE_HISTORY_ITEM_SUCCESS,
          })

          dispatch(getUserServiceHistory(serviceId))

          sendNotification(
            MESSAGE_TYPES.SUCCESS,
            t(HISTORY_SISTEM_NOTIFICATIONS.UPDATE_HISTORY_DATA_SUCCESS),
          )
        }
      })
      .catch((err: AxiosError) =>
        dispatch(handleLoadError(err, UserServicesActionTypes.LOAD_SERVICES_ERROR)),
      )
  }

// Delete Service History Item   DELETE

export const deleteServiceHistoryItem =
  (serviceId: string | undefined, uuid: string) =>
  (dispatch: AppDispatch, getState: GetServicesStateFunctionType, api: AxiosInstance) => {
    dispatch(startDataLoading(UserServicesActionTypes.START_SERVICES_LOADING))

    api
      .delete(urlJoin(HISTORY_ENDPOINT, USER_SERVICE_HISTORY_ENDPOINT, uuid))
      .then((res: AxiosResponse) => {
        if (res.status === 204) {
          dispatch({
            type: UserServicesActionTypes.DELETE_SERVICE_HISTORY_ITEM_SUCCESS,
          })

          dispatch(getUserServiceHistory(serviceId))

          sendNotification(
            MESSAGE_TYPES.SUCCESS,
            t(HISTORY_SISTEM_NOTIFICATIONS.DELETE_HISTORY_ITEM_SUCCESS),
          )
        }
      })
      .catch((err: AxiosError) =>
        dispatch(handleLoadError(err, UserServicesActionTypes.LOAD_SERVICES_ERROR)),
      )
  }
