export enum USER_ROLES {
  GUEST = 'guest',
  REPORTER = 'reporter',
  ADMIN = 'admin',
  OWNER = 'owner',
  DISTRIBUTOR = 'distributor',
}

export enum WORKSPACE_KINDS {
  PERSONAL = 'personal',
  WORKSPACE = 'workspace',
}

export enum OPERATION_TYPES {
  LOSS = 'LOSS',
  PROFIT = 'PROFIT',
  JOIN_TASK = 'JOIN_TASK',
}

export enum HISTORY_RANGE_VALUES {
  YEAR = 'year',
  MONTH = 'month',
  ALL = 'all',
  EMPTY = '',
}

export enum ERROR_TYPES {
  INFO = 'INFO',
  ERROR = 'ERROR',
  WARNING = 'WARNING',
}

export enum TERM_TYPES {
  MONTH = '31D',
  THREE_MONTH = '93D',
  YEAR = '366D',
}
