import { NavigateFunction } from 'react-router-dom'
import { t } from 'i18next'

import { FilesLimitsType } from '../api/types'
import { sendLimitsErrorNotification } from '../components/common/notification'
import { EMPTY_STRING, USER_SISTEM_NOTIFICATIONS } from '../constants'
import { FilesStorageType, UserLimitsTariffPlanType, UserLimitsType } from '../api'

export type LimitsKeyType =
  | 'equipment'
  | 'materials'
  | 'places'
  | 'meters'
  | 'tasks'
  | 'userServices'
  | 'ppwGraphics'

const DEFAULT_OBJECT_LIMIT = 50
const DEFAULT_FILES_LIMIT = 5000
const DEFAULT_FILE_SIZE = 15

export const getObjectslimit = (limits: UserLimitsType, key: LimitsKeyType) => {
  if (limits) return limits[key]?.db.maxRecords

  return DEFAULT_OBJECT_LIMIT
}

export const getFileslimit = (limits: UserLimitsType) => {
  if (limits) return limits?.common.maxFilesCount

  return DEFAULT_FILES_LIMIT
}

export const getFilesTariffLimits = (limits: UserLimitsType, key: LimitsKeyType) => {
  if (limits)
    return {
      filesAmount: limits?.common.filesAmount,
      filesLimit: limits ? limits.common.maxFilesCount : DEFAULT_FILES_LIMIT,
      fileMaxSize: limits ? limits[key].object_storage.maxFileSize : DEFAULT_FILE_SIZE,
    }

  return null
}

export const isFilesLimitOver = (limits: UserLimitsType) => {
  if (limits) {
    if (limits.common.filesAmount >= getFileslimit(limits)) return true

    return false
  }

  return true
}

export const getFilesLimitError = (availableLimits: FilesLimitsType, tempImagesAmount: number) => {
  if (availableLimits) {
    const rest = availableLimits.filesLimit - availableLimits.filesAmount - tempImagesAmount
    if (rest >= 0) return false

    return true
  }

  return true
}

export const getAvailableFiles = (availableLimits: FilesLimitsType) => {
  if (availableLimits) {
    const availableFiles = availableLimits.filesLimit - availableLimits.filesAmount

    return availableFiles
  }

  return 0
}

export const getInviteUserLimit = (limits: UserLimitsType) =>
  limits?.multiuser?.employees?.maxRecords

export const isInviteUserLimitError = (limits: UserLimitsType, membersAmount: number) =>
  membersAmount >= getInviteUserLimit(limits)

export const checkObjectsLimit = (
  existingObjects: any[],
  maxLimit: number,
  redirectUrl: string,
  navigate: NavigateFunction,
) => {
  if (maxLimit === 0) navigate(redirectUrl)

  if (existingObjects && maxLimit > 0) {
    if (existingObjects.length < maxLimit) navigate(redirectUrl)

    if (existingObjects.length >= maxLimit)
      sendLimitsErrorNotification(t(USER_SISTEM_NOTIFICATIONS.USER_LIMIT_ERROR), maxLimit)
  }
}

export const handleLimitError = (
  existingObjects: any[],
  maxLimit: number,
  redirectUrl: string,
  navigate: NavigateFunction,
) => {
  if (maxLimit > 0 && existingObjects?.length >= maxLimit) {
    navigate(redirectUrl)
    sendLimitsErrorNotification(t(USER_SISTEM_NOTIFICATIONS.USER_LIMIT_ERROR), maxLimit)
  }
}

export const getFileSize = (bytes: number) => {
  let size = EMPTY_STRING

  if (bytes >= 1073741824) {
    size = `${(bytes / 1073741824).toFixed(2)} GB`
  } else if (bytes >= 1048576) {
    size = `${(bytes / 1048576).toFixed(2)} MB`
  } else if (bytes >= 1024) {
    size = `${(bytes / 1024).toFixed(2)} KB`
  } else if (bytes > 1) {
    size = `${bytes} bytes`
  } else if (bytes === 1) {
    size = `${bytes} + byte`
  } else {
    size = '0 byte'
  }
  return size
}

export const getFileSizeInMb = (bytes: number) => Math.round(bytes / 1048576)

/* eslint-disable */

export const convertUserLimits = (
  data: any,
  tariffPlan: UserLimitsTariffPlanType,
  storageInfo: FilesStorageType,
) => {
  if (data && tariffPlan) {
    const { services, common, multiuser } = data[tariffPlan]

    return {
      equipment: services.equipments,
      places: services.places,
      materials: services.materials,
      meters: services.meters,
      tasks: services.tasks,
      userServices: services.user_services,
      triggers: services.triggers,
      multiuser,
      common: common
        ? {
            ...common.object_storage,
            filesAmount: storageInfo.filesCount,
            usedSpace: storageInfo.usedSpace,
          }
        : undefined,
    }
  }

  return undefined
}

export const isTaskRulesEditingAvailable = (limits: UserLimitsType) => limits?.triggers?.enabled
