import { CompanyActionType, CompanyActionTypes, CompanyStateType } from '../actionTypes'

const initialState: CompanyStateType = {
  isCompanyLoading: false,
  currentCompany: undefined,
  companies: undefined,
  companyImageInfo: undefined,
  companyDeleteImageUrl: null,
  loadCompanyError: null,
}

export const companies = (state = initialState, action: CompanyActionType): CompanyStateType => {
  if (action) {
    switch (action.type) {
      case CompanyActionTypes.START_COMPANIES_LOADING:
        return {
          ...state,
          isCompanyLoading: true,
        }

      case CompanyActionTypes.GET_CURRENT_COMPANY_SUCCESS:
        return {
          ...state,
          isCompanyLoading: false,
          currentCompany: action.payload,
          loadCompanyError: null,
        }

      case CompanyActionTypes.GET_COMPANIES_SUCCESS:
        return {
          ...state,
          isCompanyLoading: false,
          companies: action.payload,
          loadCompanyError: null,
        }

      case CompanyActionTypes.EDIT_COMPANY_ITEM_SUCCESS:
        return {
          ...state,
          isCompanyLoading: false,
          currentCompany: action.payload,
          loadCompanyError: null,
        }

      case CompanyActionTypes.GET_COMPANY_IMAGE_INFO_SUCCESS:
        return {
          ...state,
          isCompanyLoading: false,
          companyImageInfo: action.payload,
          loadCompanyError: null,
        }

      case CompanyActionTypes.GET_COMPANY_DELETE_IMAGE_INFO_SUCCESS:
        return {
          ...state,
          isCompanyLoading: false,
          companyDeleteImageUrl: action.payload,
          loadCompanyError: null,
        }
      case CompanyActionTypes.SET_LOAD_COMPANIES_ERROR:
        return {
          ...state,
          isCompanyLoading: false,
          loadCompanyError: action.payload,
        }

      default:
        return state
    }
  } else {
    return state
  }
}
