import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest'
import { useTranslation } from 'react-i18next'
import { FC, memo, ReactNode, useState } from 'react'

import { SelectTableModal } from '../tables'
import { sendNotification } from '../common/notification'
import { setSearchValue } from '../../store/actions'
import { CardTitle, PlannyButton, StyledSmallPanelCard } from '../common'
import { Color, Spacing } from '../../styles'
import {
  COMMON_SISTEM_NOTIFICATIONS,
  DATA_TYPE,
  EMPTY_STRING,
  MESSAGE_TYPES,
} from '../../constants'
import { getEquipment, getTemporaryData } from '../../store/selectors'
import { useTypedDispatch, useTypedSelector } from '../../hooks'

type EquipmentPanelPropsType = {
  title?: string
  modalPrompt: string
  buttonText: string
  table: ReactNode
  cardContent: ReactNode
  isSubmitButtonDisabled?: boolean
}

export const EquipmentPanel: FC<EquipmentPanelPropsType> = memo(
  ({ buttonText, table, cardContent, modalPrompt, title, isSubmitButtonDisabled }) => {
    const [isModalVisible, setIsModalVisible] = useState(false)

    const { tempIds } = useTypedSelector(getTemporaryData)
    const { equipment } = useTypedSelector(getEquipment)

    const dispatch = useTypedDispatch()

    const { t } = useTranslation()

    const showModal = () => setIsModalVisible(true)

    const resetSearchValue = () => {
      dispatch(setSearchValue(DATA_TYPE.EQUIPMENT, EMPTY_STRING))
    }

    const handleCancel = () => {
      setIsModalVisible(false)
      resetSearchValue()
    }

    const handleSave = () => {
      setIsModalVisible(false)
      resetSearchValue()

      if (tempIds.equipment !== undefined)
        sendNotification(
          MESSAGE_TYPES.SUCCESS,
          t(COMMON_SISTEM_NOTIFICATIONS.SAVE_TEMPORARY_DATA_SUCCESS),
        )
    }

    return (
      <>
        <SelectTableModal
          visible={isModalVisible}
          title={title || t('equipment')}
          subTitle={modalPrompt}
          dataType={DATA_TYPE.EQUIPMENT}
          onCancel={handleCancel}
          handleSave={handleSave}
          icon={<SettingsSuggestIcon />}
          table={table}
          isSubmitButtonDisabled={isSubmitButtonDisabled}
        />
        <StyledSmallPanelCard
          title={
            <CardTitle icon={<SettingsSuggestIcon />} $iconColor={Color.gray} $mbIcon={Spacing.sm6}>
              {title || t('equipment')}
            </CardTitle>
          }
          actions={[
            <PlannyButton
              type="primary"
              ghost
              onClick={showModal}
              $marginBottom={Spacing.sm8}
              disabled={!equipment || (equipment && !equipment.length)}
            >
              {buttonText}
            </PlannyButton>,
          ]}
          bordered={false}
        >
          {cardContent || null}
        </StyledSmallPanelCard>
      </>
    )
  },
)
