import { UserActionType, UserActionTypes, UserStateType } from '../actionTypes'

const initialState: UserStateType = {
  isUserLoading: false,
  user: null,
  limits: undefined,
  loadUserError: null,
}

export const user = (state = initialState, action: UserActionType): UserStateType => {
  if (action) {
    switch (action.type) {
      case UserActionTypes.START_USER_LOADING:
        return {
          ...state,
          isUserLoading: true,
        }

      case UserActionTypes.GET_USER_SUCCESS:
        return {
          ...state,
          user: action.payload,
          isUserLoading: false,
          loadUserError: null,
        }

      case UserActionTypes.GET_USER_LIMITS_SUCCESS:
        return {
          ...state,
          limits: action.payload,
          isUserLoading: false,
        }

      case UserActionTypes.SET_LOAD_USER_ERROR:
        return {
          ...state,
          user: null,
          loadUserError: action.payload,
        }

      default:
        return state
    }
  } else {
    return state
  }
}
