import { AxiosError } from 'axios'
import { IPlaces } from '../../api'

export type PlacesStateType = {
  places: IPlaces[]
  placeItem: IPlaces | undefined
  createdId: string | undefined
  tempPlaceId: number | undefined | null
  isPlacesLoading: boolean
  placesError: null | AxiosError
}

export enum PlacesActionTypes {
  START_PLACES_LOADING = 'START_PLACES_LOADING',
  LOAD_PLACES_SUCCESS = 'LOAD_PLACES_SUCCESS',
  CREATE_PLACE_SUCCESS = 'CREATE_PLACE_SUCCESS',
  LOAD_PLACE_ITEM_SUCCESS = 'LOAD_PLACE_ITEM_SUCCESS',
  SAVE_TEMPORARY_PLACE_ID = 'SAVE_TEMPORARY_PLACE_ID',
  DELETE_TEMPORARY_PLACE_ID = 'DELETE_TEMPORARY_PLACE_ID',
  DELETE_PLACE_ITEM_SUCCESS = 'DELETE_PLACE_ITEM_SUCCESS',
  DELETE_PLACE_ITEM_FROM_STATE = 'DELETE_PLACE_ITEM_FROM_STATE',
  LOAD_PLACES_ERROR = 'LOAD_PLACES_ERROR',
  SET_CREATED_PLACE_ID = 'SET_CREATED_PLACE_ID',
}

export type StartLoadingPlacesActionType = {
  type: PlacesActionTypes.START_PLACES_LOADING
}

export type LoadPlacesSuccessActionType = {
  type: PlacesActionTypes.LOAD_PLACES_SUCCESS
  payload: IPlaces[]
}

export type LoadPlaceItemSuccessActionType = {
  type: PlacesActionTypes.LOAD_PLACE_ITEM_SUCCESS
  payload: IPlaces
}

export type CreatePlaceSuccessActionType = {
  type: PlacesActionTypes.CREATE_PLACE_SUCCESS
}

export type SavePlaceParentIdActionType = {
  type: PlacesActionTypes.SAVE_TEMPORARY_PLACE_ID
  payload: number | null
}

export type DeletePlaceParentIdActionType = {
  type: PlacesActionTypes.DELETE_TEMPORARY_PLACE_ID
  payload: number
}

export type DeletePlaceItemFromStateActionType = {
  type: PlacesActionTypes.DELETE_PLACE_ITEM_FROM_STATE
}

export type DeletePlaceItemActionType = {
  type: PlacesActionTypes.DELETE_PLACE_ITEM_SUCCESS
  payload: number
}

export type LoadingErrorPlacesActionType = {
  type: PlacesActionTypes.LOAD_PLACES_ERROR
  payload: AxiosError
}

export type SetCreatedPlaceIdActionType = {
  type: PlacesActionTypes.SET_CREATED_PLACE_ID
  payload: string | undefined
}

export type PlacesActionType =
  | StartLoadingPlacesActionType
  | LoadingErrorPlacesActionType
  | LoadPlacesSuccessActionType
  | CreatePlaceSuccessActionType
  | LoadPlaceItemSuccessActionType
  | SavePlaceParentIdActionType
  | DeletePlaceParentIdActionType
  | DeletePlaceItemFromStateActionType
  | SetCreatedPlaceIdActionType
  | DeletePlaceItemActionType
