import { useTranslation } from 'react-i18next'
import { FC, useEffect, useState } from 'react'
import { Row, Table } from 'antd'

import { EmptyTable } from './commonComponents/EmptyTable'
import { ExpandedIcon } from './commonComponents/ExpandedIcon'
import { getItemsSelectTableColumns } from './columns/itemSelectTableColumns'
import { getRowClassName } from './helpers'
import { MetersColumnsDataType } from '../../api'
import { prepareSearchValue } from './helpers/sorter'
import { SearchForm } from '../forms'
import { setTemporaryDataIds } from '../../store/actions'
import { StyledTableDivider } from '../common'
import { TableWrapper } from './commonComponents/styled'
import { Color, Spacing } from '../../styles'
import { CREATE, DATA_TYPE } from '../../constants'
import { getFilters, getTemporaryData } from '../../store/selectors'
import { useTypedDispatch, useTypedSelector } from '../../hooks'

export type MetersSelectTablePropsType = {
  data?: MetersColumnsDataType[]
  selectedIds?: number[]
  isMultipleSelection?: boolean
}

export const MeterSelectTable: FC<MetersSelectTablePropsType> = ({
  data,
  selectedIds,
  isMultipleSelection,
}) => {
  const [selectedKeys, setSelectedKeys] = useState(selectedIds || [])

  const { searchValue } = useTypedSelector(getFilters)
  const { tempIds } = useTypedSelector(getTemporaryData)

  const dispatch = useTypedDispatch()

  const { t } = useTranslation()

  const handleSelectItem = (sKey: number) => {
    if (!isMultipleSelection) {
      setSelectedKeys([sKey])

      dispatch(setTemporaryDataIds(DATA_TYPE.METERS, [sKey]))
    }

    if (isMultipleSelection) {
      if (selectedIds.includes(sKey)) return

      if (!selectedKeys.includes(sKey)) {
        setSelectedKeys([...selectedKeys, sKey])
        dispatch(setTemporaryDataIds(DATA_TYPE.METERS, [...selectedKeys, sKey]))
      }

      if (selectedKeys.includes(sKey)) {
        setSelectedKeys(selectedIds.filter((i) => i !== sKey))
        dispatch(
          setTemporaryDataIds(
            DATA_TYPE.METERS,
            selectedIds.filter((i) => i !== sKey),
          ),
        )
      }
    }
  }

  const handleSearchRow = (key: number) =>
    getRowClassName(data, key, prepareSearchValue(searchValue.meters))

  useEffect(() => {
    if (tempIds.meters) setSelectedKeys(tempIds.meters)

    if (selectedIds && tempIds.meters === undefined) setSelectedKeys(selectedIds)

    return () => setSelectedKeys([])
  }, [tempIds.meters, selectedIds])

  return (
    <TableWrapper>
      <Row align="middle">
        <SearchForm $isModal dataType={DATA_TYPE.METERS} />
        <StyledTableDivider orientation="center" $mt={Spacing.sm8} $mb={Spacing.sm12} />
      </Row>

      <Table
        columns={getItemsSelectTableColumns(selectedKeys as number[])}
        dataSource={data || []}
        bordered={false}
        pagination={false}
        scroll={data.length > 6 ? { y: '350px' } : {}}
        showHeader={false}
        rowClassName={(record) => handleSearchRow(record.key)}
        onRow={(record) => ({
          onClick: () => handleSelectItem(record.key),
        })}
        locale={{
          emptyText: (
            <EmptyTable
              prompt={t('no-items')}
              hasButton
              navigationPath={`/${DATA_TYPE.METERS}/${CREATE}`}
            />
          ),
        }}
        expandable={{
          defaultExpandAllRows: true,
          indentSize: 20,
          expandIcon: ({ expanded, onExpand, record }) => (
            <ExpandedIcon
              expanded={expanded}
              record={record}
              onExpand={onExpand}
              color={Color.gray}
            />
          ),
        }}
        style={{ minWidth: '100%', margin: '0' }}
      />
    </TableWrapper>
  )
}
