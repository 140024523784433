import DashboardCustomizeIcon from '@mui/icons-material/DashboardCustomize'
import { FC } from 'react'
import { PlusOutlined } from '@ant-design/icons'
import styled from 'styled-components'
import { Tag } from 'antd'

import { Color } from '../../../styles'

export const StyledAddTag = styled(Tag)`
  margin-bottom: 12px;
  padding: 4px 12px;
  border: 1px dashed ${Color.gray};
  border-radius: 8px;
  cursor: pointer;
`

export const StyledDashboardCustomizeIcon = styled(DashboardCustomizeIcon)`
  margin-bottom: -2px !important;
  width: 12px !important;
  height: 12px !important;
`

export type AddTagButtonPropsType = {
  color?: string
  onClick?: () => void
  $isChooseButton?: boolean
}

export const AddTagButton: FC<AddTagButtonPropsType> = ({
  children,
  onClick,
  color,
  $isChooseButton = false,
}) => (
  <StyledAddTag onClick={onClick} color={color}>
    {$isChooseButton ? <StyledDashboardCustomizeIcon /> : <PlusOutlined />} {children}
  </StyledAddTag>
)
