import { Button } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import styled from 'styled-components'
import { FC, ReactNode } from 'react'

import { BoxShadow, Color } from '../../../styles'

const StyledButton = styled(Button)`
  color: ${Color.white};
  height: 40px;
  box-shadow: ${BoxShadow.addButton};
`
type AddButtonPropsType = {
  children: ReactNode
  onClick: () => void
}

export const AddButton: FC<AddButtonPropsType> = ({ children, onClick }) => (
  <StyledButton block type="primary" icon={<PlusOutlined />} onClick={onClick}>
    {children}
  </StyledButton>
)
