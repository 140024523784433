import { Col } from 'antd'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { FC, memo } from 'react'

import { EMPTY_STRING } from '../../constants'
import { Color, FontSize } from '../../styles'

type StyledLinkPropsType = {
  $isActive: boolean
}

export const StyledLink = styled(Link)<StyledLinkPropsType>`
  color: ${(props) => (props.$isActive ? EMPTY_STRING : Color.gray)};
  font-size: ${FontSize.sm};
`

export const StyledLine = styled.div`
  position: absolute;
  top: 78px;
  left: 0;
  width: 100%;
  border-bottom: 3px solid ${Color.blue};
`

export type TabPropsType = {
  title: string
  itemsAmount?: number
  $isActive: boolean
  $isVisible: boolean
  link: string
}

export const Tab: FC<TabPropsType> = memo(({ itemsAmount, $isVisible, $isActive, link, title }) => {
  return $isVisible ? (
    <Col>
      <StyledLink to={link} $isActive={$isActive}>
        {title} {itemsAmount ? `(${itemsAmount})` : EMPTY_STRING}
      </StyledLink>
      {$isActive && <StyledLine />}
    </Col>
  ) : null
})
