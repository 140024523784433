import { FC } from 'react'
import { StyledStarBorderIcon, StyledStarFavoriteIcon } from './styled'

type FavoriteIconPropsType = {
  id: string
  $isFavorite: boolean | null
  onUpdate: (id: string, isFavorite: boolean | null) => void
  $isTableCell?: boolean
}

export const FavoriteIcon: FC<FavoriteIconPropsType> = ({
  id,
  $isFavorite,
  onUpdate,
  $isTableCell,
}) =>
  $isFavorite ? (
    <StyledStarFavoriteIcon
      onClick={() => onUpdate(id, false)}
      $isTableCell={$isTableCell}
      className={!$isTableCell ? 'extraIcon' : undefined}
    />
  ) : (
    <StyledStarBorderIcon
      onClick={() => onUpdate(id, true)}
      $isTableCell={$isTableCell}
      className={!$isTableCell ? 'extraIcon' : undefined}
    />
  )
