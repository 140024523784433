import styled from 'styled-components'
import { FC, ReactElement, ReactNode } from 'react'
import { Row, Typography } from 'antd'

import { Color, Spacing } from '../../styles'

const { Title } = Typography

export type StyledTitleWrapperPropsType = {
  $mbIcon?: string
  $mtIcon?: string
  $mbTitle?: string
  $iconColor?: string
}

export const StyledTitleWrapper = styled(Row)<StyledTitleWrapperPropsType>`
  margin-bottom: ${(props) => (props.$mbTitle ? props.$mbTitle : {})};

  svg {
    margin-right: ${Spacing.sm12};
    margin-bottom: ${(props) => (props.$mbIcon ? props.$mbIcon : {})};
    margin-top: ${(props) => (props.$mtIcon ? props.$mtIcon : {})};
    color: ${(props) => (props.$iconColor ? props.$iconColor : Color.gray)};
  }

  h4 {
    margin-bottom: 0;
  }
`

export type ModalTitlePropsType = {
  icon?: ReactElement | ReactNode
  children: ReactNode
  $mbIcon?: string
  $mbTitle?: string
  $mtIcon?: string
  $iconColor?: string
}

export const ModalTitle: FC<ModalTitlePropsType> = ({
  icon,
  children,
  $mbIcon,
  $mbTitle,
  $mtIcon,
  $iconColor,
}) => {
  return (
    <StyledTitleWrapper
      align="middle"
      $mbIcon={$mbIcon}
      $mbTitle={$mbTitle}
      $mtIcon={$mtIcon}
      $iconColor={$iconColor}
    >
      {icon && icon}
      <Title level={4}>{children}</Title>
    </StyledTitleWrapper>
  )
}
