/* eslint-disable */

import { CHANNELS, SW_ACTIONS, SW_REGISTRATION_ERROR } from './constants'

type Config = {
  onSuccess?: (registration: ServiceWorkerRegistration) => void
  onUpdate?: (registration: ServiceWorkerRegistration) => void
}

const serviceWorkerStatusChannel = new BroadcastChannel(CHANNELS.SW_STATUS_CHANNEL)

const SERVICE_WORKER = 'serviceWorker'

function registerValidSW(swUrl: string, config?: Config) {
  navigator.serviceWorker
    .register(swUrl)
    .then((registration) => {
      serviceWorkerStatusChannel.postMessage(SW_ACTIONS.SW_REGISTER_SUCCESS)

      if (navigator.serviceWorker.controller === null) {
        // we get here after a ctrl+f5 OR if there was no previous service worker.
        navigator.serviceWorker.ready.then(() => {
          registration.active.postMessage('claimMe')
        })
      }

      registration.onupdatefound = () => {
        const installingWorker = registration.installing

        if (installingWorker == null) return

        installingWorker.onstatechange = () => {
          if (installingWorker.state === 'installed') {
            if (navigator.serviceWorker.controller) {
              if (config && config.onUpdate) {
                config.onUpdate(registration)
              }
            } else {
              if (config && config.onSuccess) {
                config.onSuccess(registration)
              }
            }
          }
        }
      }
    })
    .catch((error) => {
      console.error('Error during service worker registration:', error)
      serviceWorkerStatusChannel.postMessage(SW_ACTIONS.SW_REGISTER_ERROR)
    })
}

export function register(config?: Config) {
  if (SERVICE_WORKER in navigator) {
    const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href)

    if (publicUrl.origin !== window.location.origin) return

    window.addEventListener('load', () => {
      const swUrl = '/service-worker.js'
      registerValidSW(swUrl, config)
    })
  }
}

export function unregister() {
  if (SERVICE_WORKER in navigator) {
    navigator.serviceWorker.ready
      .then((registration) => {
        registration.unregister()
      })
      .catch((error) => {
        console.error(error.message)
      })
  }
}
