import { CheckOutlined } from '@ant-design/icons'
import { FC } from 'react'

import { Color } from '../../../styles'
import { PlannyButton } from '../styled'

export type SaveGreenButtonPropType = {
  disabled?: boolean
  onClick?: () => void
}

export const SaveGreenButton: FC<SaveGreenButtonPropType> = ({ children, disabled, onClick }) => {
  return (
    <PlannyButton
      htmlType={onClick !== undefined ? 'button' : 'submit'}
      type="primary"
      $color={Color.darkGreen}
      $disabled={disabled}
      icon={<CheckOutlined />}
      style={{ color: '#fff' }}
      disabled={disabled}
      onClick={onClick !== undefined ? onClick : undefined}
    >
      {children}
    </PlannyButton>
  )
}
