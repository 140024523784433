import { FC } from 'react'
import { Link } from 'react-router-dom'
import { Col, Row } from 'antd'

import { CURRENT_LOCATION } from '../../../constants'
import { IPlaces } from '../../../api'
import { StyledCardText } from '../../common'
import { getPlaces, getTemporaryData } from '../../../store/selectors'
import { resetTemporaryEquipmentIds, resetTemporaryPlaceIds } from '../../../store/actions'
import { useTypedDispatch, useTypedSelector } from '../../../hooks'

export type PlacesListPropsType = {
  prompt: string
  selectedPlaceItem?: IPlaces[]
}

export const PlacesList: FC<PlacesListPropsType> = ({ prompt, selectedPlaceItem }) => {
  const { places } = useTypedSelector(getPlaces)

  const { tempIds } = useTypedSelector(getTemporaryData)

  const dispatch = useTypedDispatch()

  const getLocation = () => {
    if (tempIds.places && tempIds.places.length)
      return places.filter((item) => item.id === tempIds.places[0]) || []

    if (selectedPlaceItem) return selectedPlaceItem

    return []
  }

  const handleClick = () => {
    dispatch(resetTemporaryEquipmentIds())
    dispatch(resetTemporaryPlaceIds())
  }

  return getLocation().length ? (
    <Row justify="center">
      {getLocation().map(({ id, name }: IPlaces) => (
        <Col xs={24} key={id}>
          <StyledCardText>
            <Link key={id} to={`/${CURRENT_LOCATION.LOCATIONS}/${id}`} onClick={handleClick}>
              {name}
            </Link>
          </StyledCardText>
        </Col>
      ))}
    </Row>
  ) : (
    <StyledCardText>{prompt}</StyledCardText>
  )
}
