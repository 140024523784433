import { Provider } from 'react-redux'
import ReactDOM from 'react-dom'

import * as serviceWorkerRegistration from './serviceWorkerRegistration'

import { AuthProvider } from './components/auth'
import { configureStore } from './store'
import { injectStore } from './api'
import { Root } from './components/root'

const store = configureStore()

injectStore(store)

const ROOT = 'root'

serviceWorkerRegistration.register()

ReactDOM.render(
  <Provider store={store}>
    <AuthProvider>
      <Root />
    </AuthProvider>
  </Provider>,
  document.getElementById(ROOT),
)
