import { Col } from 'antd'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { FC, ReactNode } from 'react'

import { DataTypes } from '../../api'
import { ModalTitle } from '../common'
import { setTemporaryDataIds } from '../../store/actions'
import { useTypedDispatch } from '../../hooks'
import { BUTTON_TYPE, DATA_TYPE } from '../../constants'
import { CancelRedOutlinedButton, SaveGreenButton } from '../common/buttons'
import { StyledCardText, StyledModal } from '../common/styled'

const Space = styled(Col)`
  width: 126px;
  height: 32px;
`

export type SelectTableModalPropsType = {
  visible: boolean
  title: string
  dataType: DataTypes
  subTitle: string
  icon: ReactNode
  extraButton?: ReactNode
  submitBtnText?: string
  table: ReactNode
  isSubmitButtonDisabled: boolean
  onCancel: () => void
  handleSave: () => void
}

export const SelectTableModal: FC<SelectTableModalPropsType> = ({
  title,
  subTitle,
  visible,
  dataType,
  extraButton,
  submitBtnText,
  handleSave,
  onCancel,
  table,
  icon,
  isSubmitButtonDisabled = false,
}) => {
  const dispatch = useTypedDispatch()

  const { t } = useTranslation()

  const handleCancel = () => {
    dispatch(setTemporaryDataIds(dataType, undefined))
    dispatch(setTemporaryDataIds(DATA_TYPE.PLACES, undefined))

    onCancel()
  }

  const getFooterActions = () => {
    if (extraButton) {
      return [
        extraButton,
        <Space key="" />,
        <SaveGreenButton key="submit" onClick={handleSave} disabled={isSubmitButtonDisabled}>
          {submitBtnText || t('ok-button')}
        </SaveGreenButton>,
        <CancelRedOutlinedButton key="back" onClick={handleCancel}>
          {t('cancel-button')}
        </CancelRedOutlinedButton>,
      ]
    }

    return [
      <SaveGreenButton key="submit" onClick={handleSave} disabled={isSubmitButtonDisabled}>
        {submitBtnText || t('ok-button')}
      </SaveGreenButton>,

      <CancelRedOutlinedButton key="back" type={BUTTON_TYPE.BUTTON} onClick={handleCancel}>
        {t('cancel-button')}
      </CancelRedOutlinedButton>,
    ]
  }

  return (
    <StyledModal
      title={
        <>
          <ModalTitle $mbTitle="16px" $mbIcon="4px" icon={icon}>
            {title}
          </ModalTitle>

          <StyledCardText>{subTitle}</StyledCardText>
        </>
      }
      footer={getFooterActions()}
      visible={visible}
      onCancel={onCancel}
      $mb="0"
    >
      {table || null}
    </StyledModal>
  )
}
