export enum EQUIPMENT_ERROR_TYPES {
  EQUIPMENT = 'equipment',
  EQUIPMENT_ITEM = 'equipmentItem',
  STATUSES = 'equipmentStatuses',
  HISTORY = 'equipmentHistory',
}

export enum TASKS_ERROR_TYPES {
  TASKS = 'tasks',
  TASK_ITEM = 'taskItem',
  STATUSES = 'taskStatuses',
  HISTORY = 'taskHistory',
  PRIORITIES = 'taskPriorities',
  RULES = 'rules',
}
