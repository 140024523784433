import { Row } from 'antd'
import { useTranslation } from 'react-i18next'
import { FC, useCallback, useEffect, useState } from 'react'

import { getNotifications } from '../../../store/selectors'
import { NotificationsHistoryTableCard } from './NotificationsHistoryTableCard'
import { RangeDateCard } from '../../cards'
import { getAllNotifications, markAllNotificationsAsRead } from '../../../store/actions'
import { HistoryBlockContainer, PlannyButton, ZeroPaddingCol } from '../../common'
import { useTypedDispatch, useTypedSelector } from '../../../hooks'

export const NotificationsHistoryTableBlock: FC = () => {
  const [startDateValue, setStartDateValue] = useState('')
  const [endDateValue, setEndDateValue] = useState('')
  const [isAllViewed, setIsAllViewed] = useState(false)

  const { notifications, loadNotificationsError } = useTypedSelector(getNotifications)

  const { t } = useTranslation()

  const dispatch = useTypedDispatch()

  const getLastNotificationsId = useCallback(() => {
    const max = (arr: number[]) => arr.reduce((x, y) => Math.max(x, y))

    return max(notifications.map((i) => i.id))
  }, [notifications])

  const handleOnClick = () => {
    setIsAllViewed(true)

    setTimeout(() => {
      setIsAllViewed(false)
    }, 200)
  }

  const handleChangeRange = useCallback(
    (startDate: string, endDate: string) => {
      setStartDateValue(startDate)
      setEndDateValue(endDate)
      dispatch(getAllNotifications(startDate, endDate))
    },
    [dispatch],
  )

  useEffect(() => {
    if (!notifications && !loadNotificationsError)
      dispatch(getAllNotifications(startDateValue, endDateValue))
  }, [dispatch, notifications, startDateValue, endDateValue, loadNotificationsError])

  useEffect(() => {
    if (isAllViewed && notifications && !loadNotificationsError)
      dispatch(markAllNotificationsAsRead(getLastNotificationsId()))
  }, [dispatch, isAllViewed, notifications, loadNotificationsError, getLastNotificationsId])

  return (
    <HistoryBlockContainer>
      <Row gutter={[20, 20]}>
        <ZeroPaddingCol xs={24}>
          <RangeDateCard
            onRangeChange={handleChangeRange}
            isHistoryEmpty={notifications && !notifications.length}
            actionButton={
              <PlannyButton
                type="primary"
                ghost
                onClick={handleOnClick}
                disabled={!notifications?.length}
              >
                {t('allIsViewed')}
              </PlannyButton>
            }
          />
        </ZeroPaddingCol>

        <ZeroPaddingCol xs={24}>
          <NotificationsHistoryTableCard isAllViewed={isAllViewed} />
        </ZeroPaddingCol>
      </Row>
    </HistoryBlockContainer>
  )
}
