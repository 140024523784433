import { FC } from 'react'
import { Link } from 'react-router-dom'
import { Image, Popover } from 'antd'

import { Spacing } from '../../styles'
import { StyledPhotoCameraOutlinedIcon } from './styled'

type TablePreviewIconPropsType = {
  token?: string
  link?: string
  imageSource?: string
  $isSmall?: boolean
  $isHeaderIcon?: boolean
  $color?: string
}

export const TablePreviewIcon: FC<TablePreviewIconPropsType> = ({
  token,
  link,
  imageSource,
  $isSmall,
  $isHeaderIcon,
  $color,
}) => {
  return $isHeaderIcon ? (
    <StyledPhotoCameraOutlinedIcon
      style={
        $isSmall
          ? {
              width: Spacing.md20,
              height: Spacing.md20,
            }
          : undefined
      }
      $color={$color}
      className="table-header-icon"
    />
  ) : (
    <Popover
      trigger="hover"
      placement="topRight"
      content={token ? <Image src={imageSource} width="250px" /> : <div>Loading...</div>}
    >
      <Link to={link}>
        <StyledPhotoCameraOutlinedIcon
          $cursor
          style={
            $isSmall
              ? {
                  width: Spacing.md20,
                  height: Spacing.md20,
                }
              : undefined
          }
        />
      </Link>
    </Popover>
  )
}
