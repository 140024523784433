import { AxiosError } from 'axios'
import { LoginBagType, StatusType } from '../../api'

export type AuthStateType = {
  isAuthLoading: boolean
  isRefreshStarts: boolean
  accessToken: string | null
  status: number | null
  authError: AxiosError | null
  telegramBotError: AxiosError | null
  isTgBotConnected: boolean
}

export enum AuthActionTypes {
  START_AUTH_LOADING = 'START_AUTH_LOADING',
  START_REFRESH = 'START_REFRESH',
  FINISH_REFRESH = 'FINISH_REFRESH',
  REFRESH_SUCCESS = 'REFRESH_SUCCESS',
  REGISTER_SUCCESS = 'REGISTER_SUCCESS',
  SEND_EMAIL_SUCCESS = 'SEND_EMAIL_SUCCESS',
  RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS',
  LOGIN_SUCCESS = 'LOGIN_SUCCESS',
  LOGOUT = 'LOGOUT',
  SET_STATUS = 'SET_STATUS',
  SET_AUTH_ERROR = 'SET_AUTH_ERROR',
  SET_TELEGRAM_BOT_ERROR = 'SET_TELEGRAM_BOT_ERROR',
  SET_IS_TELEGRAM_BOT_CONNECTED = 'SET_IS_TELEGRAM_BOT_CONNECTED',
}

export type LoginSuccessActionType = {
  type: AuthActionTypes.LOGIN_SUCCESS
  payload: LoginBagType
}

export type RefreshSuccessActionType = {
  type: AuthActionTypes.REFRESH_SUCCESS
  payload: LoginBagType
}

export type RegisterSuccessActionType = {
  type: AuthActionTypes.REGISTER_SUCCESS
  payload: StatusType
}

export type ResetPasswordSuccessActionType = {
  type: AuthActionTypes.RESET_PASSWORD_SUCCESS
  payload: StatusType
}

export type SendEmailToResetPasswordSuccessActionType = {
  type: AuthActionTypes.SEND_EMAIL_SUCCESS
  payload: StatusType
}

export type StartAuthLoadingActionType = {
  type: AuthActionTypes.START_AUTH_LOADING
}

export type StartRefreshActionType = {
  type: AuthActionTypes.START_REFRESH
}

export type FinishRefreshActionType = {
  type: AuthActionTypes.FINISH_REFRESH
}

export type SetStatusActionType = {
  type: AuthActionTypes.SET_STATUS
  payload: number | null
}

export type LogoutActionType = {
  type: AuthActionTypes.LOGOUT
}

export type SetAuthErrorActionType = {
  type: AuthActionTypes.SET_AUTH_ERROR
  payload: AxiosError | null
}

export type SetTelegramBotErrorActionType = {
  type: AuthActionTypes.SET_TELEGRAM_BOT_ERROR
  payload: AxiosError | null
}

export type SetIsTelegramBotConnectedActionType = {
  type: AuthActionTypes.SET_IS_TELEGRAM_BOT_CONNECTED
  payload: boolean
}

export type AuthActionType =
  | LoginSuccessActionType
  | RegisterSuccessActionType
  | StartAuthLoadingActionType
  | SetStatusActionType
  | LogoutActionType
  | SendEmailToResetPasswordSuccessActionType
  | ResetPasswordSuccessActionType
  | SetAuthErrorActionType
  | StartRefreshActionType
  | FinishRefreshActionType
  | RefreshSuccessActionType
  | SetTelegramBotErrorActionType
  | SetIsTelegramBotConnectedActionType
