import { ClientsActionType, ClientsActionTypes, ClientsStateType } from '../actionTypes'

const initialState: ClientsStateType = {
  isClientsLoading: false,
  clients: undefined,
  client: undefined,
  history: undefined,
  historyStats: undefined,
  plans: undefined,
  createdId: undefined,
  loadClientsError: null,
}

export const clients = (state = initialState, action: ClientsActionType): ClientsStateType => {
  if (action) {
    switch (action.type) {
      case ClientsActionTypes.START_CLIENTS_LOADING:
        return {
          ...state,
          isClientsLoading: true,
        }

      case ClientsActionTypes.LOAD_CLIENTS_SUCCESS:
        return {
          ...state,
          clients: action.payload,
          isClientsLoading: false,
          loadClientsError: null,
        }

      case ClientsActionTypes.LOAD_CLIENT_SUCCESS:
        return {
          ...state,
          client: action.payload,
          isClientsLoading: false,
          loadClientsError: null,
        }

      case ClientsActionTypes.LOAD_PLANS_SUCCESS:
        return {
          ...state,
          plans: action.payload?.sort((a, b) => (a.tier > b.tier ? 1 : -1)),
          isClientsLoading: false,
          loadClientsError: null,
        }

      case ClientsActionTypes.CREATE_CLIENT_SUCCESS:
        return {
          ...state,
          client: action.payload,
          isClientsLoading: false,
          loadClientsError: null,
        }

      case ClientsActionTypes.EDIT_CLIENT_SUCCESS:
        return {
          ...state,
          client: action.payload,
          isClientsLoading: false,
          loadClientsError: null,
        }

      case ClientsActionTypes.SET_CREATED_ID:
        return {
          ...state,
          createdId: action.payload,
        }

      case ClientsActionTypes.LOAD_CLIENT_HISTORY_SUCCESS:
        return {
          ...state,
          history: action.payload,
          isClientsLoading: false,
          loadClientsError: null,
        }

      case ClientsActionTypes.LOAD_CLIENT_HISTORY_STATS_SUCCESS:
        return {
          ...state,
          historyStats: action.payload,
          isClientsLoading: false,
          loadClientsError: null,
        }

      case ClientsActionTypes.LOAD_CLIENTS_ERROR:
        return {
          ...state,
          isClientsLoading: false,
          loadClientsError: action.payload,
        }

      default:
        return state
    }
  } else {
    return state
  }
}
