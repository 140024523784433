import { AxiosError } from 'axios'
import { t } from 'i18next'

import { API_ERROR_DETAILS, STATUS_CODES } from '../../constants'
import {
  getErrorDetails,
  getErrorStatus,
  getResponseUrl,
  isDevelopmentMode,
  isNeedSendNotification,
  isProductionMode,
  isTimeoutError,
} from '../functions'

export const getErrorMessage = (err: AxiosError) => {
  if (isNeedSendNotification(err)) {
    const details = getErrorDetails(err)
    const status = getErrorStatus(err)
    const isTimeoutErr = isTimeoutError(err)

    if (isProductionMode()) {
      console.log(
        t(
          `${err}; Problem with '${getResponseUrl(err)}';  STATUS:${status || ' none'};  DETALES:${
            details || ' none'
          };  IS_TIMEOUT_ERR: ${isTimeoutErr}`,
        ),
      )

      return ''
    }

    if (status === STATUS_CODES.CODE_400 && details === API_ERROR_DETAILS.LOGIN_BAD_CREDENTIALS) {
      return t('bad-login')
    }

    if (
      status === STATUS_CODES.CODE_400 &&
      details === API_ERROR_DETAILS.REGISTER_USER_ALREADY_EXISTS
    ) {
      return t('user-allready-exists')
    }

    if (
      status === STATUS_CODES.CODE_400 &&
      details === API_ERROR_DETAILS.UPDATE_USER_EMAIL_ALREADY_EXISTS
    ) {
      return t('user-email-allready-exists')
    }

    if (status === STATUS_CODES.CODE_400 && details === API_ERROR_DETAILS.LOGIN_USER_NOT_VERIFIED) {
      return t('not-verified-user')
    }

    if (status === STATUS_CODES.CODE_429 && details === API_ERROR_DETAILS.TOO_MANY_LOGIN_ATTEMPTS) {
      return t('to-many-login-attempts')
    }

    if (isTimeoutErr) return t('timeout-loading-error')

    if (isProductionMode()) {
      /* eslint-disable-next-line */
      alert(
        t(
          `${err} Problem with '${getResponseUrl(err)}'  STATUS:${status || ' none'}  DETALES:${
            details || ' none'
          }`,
        ),
      )

      return ''
    }

    return isDevelopmentMode()
      ? `${err} Problem with '${getResponseUrl(err)}'`
      : t('unknown-error', err)
  }

  return ''
}
