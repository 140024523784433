import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import ForwardToInboxOutlinedIcon from '@mui/icons-material/ForwardToInboxOutlined'
import GpsFixedOutlinedIcon from '@mui/icons-material/GpsFixedOutlined'
import HeadsetMicOutlinedIcon from '@mui/icons-material/HeadsetMicOutlined'
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined'
import PhotoCameraOutlinedIcon from '@mui/icons-material/PhotoCameraOutlined'
import StarBorderIcon from '@mui/icons-material/StarBorder'
import StarIcon from '@mui/icons-material/Star'
import styled from 'styled-components'
import UpdateOutlinedIcon from '@mui/icons-material/UpdateOutlined'

import { Color } from '../../../styles'
import { EMPTY_STRING } from '../../../constants'

// ******** Icons ******** //

export const IconWrapper = styled.span`
  margin: 0;
  padding: 0;
`

type StyledMoreActionsButtonProps = {
  $isTablePage?: boolean
  $isCardActions?: boolean
  $ml?: string
}

export const StyledMoreActionsButton = styled(MoreHorizOutlinedIcon)<StyledMoreActionsButtonProps>`
  position: ${(props) => (props.$isCardActions ? 'absolute' : undefined)};
  top: ${(props) => (props.$isCardActions ? '12px' : undefined)};
  right: ${(props) => (props.$isCardActions ? '12px' : undefined)};
  margin-left: ${(props) => (props.$ml ? props.$ml : '30px')} !important;
  color: ${Color.gray};
  cursor: pointer;
`

// * Styled PhotoCameraOutlinedIcon * //

type StyledPhotoIconProps = {
  $cursor?: boolean
  $isSmall?: boolean
  $color?: string
}

export const StyledPhotoCameraOutlinedIcon = styled(PhotoCameraOutlinedIcon)<StyledPhotoIconProps>`
  position: absolute;

  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
  color: ${(props) => (props.$color ? props.$color : Color.gray)};

  svg {
    width: ${(props) => (props.$isSmall ? '20px' : '24px')} !important;
    height: ${(props) => (props.$isSmall ? '20px' : '24px')} !important;
  }

  cursor: ${(props) => (props.$cursor ? 'pointer' : EMPTY_STRING)};

  :hover {
    color: ${(props) => (props.$cursor ? Color.blue : EMPTY_STRING)};
  }
`

// * Styled GpsFixedOutlinedIcon * //

type StyledGpsFixedIconProps = {
  $cursor?: boolean
  $color?: string
}

export const StyledGpsFixedOutlinedIcon = styled(GpsFixedOutlinedIcon)<StyledGpsFixedIconProps>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
  cursor: ${(props) => (props.$cursor ? 'pointer' : EMPTY_STRING)};
  color: ${(props) => (props.$color ? props.$color : Color.gray)};

  :hover {
    color: ${(props) => (props.$cursor ? Color.blue : EMPTY_STRING)};
  }
`

export const StyledAddTaskIcon = styled(AddCircleOutlineIcon)`
  color: ${Color.blue};
  padding-top: 4px;
  margin-left: -4px;
`

type StyledStarBorderIconProps = {
  $isTableCell?: boolean
  $color?: string
}

export const StyledStarBorderIcon = styled(StarBorderIcon)<StyledStarBorderIconProps>`
  position: ${(props) => (props.$isTableCell ? 'absolute' : 'relative')};
  top: ${(props) => (props.$isTableCell ? '50%' : undefined)};
  left: ${(props) => (props.$isTableCell ? '50%' : undefined)};
  transform: ${(props) => (props.$isTableCell ? 'translate(-50%, -50%)' : undefined)};
  -ms-transform: ${(props) => (props.$isTableCell ? 'translate(-50%, -50%)' : undefined)};

  text-align: center;
  cursor: pointer;
  color: ${(props) => (props.$color ? props.$color : Color.lightGray)};
`

type StyledStarFavoriteIconProps = {
  $isTableCell?: boolean
}

export const StyledStarFavoriteIcon = styled(StarIcon)<StyledStarFavoriteIconProps>`
  position: ${(props) => (props.$isTableCell ? 'absolute' : 'relative')};
  top: ${(props) => (props.$isTableCell ? '50%' : undefined)};
  left: ${(props) => (props.$isTableCell ? '50%' : undefined)};
  transform: ${(props) => (props.$isTableCell ? 'translate(-50%, -50%)' : undefined)};
  -ms-transform: ${(props) => (props.$isTableCell ? 'translate(-50%, -50%)' : undefined)};

  text-align: center;
  cursor: pointer;
  color: ${Color.yellow};
`
type StyledInviteIconProps = {
  $cursor?: boolean
  $color?: string
}

export const StyledInviteIcon = styled(ForwardToInboxOutlinedIcon)<StyledInviteIconProps>`
  color: ${(props) => (props.$color ? props.$color : Color.blue)};
  cursor: ${(props) => (props.$cursor ? 'pointer' : undefined)};
`

// * Styled StyledUpdateOutlinedIcon * //

type StyledUpdateOutlinedIconProps = {
  $cursor?: boolean
  $color?: string
}

export const StyledUpdateOutlinedIcon = styled(UpdateOutlinedIcon)<StyledUpdateOutlinedIconProps>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
  color: ${(props) => (props.$color ? props.$color : Color.gray)};

  svg {
    width: 24px !important;
    height: 24px !important;
  }

  cursor: ${(props) => (props.$cursor ? 'pointer' : EMPTY_STRING)};

  :hover {
    color: ${(props) => (props.$cursor ? Color.blue : EMPTY_STRING)};
  }
`

export const StyledHeadsetMicOutlinedIcon = styled(HeadsetMicOutlinedIcon)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;

  svg {
    width: 24px !important;
    height: 24px !important;
  }
`

// ******** Icons ******** //
