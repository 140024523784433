import {
  ArrayOptions,
  BooleanOptions,
  ClientType,
  CoordinateOptions,
  IEquipment,
  IFile,
  IMaterial,
  IMeter,
  IPlaces,
  IPpwGraphics,
  ITask,
  SortColumnBooleanType,
  SortColumnCoordinatesType,
  SortColumnImagesType,
  SortColumnStringType,
  StringOptions,
  UserServicesColumnsDataType,
} from '../../../api'

const LAST = '-'

export const EMPTY_OBJECT = { name: LAST }

// Compare values as a string *value exp.  {name: 'Olga'}

export const compareValues = (
  a: SortColumnStringType,
  b: SortColumnStringType,
  option: StringOptions,
) => {
  const prepareString = (string: string) =>
    string ? string.trim().replace(/\s+/g, '').toLowerCase() : LAST

  return prepareString(a[option]) > prepareString(b[option]) ? 1 : -1
}

const convertArrayIntoBoolean = (array: IFile[]) => Boolean(array?.length)

const convertStringIntoBoolean = (string: string) => Boolean(string)

// Compare icons(starIcon) if there are an icon, function returns true,
// else false *value exp.  {isFavorite: true}

export const compareBooleanValues = (
  a: SortColumnBooleanType,
  b: SortColumnBooleanType,
  option: BooleanOptions,
) => (a[option] === b[option] ? 0 : a[option] ? -1 : 1)

// Compare icons(ImageIcons) if there are not an empty array, function returns true,
// else false *value exp.  {images: [{id: 1, ...}]}

export const compareArray = (
  a: SortColumnImagesType,
  b: SortColumnImagesType,
  option: ArrayOptions,
) =>
  convertArrayIntoBoolean(a[option]) === convertArrayIntoBoolean(b[option])
    ? 0
    : convertArrayIntoBoolean(a[option])
    ? -1
    : 1

// Compare icons(CoordinateIcon) if there are at list one letter in string, function returns true,
// else false *value exp.  {location: "12345678,5845685"}

export const compareStrings = (
  a: SortColumnCoordinatesType,
  b: SortColumnCoordinatesType,
  option: CoordinateOptions,
) => {
  return convertStringIntoBoolean(a[option]) === convertStringIntoBoolean(b[option])
    ? 0
    : convertStringIntoBoolean(a[option])
    ? -1
    : 1
}

// Trim search string

export const prepareSearchValue = (str: string) =>
  str
    ? str
        .toLowerCase()
        .replace(/[^a-zа-яё0-9]/gi, '')
        .trim()
    : ''

export const getSearchedEquipmentItems = (
  data: IEquipment[],
  value?: string,
): IEquipment[] | null => {
  if (value && data)
    return data.filter(
      (item: IEquipment) =>
        prepareSearchValue(item.name).includes(value) ||
        prepareSearchValue(item.serialNumber).includes(value) ||
        prepareSearchValue(item.modelName).includes(value) ||
        prepareSearchValue(item.inventoryNumber).includes(value) ||
        prepareSearchValue(item.description).includes(value),
    )

  return data || []
}

export const getSearchedPlaceItems = (data: IPlaces[], value?: string): IPlaces[] | null => {
  if (value && data)
    return data.filter(
      (item: IPlaces) =>
        prepareSearchValue(item.name).includes(value) ||
        prepareSearchValue(item.address).includes(value) ||
        prepareSearchValue(item.ownerName).includes(value) ||
        prepareSearchValue(item.description).includes(value) ||
        prepareSearchValue(item.ownerPhone).includes(value),
    )

  return data || []
}

export const getSearchedMeterItems = (data: IMeter[], value?: string): IMeter[] | null => {
  if (value && data)
    return data.filter(
      (item: IMeter) =>
        prepareSearchValue(item.name).includes(value) ||
        prepareSearchValue(item.description).includes(value) ||
        prepareSearchValue(item.lastOperationDate).includes(value) ||
        prepareSearchValue(item.readingPeriodValue.toString()).includes(value) ||
        prepareSearchValue(item.lastOperationValue.toString()).includes(value),
    )

  return data || []
}

export const getSearchedMaterialItems = (data: IMaterial[], value?: string): IMaterial[] | null => {
  if (value && data)
    return data.filter(
      (item: IMaterial) =>
        prepareSearchValue(item.name).includes(value) ||
        prepareSearchValue(item.description).includes(value) ||
        prepareSearchValue(item.unitCount.toString()).includes(value) ||
        prepareSearchValue(item.unitPrice.toString()).includes(value),
    )

  return data || []
}

export const getSearchedTaskItems = (data: ITask[], value?: string): ITask[] | null => {
  if (value && data)
    return data.filter(
      (item: ITask) =>
        prepareSearchValue(item.title).includes(value) ||
        prepareSearchValue(item.description).includes(value) ||
        prepareSearchValue(item.number.toString()).includes(value) ||
        prepareSearchValue(item.createdDatetime).includes(value),
    )

  return data || []
}

export const getSearchedUserServicesItems = (
  data: UserServicesColumnsDataType[],
  value?: string,
): UserServicesColumnsDataType[] | null => {
  if (value && data)
    return data.filter(
      (item: UserServicesColumnsDataType) =>
        prepareSearchValue(item.name).includes(value) ||
        prepareSearchValue(item.comment).includes(value) ||
        prepareSearchValue(item.contacts).includes(value) ||
        prepareSearchValue(item.position).includes(value),
    )

  return data || []
}

export const getSearchedPpwItems = (
  data: IPpwGraphics[],
  value?: string,
): IPpwGraphics[] | null => {
  if (value && data)
    return data.filter(
      (item: IPpwGraphics) =>
        prepareSearchValue(item.name).match(value) ||
        prepareSearchValue(item.description).match(value) ||
        item.startDatetime.match(value) ||
        item.endDatetime.match(value),
    )

  return data || []
}

export const getSearchedClientItems = (data: ClientType[], value?: string): ClientType[] | null => {
  if (value && data) {
    return data.filter(
      (item: ClientType) =>
        prepareSearchValue(item.name).includes(value) ||
        prepareSearchValue(item.email).includes(value) ||
        prepareSearchValue(item.description).includes(value),
    )
  }

  return data || []
}

export const hideDefaultSortIcon = (customIconClass: string) => {
  const customIconEl = document.querySelectorAll<HTMLElement>(`.${customIconClass}`)
  customIconEl.forEach((element) => {
    const customIconParentNode = element?.parentNode
    const bothIconsParentNode = customIconParentNode?.parentNode
    const defaultIconEl = bothIconsParentNode?.querySelector<HTMLElement>(
      '.ant-table-column-sorter',
    )

    if (defaultIconEl) defaultIconEl.style.display = 'none'
  })
}
