import { AxiosError } from 'axios'

import { ICompany, ICompanyImageInfo } from '../../api'

export type CompanyStateType = {
  isCompanyLoading: boolean
  currentCompany: ICompany
  companies: ICompany[]
  companyImageInfo: ICompanyImageInfo
  companyDeleteImageUrl: string
  loadCompanyError: AxiosError | null
}

export enum CompanyActionTypes {
  START_COMPANIES_LOADING = 'START_COMPANIES_LOADING',
  GET_CURRENT_COMPANY_SUCCESS = 'GET_CURRENT_COMPANY_SUCCESS',
  GET_COMPANIES_SUCCESS = 'GET_COMPANIES_SUCCESS',
  EDIT_COMPANY_ITEM_SUCCESS = 'EDIT_COMPANY_ITEM_SUCCESS',
  GET_COMPANY_IMAGE_INFO_SUCCESS = 'GET_COMPANY_IMAGE_INFO_SUCCESS',
  GET_COMPANY_DELETE_IMAGE_INFO_SUCCESS = 'GET_COMPANY_DELETE_IMAGE_INFO_SUCCESS',
  SET_LOAD_COMPANIES_ERROR = 'SET_LOAD_COMPANIES_ERROR',
}

export type StartCompanyLoadingActionType = {
  type: CompanyActionTypes.START_COMPANIES_LOADING
}

export type GetCurrentCompanySuccessActionType = {
  type: CompanyActionTypes.GET_CURRENT_COMPANY_SUCCESS
  payload: ICompany
}

export type GetCompaniesSuccessActionType = {
  type: CompanyActionTypes.GET_COMPANIES_SUCCESS
  payload: ICompany[]
}

export type EditCompanySuccessActionType = {
  type: CompanyActionTypes.EDIT_COMPANY_ITEM_SUCCESS
  payload: ICompany
}

export type GetCompanyImageInfoSuccessActionType = {
  type: CompanyActionTypes.GET_COMPANY_IMAGE_INFO_SUCCESS
  payload: ICompanyImageInfo
}

export type GetCompanyDeleteImageInfoSuccessActionType = {
  type: CompanyActionTypes.GET_COMPANY_DELETE_IMAGE_INFO_SUCCESS
  payload: string
}

export type SetLoadCompanyErrorActionType = {
  type: CompanyActionTypes.SET_LOAD_COMPANIES_ERROR
  payload: AxiosError | null
}

export type CompanyActionType =
  | StartCompanyLoadingActionType
  | GetCurrentCompanySuccessActionType
  | GetCompaniesSuccessActionType
  | EditCompanySuccessActionType
  | SetLoadCompanyErrorActionType
  | GetCompanyImageInfoSuccessActionType
  | GetCompanyDeleteImageInfoSuccessActionType
