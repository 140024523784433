import { t } from 'i18next'

import {
  ClientHistoryColumnTitlesType,
  ClientsColumnTitlesType,
  EquipmentColumnsTitlesType,
  EquipmentHistoryColumnTitlesType,
  MaterialColumnTitlesType,
  MaterialHistoryTitlesType,
  MeterColumnTitlesType,
  MetersHistoryTitlesType,
  NotificationsHistoryColumnTitlesType,
  PlaceColumnTitlesType,
  PpwGraphicsColumnTitlesType,
  ServicesColumnTitlesType,
  ServicesHistoryColumnTitlesType,
  TaskGraphicsColumnTitlesType,
  TaskHistoryColumnTitlesType,
  TasksColumnsTitlesType,
  UsersRolesColumnTitlesType,
} from './columnTypes'

export const TABLE_ICONS_DATA_INDEXES = [
  { id: 1, value: 'images', label: 'image' },
  { id: 2, value: 'location', label: 'location' },
  { id: 3, value: 'isFavorite', label: 'favorites-tab' },
  { id: 4, value: 'materials', label: 'materials' },
  { id: 5, value: 'isPlanned', label: 'isPlanned' },
]

export const getEquipmentColumnTitles = (): EquipmentColumnsTitlesType => ({
  name: t('name-column-title'),
  modelName: t('modelName-column-title'),
  serialNumber: t('serialNumber-column-title'),
  description: t('description-column-title'),
  inventoryNumber: t('inventoryNumber-column-title'),
  importanceLevel: t('importanceLevel-column-title'),
  tags: t('tags-column-title'),
  place: t('place-column-title'),
  status: t('status-column-title'),
  responsibleUser: t('responsible-user-column-title'),
})

export const getMeterColumnTitles = (): MeterColumnTitlesType => ({
  name: t('name-column-title'),
  location: t('place-column-title'),
  equipment: t('equipments-column-title'),
  tags: t('tags-column-title'),
  description: t('description-column-title'),
  readingPeriod: t('readingPeriodValue-column-title'),
  lastOperationValue: t('lastOperationValue-column-title'),
  lastOperationDate: t('lastOperationDate-column-title'),
  measurementUnit: t('measurementUnit-column-title'),
  measUnit: t('meas-unit'),
  responsibleUser: t('responsible-user-column-title'),
})

export const getMaterialColumnTitles = (): MaterialColumnTitlesType => ({
  name: t('name-column-title'),
  equipmentArray: t('equipments-column-title'),
  unitCount: t('unit-count-column-title'),
  description: t('description-column-title'),
  unitPrice: t('unit-price-column-title'),
  partNumber: t('sNumber-column-title'),
  tags: t('tags-column-title'),
  place: t('location-column-title'),
  responsibleUser: t('responsible-user-column-title'),
})

export const getPlaceColumnTitles = (): PlaceColumnTitlesType => ({
  name: t('name-column-title'),
  address: t('address-column-title'),
  phone: t('phone-column-title'),
  description: t('description-column-title'),
  ownerName: t('owner-name-column-title'),
})

export const getMaterialsHistoryColumnTitles = (): MaterialHistoryTitlesType => ({
  operationDate: t('operationDate-column-title'),
  operationType: t('operationType-column-title'),
  operationCount: t('operationCount-column-title'),
  materialNumber: t('materialNumber-column-title'),
  comments: t('comments-column-title'),
  unitCount: t('unitCount-column-title'),
  loss: t('loss-title'),
  profit: t('profit-title'),
  task: t('task-column-title'),
})

export const getMeterHistoryColumnTitles = (): MetersHistoryTitlesType => ({
  operationDate: t('operationDate-column-title'),
  lastOperationValue: t('lastOperationValue-column-title'),
  measurementUnit: t('measurementUnit-column-title'),
  operationValueDiff: t('operationValueDiff-column-title'),
  comments: t('comments-column-title'),
  operationDateDiff: t('operationDateDiff-column-title'),
  task: t('task-column-title'),
})

export const getLinkedMaterialsColumnTitles = (): MaterialColumnTitlesType => ({
  name: t('name-column-title'),
  partNumber: t('sNumber-column-title'),
  count: t('count-shortcut'),
  unitCount: t('unitCount-column-title'),
  description: t('description-column-title'),
})

export const getEquipmentHistoryColumnTitles = (): EquipmentHistoryColumnTitlesType => ({
  period: t('period-column-title'),
  operationDate: t('operationDate-column-title'),
  statusName: t('status-column-title'),
  operationComment: t('comments-column-title'),
  task: t('task-column-title'),
  durationHours: t('duration-column-title'),
})

export const getTasksColumnTitles = (): TasksColumnsTitlesType => ({
  title: t('name-column-title'),
  description: t('description-column-title'),
  tags: t('tags-column-title'),
  createDatetime: t('createDate-column-title'),
  dueDatetime: t('dueDatetime-column-title'),
  number: t('number-column-title'),
  status: t('task-status-column-title'),
  owner: t('owner-column-title'),
  location: t('place-column-title'),
  equipment: t('equipments-column-title'),
  priority: t('task-columns-priorityName'),
  responsibleUser: t('responsible-user-column-title'),
  type: t('task-type-column-title'),
})

export const getTaskHistoryColumnTitles = (): TaskHistoryColumnTitlesType => ({
  owner: t('owner-column-title'),
  operationDate: t('operationDate-column-title'),
  operationTime: t('operationTime-column-title'),
  operationDue: t('dueDatetime-column-title'),
  statusName: t('task-status-column-title'),
  operationComment: t('comments-column-title'),
  responsibleUser: t('responsible-user-column-title'),
})

export const getServicesColumnTitles = (): ServicesColumnTitlesType => ({
  name: t('name-column-title'),
  status: t('status-column-title'),
  admission: t('admission-column-title'),
  position: t('position-column-title'),
  description: t('description-column-title'),
  contacts: t('contacts-column-title'),
  tags: t('tags-column-title'),
})

export const getUserServiceHistoryColumnTitles = (): ServicesHistoryColumnTitlesType => ({
  operationDate: t('operationDate-column-title'),
  statusName: t('task-status-column-title'),
  operationComment: t('comments-column-title'),
  admission: t('admission-column-title'),
  task: t('task-column-title'),
})

export const getUsersRolesColumnTitles = (): UsersRolesColumnTitlesType => ({
  status: t('user-status-column-title'),
  email: t('email-column-title'),
  userPermissions: t('user-admission-column-title'),
  role: t('role-column-title'),
  inviteAction: t('invite-column-title'),
})

export const getNotificationsHistoryColumnTitles = (): NotificationsHistoryColumnTitlesType => ({
  createdAt: t('currOperationDate-column-title'),
  eventMsg: t('notifyMsg-column-title'),
  isMsgRead: t('isViewed-column-title'),
  eventTypeName: t('sourceObjectName-column-title'),
})

export const getPpwColumnTitles = (): PpwGraphicsColumnTitlesType => ({
  name: t('name-column-title'),
  equipmentArray: t('equipments-column-title'),
  tags: t('tags-column-title'),
  description: t('description-column-title'),
  startDatetime: t('startDatetime-column-title'),
  endDatetime: t('endDatetime-column-title'),
})

export const getTaskGraphicsColumnTitles = (): TaskGraphicsColumnTitlesType => ({
  taskName: t('task-column-title'),
  taskDate: t('operationDate-column-title'),
  equipment: t('equipments-column-title'),
  equipmentDowntime: t('equipmentDowntime-column-title'),
  task: t('task-column-title'),
  laborIntensity: t('laborIntensity-column-title'),
})

export const getClientsColumnTitles = (): ClientsColumnTitlesType => ({
  name: t('name-column-title'),
  email: t('email-column-title'),
  description: t('description-column-title'),
  tariffPlan: t('tariff-column-title'),
  startsAt: t('plan-start-column-title'),
  term: t('plan-term-column-title'),
  expiresAt: t('plan-end-column-title'),
})

export const getClientHistoryColumnTitles = (): ClientHistoryColumnTitlesType => ({
  operationDate: t('operationDate-column-title'),
  subscriptionPlanName: t('tariff'),
  durationHours: t('duration-column-title'),
  operationComment: t('comments-column-title'),
  subscriptionPlanStart: t('plan-start-column-title'),
  subscriptionPlanTerm: t('plan-term-column-title'),
})
