import { CheckboxChangeEvent } from 'antd/lib/checkbox'
import { useTranslation } from 'react-i18next'
import { Checkbox, Row, Table, Typography } from 'antd'
import { FC, useEffect, useState } from 'react'

import { editNotification } from '../../../store/actions'
import { getNotifications } from '../../../store/selectors'
import { getNotificationsHistoryTableColumns } from './getNotificationsHistoryTableColumns'
import { HistoryTableWrapper } from '../../tables'
import { Spacing } from '../../../styles'
import { INotification, NotificationsDataType } from '../../../api'
import { SkeletonCard, StyledCard, StyledHistoryPromptWrapper } from '../../common'
import { useTypedDispatch, useTypedSelector } from '../../../hooks'

const { Title } = Typography

type NotificationsHistoryTableCardPropsType = {
  isAllViewed?: boolean
}

export const NotificationsHistoryTableCard: FC<NotificationsHistoryTableCardPropsType> = ({
  isAllViewed,
}) => {
  const [notificationsList, setNotificationsList] = useState<INotification[]>(null)

  const { t } = useTranslation()

  const { notifications } = useTypedSelector(getNotifications)

  const dispatch = useTypedDispatch()

  const handleOnChange = (e: CheckboxChangeEvent, id: number) => {
    const isRead = e.target.checked

    if (notificationsList)
      setNotificationsList(
        notificationsList.map((i) => {
          if (i.id === id) return { id, isRead }

          return i
        }),
      )

    dispatch(editNotification([{ id, isRead }]))
  }

  const isNotificationRead = (id: number) => {
    if (notificationsList) return notificationsList?.filter((i) => i.id === id)[0]?.isRead

    return false
  }

  const getData = () => {
    const notificationList = notifications ? [...notifications] : []

    return notificationList
      .map(({ id, createdAt, message, type, obj }: INotification) => ({
        key: id,
        createdAt,
        message,
        isMsgRead: (
          <Row justify="center" align="middle">
            <Checkbox
              checked={isNotificationRead(id)}
              name="quantity"
              onChange={(e) => handleOnChange(e, id)}
            />
          </Row>
        ),
        isRead: isNotificationRead(id),
        obj,
        type,
      }))
      .sort((a: NotificationsDataType, b: NotificationsDataType) =>
        a.createdAt < b.createdAt ? 1 : -1,
      ) as NotificationsDataType[]
  }

  useEffect(() => {
    if (notifications && !notificationsList) setNotificationsList(notifications)
    if (isAllViewed) setNotificationsList(notifications.map((i) => ({ ...i, isRead: true })))
  }, [notifications, notificationsList, isAllViewed])

  return (
    <StyledCard
      $width="100%"
      $height="550px"
      $pl={Spacing.sm12}
      $pr={Spacing.sm12}
      $pt={Spacing.sm12}
      $pb={Spacing.sm12}
    >
      <HistoryTableWrapper>
        {notifications && getData()?.length ? (
          <Table
            columns={getNotificationsHistoryTableColumns()}
            dataSource={getData()}
            bordered={false}
            pagination={false}
            scroll={getData().length > 8 ? { y: '500px' } : {}}
            style={{ width: '100%' }}
          />
        ) : (
          <div style={{ width: '100%' }}>
            {notifications && !getData()?.length ? (
              <StyledHistoryPromptWrapper justify="center" align="middle" $height="500px">
                <Title level={3}>{t('history-notifications-table-card-prompt')}</Title>
              </StyledHistoryPromptWrapper>
            ) : (
              <SkeletonCard width="500px" height="calc(100% - 16px)" />
            )}
          </div>
        )}
      </HistoryTableWrapper>
    </StyledCard>
  )
}
