import { ErrorActionType, ErrorActionTypes, ErrorStateType } from '../actionTypes'

const initialState: ErrorStateType = {
  notFoundError: null,
  serverError: null,
  unknownError: null,
  authError: null,
  isUserOnline: null,
}

export const errors = (state = initialState, action: ErrorActionType): ErrorStateType => {
  if (action) {
    switch (action.type) {
      case ErrorActionTypes.SET_ERROR:
        return {
          ...state,
          [action.payload.dataType]: action.payload.error,
        }

      default:
        return state
    }
  } else {
    return state
  }
}
