import { AxiosError } from 'axios'
import { UserLimitsType, UserType } from '../../api'

export type UserStateType = {
  isUserLoading: boolean
  user: UserType
  limits: UserLimitsType | undefined
  loadUserError: AxiosError | null
}

export enum UserActionTypes {
  START_USER_LOADING = 'START_USER_LOADING',
  GET_USER_SUCCESS = 'GET_USER_SUCCESS',
  GET_USER_LIMITS_SUCCESS = 'GET_USER_LIMITS_SUCCESS',
  SET_LOAD_USER_ERROR = 'SET_LOAD_USER_ERROR',
}

export type StartUserLoadingActionType = {
  type: UserActionTypes.START_USER_LOADING
}

export type LoadUserSuccessActionType = {
  type: UserActionTypes.GET_USER_SUCCESS
  payload: UserType
}

export type LoadUserLimitsSuccessActionType = {
  type: UserActionTypes.GET_USER_LIMITS_SUCCESS
  payload: UserLimitsType
}

export type SetLoadUserErrorActionType = {
  type: UserActionTypes.SET_LOAD_USER_ERROR
  payload: AxiosError | null
}

export type UserActionType =
  | StartUserLoadingActionType
  | LoadUserSuccessActionType
  | SetLoadUserErrorActionType
  | LoadUserLimitsSuccessActionType
