import { FC, memo, ReactElement, ReactNode, useEffect } from 'react'

import { CommonErrorPage } from '../service/CommonErrorPage'
import { isUserOnline } from '../../functions'
import { setError } from '../../store/actions'
import { AuthLayout, Layout } from '../../components/layout'
import { BREAKPOINTS, ERROR } from '../../constants'
import { getErrors, getWorkspaces } from '../../store/selectors'
import { useBreakpoint, useTypedDispatch, useTypedSelector } from '../../hooks'

export type PagePropsType = {
  children?: ReactElement
  header?: ReactNode
  isTablePage?: boolean
  pageTitle?: string
  authLayout?: boolean
}

export const Page: FC<PagePropsType> = memo(
  ({ children, header, isTablePage, pageTitle, authLayout = false }) => {
    const { isUserOnline: isOnline } = useTypedSelector(getErrors)
    const { currentWorkspace, defaultWorkspaceId } = useTypedSelector(getWorkspaces)

    const breakpoint = useBreakpoint()

    const dispatch = useTypedDispatch()

    const body = document.querySelector('body')

    if (isTablePage && breakpoint === BREAKPOINTS.xxxl) body.style.overflowY = 'hidden'

    if (!(isTablePage && breakpoint === BREAKPOINTS.xxxl)) body.removeAttribute('style')

    useEffect(() => {
      if (isUserOnline() === false) {
        dispatch(setError(false, ERROR.IS_USER_ONLINE_ERR))
      } else {
        dispatch(setError(true, ERROR.IS_USER_ONLINE_ERR))
      }
    }, [dispatch, isOnline])

    if (isOnline === false) return <CommonErrorPage isOffline />

    return !authLayout ? (
      <Layout header={header} isTablePage={isTablePage} pageTitle={pageTitle}>
        {currentWorkspace || defaultWorkspaceId ? children : null}
      </Layout>
    ) : (
      <AuthLayout pageTitle={pageTitle}>{children}</AuthLayout>
    )
  },
)
