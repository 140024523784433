import { CSSProperties } from 'react'
import { measureTextWidth } from '@ant-design/plots'

export const getRoundNumber = (number: number) => {
  return number && !Number.isInteger(number) ? Number(number.toFixed()) : number
}

export const renderStatistics = (containerWidth: number, text: string, style: CSSProperties) => {
  const { width: textWidth, height: textHeight } = measureTextWidth(text, style)

  const R = containerWidth / 2

  let scale = 1

  if (containerWidth < textWidth) {
    scale = Math.min(Math.sqrt(Math.abs(R ** 2 / ((textWidth / 2) ** 2 + textHeight ** 2))), 1)
  }

  const textStyleStr = `width:${containerWidth}px;`

  return `<div style="${textStyleStr};font-size:${scale}em;line-height:${
    scale < 1 ? 1 : 'inherit'
  };">${text}</div>`
}

export const getStatisticsPieTitleOptions = (fontSize: string, offsetY = -6) => ({
  offsetY,
  style: {
    fontSize,
  },
})

export const getStatisticsPieContentOptions = (fontSize: string, offsetY = 4) => ({
  offsetY,
  style: {
    fontSize,
  },
})

export const getInteractions = () => [
  {
    type: 'element-selected',
  },
  {
    type: 'element-active',
  },
  {
    type: 'pie-statistic-active',
  },
  {
    type: 'tooltip',
    cfg: { start: [{ trigger: 'element:click', action: 'tooltip:show' }] },
  },
]

export const getCommonStatisticsPieConfig = (
  metaValueString: string,
  contentValueString: string,
) => {
  return {
    appendPadding: 8,
    angleField: 'value',
    colorField: 'type',
    radius: 1,
    color: ['blue'],
    innerRadius: 0.64,
    autoFit: false,
    meta: {
      value: {
        formatter: (v: number) => `${v} ${metaValueString}`,
      },
    },
    label: {
      type: 'inner',
      offset: '-50%',
      style: {
        textAlign: 'center',
        fontSize: 14,
      },
      autoRotate: false,
      content: contentValueString,
    },
  }
}
