import { RcFile } from 'antd/lib/upload'
import { t } from 'i18next'
import urlJoin from 'url-join'
import { AxiosError, AxiosInstance, AxiosResponse } from 'axios'

import { AppDispatch } from '../../hooks'
import { getBaseLocation } from '../../functions'
import { getMaterialItemHistory } from './history'
import { handleLoadError } from './errors'
import { MaterialsActionTypes } from '../actionTypes'
import { RootState } from '../reducers/rootReducer'
import { sendNotification } from '../../components/common/notification'
import {
  CACHE_KEYS,
  CURRENT_LOCATION,
  HISTORY_SISTEM_NOTIFICATIONS,
  INIT,
  MATERIALS_SISTEM_NOTIFICATIONS,
  MESSAGE_TYPES,
} from '../../constants'
import { createObject, editObject, linkMaterials, unlinkMaterials } from '../../api/shortcuts'
import {
  deleteTemporaryImages,
  deleteTemporaryTags,
  resetTemporaryIds,
  resetTemporaryMaterialIds,
} from './temporaryData'
import { getJsonHeader, startDataLoading } from './utils'
import { HISTORY_ENDPOINT, HISTORY_MATERIALS_ENDPOINT, MATERIALS_ENDPOINT } from '../../env'
import { IMaterial, IMaterialHistory, removeItemFromLocalStorage } from '../../api'

export type GetMaterialsStateFunctionType = () => RootState

// Materials

// Load material item success

export const loadMaterialItemSuccess = (data: IMaterial) => (dispatch: AppDispatch) =>
  dispatch({
    type: MaterialsActionTypes.LOAD_MATERIAL_ITEM_SUCCESS,
    payload: data,
  })

// Delete material item from state

export const deleteMaterialItemFromState = () => (dispatch: AppDispatch) =>
  dispatch({
    type: MaterialsActionTypes.DELETE_MATERIAL_ITEM_FROM_STATE,
  })

export const setCreatedMaterialId = (value: string | undefined) => (dispatch: AppDispatch) => {
  dispatch({
    type: MaterialsActionTypes.SET_CREATED_MATERIAL_ID,
    payload: value,
  })
}

// Get  Materials  GET

export const getAllMaterials =
  (init?: string) =>
  (dispatch: AppDispatch, getState: GetMaterialsStateFunctionType, api: AxiosInstance) => {
    dispatch(startDataLoading(MaterialsActionTypes.START_MATERIALS_LOADING))

    if (init && init === INIT) {
      dispatch(resetTemporaryIds())

      dispatch(deleteTemporaryTags())

      dispatch(deleteTemporaryImages())

      dispatch({
        type: MaterialsActionTypes.DELETE_MATERIAL_ITEM_FROM_STATE,
      })
    }

    api
      .get<IMaterial[]>(urlJoin(MATERIALS_ENDPOINT))
      .then((res: AxiosResponse) => {
        if (res.status === 200)
          dispatch({
            type: MaterialsActionTypes.LOAD_MATERIALS_SUCCESS,
            payload: res.data.data,
          })
      })
      .catch((err: AxiosError) =>
        dispatch(handleLoadError(err, MaterialsActionTypes.LOAD_MATERIALS_ERROR)),
      )
  }

// Get Material Item   GET

export const getMaterialItem =
  (materialId: string | undefined) =>
  (dispatch: AppDispatch, getState: GetMaterialsStateFunctionType, api: AxiosInstance) => {
    dispatch(startDataLoading(MaterialsActionTypes.START_MATERIALS_LOADING))

    api
      .get(urlJoin(MATERIALS_ENDPOINT, materialId))
      .then((res: AxiosResponse) => {
        dispatch(loadMaterialItemSuccess(res.data.data[0]))
      })
      .catch((err: AxiosError) =>
        dispatch(handleLoadError(err, MaterialsActionTypes.LOAD_MATERIALS_ERROR)),
      )
  }

//  Edit Material Item    PUT

export const editMaterialItem =
  (materialId: string | undefined, data?: IMaterial) =>
  (dispatch: AppDispatch, getState: GetMaterialsStateFunctionType, api: AxiosInstance) => {
    dispatch(startDataLoading(MaterialsActionTypes.START_MATERIALS_LOADING))

    const baseLocation = getBaseLocation(window.location.pathname)

    api
      .put(urlJoin(MATERIALS_ENDPOINT, materialId), editObject(data))
      .then((res: AxiosResponse) => {
        if (res.status === 200) {
          dispatch(resetTemporaryIds())

          dispatch(deleteTemporaryTags())

          dispatch(loadMaterialItemSuccess(res.data.data[0]))

          if (baseLocation === CURRENT_LOCATION.MATERIALS) dispatch(getAllMaterials())

          sendNotification(
            MESSAGE_TYPES.SUCCESS,
            t(MATERIALS_SISTEM_NOTIFICATIONS.UPDATE_MATERIALS_DATA_SUCCESS),
          )
        }
      })
      .catch((err: AxiosError) =>
        dispatch(handleLoadError(err, MaterialsActionTypes.LOAD_MATERIALS_ERROR)),
      )
  }

// Create Materials   POST

export const createMaterialItem =
  (data: IMaterial, files?: RcFile[]) =>
  (dispatch: AppDispatch, getState: GetMaterialsStateFunctionType, api: AxiosInstance) => {
    dispatch(startDataLoading(MaterialsActionTypes.START_MATERIALS_LOADING))

    api
      .post(urlJoin(MATERIALS_ENDPOINT), createObject(data, files))
      .then((res: AxiosResponse) => {
        if (res.status === 201) {
          dispatch(setCreatedMaterialId(res.headers.location.split('/')[2]))

          dispatch(resetTemporaryIds())

          removeItemFromLocalStorage(CACHE_KEYS.CREATE_MATERIAL_FORM)

          dispatch({
            type: MaterialsActionTypes.CREATE_MATERIAL_ITEM_SUCCESS,
          })

          dispatch(loadMaterialItemSuccess(res.data.data[0]))

          sendNotification(
            MESSAGE_TYPES.SUCCESS,
            t(MATERIALS_SISTEM_NOTIFICATIONS.CREATE_MATERIALS_DATA_SUCCESS),
          )
        }
      })
      .catch((err: AxiosError) =>
        dispatch(handleLoadError(err, MaterialsActionTypes.LOAD_MATERIALS_ERROR)),
      )
  }

// Delete Materials Item   DELETE

export const deleteMaterialItem =
  (materialId: string | undefined) =>
  (dispatch: AppDispatch, getState: GetMaterialsStateFunctionType, api: AxiosInstance) => {
    dispatch(startDataLoading(MaterialsActionTypes.START_MATERIALS_LOADING))

    api
      .delete(urlJoin(MATERIALS_ENDPOINT, materialId))
      .then((res: AxiosResponse) => {
        if (res.status === 204) {
          dispatch(resetTemporaryIds())

          dispatch({
            type: MaterialsActionTypes.DELETE_MATERIAL_ITEM_SUCCESS,
            payload: Number(materialId),
          })

          sendNotification(
            MESSAGE_TYPES.SUCCESS,
            t(MATERIALS_SISTEM_NOTIFICATIONS.DELETE_MATERIALS_ITEM_SUCCESS),
          )
        }
      })
      .catch((err: AxiosError) =>
        dispatch(handleLoadError(err, MaterialsActionTypes.LOAD_MATERIALS_ERROR)),
      )
  }

// Link Material to Equipment PUT

export const linkMaterialsToEquipment =
  (materialId: number | undefined, equipmentId?: number, isLast?: boolean) =>
  (dispatch: AppDispatch, getState: GetMaterialsStateFunctionType, api: AxiosInstance) => {
    dispatch(startDataLoading(MaterialsActionTypes.START_MATERIALS_LOADING))

    api
      .put(urlJoin(MATERIALS_ENDPOINT, materialId.toString()), linkMaterials(equipmentId))
      .then((res: AxiosResponse) => {
        if (res.status === 200 && isLast) {
          dispatch(resetTemporaryMaterialIds())

          dispatch(getAllMaterials())

          sendNotification(
            MESSAGE_TYPES.SUCCESS,
            t(MATERIALS_SISTEM_NOTIFICATIONS.LINK_MATERIALS_ITEM_SUCCESS),
          )
        }
      })
      .catch((err: AxiosError) =>
        dispatch(handleLoadError(err, MaterialsActionTypes.LOAD_MATERIALS_ERROR)),
      )
  }

// Unlink Materials Item   PUT

export const deleteLinkedEquipmentItem =
  (materialId: string | undefined, deletedId: number) =>
  (dispatch: AppDispatch, getState: GetMaterialsStateFunctionType, api: AxiosInstance) => {
    dispatch(startDataLoading(MaterialsActionTypes.START_MATERIALS_LOADING))

    api
      .put(urlJoin(MATERIALS_ENDPOINT, materialId), unlinkMaterials(deletedId))
      .then((res: AxiosResponse) => {
        if (res.status === 200) {
          dispatch(getAllMaterials())

          sendNotification(
            MESSAGE_TYPES.SUCCESS,
            t(MATERIALS_SISTEM_NOTIFICATIONS.UNLINK_MATERIALS_ITEM_SUCCESS),
          )
        }
      })
      .catch((err: AxiosError) =>
        dispatch(handleLoadError(err, MaterialsActionTypes.LOAD_MATERIALS_ERROR)),
      )
  }

//  Edit Material History Item    PUT

export const editMaterialHistoryItem =
  (materialId: string, uuid: string, data: IMaterialHistory) =>
  (dispatch: AppDispatch, getState: GetMaterialsStateFunctionType, api: AxiosInstance) => {
    dispatch(startDataLoading(MaterialsActionTypes.START_MATERIALS_LOADING))

    api
      .put(urlJoin(HISTORY_ENDPOINT, HISTORY_MATERIALS_ENDPOINT, uuid), data, getJsonHeader())
      .then((res: AxiosResponse) => {
        if (res.status === 200) {
          dispatch({
            type: MaterialsActionTypes.EDIT_MATERIAL_HISTORY_ITEM_SUCCESS,
          })

          dispatch(getMaterialItemHistory(materialId))
          // dispatch(getMaterialItem(materialId))

          sendNotification(
            MESSAGE_TYPES.SUCCESS,
            t(HISTORY_SISTEM_NOTIFICATIONS.UPDATE_HISTORY_DATA_SUCCESS),
          )
        }
      })
      .catch((err: AxiosError) =>
        dispatch(handleLoadError(err, MaterialsActionTypes.LOAD_MATERIALS_ERROR)),
      )
  }

// Delete Material History Item   DELETE

export const deleteMaterialHistoryItem =
  (materialId: string | undefined, uuid: string) =>
  (dispatch: AppDispatch, getState: GetMaterialsStateFunctionType, api: AxiosInstance) => {
    dispatch(startDataLoading(MaterialsActionTypes.START_MATERIALS_LOADING))

    api
      .delete(urlJoin(HISTORY_ENDPOINT, HISTORY_MATERIALS_ENDPOINT, uuid))
      .then((res: AxiosResponse) => {
        if (res.status === 204) {
          dispatch({
            type: MaterialsActionTypes.DELETE_MATERIAL_HISTORY_ITEM_SUCCESS,
          })

          dispatch(getMaterialItemHistory(materialId))

          sendNotification(
            MESSAGE_TYPES.SUCCESS,
            t(HISTORY_SISTEM_NOTIFICATIONS.DELETE_HISTORY_ITEM_SUCCESS),
          )
        }
      })
      .catch((err: AxiosError) =>
        dispatch(handleLoadError(err, MaterialsActionTypes.LOAD_MATERIALS_ERROR)),
      )
  }
