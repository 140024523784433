import { AuthActionType, AuthActionTypes, AuthStateType } from '../actionTypes'

const initialState: AuthStateType = {
  isAuthLoading: false,
  isRefreshStarts: false,
  accessToken: null,
  status: null,
  authError: null,
  telegramBotError: null,
  isTgBotConnected: false,
}

export const auth = (state = initialState, action: AuthActionType): AuthStateType => {
  if (action) {
    switch (action.type) {
      case AuthActionTypes.START_AUTH_LOADING:
        return {
          ...state,
          isAuthLoading: true,
        }

      case AuthActionTypes.START_REFRESH:
        return {
          ...state,
          isRefreshStarts: true,
        }

      case AuthActionTypes.FINISH_REFRESH:
        return {
          ...state,
          isRefreshStarts: false,
        }

      case AuthActionTypes.LOGIN_SUCCESS:
        return {
          ...state,
          accessToken: action.payload.accessToken,
          status: action.payload.status,
          isAuthLoading: false,
          authError: null,
        }

      case AuthActionTypes.REFRESH_SUCCESS:
        return {
          ...state,
          accessToken: action.payload.accessToken,
          status: action.payload.status,
          isAuthLoading: false,
          authError: null,
          isRefreshStarts: false,
        }

      case AuthActionTypes.REGISTER_SUCCESS:
        return {
          ...state,
          status: action.payload.status,
          isAuthLoading: false,
          authError: null,
        }

      case AuthActionTypes.RESET_PASSWORD_SUCCESS:
        return {
          ...state,
          status: action.payload.status,
          isAuthLoading: false,
          authError: null,
        }

      case AuthActionTypes.SEND_EMAIL_SUCCESS:
        return {
          ...state,
          status: action.payload.status,
          isAuthLoading: false,
          authError: null,
        }

      case AuthActionTypes.LOGOUT:
        return {
          ...state,
          accessToken: null,
          isAuthLoading: false,
          authError: null,
        }

      case AuthActionTypes.SET_STATUS:
        return {
          ...state,
          status: action.payload,
        }

      case AuthActionTypes.SET_AUTH_ERROR:
        return {
          ...state,
          accessToken: null,
          authError: action.payload,
        }

      case AuthActionTypes.SET_TELEGRAM_BOT_ERROR:
        return {
          ...state,
          telegramBotError: action.payload,
        }

      case AuthActionTypes.SET_IS_TELEGRAM_BOT_CONNECTED:
        return {
          ...state,
          isTgBotConnected: action.payload,
        }

      default:
        return state
    }
  } else {
    return state
  }
}
