import GraphicEqRoundedIcon from '@mui/icons-material/GraphicEqRounded'
import HeadsetMicOutlinedIcon from '@mui/icons-material/HeadsetMicOutlined'
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined'
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined'
import { useTranslation } from 'react-i18next'
import { Col, Row } from 'antd'
import { FC, useEffect } from 'react'

import { AllColumnsType, FavoritesTable } from '../tables'
import { AllServicesType, ITask, TasksColumnsDataType } from '../../api'
import {
  CARD_HEIGHT,
  CARD_WIDTH,
  EQUIPMENT_URL,
  INIT,
  LOCATIONS_URL,
  MATERIALS_URL,
  METERS_URL,
  TASKS_URL,
  USER_SERVICES_URL,
} from '../../constants'
import { CardTitle, NutIcon, StyledCard } from '../common'
import { Color, Spacing } from '../../styles'
import {
  getAllEquipment,
  getAllMaterials,
  getAllMeters,
  getAllPlaces,
  getAllServices,
  getAllTasks,
} from '../../store/actions'
import {
  getEquipment,
  getMaterials,
  getMeters,
  getPlaces,
  getTasks,
  getUserServices,
  getWorkspaces,
} from '../../store/selectors'
import { useTypedDispatch, useTypedSelector } from '../../hooks'

export const DashboardFavoritesBlock: FC = () => {
  const { materials, isMaterialsLoading } = useTypedSelector(getMaterials)
  const { equipment, isEquipmentLoading } = useTypedSelector(getEquipment)
  const { places, isPlacesLoading } = useTypedSelector(getPlaces)
  const { meters, isMetersLoading } = useTypedSelector(getMeters)
  const { tasks, isTasksLoading } = useTypedSelector(getTasks)
  const { userServices, isServicesLoading } = useTypedSelector(getUserServices)
  const { currentWorkspace, defaultWorkspaceId } = useTypedSelector(getWorkspaces)

  const { t } = useTranslation()

  const dispatch = useTypedDispatch()

  const filterFavorites = (data: AllServicesType[]) => data?.filter((item) => item.isFavorite) || []

  const getData = (data: AllServicesType[]) =>
    data
      ? data
          .map<AllColumnsType>(({ id, name }: AllServicesType) => ({
            key: id,
            name,
          }))
          .sort((a: AllColumnsType, b: AllColumnsType) =>
            a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1,
          )
      : []

  const getTasksData = (data: ITask[]) =>
    data
      ?.map<ITask>(({ id, title }) => ({
        key: id,
        title,
      }))
      .sort((a: TasksColumnsDataType, b: TasksColumnsDataType) =>
        a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1,
      ) || []

  useEffect(() => {
    if (currentWorkspace || defaultWorkspaceId) {
      dispatch(getAllMaterials(INIT))
      dispatch(getAllPlaces(INIT))
      dispatch(getAllEquipment(INIT))
      dispatch(getAllMeters(INIT))
      dispatch(getAllTasks(INIT))
      dispatch(getAllServices(INIT))
    }
  }, [dispatch, currentWorkspace, defaultWorkspaceId])

  return (
    <Row gutter={[20, 20]}>
      <Col xs={8}>
        {!isEquipmentLoading && (
          <StyledCard
            title={
              <CardTitle $iconColor={Color.black} icon={<SettingsOutlinedIcon />}>
                {t('equipmemtPageTitle')}
              </CardTitle>
            }
            $width={CARD_WIDTH.WIDTH_100_PER}
            $height={CARD_HEIGHT.HEIGHT_300}
            $pt={Spacing.sm2}
          >
            <FavoritesTable data={getData(filterFavorites(equipment))} pathname={EQUIPMENT_URL} />
          </StyledCard>
        )}
      </Col>

      <Col xs={8}>
        {!isMaterialsLoading && (
          <StyledCard
            title={<CardTitle icon={<NutIcon />}>{t('materials')}</CardTitle>}
            $width={CARD_WIDTH.WIDTH_100_PER}
            $height={CARD_HEIGHT.HEIGHT_300}
            $pt={Spacing.sm2}
          >
            <FavoritesTable data={getData(filterFavorites(materials))} pathname={MATERIALS_URL} />
          </StyledCard>
        )}
      </Col>

      <Col xs={8}>
        {!isMetersLoading && (
          <StyledCard
            title={
              <CardTitle $iconColor={Color.black} icon={<GraphicEqRoundedIcon />}>
                {t('meters')}
              </CardTitle>
            }
            $width={CARD_WIDTH.WIDTH_100_PER}
            $height={CARD_HEIGHT.HEIGHT_300}
            $pt={Spacing.sm2}
          >
            <FavoritesTable data={getData(filterFavorites(meters))} pathname={METERS_URL} />
          </StyledCard>
        )}
      </Col>

      <Col xs={8}>
        {!isPlacesLoading && (
          <StyledCard
            title={
              <CardTitle $iconColor={Color.black} icon={<LocationOnOutlinedIcon />}>
                {t('placesPageTitle')}
              </CardTitle>
            }
            $width={CARD_WIDTH.WIDTH_100_PER}
            $height={CARD_HEIGHT.HEIGHT_300}
            $pt={Spacing.sm2}
          >
            <FavoritesTable data={getData(filterFavorites(places))} pathname={LOCATIONS_URL} />
          </StyledCard>
        )}
      </Col>

      <Col xs={8}>
        {!isTasksLoading && (
          <StyledCard
            title={
              <CardTitle $iconColor={Color.black} icon={<HeadsetMicOutlinedIcon />}>
                {t('tasks')}
              </CardTitle>
            }
            $width={CARD_WIDTH.WIDTH_100_PER}
            $height={CARD_HEIGHT.HEIGHT_300}
            $pt={Spacing.sm2}
          >
            <FavoritesTable
              data={getTasksData(filterFavorites(tasks))}
              $isTasks
              pathname={TASKS_URL}
            />
          </StyledCard>
        )}
      </Col>

      <Col xs={8}>
        {!isServicesLoading && (
          <StyledCard
            title={
              <CardTitle $iconColor={Color.black} icon={<PeopleAltOutlinedIcon />}>
                {t('servicesPageTitle')}
              </CardTitle>
            }
            $width={CARD_WIDTH.WIDTH_100_PER}
            $height={CARD_HEIGHT.HEIGHT_300}
            $pt={Spacing.sm2}
          >
            <FavoritesTable
              data={getData(filterFavorites(userServices))}
              pathname={USER_SERVICES_URL}
            />
          </StyledCard>
        )}
      </Col>
    </Row>
  )
}
