import { RcFile } from 'antd/lib/upload'
import { t } from 'i18next'
import urlJoin from 'url-join'
import { AxiosError, AxiosInstance, AxiosResponse } from 'axios'

import { AppDispatch } from '../../hooks'
import { CompanyActionTypes } from '../actionTypes'
import { handleLoadError } from './errors'
import { RootState } from '../reducers/rootReducer'
import { sendNotification } from '../../components/common/notification'
import { startDataLoading } from './utils'
import { transformKeysToCamelCase } from '../../api/converters'
import { updateCompanyPreviewImage } from '../../api/shortcuts'
import { COMPANIES_ENDPOINT, COMPANY_ENDPOINT } from '../../env'
import {
  COMPANY_SISTEM_NOTIFICATIONS,
  JSON_HEADERS,
  MESSAGE_TYPES,
  STATUS_CODES,
} from '../../constants'
import { ICompany, ICompanyImageInfo } from '../../api'

import { deleteTemporaryImages } from './temporaryData'

export type GetCompanyStateFunctionType = () => RootState

// GET   Get Company Image info

export const getCompanyPreviewInfo =
  (companyId: number) =>
  (dispatch: AppDispatch, getState: GetCompanyStateFunctionType, api: AxiosInstance) => {
    dispatch(startDataLoading(CompanyActionTypes.START_COMPANIES_LOADING))

    api
      .get(urlJoin(COMPANY_ENDPOINT, companyId?.toString(), 'logo', 'upload-url'))
      .then((res: AxiosResponse) => {
        if (res.status === 200) {
          dispatch({
            type: CompanyActionTypes.GET_COMPANY_IMAGE_INFO_SUCCESS,
            payload: res.data,
          })
        }
      })
      .catch((err: AxiosError) =>
        dispatch(handleLoadError(err, CompanyActionTypes.SET_LOAD_COMPANIES_ERROR)),
      )
  }

// GET   Get Company Image info

export const getCompanyPreviewDeleteUrl =
  (companyId: number) =>
  (dispatch: AppDispatch, getState: GetCompanyStateFunctionType, api: AxiosInstance) => {
    dispatch(startDataLoading(CompanyActionTypes.START_COMPANIES_LOADING))

    api
      .get(urlJoin(COMPANY_ENDPOINT, companyId?.toString(), 'logo', 'delete-url'))
      .then((res: AxiosResponse) => {
        if (res.status === 200) {
          dispatch({
            type: CompanyActionTypes.GET_COMPANY_DELETE_IMAGE_INFO_SUCCESS,
            payload: res.data?.url,
          })
        }
      })
      .catch((err: AxiosError) =>
        dispatch(handleLoadError(err, CompanyActionTypes.SET_LOAD_COMPANIES_ERROR)),
      )
  }

// Get Companies

export const getAllCompanies =
  () => (dispatch: AppDispatch, getState: GetCompanyStateFunctionType, api: AxiosInstance) => {
    dispatch(startDataLoading(CompanyActionTypes.START_COMPANIES_LOADING))

    api
      .get(urlJoin(COMPANIES_ENDPOINT))
      .then((res: AxiosResponse) => {
        if (res.status === 200) {
          dispatch({
            type: CompanyActionTypes.GET_COMPANIES_SUCCESS,
            payload: transformKeysToCamelCase(res.data),
          })
        }
      })
      .catch((err: AxiosError) =>
        dispatch(handleLoadError(err, CompanyActionTypes.SET_LOAD_COMPANIES_ERROR)),
      )
  }

// Get Current Company

export const getCurrentCompany =
  (companyId?: number) =>
  (dispatch: AppDispatch, getState: GetCompanyStateFunctionType, api: AxiosInstance) => {
    dispatch(startDataLoading(CompanyActionTypes.START_COMPANIES_LOADING))

    api
      .get(urlJoin(COMPANY_ENDPOINT, companyId?.toString()))
      .then((res: AxiosResponse) => {
        if (res.status === 200) {
          dispatch({
            type: CompanyActionTypes.GET_CURRENT_COMPANY_SUCCESS,
            payload: transformKeysToCamelCase(res.data),
          })
        }
      })
      .catch((err: AxiosError) =>
        dispatch(handleLoadError(err, CompanyActionTypes.SET_LOAD_COMPANIES_ERROR)),
      )
  }

// PATCH   Edit Company

export const editCompany =
  (companyId: number, data: ICompany) =>
  (dispatch: AppDispatch, getState: GetCompanyStateFunctionType, api: AxiosInstance) => {
    dispatch(startDataLoading(CompanyActionTypes.START_COMPANIES_LOADING))

    api
      .patch(urlJoin(COMPANY_ENDPOINT, companyId.toString()), data, JSON_HEADERS)
      .then((res: AxiosResponse) => {
        if (res.status === STATUS_CODES.CODE_200) {
          dispatch({
            type: CompanyActionTypes.GET_CURRENT_COMPANY_SUCCESS,
            payload: transformKeysToCamelCase(res.data),
          })

          sendNotification(
            MESSAGE_TYPES.SUCCESS,
            t(COMPANY_SISTEM_NOTIFICATIONS.EDIT_COMPANY_SUCCESS),
          )

          dispatch(getCurrentCompany(companyId))
        }

        if (res.status === STATUS_CODES.CODE_404) {
          sendNotification(
            MESSAGE_TYPES.SUCCESS,
            t(COMPANY_SISTEM_NOTIFICATIONS.COMPANY_NOT_FOUND_ERROR),
          )
        }
      })
      .catch((err: AxiosError) =>
        dispatch(handleLoadError(err, CompanyActionTypes.SET_LOAD_COMPANIES_ERROR)),
      )
  }

// POST   Update Company Image info

export const updateCompanyPreview =
  (companyId: number, data: ICompanyImageInfo, file: RcFile[], url: string) =>
  (dispatch: AppDispatch, getState: GetCompanyStateFunctionType, api: AxiosInstance) => {
    dispatch(startDataLoading(CompanyActionTypes.START_COMPANIES_LOADING))

    const values = updateCompanyPreviewImage(data, file)

    api
      .post(urlJoin(url), values)
      .then((res: AxiosResponse) => {
        if (res.status) {
          sendNotification(
            MESSAGE_TYPES.SUCCESS,
            t(COMPANY_SISTEM_NOTIFICATIONS.UPDATE_COMPANY_IMAGE_SUCCESS),
          )

          dispatch(getCurrentCompany(companyId))
          dispatch(deleteTemporaryImages())
        }
      })
      .catch((err: AxiosError) =>
        dispatch(handleLoadError(err, CompanyActionTypes.SET_LOAD_COMPANIES_ERROR)),
      )
  }

// Delete   Delete Company Image info

export const deleteCompanyPreview =
  (companyId: number, url: string) =>
  (dispatch: AppDispatch, getState: GetCompanyStateFunctionType, api: AxiosInstance) => {
    dispatch(startDataLoading(CompanyActionTypes.START_COMPANIES_LOADING))

    api
      .delete(url)
      .then((res: AxiosResponse) => {
        if (res.status) {
          sendNotification(
            MESSAGE_TYPES.SUCCESS,
            t(COMPANY_SISTEM_NOTIFICATIONS.DELETE_COMPANY_IMAGE_SUCCESS),
          )

          dispatch(getCurrentCompany(companyId))

          dispatch(getCompanyPreviewInfo(companyId))
        }
      })
      .catch((err: AxiosError) =>
        dispatch(handleLoadError(err, CompanyActionTypes.SET_LOAD_COMPANIES_ERROR)),
      )
  }
