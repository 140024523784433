import { FC } from 'react'
import { Link } from 'react-router-dom'
import { Tooltip } from 'antd'
import { useTranslation } from 'react-i18next'

import { StyledUpdateOutlinedIcon } from './styled'

type StyledUpdateOutlinedIconProps = {
  link?: string
  $color?: string
  $isHeaderIcon?: boolean
  isPlanned?: boolean
}

export const TaskRulesIcon: FC<StyledUpdateOutlinedIconProps> = ({
  link,
  $color,
  $isHeaderIcon,
  isPlanned,
}) => {
  const { t } = useTranslation()

  const tooltipText = isPlanned ? t('task-planed-icon-prompt') : t('task-no-planed-icon-prompt')

  if (!$isHeaderIcon && link)
    return (
      <Tooltip title={tooltipText} placement="bottom">
        <Link to={link}>
          <StyledUpdateOutlinedIcon $cursor $color={$color} />
        </Link>
      </Tooltip>
    )

  if ($isHeaderIcon)
    return <StyledUpdateOutlinedIcon $color={$color} className="table-header-icon" />

  return (
    <Tooltip title={tooltipText} placement="bottom">
      <StyledUpdateOutlinedIcon $color={$color} />
    </Tooltip>
  )
}
