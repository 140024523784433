import { FC } from 'react'
import { Divider, Menu, Typography } from 'antd'

import { ActionButtonPropsType } from '../../../api'
import { StyledActionButton, StyledCloseButton } from '../../common'

const { Text } = Typography
const { Item } = Menu

export type UserActionsPropsType = {
  actions: ActionButtonPropsType[]
  extraActions?: ActionButtonPropsType[]
  onPopoverClose: () => void
}

export const UserActions: FC<UserActionsPropsType> = ({
  actions,
  extraActions,
  onPopoverClose,
}) => {
  return (
    <>
      <Menu mode="inline">
        {extraActions
          ? extraActions.map(({ key, text, icon, extraIcon, onClick }, idx) => (
              <StyledActionButton
                key={key}
                icon={icon}
                onClick={() => {
                  onClick()
                  onPopoverClose()
                }}
                $mt={idx === 0 ? '12px' : '0px'}
                $height="52px"
              >
                {extraIcon && extraIcon}
                {text}
              </StyledActionButton>
            ))
          : null}

        {extraActions ? (
          <Item key="divider" className="divider" disabled>
            <Divider />
          </Item>
        ) : null}

        {actions.map(({ key, text, icon, onClick }) => (
          <StyledActionButton
            key={key}
            icon={icon}
            onClick={() => {
              onClick()
              onPopoverClose()
            }}
          >
            <Text style={{ color: 'inherit' }}>{text}</Text>
          </StyledActionButton>
        ))}
      </Menu>

      <StyledCloseButton onClick={onPopoverClose} />
    </>
  )
}
