import { TasksActionType, TasksActionTypes, TasksStateType } from '../actionTypes'

const initialState: TasksStateType = {
  tasks: undefined,
  statuses: undefined,
  taskItem: undefined,
  createdId: undefined,
  history: null,
  priorities: null,
  taskRule: null,

  isTasksLoading: false,
  isTaskItemLoading: false,
  isTaskHistoryLoading: false,
  isTaskStatusesLoading: false,
  isTaskPrioritiesLoading: false,
  isTaskRulesLoading: false,

  tasksError: null,
  taskItemError: null,
  taskStatusesError: null,
  taskHistoryError: null,
  taskPrioritiesError: null,
  taskRulesError: null,
}

export const tasks = (state = initialState, action: TasksActionType): TasksStateType => {
  if (action) {
    switch (action.type) {
      case TasksActionTypes.START_TASKS_LOADING:
        return { ...state, isTasksLoading: true }

      case TasksActionTypes.START_TASK_ITEM_LOADING:
        return { ...state, isTaskItemLoading: true }

      case TasksActionTypes.START_TASK_STATUSES_LOADING:
        return { ...state, isTaskStatusesLoading: true }

      case TasksActionTypes.START_TASK_HISTORY_LOADING:
        return { ...state, isTaskHistoryLoading: true }

      case TasksActionTypes.START_TASK_PRIORITIES_LOADING:
        return { ...state, isTaskHistoryLoading: true }

      case TasksActionTypes.START_TASK_RULES_LOADING:
        return { ...state, isTaskRulesLoading: true }

      case TasksActionTypes.LOAD_TASKS_SUCCESS:
        return {
          ...state,
          isTasksLoading: false,
          tasksError: null,
          tasks: action.payload,
        }

      case TasksActionTypes.LOAD_TASK_ITEM_SUCCESS:
        return {
          ...state,
          isTaskItemLoading: false,
          taskItemError: null,
          taskItem: action.payload,
        }

      case TasksActionTypes.LOAD_TASK_STATUSES_SUCCESS:
        return {
          ...state,
          isTaskStatusesLoading: false,
          taskStatusesError: null,
          statuses: action.payload,
        }

      case TasksActionTypes.LOAD_TASK_HISTORY_SUCCESS:
        return {
          ...state,
          isTaskHistoryLoading: false,
          taskHistoryError: null,
          history: action.payload,
        }

      case TasksActionTypes.LOAD_TASK_PRIORITIES_SUCCESS:
        return {
          ...state,
          isTaskPrioritiesLoading: false,
          taskPrioritiesError: null,
          priorities: action.payload,
        }

      case TasksActionTypes.LOAD_TASK_RULES_SUCCESS:
        return {
          ...state,
          isTaskRulesLoading: false,
          taskRulesError: null,
          taskRule: action.payload,
        }

      case TasksActionTypes.UPDATE_TASK_RULES_SUCCESS:
        return {
          ...state,
          isTaskRulesLoading: false,
          taskRulesError: null,
          taskRule: action.payload,
        }

      case TasksActionTypes.DELETE_TASK_ITEM_SUCCESS:
        return {
          ...state,
          tasks: state.tasks ? state.tasks.filter((i) => i.id !== action.payload) : undefined,
        }

      case TasksActionTypes.DELETE_TASK_RULE_SUCCESS:
        return {
          ...state,
          taskRule: null,
          isTaskRulesLoading: false,
        }

      case TasksActionTypes.DELETE_TASK_ITEM_FROM_STATE:
        return { ...state, taskItem: undefined }

      case TasksActionTypes.SET_CREATED_TASK_ID:
        return { ...state, createdId: action.payload }

      case TasksActionTypes.CREATE_TASK_ITEM_SUCCESS:
        return { ...state, isTaskItemLoading: false, taskItemError: null }

      case TasksActionTypes.LOAD_TASKS_ERROR:
        return { ...state, isTasksLoading: false, tasksError: action.payload }

      case TasksActionTypes.LOAD_TASK_ITEM_ERROR:
        return { ...state, isTaskItemLoading: false, taskItemError: action.payload }

      case TasksActionTypes.LOAD_TASK_STATUSES_ERROR:
        return { ...state, isTaskStatusesLoading: false, taskStatusesError: action.payload }

      case TasksActionTypes.LOAD_TASK_PRIORITIES_ERROR:
        return { ...state, isTaskPrioritiesLoading: false, taskPrioritiesError: action.payload }

      case TasksActionTypes.LOAD_TASK_HISTORY_ERROR:
        return { ...state, isTaskHistoryLoading: false, taskHistoryError: action.payload }

      case TasksActionTypes.LOAD_TASK_RULES_ERROR:
        return { ...state, isTaskRulesLoading: false, taskRulesError: action.payload }

      default:
        return state
    }
  } else {
    return state
  }
}
