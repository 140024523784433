import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined'
import { useTranslation } from 'react-i18next'
import { FC, memo, ReactNode, useState } from 'react'

import { SelectTableModal } from '../tables'
import { sendNotification } from '../common/notification'
import { setSearchValue } from '../../store/actions'

import { CardTitle, PlannyButton, StyledSmallPanelCard } from '../common'
import { Color, Spacing } from '../../styles'
import {
  COMMON_SISTEM_NOTIFICATIONS,
  DATA_TYPE,
  EMPTY_STRING,
  MESSAGE_TYPES,
} from '../../constants'
import { getPlaces, getTemporaryData } from '../../store/selectors'
import { useTypedDispatch, useTypedSelector } from '../../hooks'

type PlacesPanelPropsType = {
  modalPrompt: string
  buttonText: string
  table: ReactNode
  cardContent: ReactNode
  isSubmitButtonDisabled?: boolean
}

export const PlacesPanel: FC<PlacesPanelPropsType> = memo(
  ({ modalPrompt, buttonText, table, cardContent, isSubmitButtonDisabled = false }) => {
    const [isModalVisible, setIsModalVisible] = useState(false)

    const { tempIds } = useTypedSelector(getTemporaryData)
    const { places } = useTypedSelector(getPlaces)

    const dispatch = useTypedDispatch()

    const { t } = useTranslation()

    const showModal = () => setIsModalVisible(true)

    const resetSearchValue = () => {
      dispatch(setSearchValue(DATA_TYPE.PLACES, EMPTY_STRING))
    }

    const handleCancel = () => {
      setIsModalVisible(false)
      resetSearchValue()
    }

    const handleSave = () => {
      setIsModalVisible(false)
      resetSearchValue()

      if (tempIds.places !== undefined)
        sendNotification(
          MESSAGE_TYPES.SUCCESS,
          t(COMMON_SISTEM_NOTIFICATIONS.SAVE_TEMPORARY_DATA_SUCCESS),
        )
    }

    return (
      <>
        <SelectTableModal
          title={t('place')}
          subTitle={modalPrompt}
          dataType={DATA_TYPE.PLACES}
          onCancel={handleCancel}
          handleSave={handleSave}
          icon={<LocationOnOutlinedIcon />}
          table={table}
          visible={isModalVisible}
          isSubmitButtonDisabled={isSubmitButtonDisabled}
        />
        <StyledSmallPanelCard
          title={
            <CardTitle
              icon={<LocationOnOutlinedIcon />}
              $iconColor={Color.gray}
              $mbIcon={Spacing.sm6}
            >
              {t('place')}
            </CardTitle>
          }
          actions={[
            <PlannyButton
              type="primary"
              ghost
              onClick={showModal}
              $marginBottom={Spacing.sm8}
              disabled={!places || (places && !places.length)}
            >
              {buttonText}
            </PlannyButton>,
          ]}
          bordered={false}
        >
          {cardContent || null}
        </StyledSmallPanelCard>
      </>
    )
  },
)
