import { Pie } from '@ant-design/plots'
import { t as translate } from 'i18next'
import { useTranslation } from 'react-i18next'
import { FC, memo, useEffect } from 'react'

import { Color } from '../../styles'
import { PieDataType } from '../../api'
import { getAllServices, getServiceStatuses } from '../../store/actions'
import { getUserServices, getWorkspaces } from '../../store/selectors'
import { INIT, USER_SERVICES_STATUSES_CODES } from '../../constants'
import { STATUSES_COLOR_MAP, UserServicesStatusesType } from '../common'
import { useTypedDispatch, useTypedSelector } from '../../hooks'

import {
  getCommonStatisticsPieConfig,
  getInteractions,
  getStatisticsPieContentOptions,
  getStatisticsPieTitleOptions,
  renderStatistics,
} from '../../statisticsPieCommonOptions'

const getDefaultColors = () => [Color.green, Color.pink]

const getEmptyStatistics = () => [
  { type: translate(USER_SERVICES_STATUSES_CODES.PRESENT), value: 0 },
  { type: translate(USER_SERVICES_STATUSES_CODES.ABSENT), value: 0 },
]

export type UserServicesStatusesPiePropsType = {
  multiplier: number
}

export const UserServicesStatusesPie: FC<UserServicesStatusesPiePropsType> = memo(
  ({ multiplier }) => {
    const { userServices, statuses } = useTypedSelector(getUserServices)
    const { currentWorkspace, defaultWorkspaceId } = useTypedSelector(getWorkspaces)

    const { t } = useTranslation()

    const dispatch = useTypedDispatch()

    const colors: string[] = statuses?.length && userServices?.length ? [] : getDefaultColors()

    const getPersonal = (statusId: number) =>
      userServices ? userServices.filter((item) => item.statusId === statusId) : []

    const getStatusesData = (): PieDataType[] => {
      if (statuses?.length && userServices?.length) {
        return statuses
          .filter((i) => i.id !== 3)
          .map(({ id, code }) => {
            if (code)
              colors.push(
                STATUSES_COLOR_MAP.userServices[code as keyof UserServicesStatusesType].brightColor,
              )

            return {
              type: t(code),
              value: getPersonal(id).length || null,
            }
          })
      }

      return []
    }

    const data = getStatusesData().length ? getStatusesData() : getEmptyStatistics()

    const config = {
      ...getCommonStatisticsPieConfig(t('short-items'), '{value}'),

      data,
      color: colors,
      width: 220 * multiplier,
      height: 220,

      statistic: {
        title: {
          ...getStatisticsPieTitleOptions('16px', -4),
          customHtml: (container: HTMLElement, view: any, datum: any) => {
            const { width, height } = container.getBoundingClientRect()

            const d = Math.sqrt((width / 1.2) ** 2 + (height / 1.2) ** 2)

            const text = datum ? datum.type : t('all-items')

            return renderStatistics(d, text, {
              fontSize: 16,
            })
          },
        },
        content: {
          ...getStatisticsPieContentOptions('20px', 4),
          customHtml: (container: HTMLElement, view: any, datum: any, items: any) => {
            const { width } = container.getBoundingClientRect()

            const text = datum
              ? `${datum.value} ${t('short-items')}`
              : `${items.reduce((r: number, d: PieDataType) => r + d.value, 0)} ${t('short-items')}`

            return renderStatistics(width, text, {
              fontSize: 20,
            })
          },
        },
      },

      interactions: getInteractions(),
    }

    useEffect(() => {
      if (currentWorkspace || defaultWorkspaceId) {
        dispatch(getServiceStatuses())
        dispatch(getAllServices(INIT))
      }
    }, [dispatch, currentWorkspace, defaultWorkspaceId])
    /* eslint-disable */

    return <Pie {...config} />
  },
)
