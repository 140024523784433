export const PLANNY_HELP_LINK = 'https://planny24.ru/help'
export const PLANNY_SUPPORT_LINK = 'mailto:support@planny24.ru'
export const YANDEX_MAPS_LINK = 'https://maps.yandex.ru/'
export const GOOGLE_MAPS_LINK = 'https://maps.google.com/'
export const SEA_PROJECT_LINK = 'https://www.seaproject.ru'
export const PLANNY_24_RU_LINK = 'https://planny24.ru'
export const TELEGRAM_BOT_LINK = 'https://t.me/Planny24bot'

// Planny base urls
export const HOME_URL = '/'
export const DASHBOARD_URL = '/dashboard'
export const NOTIFICATIONS_URL = '/notifications'
export const TASKS_URL = '/tasks'
export const INSPECTIONS_URL = '/inspections'
export const CALENDAR_URL = '/calendar'
export const ANALITICS_URL = '/analitics'
export const PREVENTIVE_MAINTENANCE_URL = '/preventive-maintenance'
export const LOCATIONS_URL = '/places'
export const EQUIPMENT_URL = '/equipment'
export const METERS_URL = '/meters'
export const MATERIALS_URL = '/materials'
export const USER_SERVICES_URL = '/user-services'
export const ORDERS_URL = '/orders'
export const ORGANIZATIONS_URL = '/organizations'
export const FILES_URL = '/files'
export const ASSETS_ARCHIVE_URL = '/assets-archive'
export const AUTHENTICATION_PAGE_URL = '/authentication'
export const FEEDBACK_URL = '/feedback'
export const CLIENTS_URL = '/clients'
export const NOT_FOUND_URL = '/not-found'
export const ACCOUNT_DELETION_SUCCESS_URL = '/account-deletion/success'
export const INTEGRATION_TELEGRAM_URL = '/integration/telegram'

// Auth

export const AUTH = '/auth'
export const FORGOT_PASSWORD = '/forgot-password'
export const LOGIN = '/login'
export const REGISTER = '/register'
export const LOGOUT = '/logout'
export const RESET_PASSWORD = '/reset-password'
export const SUCCESS_VERIFICATION_EMAIL = '/auth/verification/success'
export const USERS = '/users'
export const CHANGE_PASSWORD = '/change-password'
export const OWNER = '/owner'

// URL Params

export const EQUIPMENT_ID = 'equipmentId'
export const PLACE_ID = 'locationId'
export const MATERIAL_ID = 'materialId'
export const METER_ID = 'meterId'
export const USER_ID = 'userId'
export const TASK_ID = 'taskId'
export const SERVICE_ID = 'serviceId'
export const SCOPE_ID = 'scopeId'
export const PPW_ID = 'ppwGraphicsId'
export const CLIENT_ID = 'clientId'

//  Url Parts

export const CREATE = 'create'

export const PUBLIC_ROUTES = [
  'login',
  'register',
  'forgot-password',
  'reset-password',
  'account-deletion',
  'authentication',
  'not-found',
  'auth',
]

export const NO_REFRESH_ROUTES = PUBLIC_ROUTES.filter((i) => i !== 'authentication')
