import { CloseOutlined } from '@ant-design/icons'
import { FC } from 'react'

import { BUTTON_TYPE } from '../../../constants'

import { PlannyButton } from '../styled'

export type CancelRedOutlinedButtonPropType = {
  onClick?: () => void
  type?: BUTTON_TYPE.BUTTON | BUTTON_TYPE.RESET
  disabled?: boolean
}

export const CancelRedOutlinedButton: FC<CancelRedOutlinedButtonPropType> = ({
  children,
  onClick,
  type,
  disabled,
}) => {
  return (
    <PlannyButton
      htmlType={type || BUTTON_TYPE.RESET}
      type="primary"
      danger
      ghost
      disabled={disabled}
      icon={<CloseOutlined />}
      onClick={onClick}
    >
      {children}
    </PlannyButton>
  )
}
