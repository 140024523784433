export enum API_ERROR_DETAILS {
  BAD_REFRESH_TOKEN = 'REFRESH_TOKENS_BAD_TOKEN',
  BAD_SESSION_ID = 'BAD_SESSION_ID',
  SESSION_DOES_NOT_EXIST = 'SESSION_DOES_NOT_EXIST',
  LOGIN_USER_NOT_VERIFIED = 'LOGIN_USER_NOT_VERIFIED',
  REGISTER_USER_ALREADY_EXISTS = 'REGISTER_USER_ALREADY_EXISTS',
  LOGIN_BAD_CREDENTIALS = 'LOGIN_BAD_CREDENTIALS',
  RESET_PASSWORD_BAD_TOKEN = 'RESET_PASSWORD_BAD_TOKEN',
  TOO_MANY_LOGIN_ATTEMPTS = 'TOO_MANY_LOGIN_ATTEMPTS',
  UPDATE_USER_EMAIL_ALREADY_EXISTS = 'UPDATE_USER_EMAIL_ALREADY_EXISTS',
  JWT_VERIFICATION_FAILED = 'Unauthorized: JWT verification failed',
  INVALID_AUTORIZATION_HEADER = 'Unauthorized: `Authorization` header is invalid',
  UNAUTHORIZED_USER_ERROR = 'Unauthorized',
  EXPIRED_TOKEN = '"exp" claim timestamp check failed',
  INVALID_TOKEN = 'INVALID_TOKEN',
  MISSING_TOKEN = 'Unauthorized: `Authorization` header is missing',
}

export enum STATUS_CODES {
  CODE_200 = 200,
  CODE_201 = 201,
  CODE_202 = 202,
  CODE_204 = 204,
  CODE_400 = 400,
  CODE_401 = 401,
  CODE_403 = 403,
  CODE_404 = 404,
  CODE_409 = 409,
  CODE_413 = 413,
  CODE_424 = 424,
  CODE_429 = 429,
  CODE_500 = 500,
  CODE_422 = 422,
  ECONNABORTED = 'ECONNABORTED',
}
