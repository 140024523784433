import { AxiosError } from 'axios'
import { IMeasurementUnits, IMeter, IMeterHistory, IMeterMetaHistory } from '../../api'

export type MetersStateType = {
  meters: IMeter[]
  meterItem: IMeter | undefined
  measurementUnits: IMeasurementUnits[] | null
  readingPeriods: IMeasurementUnits[] | null
  createdId: string | undefined
  history: IMeterHistory[] | null
  metaHistory: IMeterMetaHistory
  isMetersLoading: boolean
  metersError: AxiosError | null
}

export enum MetersActionTypes {
  START_METERS_LOADING = 'START_METERS_LOADING',
  LOAD_METERS_SUCCESS = 'LOAD_METERS_SUCCESS',
  LOAD_METER_ITEM_SUCCESS = 'LOAD_METER_ITEM_SUCCESS',
  LOAD_METERS_ERROR = 'LOAD_METERS_ERROR',
  LOAD_METER_READING_PERIOD_SUCCESS = 'LOAD_METER_READING_PERIOD_SUCCESS',
  LOAD_METER_MEASUREMENT_UNITS_SUCCESS = 'LOAD_METER_MEASUREMENT_UNITS_SUCCESS',
  LOAD_METER_HISTORY_SUCCESS = 'LOAD_METER_HISTORY_SUCCESS',
  LOAD_METER_META_HISTORY_SUCCESS = 'LOAD_METER_META_HISTORY_SUCCESS',
  CREATE_METER_ITEM_SUCCESS = 'CREATE_METER_ITEM_SUCCESS',
  EDIT_METER_HISTORY_ITEM_SUCCESS = 'EDIT_METER_HISTORY_ITEM_SUCCESS',
  DELETE_METER_HISTORY_ITEM_SUCCESS = 'DELETE_METER_HISTORY_ITEM_SUCCESS',
  DELETE_METER_ITEM_SUCCESS = 'DELETE_METER_ITEM_SUCCESS',
  SAVE_TEMPORARY_METER_TAGS = 'SAVE_TEMPORARY_METER_TAGS',
  DELETE_METER_ITEM_FROM_STATE = 'DELETE_METER_ITEM_FROM_STATE',
  SET_CREATED_METER_ID = 'SET_CREATED_METER_ID',
}

export type StartLoadingMetersActionType = {
  type: MetersActionTypes.START_METERS_LOADING
}

export type LoadMetersSuccessActionType = {
  type: MetersActionTypes.LOAD_METERS_SUCCESS
  payload: IMeter[]
}

export type LoadMeterItemSuccessActionType = {
  type: MetersActionTypes.LOAD_METER_ITEM_SUCCESS
  payload: IMeter
}

export type LoadMeterHistorySuccessActionType = {
  type: MetersActionTypes.LOAD_METER_HISTORY_SUCCESS
  payload: IMeterHistory[]
}

export type LoadMeterMetaHistorySuccessActionType = {
  type: MetersActionTypes.LOAD_METER_META_HISTORY_SUCCESS
  payload: IMeterMetaHistory
}

export type LoadingErrorMetersActionType = {
  type: MetersActionTypes.LOAD_METERS_ERROR
  payload: AxiosError
}

export type LoadMeterMeasurementUnitsSuccessActionType = {
  type: MetersActionTypes.LOAD_METER_MEASUREMENT_UNITS_SUCCESS
  payload: IMeasurementUnits[]
}

export type LoadMeterReadingPeriodSuccessActionType = {
  type: MetersActionTypes.LOAD_METER_READING_PERIOD_SUCCESS
  payload: IMeasurementUnits[]
}

export type CreateMeterItemSuccessActionType = {
  type: MetersActionTypes.CREATE_METER_ITEM_SUCCESS
}

export type DeleteMeterItemSuccessActionType = {
  type: MetersActionTypes.DELETE_METER_ITEM_SUCCESS
  payload: number
}

export type DeleteMeterItemFromStateActionType = {
  type: MetersActionTypes.DELETE_METER_ITEM_FROM_STATE
}

export type SetCreatedMeterIdActionType = {
  type: MetersActionTypes.SET_CREATED_METER_ID
  payload: string | undefined
}

export type EditMeterHistoryItemSuccessActionType = {
  type: MetersActionTypes.EDIT_METER_HISTORY_ITEM_SUCCESS
}

export type DeleteMeterHistoryItemSuccessActionType = {
  type: MetersActionTypes.DELETE_METER_HISTORY_ITEM_SUCCESS
}

export type MetersActionType =
  | StartLoadingMetersActionType
  | LoadMetersSuccessActionType
  | LoadingErrorMetersActionType
  | CreateMeterItemSuccessActionType
  | DeleteMeterItemSuccessActionType
  | LoadMeterItemSuccessActionType
  | LoadMeterHistorySuccessActionType
  | LoadMeterMetaHistorySuccessActionType
  | LoadMeterMeasurementUnitsSuccessActionType
  | LoadMeterReadingPeriodSuccessActionType
  | DeleteMeterItemFromStateActionType
  | SetCreatedMeterIdActionType
  | EditMeterHistoryItemSuccessActionType
  | DeleteMeterHistoryItemSuccessActionType
