import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import { FC } from 'react'
import styled from 'styled-components'
import { TooltipPlacement } from 'antd/lib/tooltip'
import { useTranslation } from 'react-i18next'
import { CheckOutlined, CloseOutlined } from '@ant-design/icons'
import { Popconfirm, Row } from 'antd'

import { Color, FontSize, Spacing } from '../../styles'

export const StyledText = styled(Row)`
  text-align: center;
  width: 100%;
  height: 100px;
  font-size: ${FontSize.sm};
`

export const StyledTitle = styled.span`
  font-size: ${FontSize.md};
  font-weight: bold;
`

export const StyledIcon = styled(DeleteOutlineOutlinedIcon)`
  margin: 0 10px -4px 0;
`

export type PlannyPopconfirmPropsType = {
  text: string
  title: string
  subTitle?: string
  placement?: TooltipPlacement
  style?: any
  onConfirm?: () => void
  className?: string
  disabled?: boolean
}

export const PlannyPopconfirm: FC<PlannyPopconfirmPropsType> = ({
  text,
  title,
  subTitle,
  onConfirm,
  placement,
  style,
  children,
  className,
  disabled,
}) => {
  const { t } = useTranslation()

  return (
    <Popconfirm
      placement={placement || 'top'}
      disabled={disabled}
      title={
        <StyledText justify="center" align="middle">
          {text} <br /> {subTitle}
        </StyledText>
      }
      overlayClassName={className || ''}
      onConfirm={onConfirm}
      okButtonProps={{
        type: 'primary',
        size: 'large',
        icon: <CheckOutlined />,
        style: {
          borderRadius: `${Spacing.sm8}`,
          minWidth: '120px',
          boxShadow: '0px 1px 18px rgba(0, 0, 0, 0.12)',
          color: `${Color.white}`,
          backgroundColor: `${Color.darkGreen}`,
          border: 'none',
        },
      }}
      cancelButtonProps={{
        type: 'ghost',
        size: 'large',
        danger: true,
        icon: <CloseOutlined />,
        style: {
          borderRadius: `${Spacing.sm8}`,
          boxShadow: '0px 1px 18px rgba(0, 0, 0, 0.12)',
        },
      }}
      okText="Ok"
      cancelText={t('cancel-button')}
      icon={
        <>
          <StyledIcon />
          <StyledTitle>{title}</StyledTitle>
        </>
      }
      style={style}
    >
      {children}
    </Popconfirm>
  )
}
