import { FC, ReactElement } from 'react'
import { Navigate, Outlet } from 'react-router-dom'

import { getAuth } from '../../store/selectors'
import { isNeedLogin } from '../../api'
import { LOGIN } from '../../constants'
import { useTypedSelector } from '../../hooks'

export type PrivateRoutePropsType = {
  children?: ReactElement
}

export const PrivateRoute: FC<PrivateRoutePropsType> = ({ children }) => {
  const { authError } = useTypedSelector(getAuth)

  // If the token or session has expired, then redirect to the login page

  if (isNeedLogin(authError)) return <Navigate to={LOGIN} replace />

  return children || <Outlet />
}
