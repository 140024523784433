import { FC, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { CommonErrorPage } from '../service'
import { Loader } from '../../components/common'
import {
  CHANNELS,
  ERROR,
  HOME_URL,
  LOGIN,
  REFRESH_TOKEN,
  SESSION_ID,
  SW_ACTIONS,
  SW_REGISTRATION_ERROR,
} from '../../constants'
import { getAuth, getErrors } from '../../store/selectors'
import { getBaseLocation, isUserOnline } from '../../functions'
import {
  getErrorDetails,
  getItemFromLocalStorage,
  isNeedLogin,
  isNeedRefresh,
  log,
} from '../../api/functions'
import { refreshToken, setError } from '../../store/actions'
import { useTypedDispatch, useTypedSelector } from '../../hooks'

export const AuthenticationPage: FC = () => {
  const { accessToken, authError, isRefreshStarts } = useTypedSelector(getAuth)
  const { isUserOnline: isOnline } = useTypedSelector(getErrors)

  const isServiceWorkerError = JSON.parse(getItemFromLocalStorage(SW_REGISTRATION_ERROR))

  // eslint-disable-next-line
  const refreshChannel = new BroadcastChannel(CHANNELS.REFRESH_CHANNEL)
  const sendPathChannel = new BroadcastChannel(CHANNELS.SEND_PATH_CHANNEL)

  const dispatch = useTypedDispatch()

  const navigate = useNavigate()

  const { pathname } = useLocation()

  const currentPath = getBaseLocation(pathname)

  const prevPath = 'previousPath'

  const errorDetails = getErrorDetails(authError)

  const hasCredentials = () => {
    const sessionId = getItemFromLocalStorage(SESSION_ID)
    const refToken = getItemFromLocalStorage(REFRESH_TOKEN)

    if (sessionId && refToken) return true

    return false
  }

  useEffect(() => {
    if (isUserOnline() === false) {
      dispatch(setError(false, ERROR.IS_USER_ONLINE_ERR))
    } else {
      dispatch(setError(true, ERROR.IS_USER_ONLINE_ERR))
    }

    setTimeout(() => dispatch(setError(null, ERROR.IS_USER_ONLINE_ERR)), 10000)
  }, [dispatch, isOnline])

  useEffect(() => {
    sendPathChannel.postMessage(currentPath)
    log(`isServiceWorkerError =>  ${isServiceWorkerError}`)

    if (isServiceWorkerError === false) {
      log(`isNeedRefresh => ${isNeedRefresh(authError, accessToken)}`)

      if (isNeedRefresh(authError, accessToken))
        refreshChannel.postMessage(SW_ACTIONS.NEED_REFRESH_ACT)

      log(`isNeedLogin => ${isNeedLogin(authError)}`)

      if (isNeedLogin(authError)) navigate(LOGIN)
    }
    // * for HTTP *

    if (isServiceWorkerError || isServiceWorkerError === null) {
      if (isNeedRefresh(authError, accessToken) && !isRefreshStarts) {
        if (hasCredentials()) dispatch(refreshToken())
      }

      if (isNeedLogin(authError) || !hasCredentials()) navigate(LOGIN)
    }

    // * for HTTP *

    if (accessToken && !authError && !errorDetails) {
      const path = sessionStorage.getItem(prevPath)

      if (path) {
        navigate(path)
      } else {
        navigate(HOME_URL)
      }
    }
    // eslint-disable-next-line
  }, [
    dispatch,
    navigate,
    accessToken,
    authError,
    errorDetails,
    isNeedLogin,
    isNeedRefresh,
    isServiceWorkerError,
    currentPath,
    isRefreshStarts,
  ])

  return isOnline === false ? <CommonErrorPage isOffline /> : <Loader />
}
