import { FC, useEffect } from 'react'
import { useLocation, useRoutes } from 'react-router-dom'

import { CHANNELS } from '../../constants'
import { getBaseLocation } from '../../functions'
import { getPrivateRoutes } from './privateRoutes'
import { getPublicRoutes } from './publicRoutes'

export const Routes: FC = () => {
  const routes = useRoutes([...getPrivateRoutes(), ...getPublicRoutes()])

  const sendPathChannel = new BroadcastChannel(CHANNELS.SEND_PATH_CHANNEL)

  const { pathname } = useLocation()

  const path = getBaseLocation(pathname)

  useEffect(() => {
    sendPathChannel.postMessage(path)
    // eslint-disable-next-line
  }, [path])

  return routes
}
