import { FC } from 'react'
import { PlusOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { Button, Empty } from 'antd'
import { useLocation, useNavigate } from 'react-router-dom'

import { CREATE } from '../../../constants'
import { Spacing } from '../../../styles'

export type EmptyTablePropsType = {
  hasButton?: boolean
  navigationPath?: string
  prompt?: string
  $isFavoriteTable?: boolean
}

export const EmptyTable: FC<EmptyTablePropsType> = ({
  hasButton,
  navigationPath,
  prompt,
  $isFavoriteTable,
}) => {
  const { t } = useTranslation()

  const navigate = useNavigate()

  const { pathname } = useLocation()

  const currentLocation = pathname.split('/')[1]

  return (
    <Empty
      imageStyle={{
        height: $isFavoriteTable ? 60 : 100,
      }}
      description={<span>{prompt || t('no-data')}</span>}
      style={{ marginTop: Spacing.md40, height: $isFavoriteTable ? 184 : undefined }}
    >
      {hasButton && (
        <Button
          type="primary"
          icon={<PlusOutlined />}
          size="large"
          onClick={() => navigate(navigationPath || `/${currentLocation}/${CREATE}`)}
          style={{ borderRadius: Spacing.sm8, width: '200px', margin: `${Spacing.md40} 0` }}
        >
          {t('create')}
        </Button>
      )}
    </Empty>
  )
}
