// Yandex meters for Helmet component must be a string

export const LOGIN_REGISTER_METER = `
(function (m, e, t, r, i, k, a) {
  m[i] = m[i] || function () { (m[i].a = m[i].a || []).push(arguments) };
  m[i].l = 1 * new Date(); k = e.createElement(t), a = e.getElementsByTagName(t)[0], k.async = 1, k.src = r, a.parentNode.insertBefore(k, a)
})
(window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");

ym(87288773, "init", {
    clickmap: true,
    trackLinks: true,
    accurateTrackBounce: true,
    webvisor: true,
    ecommerce: "dataLayer"
  });
`

export const COMMON_METER = `
(function (m, e, t, r, i, k, a) {
  m[i] = m[i] || function () { (m[i].a = m[i].a || []).push(arguments) };
  m[i].l = 1 * new Date(); k = e.createElement(t), a = e.getElementsByTagName(t)[0], k.async = 1, k.src = r, a.parentNode.insertBefore(k, a)
})
(window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");

ym(88632037, "init", {
    clickmap: true,
    trackLinks: true,
    accurateTrackBounce: true,
    webvisor: true,
    trackHash: true
  });
`

export const NO_SCRIPT_TAG_FOR_LOGIN_METER = `
    <div>
        <img
          src="https://mc.yandex.ru/watch/87288773"
          style="position: absolute; left: -9999px"
          alt=""
        />
    </div>
`

export const NO_SCRIPT_TAG_FOR_COMMON_METER = `
    <div>
        <img
          src="https://mc.yandex.ru/watch/88632037"
          style="position: absolute; left: -9999px"
          alt=""
        />
    </div>
`
