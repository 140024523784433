export enum EQUIPMENT_SISTEM_NOTIFICATIONS {
  CREATE_EQUIPMENT_DATA_SUCCESS = 'create-equipment-data-success',
  UPDATE_EQUIPMENT_DATA_SUCCESS = 'update-equipment-data-success',
  DELETE_EQUIPMENT_ITEM_SUCCESS = 'delete-equipment-data-success',
  EQUIPMENT_SELECTION_ERROR = 'equipment-selection-error',
}

export enum FILES_SISTEM_NOTIFICATIONS {
  DELETE_FILE_SUCCESS = 'delete-file-success',
  CREATE_FILES_SUCCESS = 'create-file-success',
  DELETE_IMAGE_SUCCESS = 'delete-image-success',
  CREATE_IMAGES_SUCCESS = 'create-images-success',
  LOAD_LARGE_FILE_ERROR = 'load-large-file-error',
  FILE_SIZE_ERROR = 'size-error',
  FILES_LOADING_ERROR = 'file-loading-error',
}

export enum PLACES_SISTEM_NOTIFICATIONS {
  CREATE_LOCATION_SUCCESS = 'create-location-success',
  UPDATE_LOCATION_SUCCESS = 'update-location-success',
  DELETE_LOCATION_SUCCESS = 'delete-location-success',
  PLACE_SELECTION_ERROR = 'place-selection-error',
}

export enum MATERIALS_SISTEM_NOTIFICATIONS {
  CREATE_MATERIALS_DATA_SUCCESS = 'create-material-success',
  UPDATE_MATERIALS_DATA_SUCCESS = 'update-material-success',
  DELETE_MATERIALS_ITEM_SUCCESS = 'delete-item-materials-success',
  LINK_MATERIALS_ITEM_SUCCESS = 'link-material-success',
  UNLINK_MATERIALS_ITEM_SUCCESS = 'unlink-material-success',
}

export enum METERS_SISTEM_NOTIFICATIONS {
  CREATE_METERS_DATA_SUCCESS = 'create-meter-success',
  UPDATE_METERS_DATA_SUCCESS = 'update-meter-success',
  DELETE_METERS_ITEM_SUCCESS = 'delete-meter-success',
  UNLINK_METERS_ITEM_SUCCESS = 'unlink-meter-success',
  SELECT_METER_ITEM_ERROR = 'select-meter-error',
}

export enum USER_SISTEM_NOTIFICATIONS {
  EDIT_USER_SUCCESS = 'edit-user-success',
  EDIT_EMAIL_SUCCESS = 'edit-email-success',
  USER_LIMIT_ERROR = 'user-limit-error',
  USER_FILES_LIMIT_ERROR = 'user-general-limit-error',
}

export enum TASKS_SISTEM_NOTIFICATIONS {
  CREATE_TASK_DATA_SUCCESS = 'create-task-data-success',
  CREATE_TASK_RULES_SUCCESS = 'create-task-rules-success',
  UPDATE_TASK_RULES_SUCCESS = 'update-task-rules-success',
  UPDATE_TASK_DATA_SUCCESS = 'update-task-data-success',
  DELETE_TASK_ITEM_SUCCESS = 'delete-task-data-success',
  DELETE_TASK_RULE_SUCCESS = 'delete-task-rule-success',
}

export enum TAGS_SISTEM_NOTIFICATIONS {
  DELETE_TAG_SUCCESS = 'delete-tag-success',
  CREATE_TAG_ERROR = 'create-tag-error',
  SIZE_TAG_ERROR = 'size-tag-error',
  CREATE_TEMP_TAG_ERROR = 'create-temp-tag-error',
}

export enum USER_SERVICE_SISTEM_NOTIFICATIONS {
  CREATE_USER_SERVICE_SUCCESS = 'create-user-service-success',
  EDIT_USER_SERVICE_SUCCESS = 'edit-user-service-success',
  DELETE_USER_SERVICE_SUCCESS = 'delete-user-service-success',
  USER_SERVICE_SELECTION_ERROR = 'user-service-selection-error',
}

export enum COMMON_SISTEM_NOTIFICATIONS {
  SAVE_TEMPORARY_DATA_SUCCESS = 'save-temporary-data-success',
  HIDE_COLUMN_WARNING = 'hide-column-warning',
  TIMEOUT_LOADING_ERROR = 'timeout-loading-error',
  EXPORT_DATA_ERROR = 'export-data-error',
  NO_DATA_EXPORT_ERROR = 'export-no-data-error',
  INCORRECT_DATA_ERROR = 'incorrect-data-error',
  ACCESS_DATA_ERROR = 'access-data-error',
  VALIDATION_ERROR = 'validation-error',
  INVALID_TOKEN = 'invalid-token',
  SUCCESS_LINKED_TELEGRAM_BOT = 'success-linked-telegram-bot',
}

export enum WORKSPACES_SISTEM_NOTIFICATIONS {
  INVITE_MEMBER_SUCCESS = 'invite-member-success',
  EDIT_MEMBER_SUCCESS = 'edit-member-success',
  DELETE_MEMBER_SUCCESS = 'delete-member-success',
  MEMBERS_LIMIT_ERROR = 'members-limit-error',
  MEMBER_NOT_FOUND_ERROR = 'member-not-found-error',
  MEMBER_EXIST_ERROR = 'member-exist-error',
}

export enum COMPANY_SISTEM_NOTIFICATIONS {
  EDIT_COMPANY_SUCCESS = 'edit-company-success',
  UPDATE_COMPANY_IMAGE_SUCCESS = 'update-company-image-success',
  DELETE_COMPANY_IMAGE_SUCCESS = 'delete-image-success',
  COMPANY_NOT_FOUND_ERROR = 'company-not-found-error',
}

export enum PPW_GRAPHICS_SISTEM_NOTIFICATIONS {
  CREATE_PPW_GRAPHICS_DATA_SUCCESS = 'create-ppw-success',
  UPDATE_PPW_GRAPHICS_DATA_SUCCESS = 'update-ppw-success',
  DELETE_PPW_GRAPHICS_ITEM_SUCCESS = 'delete-ppw-success',
}

export enum HISTORY_SISTEM_NOTIFICATIONS {
  UPDATE_HISTORY_DATA_SUCCESS = 'update-history-data-success',
  DELETE_HISTORY_ITEM_SUCCESS = 'delete-history-data-success',
  UPDATE_HISTORY_ERROR = 'history-update-error',
}

export enum CLIENTS_SISTEM_NOTIFICATIONS {
  CREATE_CLIENT_DATA_SUCCESS = 'create-client-success',
  UPDATE_CLIENT_DATA_SUCCESS = 'update-client-success',
  DELETE_CLIENT_ITEM_SUCCESS = 'delete-client-success',
  ADD_CLIENT_ERROR = 'add-client-error',
  NO_CLIENT_ERROR = 'no-client-error',
  CLIENT_ALREADY_EXISTS_ERROR = 'already-exists-client-error',
}
