import { Moment } from 'moment'
import { TemporaryDataPayloadType, TemporaryIdsType } from '../../api'

export type TemporaryDataStateType = {
  tempIds: TemporaryIdsType
  placeAddress: string | null
  temporaryDate: Moment | null
}

export enum TemporaryDataActionTypes {
  SET_TEMPORARY_IDS = 'SET_TEMPORARY_IDS',
  SET_ADDRESS = 'SET_ADDRESS',
  SET_TEMPORARY_DATE = 'SET_TEMPORARY_DATE',
}

export type SetTemporaryDataActionType = {
  type: TemporaryDataActionTypes.SET_TEMPORARY_IDS
  payload: TemporaryDataPayloadType
}

export type SetTemporaryDateActionType = {
  type: TemporaryDataActionTypes.SET_TEMPORARY_DATE
  payload: Moment | null
}

export type SetPlaceAddressActionType = {
  type: TemporaryDataActionTypes.SET_ADDRESS
  payload: string | null
}

export type TemporaryDataActionType =
  | SetTemporaryDataActionType
  | SetPlaceAddressActionType
  | SetTemporaryDateActionType
