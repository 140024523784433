import { combineReducers } from 'redux'

import { auth } from './auth'
import { clients } from './distribution'
import { companies } from './companies'
import { equipment } from './equipment'
import { errors } from './errors'
import { files } from './files'
import { filters } from './filters'
import { images } from './images'
import { materials } from './materials'
import { meters } from './meters'
import { notifications } from './notifications'
import { places } from './places'
import { ppwGraphics } from './ppw'
import { tags } from './tags'
import { tasks } from './tasks'
import { temporaryData } from './temporaryData'
import { user } from './user'
import { userServices } from './userServices'
import { workspaces } from './workspaces'

export const rootReducer = combineReducers({
  companies,
  equipment,
  errors,
  places,
  images,
  tags,
  files,
  materials,
  meters,
  notifications,
  filters,
  temporaryData,
  auth,
  user,
  tasks,
  userServices,
  workspaces,
  ppwGraphics,
  clients,
})

export type RootState = ReturnType<typeof rootReducer>
