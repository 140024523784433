import { FC } from 'react'
import { Link } from 'react-router-dom'
import { Table } from 'antd'
import { useTranslation } from 'react-i18next'

import { ColumnsType } from 'antd/lib/table'
import { EMPTY_STRING } from '../../constants'
import { EmptyTable } from './commonComponents/EmptyTable'
import { HistoryTableWrapper } from './commonComponents/styled'
import { TableCellContent } from './commonComponents/TableCellContent'
import { EquipmentColumnsDataType, PlacesColumnsDataType } from '../../api'

export type FavoritesTablePropsType = {
  data?: (PlacesColumnsDataType | EquipmentColumnsDataType)[]
  pathname?: string
  $isTasks?: boolean
}

export const FavoritesTable: FC<FavoritesTablePropsType> = ({
  data,
  pathname,
  $isTasks = false,
}) => {
  const { t } = useTranslation()

  const columns: ColumnsType<EquipmentColumnsDataType> = [
    {
      title: EMPTY_STRING,
      dataIndex: $isTasks ? 'title' : 'name',
      key: $isTasks ? 'title' : 'name',
      render: (text, record) => (
        <Link to={`${pathname}/${record.key}`}>
          <TableCellContent text={text.length > 40 ? `${text.slice(0, 40)}...` : text} isLink />
        </Link>
      ),
    },
  ]

  return data?.length > 0 ? (
    <HistoryTableWrapper>
      <Table
        columns={columns}
        dataSource={data || []}
        bordered={false}
        size="small"
        pagination={false}
        scroll={data.length > 5 ? { y: 200 } : {}}
        style={{ width: '100%', height: data.length <= 5 ? '200px' : undefined }}
        showHeader={false}
        locale={{
          emptyText: <EmptyTable prompt={t('no-items')} $isFavoriteTable />,
        }}
      />
    </HistoryTableWrapper>
  ) : (
    <EmptyTable prompt={t('no-items')} $isFavoriteTable />
  )
}
