import { FC } from 'react'
import styled from 'styled-components'
import urlJoin from 'url-join'

import { Spacing } from '../../../styles'
import { AUTH_ENDPOINT, AUTH_SSO_VK_ENDPOINT, PLANNY_BASE_URL } from '../../../env'

const StyledLogo = styled.div`
  cursor: pointer;

  margin-right: ${Spacing.md20};
  height: 50px;
  width: 50px;

  :hover {
    opacity: 0.9;
  }
`

const REDIRECT_LINK = urlJoin(PLANNY_BASE_URL, AUTH_ENDPOINT, AUTH_SSO_VK_ENDPOINT)

export const VkButton: FC = () => {
  return (
    <StyledLogo>
      <a href={REDIRECT_LINK}>
        <img src="/images/oauth_vk.svg" alt="vkButton" />
      </a>
    </StyledLogo>
  )
}
