import { Select } from 'antd'
import styled from 'styled-components'
import { FC, memo } from 'react'
import { Field, FieldProps } from 'formik'

import { EMPTY_STRING } from '../../constants'
import { Spacing } from '../../styles'

const { Option } = Select

export type StyledSelectPropsType = {
  margin?: string
  $width?: string
}

export const StyledSelect = styled(Select)<StyledSelectPropsType>`
  width: ${(props) => (props.$width ? props.$width : '100%')};
  margin-bottom: ${(props) => (props.margin ? props.margin : Spacing.sm16)};

  & .ant-select-selector {
    height: 40px !important;
    line-height: 40px;
    align-items: center;
    border-radius: ${Spacing.sm4} !important;
  }
`

export type OptionType = {
  id?: number
  label: string
  optionValue: string
}

export type FieldSelectPropsType = {
  placeholder?: string
  options: OptionType[]
  defaultOptionId?: number | null
  name: string
  onChange?: (value: unknown) => void
  $disabled?: boolean
  $margin?: string
  $width?: string
  customValue?: any
}

export const FieldSelect: FC<FieldSelectPropsType> = memo(
  ({
    placeholder = ' - ',
    options = [],
    defaultOptionId,
    name,
    onChange,
    customValue,
    $disabled = false,
    $margin,
    $width,
  }) => {
    const getDefaultOption = () => {
      if (options.length && defaultOptionId) {
        const [option] = options.filter((opt) => opt.id === defaultOptionId)

        return option ? option.optionValue : EMPTY_STRING
      }

      if (options.length && !defaultOptionId) return options[0].optionValue

      return EMPTY_STRING
    }

    return (
      <Field name={name}>
        {({ meta: { value }, form: { setFieldValue } }: FieldProps) => (
          <StyledSelect
            value={value || undefined}
            defaultValue={getDefaultOption()}
            showSearch
            disabled={$disabled}
            placeholder={placeholder}
            optionFilterProp="children"
            filterOption={(input, option) =>
              option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            onChange={(val) => {
              if (onChange) onChange(customValue || val)

              setFieldValue(name, val)
            }}
            margin={$margin}
            $width={$width}
          >
            {options.map(({ optionValue, label }) => {
              return (
                <Option key={label} value={optionValue}>
                  {label}
                </Option>
              )
            })}
          </StyledSelect>
        )}
      </Field>
    )
  },
)
