import { EQUIPMENT_ERROR_TYPES, TASKS_ERROR_TYPES } from '../../../api'
import { EquipmentActionTypes, TasksActionTypes } from '../../actionTypes'

export const getEquipmentErrorActionType = (key?: string) => {
  if (key === EQUIPMENT_ERROR_TYPES.STATUSES) return EquipmentActionTypes.LOAD_STATUSES_ERROR

  if (key === EQUIPMENT_ERROR_TYPES.HISTORY)
    return EquipmentActionTypes.LOAD_EQUIPMENT_HISTORY_ERROR

  if (key === EQUIPMENT_ERROR_TYPES.EQUIPMENT_ITEM)
    return EquipmentActionTypes.LOAD_EQUIPMENT_ITEM_ERROR

  return EquipmentActionTypes.LOAD_ERROR
}

export const getTasksErrorActionType = (key?: string) => {
  if (key === TASKS_ERROR_TYPES.STATUSES) return TasksActionTypes.LOAD_TASK_STATUSES_ERROR

  if (key === TASKS_ERROR_TYPES.HISTORY) return TasksActionTypes.LOAD_TASK_HISTORY_ERROR

  if (key === TASKS_ERROR_TYPES.TASK_ITEM) return TasksActionTypes.LOAD_TASK_ITEM_ERROR

  if (key === TASKS_ERROR_TYPES.PRIORITIES) return TasksActionTypes.LOAD_TASK_PRIORITIES_ERROR

  if (key === TASKS_ERROR_TYPES.RULES) return TasksActionTypes.LOAD_TASK_RULES_ERROR

  return TasksActionTypes.LOAD_TASKS_ERROR
}
