import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Button, Col, Row, Typography } from 'antd'
import Checkbox, { CheckboxChangeEvent } from 'antd/lib/checkbox'
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons'
import { FC, useState } from 'react'
import { Form, Formik } from 'formik'

import { getAuth } from '../../store/selectors'
import { Page } from '../page'
import { register } from '../../store/actions/auth'
import { REGISTRATION_VALIDATION_SCHEMA } from '../../components/forms'
import { Spacing } from '../../styles'
import { SuccessPage } from '../service/SuccessPage'
import { UserRegisterType } from '../../api/authTypes'
import { EMPTY_STRING, ERROR_STRING, LOGIN, STATUS_CODES } from '../../constants'
import {
  FormWrapper,
  Logo,
  LogoWrapper,
  NotificationPannel,
  PlannyInput,
  PlannyLabel,
  RequiredFieldStarLabel,
  StyledButton,
  StyledCol,
  StyledFieldError,
  StyledPasswordInput,
  StyledRow,
} from '../../components/common'
import { GoogleButton, MailRuButton, YandexButton } from '../../components/common/socialButtons'
import { useTypedDispatch, useTypedSelector } from '../../hooks'

const { Title, Text } = Typography

export const RegistrationPage: FC = () => {
  const [checked, setChecked] = useState(false)

  const { status, authError } = useTypedSelector(getAuth)

  const { t } = useTranslation()

  const dispatch = useTypedDispatch()

  const navigate = useNavigate()

  const handleNavigate = () => navigate(LOGIN)

  const getInitialValues = (): UserRegisterType => {
    return {
      email: EMPTY_STRING,
      password: EMPTY_STRING,
      passwordConfirmation: EMPTY_STRING,
    }
  }

  const handleOnCheckBoxChange = (e: CheckboxChangeEvent) => setChecked(e.target.checked)

  const handleSubmit = (values: UserRegisterType) => {
    const val = { email: values.email.trim(), password: values.password.trim() }

    dispatch(register(val))
  }

  if (status && status === STATUS_CODES.CODE_201) {
    return (
      <SuccessPage
        title={t('successful-registration-message')}
        subTitle={t('successful-registration-prompt')}
        redirectLink={LOGIN}
        redirectTime={7000}
      />
    )
  }

  return (
    <Page pageTitle={t('registration-page-title')} authLayout>
      <>
        <LogoWrapper justify="center">
          <Logo $isSider={false} />
        </LogoWrapper>
        <>
          {authError && <NotificationPannel error={authError} />}

          <Formik
            initialValues={getInitialValues()}
            validationSchema={REGISTRATION_VALIDATION_SCHEMA}
            validateOnBlur
            validateOnChange
            onSubmit={(values) => handleSubmit(values)}
          >
            {({ values, setFieldValue, errors, touched, handleBlur, isValid }) => (
              <FormWrapper justify="center">
                <Form>
                  <Row justify="center">
                    <StyledCol>
                      <Title level={3} data-testid="registration-page-title">
                        {t('registration-page-title')}
                      </Title>
                    </StyledCol>
                  </Row>

                  <StyledCol>
                    <PlannyLabel>
                      {t('input-label-email')}
                      <RequiredFieldStarLabel> * </RequiredFieldStarLabel>
                    </PlannyLabel>

                    <PlannyInput
                      name="email"
                      aria-label="email"
                      value={values.email}
                      onChange={(e) => setFieldValue('email', e.target.value)}
                      onBlur={(e) => handleBlur(e)}
                      style={{ marginBottom: '8px' }}
                      status={errors.email && touched.email && ERROR_STRING}
                    />

                    {errors.email && touched.email ? (
                      <StyledFieldError>{errors.email}</StyledFieldError>
                    ) : null}
                  </StyledCol>

                  <StyledCol>
                    <PlannyLabel>
                      {t('input-label-registration-password')}
                      <RequiredFieldStarLabel> * </RequiredFieldStarLabel>
                    </PlannyLabel>

                    <StyledPasswordInput
                      name="password"
                      aria-label="password"
                      value={values.password}
                      onChange={(e) => setFieldValue('password', e.target.value)}
                      iconRender={(visible) =>
                        visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                      }
                      onBlur={(e) => handleBlur(e)}
                      status={errors.password && touched.password && ERROR_STRING}
                    />

                    {errors.password && touched.password ? (
                      <StyledFieldError>{errors.password}</StyledFieldError>
                    ) : null}
                  </StyledCol>

                  <StyledCol>
                    <PlannyLabel>
                      {t('input-label-confirm-password')}
                      <RequiredFieldStarLabel> * </RequiredFieldStarLabel>
                    </PlannyLabel>

                    <StyledPasswordInput
                      name="passwordConfirmation"
                      aria-label="passwordConfirmation"
                      value={values.passwordConfirmation}
                      onChange={(e) => setFieldValue('passwordConfirmation', e.target.value)}
                      iconRender={(visible) =>
                        visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                      }
                      onBlur={(e) => handleBlur(e)}
                      status={
                        errors.passwordConfirmation && touched.passwordConfirmation && ERROR_STRING
                      }
                    />

                    {errors.passwordConfirmation && touched.passwordConfirmation ? (
                      <StyledFieldError>{errors.passwordConfirmation}</StyledFieldError>
                    ) : null}
                  </StyledCol>

                  <StyledButton
                    type="primary"
                    block
                    htmlType="submit"
                    disabled={!isValid || !checked}
                    $mt={Spacing.sm4}
                    $mb={Spacing.md20}
                    data-testid="registration-btn"
                  >
                    {t('registration-button')}
                  </StyledButton>

                  <Row justify="center">
                    <StyledCol>
                      <Text type="secondary"> {t('another-type-login-title')}</Text>
                    </StyledCol>
                  </Row>

                  <StyledRow justify="center" align="middle">
                    <Col>
                      <YandexButton />
                    </Col>

                    {/* <Col>
                    <VkButton />
                  </Col> */}

                    <Col>
                      <MailRuButton />
                    </Col>

                    <Col>
                      <GoogleButton />
                    </Col>
                  </StyledRow>

                  <Row>
                    <Checkbox checked={checked} onChange={handleOnCheckBoxChange}>
                      <Text data-testid="terms-of-use-block">
                        {t('input-label-terms-of-use')}
                        <a
                          href="https://planny24.ru/terms"
                          target="blank"
                          style={{ display: 'inline-block' }}
                        >
                          {t('input-label-terms-of-use-link')}
                        </a>
                      </Text>
                    </Checkbox>
                  </Row>
                </Form>
              </FormWrapper>
            )}
          </Formik>
        </>

        <StyledCol>
          <Text type="secondary"> {t('has-account-prompt')}</Text>

          <Button type="link" onClick={handleNavigate} data-testid="login-btn">
            {t('login-button')}
          </Button>
        </StyledCol>
      </>
    </Page>
  )
}
