import { RcFile } from 'antd/lib/upload'
import { t } from 'i18next'
import urlJoin from 'url-join'
import { AxiosError, AxiosInstance, AxiosResponse } from 'axios'

import { AppDispatch } from '../../hooks'
import { handleLoadError } from './errors'
import { handleUpdateItem } from './utils/handleUpdateItem'
import { ImagesActionTypes } from '../actionTypes'
import { RootState } from '../reducers/rootReducer'
import { sendNotification } from '../../components/common/notification'
import { createFilesRecord, deleteFiles, updatePreview } from '../../api/shortcuts'
import { FILES_SISTEM_NOTIFICATIONS, MESSAGE_TYPES } from '../../constants'
import { getBasePath, startDataLoading } from './utils'

type GetImagesStateFunctionType = () => RootState

// Delete Image Item   PUT

export const deleteImageItem =
  (parentId: number, imageId: number, imageType: string) =>
  (dispatch: AppDispatch, getState: GetImagesStateFunctionType, api: AxiosInstance) => {
    dispatch(startDataLoading(ImagesActionTypes.START_IMAGES_LOADING))

    api
      .put(urlJoin(getBasePath(imageType), parentId.toString()), deleteFiles(imageId))
      .then((res: AxiosResponse) => {
        dispatch(handleUpdateItem(res.data.data[0], imageType))

        dispatch({
          type: ImagesActionTypes.DELETE_IMAGE_SUCCESS,
        })

        sendNotification(MESSAGE_TYPES.SUCCESS, t(FILES_SISTEM_NOTIFICATIONS.DELETE_IMAGE_SUCCESS))
      })
      .catch((err: AxiosError) =>
        dispatch(handleLoadError(err, ImagesActionTypes.LOAD_IMAGES_ERROR)),
      )
  }

// Add Images   PUT

export const addImagesItems =
  (parentId: number | undefined, files: RcFile[], imageType: string) =>
  (dispatch: AppDispatch, getState: GetImagesStateFunctionType, api: AxiosInstance) => {
    dispatch(startDataLoading(ImagesActionTypes.START_IMAGES_LOADING))

    api
      .put(urlJoin(getBasePath(imageType), parentId.toString()), createFilesRecord(files))
      .then((res: AxiosResponse) => {
        if (res.status === 200) {
          dispatch(handleUpdateItem(res.data.data[0], imageType))

          dispatch({
            type: ImagesActionTypes.CREATE_IMAGES_SUCCESS,
          })

          sendNotification(
            MESSAGE_TYPES.SUCCESS,
            t(FILES_SISTEM_NOTIFICATIONS.CREATE_IMAGES_SUCCESS),
          )
        }
      })
      .catch((err: AxiosError) =>
        dispatch(handleLoadError(err, ImagesActionTypes.LOAD_IMAGES_ERROR)),
      )
  }

// Update Image Item   PUT

export const updatePreviewImage =
  (
    parentId: number | undefined,
    files: RcFile[],
    imageType: string,
    imageId?: number | undefined,
  ) =>
  (dispatch: AppDispatch, getState: GetImagesStateFunctionType, api: AxiosInstance) => {
    dispatch(startDataLoading(ImagesActionTypes.START_IMAGES_LOADING))

    api
      .put(urlJoin(getBasePath(imageType), parentId.toString()), updatePreview(files, imageId))
      .then((res: AxiosResponse) => {
        dispatch(handleUpdateItem(res.data.data[0], imageType))

        dispatch({
          type: ImagesActionTypes.CREATE_IMAGES_SUCCESS,
        })
      })
      .catch((err: AxiosError) =>
        dispatch(handleLoadError(err, ImagesActionTypes.LOAD_IMAGES_ERROR)),
      )
  }

export const saveImages = (file: RcFile) => (dispatch: AppDispatch) =>
  dispatch({
    type: ImagesActionTypes.SAVE_TEMPORARY_IMAGES,
    payload: file,
  })

export const deleteTemporaryImageItem = (id: string) => (dispatch: AppDispatch) => {
  dispatch({
    type: ImagesActionTypes.DELETE_TEMPORARY_IMAGE_ITEM,
    payload: id,
  })
}
