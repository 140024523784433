// BANNERS  *i18next variables*

export const PLACE_CREATE_BANNER_TEXT = 'create-place-banner'

export const EQUIPMENT_CREATE_BANNER_TEXT = 'create-equipment-banner'

export const MATERIALS_CREATE_BANNER_TEXT = 'create-material-banner'

export const METERS_CREATE_BANNER_TEXT = 'create-meter-banner'

export const USER_SERVICES_CREATE_BANNER_TEXT = 'create-user-service-banner'
