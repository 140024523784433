import { FC, ReactNode } from 'react'

export type ListItemPropsType = {
  children: ReactNode
  link: string
}

export const ListItem: FC<ListItemPropsType> = ({ children, link }) => (
  <li>
    <a href={link} target="_blank" rel="noreferrer">
      {children}
    </a>
  </li>
)
