import urlJoin from 'url-join'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { FC, useCallback, useEffect, useState } from 'react'
import { Radio, RadioChangeEvent, Row, Table } from 'antd'

import { EmptyTable } from './commonComponents/EmptyTable'
import { ExpandedIcon } from './commonComponents/ExpandedIcon'
import { getItemsSelectTableColumns } from './columns/itemSelectTableColumns'
import { isCreatePage } from '../../functions'
import { prepareSearchValue } from './helpers/sorter'
import { SearchForm } from '../forms'
import { setTemporaryDataIds } from '../../store/actions'
import { Spacing } from '../../styles'
import { StyledTableDivider } from '../common'
import { TableWrapper } from './commonComponents/styled'
import { CREATE, DATA_TYPE } from '../../constants'
import { EquipmentColumnsDataType, IdObject, IEquipment } from '../../api'
import { findPlaceId, getEquipmentItem, getRowClassName } from './helpers'
import { getEquipment, getFilters, getTemporaryData } from '../../store/selectors'
import { useTypedDispatch, useTypedSelector } from '../../hooks'

export type MultipleEquipmentItemsSelectTablePropsType = {
  data?: EquipmentColumnsDataType[]
  selectedEquipments?: IdObject[]
  radioButtonText?: string
}

export const MultipleEquipmentItemsSelectTable: FC<MultipleEquipmentItemsSelectTablePropsType> = ({
  data,
  selectedEquipments,
  radioButtonText,
}) => {
  const [selectedKeys, setSelectedKeys] = useState([])
  const [checked, setChecked] = useState(false)

  const { equipment } = useTypedSelector(getEquipment)
  const { tempIds } = useTypedSelector(getTemporaryData)

  const { searchValue } = useTypedSelector(getFilters)

  const dispatch = useTypedDispatch()

  const { pathname } = useLocation()

  const { t } = useTranslation()

  const createPage = isCreatePage(pathname)

  const resetState = () => {
    setSelectedKeys([])
    setChecked(true)
  }

  const handleOnChange = (e: RadioChangeEvent) => {
    setChecked(e.target.checked)
    setSelectedKeys([])
    dispatch(setTemporaryDataIds(DATA_TYPE.EQUIPMENT, []))
    dispatch(setTemporaryDataIds(DATA_TYPE.USER_SERVICES, []))
  }

  const handleSelectItems = (sKey: number) => {
    if (selectedKeys.includes(sKey)) {
      setSelectedKeys(selectedKeys.filter((i) => i !== sKey))
    } else {
      setSelectedKeys([...selectedKeys, sKey])
    }
  }

  const foundResponsibleUserId = (key: number, equipmentArray: IEquipment[]) => {
    const [foundEquipmentItem] = getEquipmentItem(equipmentArray, key)

    return foundEquipmentItem ? foundEquipmentItem.responsibleId : null
  }

  const handleDispatchSelectedItems = (sKey: number) => {
    const foundPlaceId = findPlaceId(sKey, equipment)
    const foundResponsibleId = foundResponsibleUserId(sKey, equipment)

    if (tempIds.equipment && tempIds.equipment.length && tempIds.equipment.includes(sKey))
      dispatch(
        setTemporaryDataIds(
          DATA_TYPE.EQUIPMENT,
          tempIds.equipment.filter((i) => i !== sKey),
        ),
      )

    if (tempIds.equipment && tempIds.equipment.length && !tempIds.equipment.includes(sKey)) {
      dispatch(setTemporaryDataIds(DATA_TYPE.EQUIPMENT, [...tempIds.equipment, sKey]))
    }

    if (!tempIds.equipment || !tempIds.equipment.length) {
      dispatch(setTemporaryDataIds(DATA_TYPE.EQUIPMENT, [sKey]))
      if (foundPlaceId) dispatch(setTemporaryDataIds(DATA_TYPE.PLACES, [foundPlaceId]))

      if (foundResponsibleId)
        dispatch(setTemporaryDataIds(DATA_TYPE.USER_SERVICES, [foundResponsibleId]))
    }
  }

  const handleSelectItem = (sKey: number) => {
    handleSelectItems(sKey)
    handleDispatchSelectedItems(sKey)

    if (
      (createPage && !tempIds.equipment) ||
      (createPage && selectedKeys.length === 0) ||
      (selectedEquipments && selectedEquipments.length && !tempIds.equipment)
    )
      setChecked(true)

    setChecked(false)
  }

  const getInitialSelectItems = useCallback(() => {
    if (
      (createPage && !tempIds.equipment) ||
      (createPage && tempIds.equipment && !tempIds.equipment.length) ||
      (selectedEquipments && !selectedEquipments.length && !tempIds.equipment) ||
      (selectedEquipments &&
        selectedEquipments.length &&
        tempIds.equipment &&
        !tempIds.equipment.length)
    )
      resetState()

    if (createPage && tempIds.equipment && tempIds.equipment.length)
      setSelectedKeys(tempIds.equipment.map((i) => i))

    if (!createPage && selectedEquipments && selectedEquipments.length && !tempIds.equipment) {
      setSelectedKeys(selectedEquipments.map((i) => i.id))
      dispatch(
        setTemporaryDataIds(
          DATA_TYPE.EQUIPMENT,
          selectedEquipments.map((i) => i.id),
        ),
      )
    }
  }, [dispatch, createPage, tempIds, selectedEquipments])

  const handleSearchRow = (key: number) => {
    return getRowClassName(equipment, key, prepareSearchValue(searchValue.equipment))
  }

  useEffect(() => getInitialSelectItems(), [getInitialSelectItems])

  return (
    <TableWrapper>
      <Row align="middle">
        <Radio checked={checked} onChange={handleOnChange} style={{ marginLeft: Spacing.sm8 }}>
          {t(radioButtonText)}
        </Radio>

        <StyledTableDivider orientation="center" $mt={Spacing.sm12} $mb={Spacing.sm8} />
      </Row>

      <Row align="middle">
        <SearchForm $isModal dataType={DATA_TYPE.EQUIPMENT} />
        <StyledTableDivider orientation="center" $mt={Spacing.sm8} $mb={Spacing.sm12} />
      </Row>

      <Table
        columns={getItemsSelectTableColumns(selectedKeys)}
        dataSource={data || []}
        bordered={false}
        pagination={false}
        scroll={
          data.length > 6 && !searchValue
            ? { y: '350px' }
            : equipment.length > 6 && searchValue
            ? { y: '350px' }
            : {}
        }
        showHeader={false}
        rowClassName={(record) => handleSearchRow(record.key)}
        onRow={(record) => ({
          onClick: () => handleSelectItem(record.key),
        })}
        locale={{
          emptyText: (
            <EmptyTable
              prompt={t('no-items')}
              hasButton
              navigationPath={urlJoin(DATA_TYPE.EQUIPMENT, CREATE)}
            />
          ),
        }}
        expandable={{
          defaultExpandAllRows: true,
          indentSize: 20,
          expandIcon: ({ expanded, onExpand, record }) => (
            <ExpandedIcon expanded={expanded} record={record} onExpand={onExpand} />
          ),
        }}
        style={{ minWidth: '100%', margin: '0' }}
      />
    </TableWrapper>
  )
}
