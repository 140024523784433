import { FC } from 'react'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Col, Result, Row, Typography } from 'antd'

import { FontSize } from '../../styles'
import { Page } from '../page'
import { PlannyLogo } from '../../components/common'

const { Text } = Typography

export const StyledText = styled(Text)`
  font-size: ${FontSize.md};
`

export type SuccessPagePropsType = {
  title: string
  subTitle?: string
  redirectLink?: string
  redirectTime?: number
}

export const SuccessPage: FC<SuccessPagePropsType> = ({
  title,
  subTitle,
  redirectLink,
  redirectTime,
}) => {
  const navigate = useNavigate()

  const { t } = useTranslation()

  setTimeout(() => {
    navigate(redirectLink)
  }, redirectTime)

  return (
    <Page pageTitle={t('success-pageTitle')} authLayout>
      <Row justify="center" align="middle">
        <Col xs={12}>
          <Result
            status="success"
            icon={
              <PlannyLogo>
                <img src="/images/p24_ok.svg" alt="success" />
              </PlannyLogo>
            }
            title={title}
            subTitle={<StyledText type="secondary">{subTitle}</StyledText>}
          />
        </Col>
      </Row>
    </Page>
  )
}
