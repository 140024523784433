import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'
import React, { FC } from 'react'

import { Color } from '../../../styles'
import {
  ClientsColumnsDataType,
  EquipmentColumnsDataType,
  PlacesColumnsDataType,
} from '../../../api'

type ExpandedIconPropsType = {
  record: EquipmentColumnsDataType | PlacesColumnsDataType | ClientsColumnsDataType
  expanded: boolean
  onExpand: any
  color?: string
}

export const ExpandedIcon: FC<ExpandedIconPropsType> = ({ record, expanded, onExpand, color }) => {
  if (expanded && record.children?.length > 0)
    return (
      <ArrowDropDownIcon
        onClick={(e) => onExpand(record, e)}
        style={{ margin: ' 0 3px -6px -7px', color: color || Color.gray, cursor: 'pointer' }}
      />
    )

  if (!expanded && record.children?.length > 0) {
    return (
      <ArrowRightIcon
        onClick={(e) => onExpand(record, e)}
        style={{ margin: ' 0 3px -6px -7px', color: color || Color.gray, cursor: 'pointer' }}
      />
    )
  }

  return (
    <FiberManualRecordIcon
      onClick={(e) => onExpand(record, e)}
      style={{ marginRight: '10px', color: color || Color.lightGray, fontSize: '10px' }}
    />
  )
}
