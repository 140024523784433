import { FC, memo, ReactElement, ReactNode } from 'react'

import { DashboardPageHeader } from './DashboardPageHeader'
import { ItemPageHeader } from './ItemPageHeader'
import { StyledHeader } from './styled'
import { TablePageHeader } from './TablePageHeader'

export type PlannyHeaderPropsType = {
  pageTitle: string
  headerIcon: ReactElement
  search?: ReactNode
  actions?: ReactNode
  button?: ReactNode
  buttonGroup?: ReactNode
  $boxShadow?: boolean
  extraIcon?: ReactElement
  navigationTabs?: ReactNode
  selectModal?: ReactNode
  $isDashboardPage?: boolean
  $isTablePage?: boolean
  $isEditPage?: boolean
}

export const PlannyHeader: FC<PlannyHeaderPropsType> = memo(
  ({
    pageTitle,
    headerIcon,
    search,
    actions,
    button,
    buttonGroup,
    $boxShadow,
    extraIcon,
    navigationTabs,
    selectModal,
    $isDashboardPage,
    $isTablePage,
    $isEditPage,
  }) => {
    return (
      <StyledHeader $boxShadow={$boxShadow}>
        {$isEditPage ? (
          <ItemPageHeader
            pageTitle={pageTitle}
            headerIcon={headerIcon}
            actions={actions}
            navigationTabs={navigationTabs}
            extraIcon={extraIcon}
          />
        ) : null}

        {$isTablePage ? (
          <TablePageHeader
            pageTitle={pageTitle}
            headerIcon={headerIcon}
            search={search}
            button={button}
            selectModal={selectModal}
            actions={actions}
          />
        ) : null}

        {$isDashboardPage || (!$isEditPage && !$isTablePage) ? (
          <DashboardPageHeader
            pageTitle={pageTitle}
            headerIcon={headerIcon}
            navigationTabs={navigationTabs}
            buttonGroup={buttonGroup}
          />
        ) : null}
      </StyledHeader>
    )
  },
)
