import urlJoin from 'url-join'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Button, Col, Row, Typography } from 'antd'
import Checkbox, { CheckboxChangeEvent } from 'antd/lib/checkbox'
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons'
import { FC, useCallback, useEffect, useState } from 'react'
import { Form, Formik } from 'formik'

import { getAuth } from '../../store/selectors'
import { LOGIN_VALIDATION_SCHEMA } from '../../components/forms'
import { Page } from '../page'
import { parseJwt } from '../../functions'
import { Spacing } from '../../styles'
import { TELEGRAM_PARAMETER } from '../../env'
import { UserLoginType } from '../../api/authTypes'
import {
  DASHBOARD_URL,
  EMPTY_STRING,
  ERROR_STRING,
  FORGOT_PASSWORD,
  HOME_URL,
  INTEGRATION_TELEGRAM_LINK,
  REGISTER,
  TAB_PATHS,
  USER_ID_KEY,
} from '../../constants'
import {
  FormWrapper,
  Logo,
  LogoWrapper,
  NotificationPannel,
  PlannyInput,
  PlannyLabel,
  RequiredFieldStarLabel,
  StyledButton,
  StyledCol,
  StyledFieldError,
  StyledPasswordInput,
  StyledRow,
} from '../../components/common'
import {
  getItemFromLocalStorage,
  getItemFromSessionStorage,
  setItemToLocalStorage,
} from '../../api'
import { GoogleButton, MailRuButton, YandexButton } from '../../components/common/socialButtons'
import { login, setStatus } from '../../store/actions/auth'
import { useQuery, useTypedDispatch, useTypedSelector } from '../../hooks'

const { Title, Text } = Typography

export const LoginPage: FC = () => {
  const [checked, setChecked] = useState(true)

  const { accessToken, authError } = useTypedSelector(getAuth)

  const { t } = useTranslation()

  const dispatch = useTypedDispatch()

  const navigate = useNavigate()

  const query = useQuery()

  const telegramToken = query.get(TELEGRAM_PARAMETER)

  const getInitialValues = (): UserLoginType => {
    return {
      username: EMPTY_STRING,
      password: EMPTY_STRING,
    }
  }

  const handleOnCheckBoxChange = (e: CheckboxChangeEvent) => setChecked(e.target.checked)

  const getTrimedValues = (values: UserLoginType) => ({
    username: values.username.trim(),
    password: values.password.trim(),
  })

  const handleLogin = (values: UserLoginType) =>
    dispatch(login(getTrimedValues(values), telegramToken))

  const handleNavigateToInitialPage = useCallback(() => {
    const storedUserId = getItemFromLocalStorage(USER_ID_KEY)
    const loggedUserId = accessToken ? parseJwt(accessToken)?.id : null

    const integrationTelegramLink = getItemFromSessionStorage(INTEGRATION_TELEGRAM_LINK)

    if (!storedUserId || loggedUserId !== Number(storedUserId)) {
      const userInfo = parseJwt(accessToken)

      if (userInfo) {
        const { id } = userInfo

        setItemToLocalStorage(USER_ID_KEY, id ? id.toString() : EMPTY_STRING)
      }

      navigate(urlJoin(DASHBOARD_URL, TAB_PATHS.HELP))
    }

    if (storedUserId && loggedUserId === Number(storedUserId)) {
      if (integrationTelegramLink) navigate(urlJoin(integrationTelegramLink))
      if (!integrationTelegramLink) navigate(urlJoin(HOME_URL))
    }
  }, [navigate, accessToken])

  const handleNavigateToRegister = () => navigate(REGISTER)

  const handleNavigateToResetPassword = () => navigate(FORGOT_PASSWORD)

  useEffect(() => {
    dispatch(setStatus(null))

    if (accessToken) handleNavigateToInitialPage()
  }, [accessToken, handleNavigateToInitialPage, dispatch])

  return (
    <Page pageTitle={t('login-page-title')} authLayout>
      <>
        <LogoWrapper justify="center">
          <Logo $isSider={false} />
        </LogoWrapper>

        <>
          {authError && <NotificationPannel error={authError} />}

          <Formik
            validationSchema={LOGIN_VALIDATION_SCHEMA}
            validateOnBlur
            validateOnChange
            initialValues={getInitialValues()}
            onSubmit={(values) => handleLogin(values)}
          >
            {({ values, setFieldValue, errors, touched, handleBlur, isValid }) => (
              <FormWrapper justify="center">
                <Form>
                  <Row justify="center">
                    <StyledCol>
                      <Title level={3} data-testid="login-page-title">
                        {t('login-page-title')}
                      </Title>
                    </StyledCol>
                  </Row>

                  <StyledCol>
                    <PlannyLabel>
                      {t('input-label-email')}
                      <RequiredFieldStarLabel> * </RequiredFieldStarLabel>
                    </PlannyLabel>

                    <PlannyInput
                      name="username"
                      aria-label="username"
                      value={values.username}
                      onChange={(e) => setFieldValue('username', e.target.value)}
                      onBlur={(e) => handleBlur(e)}
                      style={{ marginBottom: '8px' }}
                      status={errors.username && touched.username && ERROR_STRING}
                    />

                    {errors.username && touched.username ? (
                      <StyledFieldError>{errors.username}</StyledFieldError>
                    ) : null}
                  </StyledCol>

                  <StyledCol>
                    <PlannyLabel>
                      {t('input-label-password')}
                      <RequiredFieldStarLabel> * </RequiredFieldStarLabel>
                    </PlannyLabel>

                    <StyledPasswordInput
                      name="password"
                      aria-label="password"
                      value={values.password}
                      onChange={(e) => setFieldValue('password', e.target.value)}
                      iconRender={(visible) =>
                        visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                      }
                      onBlur={(e) => handleBlur(e)}
                      status={errors.password && touched.password && ERROR_STRING}
                    />

                    {errors.password && touched.password ? (
                      <StyledFieldError>{errors.password}</StyledFieldError>
                    ) : null}
                  </StyledCol>

                  <StyledCol>
                    <StyledButton
                      type="primary"
                      block
                      htmlType="submit"
                      disabled={!isValid || !checked}
                      $mt={Spacing.sm4}
                      $mb={Spacing.md20}
                      data-testid="login-btn"
                    >
                      {t('login-button')}
                    </StyledButton>
                  </StyledCol>

                  <Row justify="center">
                    <StyledCol>
                      <Text type="secondary"> {t('another-type-login-title')}</Text>
                    </StyledCol>
                  </Row>

                  <StyledRow justify="center" align="middle">
                    <Col>
                      <YandexButton />
                    </Col>

                    {/* <Col>
                    <VkButton />
                  </Col> */}

                    <Col>
                      <MailRuButton />
                    </Col>

                    <Col>
                      <GoogleButton />
                    </Col>
                  </StyledRow>

                  <StyledRow>
                    <Checkbox checked={checked} onChange={handleOnCheckBoxChange}>
                      <Text data-testid="terms-of-use-block">
                        {t('input-label-terms-of-use')}
                        <a
                          href="https://planny24.ru/terms"
                          target="blank"
                          style={{ display: 'inline-block' }}
                        >
                          {t('input-label-terms-of-use-link')}
                        </a>
                      </Text>
                    </Checkbox>
                  </StyledRow>
                </Form>
              </FormWrapper>
            )}
          </Formik>
        </>

        <StyledCol>
          <Text type="secondary"> {t('no-account-prompt')}</Text>

          <Button type="link" onClick={handleNavigateToRegister}>
            {t('registration-button')}
          </Button>
        </StyledCol>

        <StyledCol>
          <Button type="link" onClick={handleNavigateToResetPassword}>
            {t('forgot-password-button')}
          </Button>
        </StyledCol>
      </>
    </Page>
  )
}
