import { FC } from 'react'
import { InboxOutlined } from '@ant-design/icons'
import PhotoCameraOutlinedIcon from '@mui/icons-material/PhotoCameraOutlined'
import { RcFile } from 'antd/lib/upload'
import styled from 'styled-components'
import { UploadFile } from 'antd/lib/upload/interface'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Col, Upload } from 'antd'

import { BUTTON_TYPE } from '../../../constants'
import { CancelRedOutlinedButton } from '../buttons'
import { FilesLimitsType } from '../../../api'
import { getImages } from '../../../store/selectors'
import { ModalTitle } from '../ModalTitle'
import { SaveGreenButton } from '../buttons/SaveGreenButton'
import { sendLimitsErrorNotification } from '../notification'
import { useTypedSelector } from '../../../hooks'
import { deleteTemporaryImageItem, deleteTemporaryImages, saveImages } from '../../../store/actions'
import { FontSize, Spacing } from '../../../styles'
import { getAvailableFiles, getFileSizeInMb, getFilesLimitError } from '../../../functions'
import { StyledCardText, StyledModal } from '../styled'

const { Dragger } = Upload

const StyledCol = styled(Col)`
  margin-bottom: ${Spacing.md32};
`

export type ImagesModalUploadPropsType = {
  visible: boolean
  onCancel: () => void
  title: string
  onAddImages?: (files: RcFile[]) => void
  availableLimits?: FilesLimitsType
}

export const ImagesModalUpload: FC<ImagesModalUploadPropsType> = ({
  visible,
  onCancel,
  title = 'Загрузите фото',
  onAddImages,
  availableLimits,
}) => {
  const { tempImagesFiles } = useTypedSelector(getImages)

  const dispatch = useDispatch()

  const { t } = useTranslation()

  const isCorrectFileSize = (size: number) => {
    if (availableLimits) return availableLimits.fileMaxSize > getFileSizeInMb(size)

    return false
  }

  const handleBeforeUpload = (file: RcFile) => {
    const isImageSizeCorrect = isCorrectFileSize(file.size)

    if (!isImageSizeCorrect) {
      sendLimitsErrorNotification(
        `${t('user-file-size-limit-error')} ${availableLimits ? availableLimits.fileMaxSize : 0}${t(
          ' mb',
        )}.`,
      )
    }

    if (isImageSizeCorrect) dispatch(saveImages(file))

    return false
  }

  const handleDeleteTemporaryImages = () => {
    dispatch(deleteTemporaryImages())
    onCancel()
  }

  const handleRemoveTemporaryImageItem = (file: UploadFile) => {
    dispatch(deleteTemporaryImageItem(file.uid))
  }

  const handleSave = () => {
    const isFilesLimitError = getFilesLimitError(availableLimits, tempImagesFiles.length)

    const availableFiles = getAvailableFiles(availableLimits)

    if (isFilesLimitError)
      sendLimitsErrorNotification(
        `${t('user-files-limit-error')} ${availableFiles >= 0 ? availableFiles : 0}.`,
      )

    if (tempImagesFiles && tempImagesFiles.length && !isFilesLimitError) {
      onAddImages(tempImagesFiles)

      onCancel()
    }

    onCancel()
  }

  return (
    <StyledModal
      title={
        <>
          <ModalTitle
            $mbTitle={Spacing.sm16}
            $mbIcon={Spacing.sm2}
            icon={<PhotoCameraOutlinedIcon />}
          >
            {t('photo')}
          </ModalTitle>
          <StyledCardText> {title}</StyledCardText>
        </>
      }
      footer={[
        <SaveGreenButton key="submit" onClick={handleSave} disabled={!tempImagesFiles.length}>
          {t('save-button')}
        </SaveGreenButton>,
        <CancelRedOutlinedButton
          type={BUTTON_TYPE.BUTTON}
          key="back"
          onClick={handleDeleteTemporaryImages}
        >
          {t('cancel-button')}
        </CancelRedOutlinedButton>,
      ]}
      visible={visible}
      onCancel={onCancel}
      $itemsAmount={tempImagesFiles.length}
    >
      <Dragger
        name="file"
        multiple
        maxCount={10}
        listType="picture"
        fileList={tempImagesFiles}
        beforeUpload={(file: RcFile) => handleBeforeUpload(file)}
        onRemove={(file: UploadFile) => handleRemoveTemporaryImageItem(file)}
        accept=".jpeg,.jpg,.png,.gif,.webp,.mp4,.m4v,.mov,.mpeg,.mpg,.avi,.mkv,.webm,.flv"
      >
        <StyledCol style={{ marginTop: Spacing.md32 }}>
          <StyledCardText>{t('image-upload-modal-prompt')}</StyledCardText>
        </StyledCol>

        <StyledCol>
          <StyledCardText>
            <InboxOutlined style={{ fontSize: FontSize.lg }} />
          </StyledCardText>
        </StyledCol>

        <StyledCol>
          <StyledCardText>{t('many-images-upload-modal-prompt')}</StyledCardText>
        </StyledCol>
      </Dragger>
    </StyledModal>
  )
}
