import { FC } from 'react'
import styled from 'styled-components'
import urlJoin from 'url-join'

import { getQueryParam } from '../../../api'
import { useQuery } from '../../../hooks'
import {
  AUTH_ENDPOINT,
  AUTH_SSO_GOOGLE_ENDPOINT,
  PLANNY_BASE_URL,
  TELEGRAM_PARAMETER,
} from '../../../env'

const StyledLogo = styled.div`
  cursor: pointer;

  height: 50px;
  width: 50px;

  :hover {
    opacity: 0.9;
  }
`

export const GoogleButton: FC = () => {
  const query = useQuery()

  const telegramToken = query.get(TELEGRAM_PARAMETER)

  const PARAMETR = getQueryParam(TELEGRAM_PARAMETER, telegramToken)

  const REDIRECT_LINK = urlJoin(PLANNY_BASE_URL, AUTH_ENDPOINT, AUTH_SSO_GOOGLE_ENDPOINT, PARAMETR)

  return (
    <StyledLogo>
      <a href={REDIRECT_LINK}>
        <img src="/images/oauth_google.svg" alt="googleButton" />
      </a>
    </StyledLogo>
  )
}
