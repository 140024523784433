import styled from 'styled-components'
import urlJoin from 'url-join'
import { Col, Row } from 'antd'
import { FC, memo, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import { getCompanyLogoUrl } from '../../functions'
import { Spacing } from '../../styles'
import { UserActionsPopover } from '../actions/userActions'
import { useTypedSelector } from '../../hooks'
import { Avatar, Logo } from '../common'
import { getCompanies, getUser, getWorkspaces } from '../../store/selectors'
import { HOME_URL, USERS } from '../../constants'

type SiderHeaderPropsType = {
  collapsed: boolean
}

const StyledSiderHeader = styled(Row)`
  display: flex;
  justify-content: center;
  align-items: 'center';
  margin: ${Spacing.sm12} ${Spacing.md20} ${Spacing.md32} ${Spacing.md20};
`

const StyledIcon = styled(Col)`
  padding-top: ${Spacing.sm8};
  cursor: pointer;
`

export const SiderHeader: FC<SiderHeaderPropsType> = memo(({ collapsed }) => {
  const [isLoadImageError, setIsLoadImageError] = useState(false)

  const { user } = useTypedSelector(getUser)
  const { currentWorkspace } = useTypedSelector(getWorkspaces)
  const { currentCompany, companies } = useTypedSelector(getCompanies)

  const navigate = useNavigate()

  const handleImageLoadError = () => {
    setIsLoadImageError(true)

    return true
  }

  return (
    <StyledSiderHeader>
      <Col span={18}>
        <Logo collapsed={collapsed} onClick={() => navigate(HOME_URL)} />
      </Col>

      {!collapsed && (
        <Row justify="center">
          <Col span={20}>
            {user && currentWorkspace && companies ? (
              <Link to={urlJoin(USERS, user.id.toString())}>
                <Avatar
                  src={!isLoadImageError && getCompanyLogoUrl(currentCompany)}
                  onError={handleImageLoadError}
                />
              </Link>
            ) : (
              <Avatar />
            )}
          </Col>

          <StyledIcon span={4}>
            <UserActionsPopover />
          </StyledIcon>
        </Row>
      )}
    </StyledSiderHeader>
  )
})
