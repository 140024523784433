import { Navigate } from 'react-router-dom'
import urlJoin from 'url-join'
import React, { lazy } from 'react'

import { DashboardPage } from '../../pages'
import { NotFound } from '../../pages/service'
import { PrivateRoute } from './PrivateRoute'
import {
  CALENDAR_URL,
  CHANGE_PASSWORD,
  CLIENT_ID,
  CLIENTS_URL,
  CREATE,
  DASHBOARD_URL,
  EQUIPMENT_ID,
  EQUIPMENT_URL,
  FEEDBACK_URL,
  HOME_URL,
  INTEGRATION_TELEGRAM_URL,
  LOCATIONS_URL,
  MATERIAL_ID,
  MATERIALS_URL,
  METER_ID,
  METERS_URL,
  NOT_FOUND_URL,
  NOTIFICATIONS_URL,
  PLACE_ID,
  PPW_ID,
  PREVENTIVE_MAINTENANCE_URL,
  SERVICE_ID,
  TAB_PATHS,
  TASK_ID,
  TASKS_URL,
  USER_ID,
  USER_SERVICES_URL,
  USERS,
} from '../../constants'

const CalendarPage = lazy(() => import('../../pages/CalendarPage'))
const EquipmentAddPage = lazy(() => import('../../pages/EquipmentAddPage'))
const EquipmentEditPage = lazy(() => import('../../pages/EquipmentEditPage'))
const EquipmentTablePage = lazy(() => import('../../pages/EquipmentTablePage'))
const MaterialsAddPage = lazy(() => import('../../pages/MaterialsAddPage'))
const MaterialsEditPage = lazy(() => import('../../pages/MaterialsEditPage'))
const MaterialsTablePage = lazy(() => import('../../pages/MaterialsTablePage'))
const MetersAddPage = lazy(() => import('../../pages/MetersAddPage'))
const MetersEditPage = lazy(() => import('../../pages/MetersEditPage'))
const MetersTablePage = lazy(() => import('../../pages/MetersTablePage'))
const NotificationsPage = lazy(() => import('../../pages/NotificationsPage'))
const PlacesAddPage = lazy(() => import('../../pages/PlacesAddPage'))
const PlacesEditPage = lazy(() => import('../../pages/PlacesEditPage'))
const PlacesTablePage = lazy(() => import('../../pages/PlacesTablePage'))
const TasksAddPage = lazy(() => import('../../pages/TasksAddPage'))
const TasksEditPage = lazy(() => import('../../pages/TasksEditPage'))
const TasksTablePage = lazy(() => import('../../pages/TasksTablePage'))
const UserAccountPage = lazy(() => import('../../pages/UserAccountPage'))
const UserServiceAddPage = lazy(() => import('../../pages/UserServiceAddPage'))
const UserServiceEditPage = lazy(() => import('../../pages/UserServiceEditPage'))
const UserServicesTablePage = lazy(() => import('../../pages/UserServicesTablePage'))
const EditPasswordPage = lazy(() => import('../../pages/service/EditPasswordPage'))
const FeedbackPage = lazy(() => import('../../pages/service/FeedbackPage'))
const PpwGraphicsTablePage = lazy(() => import('../../pages/PpwGraphicsTablePage'))
const PpwGraphicsAddPage = lazy(() => import('../../pages/PpwGraphicsAddPage'))
const PpwGraphicsEditPage = lazy(() => import('../../pages/PpwGraphicsEditPage'))
const AddClientPage = lazy(() => import('../../pages/AddClientPage'))
const ClientsTablePage = lazy(() => import('../../pages/ClientsTablePage'))
const EditClientPage = lazy(() => import('../../pages/EditClientPage'))
const IntergationTelegramPage = lazy(() => import('../../pages/auth/IntergationTelegramPage'))

export const getPrivateRoutes = () => [
  {
    path: HOME_URL,
    element: <Navigate replace to={DASHBOARD_URL} />,
    index: true,
  },

  {
    path: DASHBOARD_URL,
    element: <PrivateRoute />,
    children: [
      { path: DASHBOARD_URL, element: <DashboardPage /> },
      { path: urlJoin(DASHBOARD_URL, TAB_PATHS.FAVORITES), element: <DashboardPage /> },
      { path: urlJoin(DASHBOARD_URL, TAB_PATHS.HELP), element: <DashboardPage /> },
    ],
  },

  {
    path: EQUIPMENT_URL,
    element: <PrivateRoute />,
    children: [
      { path: urlJoin(EQUIPMENT_URL), element: <EquipmentTablePage /> },
      { path: urlJoin(EQUIPMENT_URL, CREATE), element: <EquipmentAddPage /> },
      { path: urlJoin(EQUIPMENT_URL, `:${EQUIPMENT_ID}`), element: <EquipmentEditPage /> },
      {
        path: urlJoin(EQUIPMENT_URL, `:${EQUIPMENT_ID}`, TAB_PATHS.STATUSES),
        element: <EquipmentEditPage />,
      },
      {
        path: urlJoin(EQUIPMENT_URL, `:${EQUIPMENT_ID}`, TAB_PATHS.MAINTENANCE),
        element: <EquipmentEditPage />,
      },
      {
        path: urlJoin(EQUIPMENT_URL, `:${EQUIPMENT_ID}`, TAB_PATHS.FILES),
        element: <EquipmentEditPage />,
      },
      {
        path: urlJoin(EQUIPMENT_URL, `:${EQUIPMENT_ID}`, TAB_PATHS.MATERIALS),
        element: <EquipmentEditPage />,
      },
    ],
  },

  {
    path: LOCATIONS_URL,
    element: <PrivateRoute />,
    children: [
      { path: urlJoin(LOCATIONS_URL), element: <PlacesTablePage /> },
      { path: urlJoin(LOCATIONS_URL, CREATE), element: <PlacesAddPage /> },
      {
        path: urlJoin(LOCATIONS_URL, `:${PLACE_ID}`),
        element: <PlacesEditPage />,
      },
      {
        path: urlJoin(LOCATIONS_URL, `:${PLACE_ID}`, TAB_PATHS.FILES),
        element: <PlacesEditPage />,
      },
    ],
  },

  {
    path: MATERIALS_URL,
    element: <PrivateRoute />,
    children: [
      { path: urlJoin(MATERIALS_URL), element: <MaterialsTablePage /> },
      { path: urlJoin(MATERIALS_URL, CREATE), element: <MaterialsAddPage /> },
      {
        path: urlJoin(MATERIALS_URL, `:${MATERIAL_ID}`),
        element: <MaterialsEditPage />,
      },
      {
        path: urlJoin(MATERIALS_URL, `:${MATERIAL_ID}`, TAB_PATHS.FILES),
        element: <MaterialsEditPage />,
      },
      {
        path: urlJoin(MATERIALS_URL, `:${MATERIAL_ID}`, TAB_PATHS.HISTORY),
        element: <MaterialsEditPage />,
      },
    ],
  },

  {
    path: METERS_URL,
    element: <PrivateRoute />,
    children: [
      { path: urlJoin(METERS_URL), element: <MetersTablePage /> },
      { path: urlJoin(METERS_URL, CREATE), element: <MetersAddPage /> },
      {
        path: urlJoin(METERS_URL, `:${METER_ID}`),
        element: <MetersEditPage />,
      },
      {
        path: urlJoin(METERS_URL, `:${METER_ID}`, TAB_PATHS.FILES),
        element: <MetersEditPage />,
      },
      {
        path: urlJoin(METERS_URL, `:${METER_ID}`, TAB_PATHS.HISTORY),
        element: <MetersEditPage />,
      },
    ],
  },

  {
    path: USERS,
    element: <PrivateRoute />,
    children: [
      {
        path: urlJoin(USERS, `:${USER_ID}`),
        element: <UserAccountPage />,
      },
      {
        path: urlJoin(USERS, `:${USER_ID}`, TAB_PATHS.ORGANIZATIONS),
        element: <UserAccountPage />,
      },
      {
        path: urlJoin(USERS, `:${USER_ID}`, TAB_PATHS.SUBSCRIPTIONS),
        element: <UserAccountPage />,
      },
      {
        path: urlJoin(USERS, `:${USER_ID}`, CHANGE_PASSWORD),
        element: <EditPasswordPage />,
      },
    ],
  },

  {
    path: TASKS_URL,
    element: <PrivateRoute />,
    children: [
      { path: urlJoin(TASKS_URL), element: <TasksTablePage /> },
      { path: urlJoin(TASKS_URL, CREATE), element: <TasksAddPage /> },
      {
        path: urlJoin(TASKS_URL, `:${TASK_ID}`),
        element: <TasksEditPage />,
      },
      {
        path: urlJoin(TASKS_URL, `:${TASK_ID}`, TAB_PATHS.FILES),
        element: <TasksEditPage />,
      },
      {
        path: urlJoin(TASKS_URL, `:${TASK_ID}`, TAB_PATHS.PARAMETERS),
        element: <TasksEditPage />,
      },
      {
        path: urlJoin(TASKS_URL, `:${TASK_ID}`, TAB_PATHS.HISTORY),
        element: <TasksEditPage />,
      },
    ],
  },

  {
    path: USER_SERVICES_URL,
    element: <PrivateRoute />,
    children: [
      { path: urlJoin(USER_SERVICES_URL), element: <UserServicesTablePage /> },
      { path: urlJoin(USER_SERVICES_URL, CREATE), element: <UserServiceAddPage /> },
      {
        path: urlJoin(USER_SERVICES_URL, `:${SERVICE_ID}`),
        element: <UserServiceEditPage />,
      },
      {
        path: urlJoin(USER_SERVICES_URL, `:${SERVICE_ID}`, TAB_PATHS.FILES),
        element: <UserServiceEditPage />,
      },
      {
        path: urlJoin(USER_SERVICES_URL, `:${SERVICE_ID}`, TAB_PATHS.HISTORY),
        element: <UserServiceEditPage />,
      },
    ],
  },

  {
    path: PREVENTIVE_MAINTENANCE_URL,
    element: <PrivateRoute />,
    children: [
      {
        path: urlJoin(PREVENTIVE_MAINTENANCE_URL),
        element: <PpwGraphicsTablePage />,
      },
      {
        path: urlJoin(PREVENTIVE_MAINTENANCE_URL, CREATE),
        element: <PpwGraphicsAddPage />,
      },
      {
        path: urlJoin(PREVENTIVE_MAINTENANCE_URL, `:${PPW_ID}`),
        element: <PpwGraphicsEditPage />,
      },
      {
        path: urlJoin(PREVENTIVE_MAINTENANCE_URL, `:${PPW_ID}`, TAB_PATHS.GRAPHICS),
        element: <PpwGraphicsEditPage />,
      },
    ],
  },

  {
    path: CLIENTS_URL,
    element: <PrivateRoute />,
    children: [
      { path: urlJoin(CLIENTS_URL, CREATE), element: <AddClientPage /> },
      {
        path: urlJoin(CLIENTS_URL),
        element: <ClientsTablePage />,
      },
      {
        path: urlJoin(CLIENTS_URL, `:${CLIENT_ID}`),
        element: <EditClientPage />,
      },
      {
        path: urlJoin(CLIENTS_URL, `:${CLIENT_ID}`, TAB_PATHS.HISTORY),
        element: <EditClientPage />,
      },
      {
        path: urlJoin(CLIENTS_URL, `:${CLIENT_ID}`, TAB_PATHS.FILES),
        element: <EditClientPage />,
      },
    ],
  },

  {
    path: NOTIFICATIONS_URL,
    element: <PrivateRoute />,
    children: [
      { path: urlJoin(NOTIFICATIONS_URL, TAB_PATHS.HISTORY), element: <NotificationsPage /> },
    ],
  },
  {
    path: CALENDAR_URL,
    element: <PrivateRoute />,
    children: [{ path: urlJoin(CALENDAR_URL), element: <CalendarPage /> }],
  },

  {
    path: INTEGRATION_TELEGRAM_URL,
    element: <PrivateRoute />,
    children: [{ path: urlJoin(INTEGRATION_TELEGRAM_URL), element: <IntergationTelegramPage /> }],
  },

  {
    path: FEEDBACK_URL,
    element: <PrivateRoute />,
    children: [{ path: urlJoin(FEEDBACK_URL), element: <FeedbackPage /> }],
  },

  {
    path: NOT_FOUND_URL,
    element: <PrivateRoute />,
    children: [{ path: urlJoin(NOT_FOUND_URL), element: <NotFound /> }],
  },
]
