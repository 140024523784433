import { RcFile } from 'antd/lib/upload'
import { t } from 'i18next'
import urlJoin from 'url-join'
import { AxiosError, AxiosInstance, AxiosResponse } from 'axios'

import { AppDispatch } from '../../hooks'
import { getMeterItemHistory } from './history'
import { handleLoadError } from './errors'
import { loadTags } from './tags'
import { MetersActionTypes } from '../actionTypes'
import { RootState } from '../reducers/rootReducer'
import { sendNotification } from '../../components/common/notification'
import {
  CACHE_KEYS,
  CURRENT_LOCATION,
  DATA_TYPE,
  HISTORY_SISTEM_NOTIFICATIONS,
  INIT,
  MESSAGE_TYPES,
  METERS_SISTEM_NOTIFICATIONS,
  TAB_PATHS,
} from '../../constants'
import { createObject, editObject } from '../../api/shortcuts'
import { deleteTemporaryImages, deleteTemporaryTags, resetTemporaryIds } from './temporaryData'
import { getBaseLocation, getCurrentTab } from '../../functions'
import { getJsonHeader, startDataLoading } from './utils'
import { HISTORY_ENDPOINT, HISTORY_METERS_ENDPOINT, METERS_ENDPOINT } from '../../env'
import { IMeter, removeItemFromLocalStorage } from '../../api'

export type GetMetersStateFunctionType = () => RootState

// Meters

// Load Meter Item success

export const loadMeterItemSuccess = (data: IMeter) => (dispatch: AppDispatch) =>
  dispatch({
    type: MetersActionTypes.LOAD_METER_ITEM_SUCCESS,
    payload: data,
  })

// Delete Meter Item from state

export const deleteMeterItemFromState = () => (dispatch: AppDispatch) =>
  dispatch({
    type: MetersActionTypes.DELETE_METER_ITEM_FROM_STATE,
  })

export const setCreatedMeterId = (value: string | undefined) => (dispatch: AppDispatch) => {
  dispatch({
    type: MetersActionTypes.SET_CREATED_METER_ID,
    payload: value,
  })
}

// Get  Meters  GET

export const getAllMeters =
  (init?: string) =>
  (dispatch: AppDispatch, getState: GetMetersStateFunctionType, api: AxiosInstance) => {
    dispatch(startDataLoading(MetersActionTypes.START_METERS_LOADING))

    if (init && init === INIT) {
      dispatch(resetTemporaryIds())

      dispatch(deleteTemporaryTags())

      dispatch(deleteTemporaryImages())

      dispatch({
        type: MetersActionTypes.DELETE_METER_ITEM_FROM_STATE,
      })
    }

    api
      .get<IMeter[]>(urlJoin(METERS_ENDPOINT))
      .then((res: AxiosResponse) => {
        if (res.status === 200) {
          dispatch({
            type: MetersActionTypes.LOAD_METERS_SUCCESS,
            payload: res.data.data,
          })
        }
      })
      .catch((err: AxiosError) => {
        dispatch(handleLoadError(err, MetersActionTypes.LOAD_METERS_ERROR))
      })
  }

// Get Meter Item   GET

export const getMeterItem =
  (meterId: string | undefined) =>
  (dispatch: AppDispatch, getState: GetMetersStateFunctionType, api: AxiosInstance) => {
    dispatch(startDataLoading(MetersActionTypes.START_METERS_LOADING))

    api
      .get(urlJoin(METERS_ENDPOINT, meterId))
      .then((res: AxiosResponse) => {
        dispatch(loadMeterItemSuccess(res.data.data[0]))
      })
      .catch((err: AxiosError) =>
        dispatch(handleLoadError(err, MetersActionTypes.LOAD_METERS_ERROR)),
      )
  }

//  Edit Meter Item    PUT

export const editMeterItem =
  (meterId: string | undefined, data?: IMeter) =>
  (dispatch: AppDispatch, getState: GetMetersStateFunctionType, api: AxiosInstance) => {
    dispatch(startDataLoading(MetersActionTypes.START_METERS_LOADING))

    const baseLocation = getBaseLocation(window.location.pathname)
    const currentLocation = getCurrentTab(window.location.pathname)

    api
      .put(urlJoin(METERS_ENDPOINT, meterId), editObject(data))
      .then((res: AxiosResponse) => {
        if (res.status === 200) {
          dispatch(resetTemporaryIds())

          dispatch(loadMeterItemSuccess(res.data.data[0]))

          if (baseLocation === CURRENT_LOCATION.METERS || currentLocation === TAB_PATHS.MAINTENANCE)
            dispatch(getAllMeters())

          dispatch(loadTags(DATA_TYPE.METERS))

          dispatch(deleteTemporaryTags())

          sendNotification(
            MESSAGE_TYPES.SUCCESS,
            t(METERS_SISTEM_NOTIFICATIONS.UPDATE_METERS_DATA_SUCCESS),
          )
        }
      })
      .catch((err: AxiosError) =>
        dispatch(handleLoadError(err, MetersActionTypes.LOAD_METERS_ERROR)),
      )
  }

// Create Meter   POST

export const createMeterItem =
  (data: IMeter, files?: RcFile[]) =>
  (dispatch: AppDispatch, getState: GetMetersStateFunctionType, api: AxiosInstance) => {
    dispatch(startDataLoading(MetersActionTypes.START_METERS_LOADING))

    api
      .post(urlJoin(METERS_ENDPOINT), createObject(data, files))
      .then((res: AxiosResponse) => {
        if (res.status === 201) {
          dispatch(setCreatedMeterId(res.headers.location.split('/')[2]))

          dispatch({
            type: MetersActionTypes.CREATE_METER_ITEM_SUCCESS,
          })

          removeItemFromLocalStorage(CACHE_KEYS.CREATE_METER_FORM)

          dispatch(loadMeterItemSuccess(res.data.data[0]))

          dispatch(resetTemporaryIds())

          sendNotification(
            MESSAGE_TYPES.SUCCESS,
            t(METERS_SISTEM_NOTIFICATIONS.CREATE_METERS_DATA_SUCCESS),
          )
        }
      })
      .catch((err: AxiosError) =>
        dispatch(handleLoadError(err, MetersActionTypes.LOAD_METERS_ERROR)),
      )
  }

// Delete Meters Item   DELETE

export const deleteMeterItem =
  (meterId: string | undefined) =>
  (dispatch: AppDispatch, getState: GetMetersStateFunctionType, api: AxiosInstance) => {
    dispatch(startDataLoading(MetersActionTypes.START_METERS_LOADING))

    api
      .delete(urlJoin(METERS_ENDPOINT, meterId))
      .then((res: AxiosResponse) => {
        if (res.status === 204) {
          dispatch({
            type: MetersActionTypes.DELETE_METER_ITEM_SUCCESS,
            payload: Number(meterId),
          })

          dispatch(resetTemporaryIds())

          sendNotification(
            MESSAGE_TYPES.SUCCESS,
            t(METERS_SISTEM_NOTIFICATIONS.DELETE_METERS_ITEM_SUCCESS),
          )
        }
      })
      .catch((err: AxiosError) =>
        dispatch(handleLoadError(err, MetersActionTypes.LOAD_METERS_ERROR)),
      )
  }

//  Edit Meter History Item    PUT

export const editMeterHistoryItem =
  (meterId: string | undefined, uuid: string, data?: IMeter) =>
  (dispatch: AppDispatch, getState: GetMetersStateFunctionType, api: AxiosInstance) => {
    dispatch(startDataLoading(MetersActionTypes.START_METERS_LOADING))

    api
      .put(urlJoin(HISTORY_ENDPOINT, HISTORY_METERS_ENDPOINT, uuid), data, getJsonHeader())
      .then((res: AxiosResponse) => {
        if (res.status === 200) {
          dispatch({
            type: MetersActionTypes.EDIT_METER_HISTORY_ITEM_SUCCESS,
          })

          sendNotification(
            MESSAGE_TYPES.SUCCESS,
            t(HISTORY_SISTEM_NOTIFICATIONS.UPDATE_HISTORY_DATA_SUCCESS),
          )

          dispatch(getMeterItemHistory(meterId))
        }
      })
      .catch((err: AxiosError) =>
        dispatch(handleLoadError(err, MetersActionTypes.LOAD_METERS_ERROR)),
      )
  }

// Delete Meter History Item   DELETE

export const deleteMeterHistoryItem =
  (meterId: string, uuid: string) =>
  (dispatch: AppDispatch, getState: GetMetersStateFunctionType, api: AxiosInstance) => {
    dispatch(startDataLoading(MetersActionTypes.START_METERS_LOADING))

    api
      .delete(urlJoin(HISTORY_ENDPOINT, HISTORY_METERS_ENDPOINT, uuid))
      .then((res: AxiosResponse) => {
        if (res.status === 204) {
          dispatch({
            type: MetersActionTypes.DELETE_METER_HISTORY_ITEM_SUCCESS,
          })

          sendNotification(
            MESSAGE_TYPES.SUCCESS,
            t(HISTORY_SISTEM_NOTIFICATIONS.DELETE_HISTORY_ITEM_SUCCESS),
          )

          dispatch(getMeterItemHistory(meterId))
        }
      })
      .catch((err: AxiosError) =>
        dispatch(handleLoadError(err, MetersActionTypes.LOAD_METERS_ERROR)),
      )
  }
