/* eslint-disable-next-line */
import { ColumnType } from 'rc-table/lib/interface'

import { convertFirstLetterToUpperCase } from '../../../functions'
import { EMPTY_STRING } from '../../../constants'
import { FiltersType } from '../columns'
import { prepareSearchValue } from './sorter'
import {
  AllServicesType,
  EquipmentColumnsDataType,
  IEquipment,
  IFile,
  IMaterial,
  IMeter,
  IPlaces,
  IStatus,
  ITag,
  ITask,
  MaterialsColumnsDataType,
  MetersColumnsDataType,
  PlacesColumnsDataType,
  PlanType,
  StructureTableType,
  TasksColumnsDataType,
  UserServicesColumnsDataType,
} from '../../../api'

export const getPreviewImage = (images: IFile[]) => images?.filter((img) => img.isPreview)

// Compare by name

export const compare = (
  a: EquipmentColumnsDataType | PlacesColumnsDataType,
  b: EquipmentColumnsDataType | PlacesColumnsDataType,
) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)

// Unique filters array

export const condition = (obj1: FiltersType, obj2: FiltersType) => obj1.value === obj2.value

export const uniqueArray = (
  array: FiltersType[],
  cond: (al: FiltersType, el2: FiltersType) => boolean,
) => array.filter((el, index) => array.findIndex((el2) => cond(el, el2)) === index)

// Unique data array

export const idCondition = (obj1: StructureTableType, obj2: StructureTableType) => {
  if (obj1 && obj2) return obj1.id === obj2.id
  return undefined
}

export const uniqueObjectsArray = (
  array: StructureTableType[],
  cond: (obj1: StructureTableType, obj2: StructureTableType) => boolean,
) => (array ? array.filter((el, index) => array.findIndex((el2) => cond(el, el2)) === index) : [])

export const getFilteredItemsByName = <T>(data: T[], value?: string): T[] | null => {
  if (value && data) {
    return data.filter((item: AllServicesType) => prepareSearchValue(item.name).includes(value))
  }

  return data || []
}

export const getRowClassName = (data: AllServicesType[], key?: number, searchValue?: string) => {
  if (searchValue && data) {
    const filtredRowKeys = getFilteredItemsByName(data, searchValue).map((i) => i.id)

    if (filtredRowKeys.length && filtredRowKeys.includes(key)) return 'searchedRows'
  }

  return EMPTY_STRING
}

export const getTagsFilters = (
  data: (
    | MetersColumnsDataType
    | MaterialsColumnsDataType
    | EquipmentColumnsDataType
    | TasksColumnsDataType
    | UserServicesColumnsDataType
  )[],
) => {
  const allTags = data ? data.reduce((acc, item) => [...acc, ...item.tags], []) : []

  const filters = allTags.map((item: ITag) => ({
    text: item.name,
    value: item.name,
  }))

  return uniqueArray(filters, condition)
}

export const getStatusesFilters = (statuses: IStatus[]) => {
  const filters = statuses
    ? statuses.map((item: IStatus) => ({
        text: item.name,
        value: item.name,
      }))
    : []

  return uniqueArray(filters, condition)
}

export const getSubscriptionPlansFilters = (plans: PlanType[]) => {
  const filters =
    plans?.map((item: PlanType) => ({
      text: convertFirstLetterToUpperCase(item.name),
      value: convertFirstLetterToUpperCase(item.name),
    })) || []

  return uniqueArray(filters, condition)
}

export const getEquipmentImportanceLevelFilters = (
  options: { label: string; optionValue: string }[],
) => {
  const filters = options
    ? options.map((item: { label: string; optionValue: string }) => ({
        text: item.optionValue,
        value: item.optionValue,
      }))
    : []

  return uniqueArray(filters, condition)
}

const getExtraItems = (data: IEquipment[], placeId?: number, locations?: IPlaces[]) => {
  if (data && locations && placeId) {
    const locationsIds = locations
      .filter(({ hierarchy }) => hierarchy[0] === placeId)
      .map(({ id }) => id)

    const filteredData = data.filter((i: IEquipment) => locationsIds.includes(i.placeId))

    return filteredData.length ? filteredData : []
  }

  return []
}

export const getFilteredHierarchyItems = (
  data: EquipmentColumnsDataType[],
  placeId?: number,
  locations?: IPlaces[],
): IEquipment[] | null => {
  if (placeId && data && locations) {
    // Find all matches
    const searchedItems = data ? data.filter((i) => i.placeId === placeId) : []
    const extraItems = getExtraItems(data, placeId, locations)

    // Find all parent nodes

    const parentNodes = [...searchedItems, ...extraItems]
      .map((item) => {
        if (item.hierarchy) {
          if (!item.hierarchy.length || !item.parentId) return item

          return data ? data.filter((i) => i.id === item.hierarchy[0]) : []
        }

        return item
      })
      .flat()
      .filter((v, i, a) => a.indexOf(v) === i) // Unique array

    // Find all children for nodes
    const allChildren2 = parentNodes
      .map((parent) => {
        if (parent.hierarchy) {
          return data ? data.filter((i) => i.hierarchy.includes(parent.id)) : []
        }

        return []
      })
      .flat()

    return [...parentNodes, ...allChildren2]
  }

  return data || []
}

export const getFilteredItemsByPlace = (
  data: (IMaterial | IMeter)[],
  placeId?: number,
  locations?: IPlaces[],
): IEquipment[] | null => {
  if (placeId) {
    const searchedItems = data ? data.filter((i) => i.placeId === placeId) : []
    const extraItems = getExtraItems(data, placeId, locations)

    return [...searchedItems, ...extraItems]
  }

  return data || []
}

export const getFilteredTasksByPlace = (
  data: ITask[],
  placeId?: number,
  locations?: IPlaces[],
): ITask[] | null => {
  if (placeId) {
    const searchedItems = data
      ? data.filter((i) => i.places.map((item) => item.id).includes(placeId))
      : []

    const extraItems = getExtraItems(data, placeId, locations)

    return [...searchedItems, ...extraItems]
  }

  return data || []
}

export const getObjectById = (id: number, array: ITask[]) => {
  if (array) {
    const [object] = array.filter((i) => i.id === id)

    return object
  }

  return null
}
