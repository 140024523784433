import { Pie } from '@ant-design/plots'
import { t as translate } from 'i18next'
import { useTranslation } from 'react-i18next'
import { FC, memo, useEffect } from 'react'

import { Color } from '../../styles'
import { getAllMaterials } from '../../store/actions'
import { INIT } from '../../constants'
import { PieDataType } from '../../api'
import {
  getCommonStatisticsPieConfig,
  getInteractions,
  getStatisticsPieContentOptions,
  getStatisticsPieTitleOptions,
  renderStatistics,
} from '../../statisticsPieCommonOptions'
import { getMaterials, getWorkspaces } from '../../store/selectors'
import { useTypedDispatch, useTypedSelector } from '../../hooks'

const getDefaultColors = () => [Color.green, Color.orange, Color.pink]

const getEmptyStatistics = () => [
  { type: translate('many-materials'), value: 0 },
  { type: translate('less-materials'), value: 0 },
  { type: translate('no-materials'), value: 0 },
]

export type MaterialsAmountPiePropsType = {
  multiplier: number
}

export const MaterialsAmountPie: FC<MaterialsAmountPiePropsType> = memo(({ multiplier }) => {
  const { materials } = useTypedSelector(getMaterials)
  const { currentWorkspace, defaultWorkspaceId } = useTypedSelector(getWorkspaces)

  const dispatch = useTypedDispatch()

  const { t } = useTranslation()

  const getMaterialsAmount = (key: string) => {
    if (materials?.length) {
      if (key === t('many-materials'))
        return materials.filter((i) => i.unitCount > 1).length || null

      if (key === t('less-materials'))
        return materials.filter((i) => i.unitCount === 1).length || null

      return materials.filter((i) => i.unitCount === 0 || i.unitCount === null).length || null
    }

    return null
  }

  const getMaterialsData = () => {
    if (materials?.length) {
      const types = [t('many-materials'), t('less-materials'), t('no-materials')]

      return types.map((type) => {
        return {
          type,
          value: getMaterialsAmount(type),
        }
      })
    }

    return []
  }

  const data = getMaterialsData()?.length ? getMaterialsData() : getEmptyStatistics()

  const colors: string[] = data?.length ? getDefaultColors() : getDefaultColors()

  const config = {
    ...getCommonStatisticsPieConfig(t('short-items'), '{value}'),
    data,
    color: colors,
    width: 220 * multiplier,
    height: 220,

    statistic: {
      title: {
        ...getStatisticsPieTitleOptions('16px', -4),
        customHtml: (container: HTMLElement, view: any, datum: any) => {
          const { width, height } = container.getBoundingClientRect()

          const d = Math.sqrt((width / 1.5) ** 2 + (height / 1.5) ** 2)

          const text = datum ? datum.type : t('all-items')

          return renderStatistics(d, text, {
            fontSize: 16,
          })
        },
      },
      content: {
        ...getStatisticsPieContentOptions('20px', 4),
        customHtml: (container: HTMLElement, view: any, datum: any, items: any) => {
          const { width } = container.getBoundingClientRect()

          const text = datum
            ? `${datum.value} ${t('short-items')}`
            : `${items.reduce((r: number, d: PieDataType) => r + d.value, 0)} ${t('short-items')}`

          return renderStatistics(width, text, {
            fontSize: 20,
          })
        },
      },
    },

    interactions: getInteractions(),
  }

  useEffect(() => {
    if (currentWorkspace || defaultWorkspaceId) dispatch(getAllMaterials(INIT))
  }, [dispatch, currentWorkspace, defaultWorkspaceId])

  /* eslint-disable */
  return <Pie {...config} />
})
