import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { FC, memo, ReactNode } from 'react'
import { Row, Typography } from 'antd'

import { Color, FontSize, FontWeight, Spacing } from '../../styles'
import { SkeletonCard, StyledSmallPanelCard } from '../common'

const { Title, Text } = Typography

export const PromptWrapper = styled(Row)`
  margin: ${Spacing.sm12} 0;
`

export const StyledHistoryCard = styled(StyledSmallPanelCard)<{ $isIcon: boolean }>`
  .ant-card-head {
    padding-left: ${(props) => (props.$isIcon ? '0px' : '8px')};
    text-align: left !important;
  }

  h3.ant-typography {
    margin-bottom: 32px;
  }

  .ant-card-head-title {
    padding-top: ${(props) => (props.$isIcon ? '6px' : '16px')};
    font-size: ${FontSize.sm};
    font-weight: ${FontWeight.normal};
  }
`

export const StyledTitle = styled.span`
  color: ${Color.gray} !important;
`

export const StyledText = styled.span`
  font-size: ${FontSize.xs};
  color: ${Color.gray} !important;
`

export const StyledPrompt = styled.span`
  font-size: ${FontSize.md};
  color: ${Color.gray} !important;
`

export const CardText = styled(Text)`
  font-size: ${FontSize.sm};
`

export type HistoryDetailsCardPropsType = {
  title: string
  subTitle?: string
  value: string
  extraValue?: string
  noValuePrompt?: string
  icon?: ReactNode
  $danger?: boolean
}

export const HistoryDetailsCard: FC<HistoryDetailsCardPropsType> = memo(
  ({ title, subTitle, icon, value, extraValue, noValuePrompt, $danger = false }) => {
    const { t } = useTranslation()

    return (
      <StyledHistoryCard
        $height="150px"
        $width="100%"
        $isIcon={Boolean(icon)}
        title={
          <>
            {icon}
            <StyledTitle>{title}</StyledTitle>
          </>
        }
      >
        {value && (
          <Title
            type={$danger ? 'danger' : undefined}
            style={!subTitle && !extraValue ? { padding: Spacing.sm12 } : {}}
            level={3}
          >
            {value}
          </Title>
        )}

        {subTitle && <StyledText>{subTitle}</StyledText>}

        {extraValue && (
          <StyledText>
            {t('price-text')}: {extraValue}
          </StyledText>
        )}

        {!value && noValuePrompt && (
          <PromptWrapper justify="center">
            <StyledPrompt>{noValuePrompt}</StyledPrompt>
          </PromptWrapper>
        )}

        {!value && !extraValue && !noValuePrompt && <SkeletonCard width="50px" height="30px" />}
      </StyledHistoryCard>
    )
  },
)
