// import AttachFileIcon from '@mui/icons-material/AttachFile'
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined'
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined'
import EventOutlinedIcon from '@mui/icons-material/EventOutlined'
import GroupsIcon from '@mui/icons-material/Groups'
import HeadsetMicOutlinedIcon from '@mui/icons-material/HeadsetMicOutlined'
import HistoryOutlinedIcon from '@mui/icons-material/HistoryOutlined'
// import HomeWorkOutlinedIcon from '@mui/icons-material/HomeWorkOutlined'
import { LinkProps } from 'react-router-dom'
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined'
import NotificationsActiveOutlinedIcon from '@mui/icons-material/NotificationsActiveOutlined'
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined'
import { ReactNode } from 'react'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined'
// import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined'
import SpeedIcon from '@mui/icons-material/Speed'
// import TrendingUpOutlinedIcon from '@mui/icons-material/TrendingUpOutlined'
import urlJoin from 'url-join'

import { NutIcon } from '../../common/NutIcon'
import {
  // ANALITICS_URL,
  CALENDAR_URL,
  CLIENTS_URL,
  EQUIPMENT_URL,
  FEEDBACK_URL,
  // FILES_URL,
  HOME_URL,
  LOCATIONS_URL,
  MATERIALS_URL,
  METERS_URL,
  NOTIFICATIONS_URL,
  // ORDERS_URL,
  // ORGANIZATIONS_URL,
  PREVENTIVE_MAINTENANCE_URL,
  TAB_PATHS,
  TASKS_URL,
  USER_SERVICES_URL,
} from '../../../constants'

export type SiderMenuItemsType = {
  key: string
  linkProps: LinkProps
  onClick?: never
  label?: never
  icon: ReactNode
  marginBottom?: boolean
  isDisabled?: boolean
  isHidden?: boolean
}

export const getIsSiderCollapsed = () => {
  const isCollapsed = JSON.parse(localStorage.getItem('isCollapsed'))
  if (isCollapsed !== null || isCollapsed !== undefined) return isCollapsed || false

  return false
}

export const getSiderMenuItems = (isHidden: boolean): SiderMenuItemsType[] => [
  {
    key: 'dashboard',
    linkProps: {
      children: 'dashboard',
      to: `${HOME_URL}`,
    },
    icon: <DashboardOutlinedIcon />,
    isHidden,
  },
  {
    key: 'clients',
    linkProps: {
      children: 'clients',
      to: CLIENTS_URL,
    },
    icon: <GroupsIcon />,
    isHidden: !isHidden,
  },
  {
    key: 'notifications',
    linkProps: {
      children: 'notifications',
      to: urlJoin(NOTIFICATIONS_URL, TAB_PATHS.HISTORY),
    },
    icon: <NotificationsActiveOutlinedIcon />,
    marginBottom: true,
    isHidden: false,
  },
  {
    key: 'tasks',
    linkProps: {
      children: 'tasks',
      to: TASKS_URL,
    },
    icon: <HeadsetMicOutlinedIcon />,
    isHidden,
  },
  {
    key: 'calendar',
    linkProps: {
      children: 'calendar',
      to: CALENDAR_URL,
    },
    icon: <EventOutlinedIcon />,
    isHidden,
  },
  {
    key: 'preventive-mnt',
    linkProps: {
      children: 'preventive-mnt',
      to: PREVENTIVE_MAINTENANCE_URL,
    },
    icon: <HistoryOutlinedIcon />,
    marginBottom: true,
    isHidden,
  },
  // {
  //   key: 'analitics',
  //   linkProps: {
  //     children: 'analitics',
  //     to: ANALITICS_URL,
  //   },
  //   icon: <TrendingUpOutlinedIcon />,
  //   marginBottom: true,
  //   isDisabled: true,
  //   isHidden,
  // },
  {
    key: 'places',
    linkProps: {
      children: 'places',
      to: LOCATIONS_URL,
    },
    icon: <LocationOnOutlinedIcon />,
    isHidden,
  },
  {
    key: 'equipment',
    linkProps: {
      children: 'equipment',
      to: EQUIPMENT_URL,
    },
    icon: <SettingsOutlinedIcon />,
    isHidden,
  },
  {
    key: 'meters',
    linkProps: {
      children: 'meters',
      to: METERS_URL,
    },
    icon: <SpeedIcon />,
    isHidden,
  },
  {
    key: 'materials',
    linkProps: {
      children: 'materials',
      to: MATERIALS_URL,
    },
    icon: <NutIcon $outlineIcon $isSiderCollapsed={getIsSiderCollapsed()} />,
    marginBottom: true,
    isHidden,
  },
  // {
  //   key: 'orders',
  //   linkProps: {
  //     children: 'orders',
  //     to: ORDERS_URL,
  //   },
  //   icon: <ShoppingCartOutlinedIcon />,
  //   marginBottom: true,
  //   isDisabled: true,
  //   isHidden,
  // },
  {
    key: 'user-services',
    linkProps: {
      children: 'user-services',
      to: USER_SERVICES_URL,
    },
    icon: <PeopleAltOutlinedIcon />,
    marginBottom: true,
    isHidden,
  },
  // {
  //   key: 'organizations',
  //   linkProps: {
  //     children: 'organizations',
  //     to: ORGANIZATIONS_URL,
  //   },
  //   icon: <HomeWorkOutlinedIcon />,
  //   marginBottom: true,
  //   isDisabled: true,
  //   isHidden,
  // },
  // {
  //   key: 'files',
  //   linkProps: {
  //     children: 'files',
  //     to: FILES_URL,
  //   },
  //   icon: <AttachFileIcon />,
  //   isDisabled: true,
  //   isHidden,
  // },
  // {
  //   key: 'archive',
  //   linkProps: {
  //     children: 'assets-archive',
  //     to: ASSETS_ARCHIVE_URL,
  //   },
  //   icon: <ArchiveOutlinedIcon />,
  //   marginBottom: true,
  //   isDisabled: true,
  // },

  {
    key: 'feedback',
    linkProps: {
      children: 'feedback',
      to: FEEDBACK_URL,
    },
    icon: <EmailOutlinedIcon />,
    marginBottom: true,
    isHidden,
  },
]
