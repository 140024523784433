import { FC, memo, ReactElement, useCallback, useEffect, useState } from 'react'

import { LOGIN_ENDPOINT } from '../../env'
import {
  CHANNELS,
  IS_REFRESHING_STARTS,
  NO_REFRESH_ROUTES,
  REFRESH_TOKEN,
  SESSION_ID,
  SW_ACTIONS,
  SW_REGISTRATION_ERROR,
} from '../../constants'
import { getAccessTokenLifeTime, getBaseLocation } from '../../functions'
import { getItemFromLocalStorage, isNeedRefresh, setItemToLocalStorage } from '../../api'
import { useTypedDispatch, useTypedSelector } from '../../hooks'

import { getAuth, getErrors } from '../../store/selectors'

import {
  finishRefresh,
  handleAuthError,
  handleUpdateUser,
  refreshAccessTokenSuccess,
  refreshToken,
  startRefresh,
} from '../../store/actions'

const refreshChannel = new BroadcastChannel(CHANNELS.REFRESH_CHANNEL)
const serviceWorkerStatusChannel = new BroadcastChannel(CHANNELS.SW_STATUS_CHANNEL)
const logoutChannel = new BroadcastChannel(CHANNELS.LOGOUT_CHANNEL)
const authErrorChannel = new BroadcastChannel(CHANNELS.AUTH_ERROR_CHANNEL)

type AuthProviderPropsType = {
  children: ReactElement
}

const HTTP = 'http:'
const HTTPS = 'https:'

export const AuthProvider: FC<AuthProviderPropsType> = memo(({ children }) => {
  const [isServiceWorkerError, setIsServiceWorkerError] = useState(null)
  const { authError, accessToken, isRefreshStarts } = useTypedSelector(getAuth)
  const { isUserOnline } = useTypedSelector(getErrors)

  const dispatch = useTypedDispatch()

  const { protocol, pathname } = window.location

  const currentLocation = getBaseLocation(pathname)

  const isPublicRoutes = useCallback(() => {
    if (NO_REFRESH_ROUTES.indexOf(currentLocation) !== -1) return true

    return false
  }, [currentLocation])

  const isSWError = useCallback(() => {
    if (isServiceWorkerError === true || isServiceWorkerError === null) return true

    return false
  }, [isServiceWorkerError])

  // SERVICE WORKER ACTIVE

  // If SW is active, then events are transmitted through channels

  // Check if SW is active and set a flag in local storage

  serviceWorkerStatusChannel.onmessage = ({ data }) => {
    if (data === SW_ACTIONS.SW_REGISTER_ERROR) {
      setItemToLocalStorage(SW_REGISTRATION_ERROR, JSON.stringify(true))
      setIsServiceWorkerError(true)
    }

    if (data === SW_ACTIONS.SW_REGISTER_SUCCESS && protocol === HTTPS) {
      setItemToLocalStorage(SW_REGISTRATION_ERROR, JSON.stringify(false))
      setIsServiceWorkerError(false)
    }
  }

  logoutChannel.onmessage = ({ data }) => {
    if (data === SW_ACTIONS.LOGOUT_ACT) window.location.href = LOGIN_ENDPOINT
  }

  authErrorChannel.onmessage = ({ data }) => dispatch(handleAuthError(data))

  // We get a token, refresh token and sesson from SW with a successful refresh
  // and set the token in the store

  refreshChannel.onmessage = (event) => {
    if (event?.data?.accessToken) {
      dispatch(refreshAccessTokenSuccess(event.data.accessToken))
      dispatch(handleUpdateUser(event.data.accessToken))
    }

    if (event?.data === SW_ACTIONS.SW_REFRESH_STARTS) dispatch(startRefresh())
    if (event?.data === SW_ACTIONS.SW_REFRESH_FINISH) dispatch(finishRefresh())
  }

  useEffect(() => {
    // Set the SW error if the protocol is http, for local development,
    // since SW works with localhost and http

    if (protocol === HTTP) {
      setItemToLocalStorage(SW_REGISTRATION_ERROR, JSON.stringify(true))

      setIsServiceWorkerError(true)
    }
  }, [protocol])

  const refreshTokenWithCookies = useCallback(() => {
    if (!authError && !isRefreshStarts && !isPublicRoutes())
      refreshChannel.postMessage(SW_ACTIONS.NEED_REFRESH_ACT)
  }, [authError, isRefreshStarts, isPublicRoutes])

  useEffect(() => {
    // Updating the token at a constant interval

    if (!isSWError() && !isPublicRoutes()) {
      const interval = setInterval(() => refreshTokenWithCookies(), 80000)

      return () => clearInterval(interval)
    }

    return undefined
  }, [accessToken, isSWError, refreshTokenWithCookies, isPublicRoutes])

  // SERVICE WORKER IS NOT ACTIVE

  const refreshTokenWithCredentials = useCallback(() => {
    if (!authError && !isRefreshStarts && !isPublicRoutes() && isSWError()) {
      const sessionId = getItemFromLocalStorage(SESSION_ID)
      const refToken = getItemFromLocalStorage(REFRESH_TOKEN)

      if (sessionId && refToken) dispatch(refreshToken())
    }
  }, [dispatch, isPublicRoutes, authError, isRefreshStarts, isSWError])

  useEffect(() => {
    // Updating the token at a constant interval

    if (isSWError() && !isPublicRoutes()) {
      const interval = setInterval(
        () => refreshTokenWithCredentials(),
        getAccessTokenLifeTime(accessToken),
      )

      return () => clearInterval(interval)
    }

    return undefined
  }, [accessToken, isSWError, isPublicRoutes, refreshTokenWithCredentials])

  // Catch an error when the token is not valid or page reload

  useEffect(() => {
    // * Refresh token if SW is not active*
    if (
      isServiceWorkerError === true &&
      isNeedRefresh(authError, accessToken) &&
      !isPublicRoutes() &&
      !isRefreshStarts &&
      isUserOnline
    ) {
      dispatch(refreshToken())
    }

    // * Refresh token if SW is active*
    if (
      isServiceWorkerError === false &&
      !isPublicRoutes() &&
      isNeedRefresh(authError, accessToken) &&
      !isRefreshStarts &&
      isUserOnline
    ) {
      refreshChannel.postMessage(SW_ACTIONS.NEED_REFRESH_ACT)
    }
  }, [
    accessToken,
    authError,
    isRefreshStarts,
    isUserOnline,
    isServiceWorkerError,
    isPublicRoutes,
    dispatch,
  ])

  useEffect(
    () => () => {
      localStorage.removeItem(SW_REGISTRATION_ERROR)
      sessionStorage.removeItem(IS_REFRESHING_STARTS)
    },
    [],
  )

  return children
})
