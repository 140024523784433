import { RcFile } from 'antd/lib/upload'

import { FILE_TYPE } from '../constants'
import { filterEmptyValues } from '../functions'
import {
  EditBodyType,
  FileCreateType,
  ICompanyImageInfo,
  IEquipment,
  ImageInfoFieldsType,
  IMaterial,
  IMeter,
  IPlaces,
  ITag,
  ITask,
  TagCreateType,
} from './types'

const DATA = 'data'

export const isArray = (arr: any) => Array.isArray(arr)

export const getIdsArray = (ids: number[]) => (ids && isArray(ids) ? ids.map((id) => ({ id })) : [])

export const getCreatedTags = (tags: TagCreateType[]) => {
  if (tags !== undefined && tags.length)
    return tags.map(({ id, name, color }) => (!id ? { name, color } : null)).filter((i) => i)

  return []
}

export const getLinkedTags = (tags: TagCreateType[]) => {
  if (tags !== undefined && tags.length)
    return tags.map(({ id }) => (id ? { id } : null)).filter((i) => i)

  return []
}

export const prepareCreatedValues = (tags?: ITag[], files?: RcFile[]) => ({
  create: {
    tags: getCreatedTags(tags),
    images: createFiles(files),
  },
})

export const prepareUpdatedValues = (
  tags?: ITag[],
  equipmentArray?: number[],
  placesArray?: number[],
  assigneesArray?: number[],
) => ({
  update: {
    tags: getLinkedTags(tags),
    equipments: getIdsArray(equipmentArray),
    places: getIdsArray(placesArray),
    assignees: getIdsArray(assigneesArray),
  },
})

export const prepareDeletedValues = (
  deletedTagsId?: number[],
  deletedParentIds?: number[],
  placesArray?: number[],
  assigneesArray?: number[],
) => ({
  delete: {
    tags: getIdsArray(deletedTagsId),
    equipments: getIdsArray(deletedParentIds),
    places: getIdsArray(placesArray),
    assignees: getIdsArray(assigneesArray),
  },
})

export const editObject = (record: any) => {
  const formData = new FormData()

  formData.append(DATA, JSON.stringify([record]))

  return formData
}

export const createObject = (
  formValues: IEquipment | IPlaces | IMaterial | IMeter | ITask,
  files?: RcFile[],
) => {
  const formData = new FormData()

  if (files !== undefined && files.length)
    files.forEach((file) => formData.append(FILE_TYPE.FILES, file))

  formData.append(DATA, JSON.stringify([filterEmptyValues(formValues)]))

  return formData
}

export const createFiles = (files: RcFile[], type = FILE_TYPE.IMAGES) => {
  const createdFiles: FileCreateType[] = []

  if (files !== undefined && files.length)
    files.forEach((file, index) => {
      const isPreview = (type === FILE_TYPE.IMAGES && index === 0) || false
      createdFiles.push({ index, isPreview })
    })

  return createdFiles
}

// Create files

export const createFilesRecord = (files: RcFile[], type = FILE_TYPE.IMAGES) => {
  const formData = new FormData()

  const createdFiles: EditBodyType = {
    create: {
      files: [],
      images: [],
    },
  }

  if (type === FILE_TYPE.IMAGES) {
    files.forEach((file, index) => {
      createdFiles.create.images.push({ index })
      formData.append(FILE_TYPE.FILES, file)
    })
  } else {
    files.forEach((file, index) => {
      createdFiles.create.files.push({ index })
      formData.append(FILE_TYPE.FILES, file)
    })
  }

  formData.append(DATA, JSON.stringify([createdFiles]))

  return formData
}

// Delete files

export const deleteFiles = (id: number | undefined, type = FILE_TYPE.IMAGES) => {
  const formData = new FormData()

  const deletedFiles: EditBodyType = {
    delete: {
      images: [],
      files: [],
    },
  }

  if (type === FILE_TYPE.IMAGES) {
    deletedFiles.delete.images.push({ id })
  } else {
    deletedFiles.delete.files.push({ id })
  }

  formData.append(DATA, JSON.stringify([deletedFiles]))

  return formData
}

// Update preview image

export const updatePreview = (files: RcFile[], imageId?: number | undefined) => {
  const formData = new FormData()

  const updatedData: EditBodyType = {
    update: {
      images: [],
    },
    create: {
      images: [],
    },
  }

  if (imageId !== undefined)
    files.forEach((file, index) => {
      updatedData.update.images.push({ id: imageId, index, isPreview: true })
      formData.append(FILE_TYPE.FILES, file)
    })

  if (imageId === undefined)
    files.forEach((file, index) => {
      updatedData.create.images.push({ index, isPreview: true })
      formData.append(FILE_TYPE.FILES, file)
    })

  formData.append(DATA, JSON.stringify([updatedData]))

  return formData
}

// Materials

export const linkMaterials = (equipmentId: number) => {
  const formData = new FormData()

  const updatedData: EditBodyType = {
    update: {
      equipments: getIdsArray([equipmentId]),
    },
  }

  formData.append(DATA, JSON.stringify([updatedData]))

  return formData
}

export const unlinkMaterials = (equipmentId: number) => {
  const formData = new FormData()

  const updatedData: EditBodyType = {
    delete: {
      equipments: equipmentId ? [{ id: equipmentId }] : [],
    },
  }

  formData.append(DATA, JSON.stringify([updatedData]))

  return formData
}

// Update preview image

export const updateCompanyPreviewImage = (data: ICompanyImageInfo, files: RcFile[]) => {
  const formData = new FormData()

  const getContentType = () => (files?.length ? files[0].type : '')

  if (data) {
    const { fields } = data

    for (const key in fields) {
      if (Object.prototype.hasOwnProperty.call(fields, key)) {
        formData.append(key, fields[key as keyof ImageInfoFieldsType])
      }
    }
  }

  formData.append('content-type', getContentType())

  if (files) {
    files.forEach((file) => {
      formData.append('file', file)
    })
  }

  return formData
}
