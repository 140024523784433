import { throttle } from 'throttle-debounce'
import { useEffect, useState } from 'react'

import { BREAKPOINTS } from '../constants'

const RESIZE = 'resize'

const getDeviceConfig = (width: number) => {
  if (width < 720) return BREAKPOINTS.sm

  if (width >= 720 && width < 1024) return BREAKPOINTS.md

  if (width >= 1024 && width < 1200) return BREAKPOINTS.lg

  if (width >= 1200 && width < 1400) return BREAKPOINTS.xl

  if (width >= 1400 && width < 1920) return BREAKPOINTS.xxl

  return BREAKPOINTS.xxxl
}

export const useBreakpoint = () => {
  const [breakpoint, setBreakpoint] = useState(() => getDeviceConfig(window.innerWidth))

  useEffect(() => {
    const calcInnerWidth = throttle(300, () => setBreakpoint(getDeviceConfig(window.innerWidth)))

    window.addEventListener(RESIZE, calcInnerWidth)

    return () => window.removeEventListener(RESIZE, calcInnerWidth)
  }, [])

  return breakpoint
}
