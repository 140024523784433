import { RcFile } from 'antd/lib/upload'

export type ImagesStateType = {
  tempImagesFiles: RcFile[]
  isImagesLoading: boolean
  imagesError: null | string
}

export enum ImagesActionTypes {
  START_IMAGES_LOADING = 'START_IMAGES_LOADING',
  LOAD_IMAGES_ERROR = 'LOAD_IMAGES_ERROR',
  CREATE_IMAGES_SUCCESS = 'CREATE_IMAGES_SUCCESS',
  SAVE_TEMPORARY_IMAGES = 'SAVE_TEMPORARY_IMAGES',
  DELETE_IMAGE_SUCCESS = 'DELETE_IMAGE_SUCCESS',
  DELETE_TEMPORARY_IMAGES = 'DELETE_TEMPORARY_IMAGES',
  DELETE_TEMPORARY_IMAGE_ITEM = 'DELETE_TEMPORARY_IMAGE_ITEM',
}

export type StartLoadingImagesActionType = {
  type: ImagesActionTypes.START_IMAGES_LOADING
}

export type LoadingImagesErrorActionType = {
  type: ImagesActionTypes.LOAD_IMAGES_ERROR
  payload: string
}

export type SaveTemporaryImagesActionType = {
  type: ImagesActionTypes.SAVE_TEMPORARY_IMAGES
  payload: any
}

export type CreateImagesActionType = {
  type: ImagesActionTypes.CREATE_IMAGES_SUCCESS
}

export type DeleteTemporaryImagesActionType = {
  type: ImagesActionTypes.DELETE_TEMPORARY_IMAGES
}

export type DeleteImageActionType = {
  type: ImagesActionTypes.DELETE_IMAGE_SUCCESS
}

export type DeleteTemporaryImageItemActionType = {
  type: ImagesActionTypes.DELETE_TEMPORARY_IMAGE_ITEM
  payload: string
}

export type ImagesActionType =
  | StartLoadingImagesActionType
  | LoadingImagesErrorActionType
  | SaveTemporaryImagesActionType
  | DeleteTemporaryImagesActionType
  | DeleteTemporaryImageItemActionType
  | DeleteImageActionType
  | CreateImagesActionType
