export const DEFAULT_SELECT_VALUE = '12:30'
export const DEFAULT_TIME_VALUE = '09:00'
export const DEFAULT_NOTIFICATION_VALUE = 24
export const DEFAULT_OPTION_ID = 25

export const WORK_DAYS = [1, 2, 3, 4, 5]
export const ALL_WEEK_DAYS = [0, 1, 2, 3, 4, 5, 6]

export enum WEEK_DAYS {
  MONDAY = 1,
  TUESDAY = 2,
  WEDNESDAY = 3,
  THURSDAY = 4,
  FRIDAY = 5,
  SATURDAY = 6,
  SUNDAY = 0,
}

export enum PERIOD_TYPES {
  MINUTE = 'm',
  HOUR = 'h',
  DAY = 'D',
  WEEK = 'W',
  MONTHLY = 'M',
  YEAR = 'Y',
  CUSTOM = 'CUSTOM',
}

export enum REPITITION_TYPES {
  DAYLY = 'DAYLY',
  WEEKY = 'WEEKY',
  MONTHLY = 'MONTHLY',
  CUSTOM = 'CUSTOM',
}

export const REPITITION_TYPES_OPTIONS = [
  {
    id: 1,
    exactValue: PERIOD_TYPES.DAY,
    label: 'input-label-daily',
    extraLabel: '',
    optionValue: REPITITION_TYPES.DAYLY,
  },
  {
    id: 2,
    exactValue: PERIOD_TYPES.WEEK,
    label: 'input-label-weekly',
    extraLabel: '',
    optionValue: REPITITION_TYPES.WEEKY,
  },
  {
    id: 3,
    exactValue: PERIOD_TYPES.MONTHLY,
    label: 'input-label-monthly',
    extraLabel: 'input-label-each',
    suffix: 'input-label-day-of-month',
    optionValue: REPITITION_TYPES.MONTHLY,
  },
  {
    id: 4,
    exactValue: PERIOD_TYPES.DAY,
    label: 'input-label-each-pl',
    extraLabel: 'input-label-start from',
    optionValue: REPITITION_TYPES.CUSTOM,
  },
]

export enum RELATION_TYPES {
  GREATER_THEN = 'gt',
  GREATER_OR_EQUAL = 'ge',
  EQUAL = 'eq',
  NOT_EQUAL = 'ne',
  LESS_THEN = 'lt',
  LESS_OR_EQUAL = 'le',
}

export enum METER_PARAMETERS {
  VALUE = 'value',
  INTERVAL = 'interval',
}
