import { AxiosError } from 'axios'
import {
  IMaterial,
  IMaterialCurrencies,
  IMaterialHistory,
  IMaterialMetaHistory,
  IMeasurementUnits,
} from '../../api'

export type MaterialsStateType = {
  materials: IMaterial[]
  materialItem: IMaterial | undefined
  tempParentIds: number[] | undefined | null
  createdId: string | undefined
  currencies: IMaterialCurrencies[] | null
  measurementUnits: IMeasurementUnits[] | null
  history: IMaterialHistory[] | null
  metaHistory: IMaterialMetaHistory
  isMaterialsLoading: boolean
  materialsError: AxiosError | null
}

export enum MaterialsActionTypes {
  START_MATERIALS_LOADING = 'START_MATERIALS_LOADING',
  LOAD_MATERIALS_SUCCESS = 'LOAD_MATERIALS_SUCCESS',
  LOAD_MATERIAL_ITEM_SUCCESS = 'LOAD_MATERIAL_ITEM_SUCCESS',
  LOAD_MATERIALS_ERROR = 'LOAD_MATERIALS_ERROR',
  LOAD_MATERIAL_CURRENCIES_SUCCESS = 'LOAD_MATERIAL_CURRENCIES_SUCCESS',
  LOAD_MATERIAL_MEASUREMENT_UNITS_SUCCESS = 'LOAD_MATERIAL_MEASUREMENT_UNITS_SUCCESS',
  LOAD_MATERIAL_HISTORY_SUCCESS = 'LOAD_MATERIAL_HISTORY_SUCCESS',
  EDIT_MATERIAL_HISTORY_ITEM_SUCCESS = 'EDIT_MATERIAL_HISTORY_ITEM_SUCCESS',
  DELETE_MATERIAL_HISTORY_ITEM_SUCCESS = 'DELETE_MATERIAL_HISTORY_ITEM_SUCCESS',
  LOAD_MATERIAL_META_HISTORY_SUCCESS = 'LOAD_MATERIAL_META_HISTORY_SUCCESS',
  CREATE_MATERIAL_ITEM_SUCCESS = 'CREATE_MATERIAL_ITEM_SUCCESS',
  DELETE_MATERIAL_ITEM_SUCCESS = 'DELETE_MATERIAL_ITEM_SUCCESS',
  SAVE_TEMPORARY_MATERIAL_TAGS = 'SAVE_TEMPORARY_MATERIAL_TAGS',
  SAVE_TEMPORARY_PARENT_IDS = 'SAVE_TEMPORARY_PARENT_IDS',
  DELETE_TEMPORARY_PARENT_IDS = 'DELETE_TEMPORARY_PARENT_IDS',
  DELETE_MATERIAL_ITEM_FROM_STATE = 'DELETE_MATERIAL_ITEM_FROM_STATE',
  SET_CREATED_MATERIAL_ID = 'SET_CREATED_MATERIAL_ID',
}

export type StartLoadingMaterialsActionType = {
  type: MaterialsActionTypes.START_MATERIALS_LOADING
}

export type LoadMaterialsSuccessActionType = {
  type: MaterialsActionTypes.LOAD_MATERIALS_SUCCESS
  payload: IMaterial[]
}

export type LoadMaterialItemSuccessActionType = {
  type: MaterialsActionTypes.LOAD_MATERIAL_ITEM_SUCCESS
  payload: IMaterial
}

export type LoadMaterialHistorySuccessActionType = {
  type: MaterialsActionTypes.LOAD_MATERIAL_HISTORY_SUCCESS
  payload: IMaterialHistory[]
}

export type LoadingErrorMaterialsActionType = {
  type: MaterialsActionTypes.LOAD_MATERIALS_ERROR
  payload: AxiosError
}

export type LoadMaterialCurrenciesSuccessActionType = {
  type: MaterialsActionTypes.LOAD_MATERIAL_CURRENCIES_SUCCESS
  payload: IMaterialCurrencies[]
}

export type LoadMaterialMeasurementUnitsSuccessActionType = {
  type: MaterialsActionTypes.LOAD_MATERIAL_MEASUREMENT_UNITS_SUCCESS
  payload: IMeasurementUnits[]
}

export type CreateMaterialItemSuccessActionType = {
  type: MaterialsActionTypes.CREATE_MATERIAL_ITEM_SUCCESS
}

export type DeleteMaterialItemSuccessActionType = {
  type: MaterialsActionTypes.DELETE_MATERIAL_ITEM_SUCCESS
  payload: number
}

export type SaveTemporaryParentIdsActionType = {
  type: MaterialsActionTypes.SAVE_TEMPORARY_PARENT_IDS
  payload: number[] | null
}

export type DeleteTemporaryParentIdsActionType = {
  type: MaterialsActionTypes.DELETE_TEMPORARY_PARENT_IDS
}

export type SetCreatedMaterialIdActionType = {
  type: MaterialsActionTypes.SET_CREATED_MATERIAL_ID
  payload: string | undefined
}

export type DeleteMaterialItemFromStateActionType = {
  type: MaterialsActionTypes.DELETE_MATERIAL_ITEM_FROM_STATE
}

export type LoadMaterialMetaHistorySuccessActionType = {
  type: MaterialsActionTypes.LOAD_MATERIAL_META_HISTORY_SUCCESS
  payload: IMaterialMetaHistory
}

export type EditMaterialHistoryItemSuccessActionType = {
  type: MaterialsActionTypes.EDIT_MATERIAL_HISTORY_ITEM_SUCCESS
}

export type DeleteMaterialHistoryItemSuccessActionType = {
  type: MaterialsActionTypes.DELETE_MATERIAL_HISTORY_ITEM_SUCCESS
}

export type MaterialsActionType =
  | StartLoadingMaterialsActionType
  | LoadMaterialsSuccessActionType
  | LoadingErrorMaterialsActionType
  | CreateMaterialItemSuccessActionType
  | DeleteMaterialItemSuccessActionType
  | LoadMaterialItemSuccessActionType
  | LoadMaterialCurrenciesSuccessActionType
  | LoadMaterialHistorySuccessActionType
  | LoadMaterialMeasurementUnitsSuccessActionType
  | SaveTemporaryParentIdsActionType
  | DeleteTemporaryParentIdsActionType
  | DeleteMaterialItemFromStateActionType
  | SetCreatedMaterialIdActionType
  | LoadMaterialMetaHistorySuccessActionType
  | EditMaterialHistoryItemSuccessActionType
  | DeleteMaterialHistoryItemSuccessActionType
