/* eslint-disable-next-line */
import { ColumnType } from 'rc-table/lib/interface'
import scrollIntoView from 'scroll-into-view'
import { t } from 'i18next'

import { Color } from '../../../styles'
import { urlConverter } from '../../../functions'
import {
  AllColumnsType,
  SortColumnsKeysType,
  SorterColumnsType,
  TABLE_ICONS_DATA_INDEXES,
} from '../columns'
import { BREAKPOINTS, NOT_DEFAULT_CHECKED_VALUES, SW_REGISTRATION_ERROR } from '../../../constants'
import {
  EquipmentColumnsDataType,
  GenericFunctionDataType,
  getItemFromLocalStorage,
  getItemFromSessionStorage,
  IdObject,
  IEquipment,
  IFile,
  IPlaces,
  IUserServices,
  setItemToSessionStorage,
} from '../../../api'

export const getLastArrayElement = (array: number[]) => array.slice(-1)[0]

export const scrollToCreatedItem = (rowElement: HTMLElement) => {
  const element = rowElement

  scrollIntoView(rowElement, {
    align: {
      top: 0,
    },
  })

  element.className = 'searchedRows'
}

export const getCreatedParentElement = (
  createdId: string,
  data: (IEquipment | IPlaces | IUserServices)[],
) => {
  if (createdId) {
    const [createdItem] = data ? data.filter((item) => item.id === Number(createdId)) : []

    if (createdItem && createdItem.hierarchy.length) return createdItem.hierarchy[0]

    if (createdItem && !createdItem.hierarchy.length && createdItem.parentId)
      return createdItem.parentId

    return createdId
  }

  return null
}

export const getEquipmentItem = (array: IEquipment[], key: number) => {
  return array ? array.filter((item) => item.id === key) : []
}

export const findPlaceId = (key: number, data: IEquipment[]) => {
  const [equipmentItem] = getEquipmentItem(data, key)

  return equipmentItem ? equipmentItem.placeId : undefined
}

export const getEquipmentPlaceId = (item: IEquipment) => {
  if (item) return item.placeId

  return null
}

export const convertImagesUrls = (imgs: IFile[], query: string) => {
  const isServiceWorkerError = JSON.parse(getItemFromLocalStorage(SW_REGISTRATION_ERROR))

  return imgs
    ? imgs.map((img) =>
        isServiceWorkerError === false ? { ...img, url: urlConverter(img.url, query) } : img,
      )
    : []

  // return imgs ? imgs.map((img) => ({ ...img, url: urlConverter(img.url) })) : []
}

export const getDefaultVisibleColumns = (columns: ColumnType<EquipmentColumnsDataType>[]) => {
  return columns
    ? columns
        .map((col: any) => {
          return !NOT_DEFAULT_CHECKED_VALUES.includes(col.dataIndex) ? col : undefined
        })
        .filter((i: ColumnType<EquipmentColumnsDataType>) => i)
    : []
}

export const getEquipmentList = (equipments: IEquipment[]) => {
  if (equipments && equipments.length === 1) return equipments[0]

  if ((equipments && !equipments.length) || equipments.length > 1) return { name: '' }

  return { name: '' }
}

export const getHeaderIconColor = (cols: SorterColumnsType[], key: SortColumnsKeysType) => {
  const sortedColumn = cols?.find(({ column }) => column.key === key)

  if (sortedColumn?.order) return Color.blue

  return null
}

export const getColumnTitle = (dataIndex: string, title: string) => {
  if (title === '№') return t('number-column-title')

  const [icon] = TABLE_ICONS_DATA_INDEXES.filter((i) => i.value === dataIndex)

  return icon ? t(icon.label) : title
}

export const getColumnsOptions = (
  columns: ColumnType<AllColumnsType>[],
): { label: string; value: string }[] =>
  columns
    ? columns
        .map(
          ({ title, dataIndex }) =>
            title && {
              label: getColumnTitle(dataIndex as string, title as string),
              value: dataIndex as string,
            },
        )
        .filter((opt) => opt)
    : []

export const getExpandButtonText = (isAllRowsExpanded: boolean) =>
  isAllRowsExpanded ? t('collapse-all') : t('spread-all')

export const setExpandedRowsToSessionStorage = (storeKey: string, rowKeys: string[]) =>
  setItemToSessionStorage(storeKey, JSON.stringify(rowKeys))

export const getExpandedRowsFromStorage = (key: string) => {
  const expandKeys = JSON.parse(getItemFromSessionStorage(key))

  return expandKeys || []
}

export const mergeColumns = (
  columns: GenericFunctionDataType[],
  handleResize: (index: number) => void,
) => {
  return columns.map((col, index) => ({
    ...col,
    onHeaderCell: (column: GenericFunctionDataType) => ({
      width: column.width,
      onResize: handleResize(index),
    }),
  }))
}

export const getResizedColumns = (
  columns: GenericFunctionDataType[],
  size: { width: number; height: number },
  index: number,
) => {
  const newColumns = [...columns]

  newColumns[index] = {
    ...newColumns[index],
    width: size.width,
  }

  return newColumns
}

export const getTemporaryEquipment = (equipment: IEquipment[], tempIds: number[]) => {
  if (tempIds !== undefined && tempIds?.length)
    return equipment.filter((item: IEquipment) => tempIds.includes(item.id))

  return null
}

export const getEquipmentObjectsList = (
  equipment: IEquipment[],
  equipmentArray: IdObject[],
  tempIds: number[],
) => {
  if (equipmentArray && tempIds === undefined)
    return equipment
      ? equipment.filter((item: IEquipment) => equipmentArray.map((i) => i.id).includes(item.id))
      : []

  return null
}

export const getScrollHeight = (breakpoint: BREAKPOINTS) => {
  if (breakpoint === BREAKPOINTS.xxxl) return `calc(100vh - ${250}px)`

  return `calc(100vh + ${60}px)`
}

export const getElementsPerPage = (breakpoint: BREAKPOINTS) => {
  if (breakpoint === BREAKPOINTS.xxxl) return 13

  return 11
}
