import { EMPTY_STRING } from '../../constants'
import { FiltersActionType, FiltersActionTypes, FiltersStateType } from '../actionTypes'

const initialState: FiltersStateType = {
  searchValue: {
    equipment: EMPTY_STRING,
    places: EMPTY_STRING,
    materials: EMPTY_STRING,
    meters: EMPTY_STRING,
    tasks: EMPTY_STRING,
    userServices: EMPTY_STRING,
    ppwGraphics: EMPTY_STRING,
    clients: EMPTY_STRING,
  },
}

export const filters = (state = initialState, action: FiltersActionType): FiltersStateType => {
  if (action) {
    switch (action.type) {
      case FiltersActionTypes.SET_SEARCH_VALUE:
        return {
          ...state,
          searchValue: {
            ...state.searchValue,
            [action.payload.dataType]: action.payload.searchValue,
          },
        }

      default:
        return state
    }
  } else {
    return state
  }
}
