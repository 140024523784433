import { convertImagesUrls } from './helpers'
import { getPreviewImage } from './filter'
import { SelectTablesColumnsType } from '../columns/columnTypes'
import {
  EquipmentColumnsDataType,
  PlacesColumnsDataType,
  StructureTableType,
  UserServicesColumnsDataType,
} from '../../../api'

// Structure select table

const getChildrenItem = (parentId: number, data: StructureTableType[]) =>
  data ? data.filter((item: StructureTableType) => item.parentId === parentId) : []

export const getStructureTableHierarchy = (
  preparedData: StructureTableType[],
  allData: StructureTableType[],
): SelectTablesColumnsType[] => {
  return preparedData.map((item) => {
    const children = getChildrenItem(item.id, allData)

    if (children.length) {
      return {
        ...item,
        key: item.id,
        name: item.name,
        children: getStructureTableHierarchy(children, allData),
      }
    }

    return {
      ...item,
      key: item.id,
      name: item.name,
      children: [],
    }
  })
}

// Places table data

export const getPlacesTableHierarchy = (
  preparedArray: PlacesColumnsDataType[],
  tableData: PlacesColumnsDataType[],
  query: string,
): PlacesColumnsDataType[] => {
  return preparedArray.map<PlacesColumnsDataType>((item) => {
    const children = getChildrenItem(item.id, tableData)

    if (children.length) {
      return {
        ...item,
        key: item.id,
        images: convertImagesUrls(getPreviewImage(item.images), query),
        children: getPlacesTableHierarchy(children, tableData, query),
      }
    }

    return {
      ...item,
      key: item.id,
      images: convertImagesUrls(getPreviewImage(item.images), query),
      children: [],
    }
  })
}

// Equipment table data

export const getEquipmentTableHierarchy = (
  preparedArray: EquipmentColumnsDataType[],
  tableData: EquipmentColumnsDataType[],
  query: string,
): EquipmentColumnsDataType[] => {
  return preparedArray.map<EquipmentColumnsDataType>((item) => {
    const children = getChildrenItem(item.id, tableData)

    if (children.length) {
      return {
        ...item,
        key: item.id,
        images: convertImagesUrls(getPreviewImage(item.images), query),
        children: getEquipmentTableHierarchy(children, tableData, query),
      }
    }

    return {
      ...item,
      key: item.id,
      images: convertImagesUrls(getPreviewImage(item.images), query),
      children: [],
    }
  })
}

// User Services table data

export const getUserServicesTableHierarchy = (
  preparedArray: UserServicesColumnsDataType[],
  tableData: UserServicesColumnsDataType[],
  query: string,
): UserServicesColumnsDataType[] => {
  return preparedArray.map<UserServicesColumnsDataType>((item) => {
    const children = getChildrenItem(item.id, tableData)

    if (children.length) {
      return {
        ...item,
        key: item.id,
        images: item.images ? convertImagesUrls(getPreviewImage(item.images), query) : [],
        children: getUserServicesTableHierarchy(children, tableData, query),
      }
    }

    return {
      ...item,
      key: item.id,
      images: item.images ? convertImagesUrls(getPreviewImage(item.images), query) : [],
      children: [],
    }
  })
}

// Equipment table list data

export const getEquipmentTableListData = (
  data: EquipmentColumnsDataType[],
  query: string,
): EquipmentColumnsDataType[] => {
  return data.map<EquipmentColumnsDataType>((item) => {
    return {
      ...item,
      key: item.id,
      images: convertImagesUrls(getPreviewImage(item.images), query),
    }
  })
}
