import { FC } from 'react'
import { Helmet } from 'react-helmet'
import { useLocation } from 'react-router-dom'

import { LOGIN_REGISTER_METER, NO_SCRIPT_TAG_FOR_LOGIN_METER } from '../utils'
import { PLANNY_TITLE, REGISTER, SUCCESS_VERIFICATION_EMAIL } from '../../../constants'

export type AuthHelmetPropsType = {
  pageTitle?: string
}

export const AuthHelmet: FC<AuthHelmetPropsType> = ({ pageTitle }) => {
  const { pathname } = useLocation()

  return pathname === REGISTER || pathname === SUCCESS_VERIFICATION_EMAIL ? (
    <Helmet>
      <title>
        {pageTitle} | {PLANNY_TITLE}
      </title>

      <script type="text/javascript">{LOGIN_REGISTER_METER}</script>
      <noscript>{NO_SCRIPT_TAG_FOR_LOGIN_METER}</noscript>
    </Helmet>
  ) : (
    <Helmet>
      <title>
        {pageTitle} | {PLANNY_TITLE}
      </title>
    </Helmet>
  )
}
