import { useTranslation } from 'react-i18next'
import { FC, useCallback, useEffect, useState } from 'react'
import { Radio, RadioChangeEvent, Row, Table } from 'antd'
import { useLocation, useParams } from 'react-router-dom'

import { EmptyTable } from './commonComponents/EmptyTable'
import { EquipmentColumnsDataType } from '../../api'
import { ExpandedIcon } from './commonComponents/ExpandedIcon'
import { getItemsSelectTableColumns } from './columns/itemSelectTableColumns'
import { prepareSearchValue } from './helpers/sorter'
import { SearchForm } from '../forms'
import { sendNotification } from '../common/notification'
import { setTemporaryDataIds } from '../../store/actions'
import { Spacing } from '../../styles'
import { StyledTableDivider } from '../common'
import { TableWrapper } from './commonComponents/styled'
import { CREATE, DATA_TYPE, EQUIPMENT_SISTEM_NOTIFICATIONS, MESSAGE_TYPES } from '../../constants'
import { findPlaceId, getRowClassName } from './helpers'
import { getEquipment, getFilters, getTemporaryData } from '../../store/selectors'
import { useTypedDispatch, useTypedSelector } from '../../hooks'

export type EquipmentSelectTablePropsType = {
  data?: EquipmentColumnsDataType[]
  selectedItemId?: number
}

export const EquipmentSelectTable: FC<EquipmentSelectTablePropsType> = ({
  data,
  selectedItemId,
}) => {
  const [checked, setChecked] = useState(false)
  const [selectedKey, setSelectedKey] = useState(null)

  const { equipment } = useTypedSelector(getEquipment)
  const { searchValue } = useTypedSelector(getFilters)
  const { tempIds } = useTypedSelector(getTemporaryData)

  const { equipmentId } = useParams<{ equipmentId?: string }>()

  const { pathname } = useLocation()

  const { t } = useTranslation()

  const isCreatePage = pathname.split('/')[2] === CREATE

  const dispatch = useTypedDispatch()

  const handleSelectRow = (key: number) => {
    if (Number(equipmentId) === key) {
      sendNotification(
        MESSAGE_TYPES.ERROR,
        t(EQUIPMENT_SISTEM_NOTIFICATIONS.EQUIPMENT_SELECTION_ERROR),
      )
    } else {
      const foundId = findPlaceId(key, equipment)

      setChecked(false)
      setSelectedKey(key)

      dispatch(setTemporaryDataIds(DATA_TYPE.EQUIPMENT, [key]))

      dispatch(setTemporaryDataIds(DATA_TYPE.PLACES, [foundId]))
    }
  }

  const resetState = () => {
    setSelectedKey(null)
    setChecked(true)
  }

  const handleOnChange = (e: RadioChangeEvent) => {
    setChecked(e.target.checked)
    setSelectedKey(null)

    dispatch(setTemporaryDataIds(DATA_TYPE.PLACES, undefined))
    dispatch(setTemporaryDataIds(DATA_TYPE.EQUIPMENT, null))
  }

  const getInitialEquipmentSelectItem = useCallback(() => {
    if (
      (isCreatePage && !tempIds.equipment) ||
      (!isCreatePage && !selectedItemId && !tempIds.equipment)
    )
      resetState()

    if (isCreatePage && tempIds.equipment && tempIds.equipment.length)
      setSelectedKey(tempIds.equipment[0])

    if (!isCreatePage && selectedItemId && tempIds.equipment === undefined)
      setSelectedKey(selectedItemId)
  }, [isCreatePage, tempIds, selectedItemId])

  const handleSearchRow = (key: number) => {
    return getRowClassName(equipment, key, prepareSearchValue(searchValue.equipment))
  }

  useEffect(() => getInitialEquipmentSelectItem(), [getInitialEquipmentSelectItem])

  return (
    <TableWrapper>
      <Row align="middle">
        <Radio checked={checked} onChange={handleOnChange} style={{ marginLeft: Spacing.sm8 }}>
          {t('no-selections')}
        </Radio>
        <StyledTableDivider orientation="center" $mt={Spacing.sm12} $mb={Spacing.sm8} />
      </Row>

      <Row align="middle">
        <SearchForm $isModal dataType={DATA_TYPE.EQUIPMENT} />
        <StyledTableDivider orientation="center" $mt={Spacing.sm8} $mb={Spacing.sm12} />
      </Row>

      <Table
        columns={getItemsSelectTableColumns([selectedKey])}
        dataSource={data || []}
        bordered={false}
        pagination={false}
        scroll={
          data.length > 6 && !searchValue
            ? { y: '350px' }
            : equipment.length > 6 && searchValue
            ? { y: '350px' }
            : {}
        }
        showHeader={false}
        rowClassName={(record) => handleSearchRow(record.key)}
        onRow={(record) => ({
          onClick: () => handleSelectRow(record.key),
        })}
        locale={{
          emptyText: (
            <EmptyTable
              prompt={t('no-items')}
              hasButton
              navigationPath={`/${DATA_TYPE.EQUIPMENT}/${CREATE}`}
            />
          ),
        }}
        expandable={{
          defaultExpandAllRows: true,
          indentSize: 20,
          expandIcon: ({ expanded, onExpand, record }) => (
            <ExpandedIcon expanded={expanded} record={record} onExpand={onExpand} />
          ),
        }}
        style={{ minWidth: '100%', margin: '0' }}
      />
    </TableWrapper>
  )
}
