import { EquipmentActionType, EquipmentActionTypes, EquipmentStateType } from '../actionTypes'

const initialState: EquipmentStateType = {
  equipment: undefined,
  equipmentItem: undefined,
  createdId: undefined,
  statuses: undefined,
  history: null,
  historyStats: null,
  isEquipmentLoading: false,
  isEquipmentItemLoading: false,
  isStatusesLoading: false,
  isEquipmentHistoryLoading: false,
  equipmentError: null,
  equipmentItemError: null,
  equipmentStatusesError: null,
  equipmentHistoryError: null,
}

export const equipment = (
  state = initialState,
  action: EquipmentActionType,
): EquipmentStateType => {
  if (action) {
    switch (action.type) {
      case EquipmentActionTypes.START_EQUIPMENT_LOADING:
        return { ...state, isEquipmentLoading: true }

      case EquipmentActionTypes.START_EQUIPMENT_ITEM_LOADING:
        return { ...state, isEquipmentItemLoading: true }

      case EquipmentActionTypes.START_EQUIPMENT_HISTORY_LOADING:
        return { ...state, isEquipmentHistoryLoading: true }

      case EquipmentActionTypes.START_STATUSES_LOADING:
        return { ...state, isStatusesLoading: true }

      case EquipmentActionTypes.LOAD_EQUIPMENT_SUCCESS:
        return {
          ...state,
          isEquipmentLoading: false,
          equipmentError: null,
          equipment: action.payload,
        }

      case EquipmentActionTypes.LOAD_EQUIPMENT_ITEM_SUCCESS:
        return {
          ...state,
          isEquipmentItemLoading: false,
          equipmentItemError: null,
          equipmentItem: action.payload,
        }

      case EquipmentActionTypes.LOAD_EQUIPMENT_STATUSES_SUCCESS:
        return {
          ...state,
          isStatusesLoading: false,
          equipmentStatusesError: null,
          statuses: action.payload,
        }

      case EquipmentActionTypes.LOAD_EQUIPMENT_HISTORY_SUCCESS:
        return {
          ...state,
          isEquipmentHistoryLoading: false,
          equipmentHistoryError: null,
          history: action.payload,
        }

      case EquipmentActionTypes.LOAD_EQUIPMENT_HISTORY_STATS_SUCCESS:
        return {
          ...state,
          isEquipmentHistoryLoading: false,
          equipmentHistoryError: null,
          historyStats: action.payload,
        }

      case EquipmentActionTypes.DELETE_EQUIPMENT_ITEM_FROM_STATE:
        return { ...state, equipmentItem: undefined }

      case EquipmentActionTypes.DELETE_EQUIPMENT_ITEM_SUCCESS:
        return {
          ...state,
          equipment: state.equipment
            ? state.equipment.filter((i) => i.id !== action.payload)
            : undefined,
        }

      case EquipmentActionTypes.DELETE_EQUIPMENT_FROM_STATE:
        return { ...state, equipment: undefined }

      case EquipmentActionTypes.CREATE_EQUIPMENT_ITEM_SUCCESS:
        return { ...state, isEquipmentItemLoading: false, equipmentItemError: null }

      case EquipmentActionTypes.SET_CREATED_EQUIPMENT_ID:
        return {
          ...state,
          createdId: action.payload,
        }

      case EquipmentActionTypes.LOAD_ERROR:
        return { ...state, isEquipmentLoading: false, equipmentError: action.payload }

      case EquipmentActionTypes.LOAD_EQUIPMENT_ITEM_ERROR:
        return { ...state, isEquipmentItemLoading: false, equipmentItemError: action.payload }

      case EquipmentActionTypes.LOAD_STATUSES_ERROR:
        return { ...state, isStatusesLoading: false, equipmentStatusesError: action.payload }

      case EquipmentActionTypes.LOAD_EQUIPMENT_HISTORY_ERROR:
        return { ...state, isEquipmentHistoryLoading: false, equipmentHistoryError: action.payload }

      default:
        return state
    }
  } else {
    return state
  }
}
