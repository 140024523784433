import { Resizable } from 'react-resizable'
import type { ResizeCallbackData } from 'react-resizable'
import React, { FC, ReactNode } from 'react'

type PropsType = {
  props: React.HTMLAttributes<any>
}

type ResizableTitlePropsType = PropsType & {
  onResize: (e: React.SyntheticEvent<Element>, data: ResizeCallbackData) => void
  width: number
  children: ReactNode
}

export const ResizableTitle: FC<ResizableTitlePropsType> = (props) => {
  const { onResize, width, ...restProps } = props

  if (!width) {
    // eslint-disable-next-line
    return <th {...restProps} />
  }

  return (
    <Resizable
      width={width}
      height={0}
      minConstraints={[30, 0]}
      maxConstraints={[300, 0]}
      axis="x"
      handle={
        // eslint-disable-next-line
        <span
          className="react-resizable-handle"
          onClick={(e) => {
            e.stopPropagation()
          }}
        />
      }
      onResize={onResize}
      draggableOpts={{ enableUserSelectHack: false }}
      resizeHandles={['e']}
    >
      <th
        /*  eslint-disable-next-line   */
        {...restProps}
      />
    </Resizable>
  )
}
