import { FilesActionType, FilesActionTypes, FilesStateType } from '../actionTypes'

const initialState: FilesStateType = {
  tempFiles: [],
  filesError: null,
  isFilesUploading: false,
  isFilesLoading: false,
}

export const files = (state = initialState, action: FilesActionType): FilesStateType => {
  if (action) {
    switch (action.type) {
      case FilesActionTypes.START_FILES_LOADING:
        return { ...state, isFilesLoading: true }

      case FilesActionTypes.START_FILES_UPLOAD:
        return { ...state, isFilesUploading: true }

      case FilesActionTypes.CREATE_FILES_SUCCESS:
        return { ...state, tempFiles: [], isFilesLoading: false, isFilesUploading: false }

      case FilesActionTypes.LOAD_FILES_ERROR:
        return {
          ...state,
          isFilesLoading: false,
          isFilesUploading: false,
          filesError: action.payload,
          tempFiles: [],
        }

      case FilesActionTypes.SAVE_TEMPORARY_FILES:
        return { ...state, tempFiles: [...state.tempFiles, action.payload] }

      case FilesActionTypes.DELETE_TEMPORARY_FILES:
        return { ...state, tempFiles: [] }

      case FilesActionTypes.DELETE_TEMPORARY_FILE_ITEM:
        return {
          ...state,
          tempFiles: state.tempFiles.filter((file) => file.uid !== action.payload),
        }

      default:
        return state
    }
  } else {
    return state
  }
}
