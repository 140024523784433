import styled from 'styled-components'
import { FC, ReactElement, ReactNode } from 'react'
import { Row, Typography } from 'antd'

import { Color, FontSize, Spacing } from '../../styles'

const { Text } = Typography

export type StyledCardTitleWrapperPropsType = {
  $mbIcon?: string
  $mtIcon?: string
  $iconColor?: string
  $iconSize?: string
  $pb?: string
}

const StyledText = styled(Text)`
  margin-left: ${Spacing.sm10};
  font-size: ${FontSize.md};
`

const StyledCardTitleWrapper = styled(Row)<StyledCardTitleWrapperPropsType>`
  padding-bottom: ${(props) => (props.$pb ? props.$pb : 0)};
  padding-top: 8px;

  svg {
    margin-bottom: ${(props) => (props.$mbIcon ? props.$mbIcon : {})};
    margin-top: ${(props) => (props.$mtIcon ? props.$mtIcon : {})};
    color: ${(props) => (props.$iconColor ? props.$iconColor : Color.gray)};
    font-size: ${(props) => (props.$iconSize ? props.$iconSize : {})};
  }
`

export type CardTitlePropsType = {
  icon?: ReactElement | ReactNode
  children: ReactNode
  $mbIcon?: string
  $mtIcon?: string
  $iconColor?: string
  $iconSize?: string
  $pb?: string
}

export const CardTitle: FC<CardTitlePropsType> = ({
  icon,
  children,
  $mbIcon,
  $mtIcon,
  $iconColor,
  $iconSize,
  $pb,
}) => {
  return (
    <StyledCardTitleWrapper
      justify="start"
      align="middle"
      $iconColor={$iconColor}
      $mtIcon={$mtIcon}
      $mbIcon={$mbIcon}
      $iconSize={$iconSize}
      $pb={$pb}
    >
      {icon && icon}
      <StyledText>{children}</StyledText>
    </StyledCardTitleWrapper>
  )
}
