import { FC } from 'react'
import { Helmet } from 'react-helmet'
import { useLocation } from 'react-router-dom'

import { COMMON_METER, NO_SCRIPT_TAG_FOR_COMMON_METER } from '../utils'
import { FEEDBACK_URL, PLANNY_TITLE } from '../../../constants'

export type AppHelmetPropsType = {
  pageTitle?: string
  contentWidth?: string
}

export const AppHelmet: FC<AppHelmetPropsType> = ({ pageTitle, contentWidth }) => {
  const { pathname } = useLocation()

  return (
    <Helmet>
      <title>
        {pageTitle} | {PLANNY_TITLE}
      </title>

      <meta name="viewport" content={contentWidth} />

      {pathname === FEEDBACK_URL ? (
        <script src="https://yastatic.net/s3/frontend/forms/_/embed.js" />
      ) : null}

      <script type="text/javascript">{COMMON_METER}</script>

      <noscript>{NO_SCRIPT_TAG_FOR_COMMON_METER}</noscript>
    </Helmet>
  )
}
