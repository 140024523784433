import { SearchPayloadType, SearchValueType } from '../../api'

export type FiltersStateType = {
  searchValue: SearchValueType
}

export enum FiltersActionTypes {
  SET_SEARCH_VALUE = 'SET_SEARCH_VALUE',
}

export type SetSearchValueActionType = {
  type: FiltersActionTypes.SET_SEARCH_VALUE
  payload: SearchPayloadType
}

export type FiltersActionType = SetSearchValueActionType
