import { ColumnsType } from 'antd/es/table'
import moment from 'moment'
import { t } from 'i18next'

import { SelectTablesColumnsType } from './columnTypes'
import { StyledCheckOutlinedIcon } from '../commonComponents/styled'
import { TableCellContent } from '../commonComponents'
import { EMPTY_STRING, FULL_DATE_FORMAT } from '../../../constants'

const getPopoverText = (text: string, record: SelectTablesColumnsType) => {
  if (record.address) return `${text} (${t('input-label-address')}: ${record.address})`

  if (record.serialNumber && record.inventoryNumber)
    return `${text} (${`${t('input-label-serialNumber')}: ${record.serialNumber}, ${t(
      'input-label-inventoryNumber',
    )}: ${record.inventoryNumber}`})`

  if (record.serialNumber || record.inventoryNumber)
    return `${text} (${
      record.serialNumber
        ? `${t('input-label-serialNumber')}: ${record.serialNumber}`
        : `${t('input-label-inventoryNumber')}: ${record.inventoryNumber}`
    })`

  if (record.partNumber) return `${text} (${t('part-number')}: ${record.partNumber})`

  if (record.dueDatetime)
    return `${text} (${t('input-label-due-date')}: ${moment(record.dueDatetime).format(
      FULL_DATE_FORMAT,
    )})`

  return text
}

export const getItemsSelectTableColumns = (selectedKeys?: (string | number)[], isTasks = false) => {
  const columns: ColumnsType<SelectTablesColumnsType> = [
    {
      title: EMPTY_STRING,
      dataIndex: isTasks ? 'title' : 'name',
      key: isTasks ? 'title' : 'name',
      render: (text, record) => (
        <TableCellContent
          isStrong
          text={text && text.length >= 40 ? `${text.slice(0, 40)}...` : text}
          tooltipText={getPopoverText(text, record)}
          style={{ color: 'inherit', cursor: 'pointer' }}
        />
      ),
      width: '87%',
    },

    {
      title: EMPTY_STRING,
      dataIndex: 'icon',
      key: 'icon',
      render: (_, record) => {
        if (selectedKeys.includes(record.key)) return <StyledCheckOutlinedIcon />
        return ''
      },
    },
  ]

  return columns
}
