import {
  TemporaryDataActionType,
  TemporaryDataActionTypes,
  TemporaryDataStateType,
} from '../actionTypes'

const initialState: TemporaryDataStateType = {
  tempIds: {
    equipment: undefined,
    places: undefined,
    materials: undefined,
    meters: undefined,
    tasks: undefined,
    userServices: undefined,
    ppwGraphics: undefined,
    clients: undefined,
  },
  placeAddress: null,
  temporaryDate: null,
}

export const temporaryData = (
  state = initialState,
  action: TemporaryDataActionType,
): TemporaryDataStateType => {
  if (action) {
    switch (action.type) {
      case TemporaryDataActionTypes.SET_TEMPORARY_IDS:
        return {
          ...state,
          tempIds: {
            ...state.tempIds,
            [action.payload.dataType]: action.payload.tempIds,
          },
        }

      case TemporaryDataActionTypes.SET_ADDRESS:
        return {
          ...state,
          placeAddress: action.payload,
        }

      case TemporaryDataActionTypes.SET_TEMPORARY_DATE:
        return {
          ...state,
          temporaryDate: action.payload,
        }

      default:
        return state
    }
  } else {
    return state
  }
}
