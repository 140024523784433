import PersonIcon from '@mui/icons-material/Person'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { getUser } from '../../../store/selectors'
import { useTypedSelector } from '../../../hooks'
import { EMPTY_STRING, USERS } from '../../../constants'

export const useUserAccountRedirectAction = () => {
  const { user } = useTypedSelector(getUser)

  const { t } = useTranslation()

  const navigate = useNavigate()

  return {
    key: `account-redirect`,
    icon: <PersonIcon />,
    text: t(`user-account-button`),
    onClick: () => navigate(`${USERS}/${user ? user.id : EMPTY_STRING}`),
  }
}
