import { AxiosError } from 'axios'
import { ClientType, IClientHistory, IClientHistoryStats, PlanType } from '../../api'

export type ClientsStateType = {
  isClientsLoading: boolean
  clients: ClientType[]
  client: ClientType
  history: IClientHistory[]
  historyStats: IClientHistoryStats[]
  plans: PlanType[]
  createdId: string | undefined
  loadClientsError: AxiosError | null
}

export enum ClientsActionTypes {
  START_CLIENTS_LOADING = 'START_CLIENTS_LOADING',
  LOAD_CLIENTS_SUCCESS = 'LOAD_CLIENTS_SUCCESS',
  LOAD_CLIENT_SUCCESS = 'LOAD_CLIENT_SUCCESS',
  LOAD_PLANS_SUCCESS = 'LOAD_PLANS_SUCCESS',
  CREATE_CLIENT_SUCCESS = 'CREATE_CLIENT_SUCCESS',
  EDIT_CLIENT_SUCCESS = 'EDIT_CLIENT_SUCCESS',
  LOAD_CLIENT_HISTORY_SUCCESS = 'LOAD_CLIENT_HISTORY_SUCCESS',
  LOAD_CLIENT_HISTORY_STATS_SUCCESS = 'LOAD_CLIENT_HISTORY_STATS_SUCCESS',
  LOAD_CLIENTS_ERROR = 'LOAD_CLIENTS_ERROR',
  SET_CREATED_ID = 'SET_CREATED_ID',
}

export type StartClientsLoadingActionType = {
  type: ClientsActionTypes.START_CLIENTS_LOADING
}

export type SetClientCreatedIdActionType = {
  type: ClientsActionTypes.SET_CREATED_ID
  payload: string
}

export type LoadClientsSuccessActionType = {
  type: ClientsActionTypes.LOAD_CLIENTS_SUCCESS
  payload: ClientType[]
}

export type LoadPlansSuccessActionType = {
  type: ClientsActionTypes.LOAD_PLANS_SUCCESS
  payload: PlanType[]
}

export type LoadClientHistorySuccessActionType = {
  type: ClientsActionTypes.LOAD_CLIENT_HISTORY_SUCCESS
  payload: IClientHistory[]
}

export type LoadClientHistoryStatsSuccessActionType = {
  type: ClientsActionTypes.LOAD_CLIENT_HISTORY_STATS_SUCCESS
  payload: IClientHistoryStats[]
}

export type SetClientSuccessActionType = {
  type: ClientsActionTypes.LOAD_CLIENT_SUCCESS
  payload: ClientType
}

export type CreateClientSuccessActionType = {
  type: ClientsActionTypes.CREATE_CLIENT_SUCCESS
  payload: ClientType
}

export type EditClientSuccessActionType = {
  type: ClientsActionTypes.EDIT_CLIENT_SUCCESS
  payload: ClientType
}

export type SetClientsErrorActionType = {
  type: ClientsActionTypes.LOAD_CLIENTS_ERROR
  payload: AxiosError | null
}

export type ClientsActionType =
  | StartClientsLoadingActionType
  | LoadClientsSuccessActionType
  | LoadPlansSuccessActionType
  | SetClientSuccessActionType
  | CreateClientSuccessActionType
  | EditClientSuccessActionType
  | LoadClientHistorySuccessActionType
  | LoadClientHistoryStatsSuccessActionType
  | SetClientsErrorActionType
  | SetClientCreatedIdActionType
