import { AxiosError } from 'axios'
import { ErrorsPayloadType } from '../../api'

export type ErrorStateType = {
  notFoundError: AxiosError | null
  serverError: AxiosError | null
  unknownError: AxiosError | null
  authError: AxiosError | null
  isUserOnline: boolean | null
}

export enum ErrorActionTypes {
  SET_ERROR = 'SET_ERROR',
}

export type SetErrorActionType = {
  type: ErrorActionTypes.SET_ERROR
  payload: ErrorsPayloadType
}

export type ErrorActionType = SetErrorActionType
