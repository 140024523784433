import { RcFile } from 'antd/lib/upload'
import { t } from 'i18next'
import urlJoin from 'url-join'
import { AxiosError, AxiosInstance, AxiosResponse } from 'axios'

import { AppDispatch } from '../../hooks'
import { getTaskItemHistory } from './history'
import { handleLoadError } from './errors'
import { loadTags } from './tags'
import { RootState } from '../reducers/rootReducer'
import { sendNotification } from '../../components/common/notification'
import { TasksActionTypes } from '../actionTypes'
import {
  CACHE_KEYS,
  CURRENT_LOCATION,
  DATA_TYPE,
  HISTORY_SISTEM_NOTIFICATIONS,
  INIT,
  MESSAGE_TYPES,
  TAB_PATHS,
  TASKS_SISTEM_NOTIFICATIONS,
} from '../../constants'
import { convertTaskPriorities, sortTaskStatuses } from '../../api/converters'
import { createObject, editObject } from '../../api/shortcuts'
import {
  deleteTemporaryImages,
  deleteTemporaryTags,
  resetAllTemporaryData,
  resetTemporaryIds,
  setTemporaryDataIds,
} from './temporaryData'
import { getBaseLocation, getCurrentTab } from '../../functions'
import { getJsonHeader, getTasksErrorActionType, startDataLoading } from './utils'
import {
  HISTORY_ENDPOINT,
  HISTORY_UPDATE_TIMEOUT,
  PRIORITIES_ENDPOINT,
  STATUSES_ENDPOINT,
  TASKS_ENDPOINT,
  TASKS_HISTORY_ENDPOINT,
} from '../../env'
import { ITask, ITaskHistory, removeItemFromLocalStorage, TASKS_ERROR_TYPES } from '../../api'

export type GetTasksStateFunctionType = () => RootState

// Tasks

// Start task item loading

export const startTaskItemLoading = () => (dispatch: AppDispatch) =>
  dispatch({
    type: TasksActionTypes.START_TASK_ITEM_LOADING,
  })

// Load task item success

export const loadTaskItemSuccess = (data: ITask) => (dispatch: AppDispatch) =>
  dispatch({
    type: TasksActionTypes.LOAD_TASK_ITEM_SUCCESS,
    payload: data,
  })

// Delete task item from state

export const deleteTaskItemFromState = () => (dispatch: AppDispatch) =>
  dispatch({
    type: TasksActionTypes.DELETE_TASK_ITEM_FROM_STATE,
  })

// Set created task id

export const setCreatedTasksId = (value: string | undefined) => (dispatch: AppDispatch) => {
  dispatch({
    type: TasksActionTypes.SET_CREATED_TASK_ID,
    payload: value,
  })
}

// Reset temporary task data

export const resetTemporaryTaskData = () => (dispatch: AppDispatch) => {
  dispatch(deleteTemporaryTags())

  dispatch(deleteTemporaryImages())

  dispatch(deleteTaskItemFromState())

  dispatch(resetTemporaryIds())
}

// Get Task statuses   GET

export const getTaskStatuses =
  () => (dispatch: AppDispatch, getState: GetTasksStateFunctionType, api: AxiosInstance) => {
    dispatch({
      type: TasksActionTypes.START_TASK_STATUSES_LOADING,
    })

    api
      .get(urlJoin(TASKS_ENDPOINT, STATUSES_ENDPOINT))
      .then((res: AxiosResponse) => {
        dispatch({
          type: TasksActionTypes.LOAD_TASK_STATUSES_SUCCESS,
          payload: sortTaskStatuses(res.data.data),
        })
      })
      .catch((err: AxiosError) =>
        dispatch(handleLoadError(err, getTasksErrorActionType(TASKS_ERROR_TYPES.STATUSES))),
      )
  }

// Tasks Priorities  GET

export const getTaskPriorities =
  () => (dispatch: AppDispatch, getState: GetTasksStateFunctionType, api: AxiosInstance) => {
    dispatch({
      type: TasksActionTypes.START_TASK_PRIORITIES_LOADING,
    })

    api
      .get(urlJoin(TASKS_ENDPOINT, PRIORITIES_ENDPOINT))
      .then((res: AxiosResponse) => {
        dispatch({
          type: TasksActionTypes.LOAD_TASK_PRIORITIES_SUCCESS,
          payload: convertTaskPriorities(res.data.data),
        })
      })
      .catch((err: AxiosError) =>
        dispatch(handleLoadError(err, getTasksErrorActionType(TASKS_ERROR_TYPES.PRIORITIES))),
      )
  }

// Get All Tasks  GET

export const getAllTasks =
  (init?: string) =>
  (dispatch: AppDispatch, getState: GetTasksStateFunctionType, api: AxiosInstance) => {
    dispatch(startDataLoading(TasksActionTypes.START_TASKS_LOADING))

    api
      .get<ITask[]>(urlJoin(TASKS_ENDPOINT))
      .then((res: AxiosResponse) => {
        if (res.status === 200) {
          if (init && init === INIT) dispatch(resetTemporaryTaskData())

          dispatch({
            type: TasksActionTypes.LOAD_TASKS_SUCCESS,
            payload: res.data.data,
          })
        }
      })
      .catch((err: AxiosError) =>
        dispatch(handleLoadError(err, getTasksErrorActionType(TASKS_ERROR_TYPES.TASKS))),
      )
  }

// Get Tasks Item   GET

export const getTaskItem =
  (taskId: string | undefined) =>
  (dispatch: AppDispatch, getState: GetTasksStateFunctionType, api: AxiosInstance) => {
    dispatch(startTaskItemLoading())

    api
      .get(urlJoin(TASKS_ENDPOINT, taskId))
      .then((res: AxiosResponse) => {
        dispatch({
          type: TasksActionTypes.LOAD_TASK_ITEM_SUCCESS,
          payload: res.data.data[0],
        })
      })
      .catch((err: AxiosError) =>
        dispatch(handleLoadError(err, getTasksErrorActionType(TASKS_ERROR_TYPES.TASK_ITEM))),
      )
  }

//  Edit Task Item   PUT

export const editTaskItem =
  (taskId: string | undefined, data: any) =>
  (dispatch: AppDispatch, getState: GetTasksStateFunctionType, api: AxiosInstance) => {
    dispatch(startTaskItemLoading())

    const currentLocation = getCurrentTab(window.location.pathname)
    const isCalendarPage = getBaseLocation(window.location.pathname) === CURRENT_LOCATION.CALENDAR
    const baseLocation = getBaseLocation(window.location.pathname)

    api
      .put(urlJoin(TASKS_ENDPOINT, taskId), editObject(data))
      .then((res: AxiosResponse) => {
        if (res.status === 200) {
          dispatch(loadTaskItemSuccess(res.data.data[0]))

          if (
            baseLocation === CURRENT_LOCATION.TASKS ||
            currentLocation === TAB_PATHS.MAINTENANCE
          ) {
            dispatch(setTemporaryDataIds(DATA_TYPE.TASKS, undefined))
            dispatch(getAllTasks())
          }

          dispatch(loadTags(DATA_TYPE.TASKS))

          dispatch(resetAllTemporaryData())

          if (currentLocation === TAB_PATHS.HISTORY) {
            dispatch(getTaskItemHistory(taskId))
            dispatch(getTaskStatuses())
          }

          sendNotification(
            MESSAGE_TYPES.SUCCESS,
            t(TASKS_SISTEM_NOTIFICATIONS.UPDATE_TASK_DATA_SUCCESS),
          )
        }
      })
      .catch((err: AxiosError) =>
        dispatch(handleLoadError(err, getTasksErrorActionType(TASKS_ERROR_TYPES.TASK_ITEM))),
      )
      .finally(() => {
        if (currentLocation === TAB_PATHS.HISTORY) {
          if (HISTORY_UPDATE_TIMEOUT && Number(HISTORY_UPDATE_TIMEOUT) >= 0) {
            setTimeout(() => {
              dispatch(getTaskItemHistory(taskId))
            }, Number(HISTORY_UPDATE_TIMEOUT))
          }
        }

        if (isCalendarPage) dispatch(getAllTasks())
      })
  }

// Create Task  POST

export const createTaskItem =
  (data: any, files?: RcFile[]) =>
  (dispatch: AppDispatch, getState: GetTasksStateFunctionType, api: AxiosInstance) => {
    const values = createObject(data, files)

    api
      .post(urlJoin(TASKS_ENDPOINT), values)
      .then((res: AxiosResponse) => {
        if (res.status === 201) {
          const createdId = res.headers.location.split('/')[2]

          dispatch(setCreatedTasksId(createdId))

          removeItemFromLocalStorage(CACHE_KEYS.CREATE_TASK_FORM)

          dispatch({
            type: TasksActionTypes.CREATE_TASK_ITEM_SUCCESS,
          })

          dispatch(resetTemporaryIds())

          sendNotification(
            MESSAGE_TYPES.SUCCESS,
            t(TASKS_SISTEM_NOTIFICATIONS.CREATE_TASK_DATA_SUCCESS),
          )
        }
      })
      .catch((err: AxiosError) =>
        dispatch(handleLoadError(err, getTasksErrorActionType(TASKS_ERROR_TYPES.TASK_ITEM))),
      )
  }

// Delete Task Item   DELETE

export const deleteTaskItem =
  (taskId: string | undefined) =>
  (dispatch: AppDispatch, getState: GetTasksStateFunctionType, api: AxiosInstance) => {
    dispatch(startDataLoading(TasksActionTypes.START_TASKS_LOADING))

    api
      .delete(urlJoin(TASKS_ENDPOINT, taskId))
      .then((res: AxiosResponse) => {
        if (res.status === 204) {
          dispatch({
            type: TasksActionTypes.DELETE_TASK_ITEM_SUCCESS,
            payload: Number(taskId),
          })

          dispatch(getAllTasks(INIT))

          sendNotification(
            MESSAGE_TYPES.SUCCESS,
            t(TASKS_SISTEM_NOTIFICATIONS.DELETE_TASK_ITEM_SUCCESS),
          )
        }
      })
      .catch((err: AxiosError) =>
        dispatch(handleLoadError(err, getTasksErrorActionType(TASKS_ERROR_TYPES.TASK_ITEM))),
      )
  }

//  Edit Task History Item   PUT

export const editTaskHistoryItem =
  (taskId: string | undefined, uuid: string, data: ITaskHistory) =>
  (dispatch: AppDispatch, getState: GetTasksStateFunctionType, api: AxiosInstance) => {
    dispatch(startTaskItemLoading())

    api
      .put(urlJoin(HISTORY_ENDPOINT, TASKS_HISTORY_ENDPOINT, uuid), data, getJsonHeader())
      .then((res: AxiosResponse) => {
        if (res.status === 200) {
          dispatch({
            type: TasksActionTypes.EDIT_TASK_HISTORY_ITEM_SUCCESS,
          })

          dispatch(getTaskItemHistory(taskId))

          sendNotification(
            MESSAGE_TYPES.SUCCESS,
            t(HISTORY_SISTEM_NOTIFICATIONS.UPDATE_HISTORY_DATA_SUCCESS),
          )
        }
      })
      .catch((err: AxiosError) =>
        dispatch(handleLoadError(err, getTasksErrorActionType(TASKS_ERROR_TYPES.TASK_ITEM))),
      )
  }

// Delete Task History Item   DELETE

export const deleteTaskHistoryItem =
  (taskId: string, uuid: string) =>
  (dispatch: AppDispatch, getState: GetTasksStateFunctionType, api: AxiosInstance) => {
    dispatch(startDataLoading(TasksActionTypes.START_TASKS_LOADING))

    api
      .delete(urlJoin(HISTORY_ENDPOINT, TASKS_HISTORY_ENDPOINT, uuid))
      .then((res: AxiosResponse) => {
        if (res.status === 204) {
          dispatch({
            type: TasksActionTypes.DELETE_TASK_HISTORY_ITEM_SUCCESS,
          })

          dispatch(getTaskItemHistory(taskId))

          sendNotification(
            MESSAGE_TYPES.SUCCESS,
            t(HISTORY_SISTEM_NOTIFICATIONS.UPDATE_HISTORY_DATA_SUCCESS),
          )
        }
      })
      .catch((err: AxiosError) =>
        dispatch(handleLoadError(err, getTasksErrorActionType(TASKS_ERROR_TYPES.TASK_ITEM))),
      )
  }
