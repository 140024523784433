import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined'
import styled from 'styled-components'
import { Button, Col, Menu } from 'antd'

import { EMPTY_STRING } from '../../../constants'
import { BoxShadow, Color, FontSize, Spacing } from '../../../styles'

const { Item } = Menu

// ******** Buttons ******** //

// * Styled Buttons * //

// * Styled Simple Button * //

type StyledButtonPropsType = {
  $mt?: string
  $mb?: string
}

export const StyledButton = styled(Button)<StyledButtonPropsType>`
  height: 40px;
  box-shadow: ${BoxShadow.button};
  margin-bottom: ${(props) => (props.$mb ? props.$mb : Spacing.sm16)};
  margin-top: ${(props) => (props.$mt ? props.$mt : 0)};
`

type PlannyButtonPropsType = {
  $color?: string
  $marginBottom?: string
  $disabled?: boolean
  ghost?: boolean
}

export const PlannyButton = styled(Button)<PlannyButtonPropsType>`
  background-color: ${(props) => (props.$color ? props.$color : EMPTY_STRING)} !important;
  opacity: ${(props) => (props.$disabled ? 0.5 : 1)} !important;
  color: ${Color.black};
  height: 40px;
  margin-bottom: ${(props) => (props.$marginBottom ? props.$marginBottom : EMPTY_STRING)};
  border: ${(props) => (!props.ghost ? 'none' : EMPTY_STRING)};
  box-shadow: ${(props) => (!props.ghost ? '0px 1px 18px rgba(0, 0, 0, 0.12)' : 'none')};

  &:hover {
    background-color: ${(props) => (props.$color === Color.lightGreen ? '#b7d89e' : '#569426')};
    color: ${Color.black};
    transition: 1s;
  }

  &:focus {
    color: ${Color.black};
  }
`

// * Styled Text Button * //

export const TextButton = styled(Button)`
  color: ${Color.darkBlue};
  font-size: ${FontSize.sm};
`

// * Styled Close Button * //

export const StyledCloseButton = styled(ClearOutlinedIcon)`
  position: absolute;
  top: 8px;
  right: ${Spacing.sm12};
  color: ${Color.gray};
  cursor: pointer;
  font-size: 18px !important;
`

// * Styled Action Button * //

type StyledActionButtonPropsType = {
  $danger?: boolean
  $mb?: string
  $mt?: string
  $height?: string
}

export const StyledActionButton = styled(Item)<StyledActionButtonPropsType>`
  &.ant-menu-item {
    color: ${(props) => (props.$danger ? Color.red : Color.darkBlue)} !important;
    font-weight: 500 !important;
    font-size: ${FontSize.sm} !important;
    padding-left: 10px !important;
    margin-bottom: ${(props) => (props.$mb ? props.$mb : 0)} !important;
    margin-top: ${(props) => (props.$mt ? props.$mt : undefined)} !important;
    height: ${(props) => (props.$height ? props.$height : undefined)};
    min-width: 300px;
    max-width: 320px;
    & .ant-avatar.ant-avatar-circle.ant-avatar-icon {
      line-height: 34px !important;
      font-size: 16px !important;
    }
  }

  &.ant-menu-item.ant-menu-item-selected {
    border: none;
    background-color: #0968d4;
    color: ${Color.white} !important;
    border-right: none;

    .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium {
      color: ${Color.white} !important;
    }
  }

  & .ant-menu-item-icon {
    font-size: ${FontSize.lg};
    color: ${(props) => (props.$danger ? 'red' : Color.darkBlue)} !important;
  }

  &:hover {
    background-color: #0968d4;

    .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium {
      color: ${Color.white} !important;
    }

    .ant-typography {
      color: ${Color.white} !important;
    }
  }
`

// * Styled Delete Account Button * //

export const StyledDeleteAccountButton = styled(Button)`
  position: absolute;
  top: ${Spacing.sm16};
  right: ${Spacing.md20};
`

// * Styled Delete History Button * //

export const StyledDeleteHistoryButton = styled(Button)`
  width: ${Spacing.md40};
  height: ${Spacing.md40};
`

// * Square Add Button * //

type SquareOutlinedButtonPropsType = {
  $color?: string
  $top?: string
  $right?: string
}

export const SquareOutlinedButton = styled(Button)<SquareOutlinedButtonPropsType>`
  position: absolute;
  right: ${(props) => (props.$right ? props.$right : Spacing.sm16)};
  top: ${(props) => (props.$top ? props.$top : Spacing.sm16)};
  width: 40px;
  height: 40px;
  border-color: ${(props) => (props.$color ? props.$color : undefined)} !important;
  z-index: 100;

  svg {
    color: ${(props) => (props.$color ? props.$color : Color.blue)} !important;
    margin: 0;
  }
`

type StyledStatusButtonPropsType = {
  $bgColor: string
  $marginBottom?: string
  $boxShadow?: boolean
  $border?: boolean
}

export const StyledStatusButton = styled(Col)<StyledStatusButtonPropsType>`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => (props.$bgColor ? props.$bgColor : Color.pastelGreen)};
  height: 40px;
  border-radius: ${Spacing.sm8};
  border: ${(props) => (props.$border ? `1px solid #E1E1E1` : 'none')};
  margin-bottom: ${(props) => (props.$marginBottom ? props.$marginBottom : Spacing.sm4)};
  box-shadow: ${(props) => (props.$boxShadow ? 'rgb(0 0 8 / 15%) 0px 6px 12px' : 'none')};
  cursor: pointer;

  svg {
    margin-left: ${Spacing.sm8};
  }

  &:hover {
    filter: brightness(0.95);
    transition: transform 2s;
  }
`

export const LogoutButtonWrapper = styled(Col)`
  display: flex;
  align-items: center;

  span {
    text-decoration: underline;
  }

  button {
    margin-left: 0 !important;
  }

  svg {
    cursor: pointer;
  }
`

// ******** Buttons ******** //
