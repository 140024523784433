import {
  NotificationsActionType,
  NotificationsActionTypes,
  NotificationsStateType,
} from '../actionTypes'

const initialState: NotificationsStateType = {
  isNotificationsLoading: false,
  notifications: null,
  loadNotificationsError: null,
}

export const notifications = (
  state = initialState,
  action: NotificationsActionType,
): NotificationsStateType => {
  if (action) {
    switch (action.type) {
      case NotificationsActionTypes.START_NOTIFICATIONS_LOADING:
        return {
          ...state,
          isNotificationsLoading: true,
        }

      case NotificationsActionTypes.GET_NOTIFICATIONS_SUCCESS:
        return {
          ...state,
          notifications: action.payload,
          isNotificationsLoading: false,
          loadNotificationsError: null,
        }

      case NotificationsActionTypes.EDIT_NOTIFICATION_ITEM_SUCCESS:
        return {
          ...state,
          notifications: action.payload,
          isNotificationsLoading: false,
        }

      case NotificationsActionTypes.SET_LOAD_NOTIFICATIONS_ERROR:
        return {
          ...state,
          isNotificationsLoading: false,
          loadNotificationsError: action.payload,
        }

      default:
        return state
    }
  } else {
    return state
  }
}
