import styled from 'styled-components'
import { Col, Row, Skeleton } from 'antd'
import { FC, memo } from 'react'

type StyledSkeletonCardPropsType = {
  $width?: string
  $height?: string
  $isTable?: boolean
  $mr?: string
  $mb?: string
}

export const SkeletonCardWrapper = styled(Col)`
  padding: 0 !important;
`

export const StyledSkeletonCard = styled(Skeleton.Button)<StyledSkeletonCardPropsType>`
  width: ${(props) => (props.$width ? props.$width : '240px')} !important;
  height: ${(props) => (props.$height ? props.$height : '240px')} !important;
  padding: ${(props) => (props.$isTable ? '20px' : '')} !important;
  margin-bottom: ${(props) => (props.$mb ? props.$mb : '16px')} !important;
  margin-right: ${(props) => (props.$mr ? props.$mr : '')} !important;
  border-radius: ${(props) => (props.$isTable ? '8px' : '12px')} !important;
`

export type SkeletonCardPropsType = {
  width: string
  height: string
  isTable?: boolean
  isForm?: boolean
  $mb?: string
}

export const SkeletonCard: FC<SkeletonCardPropsType> = memo(
  ({ width, height, isTable = false, isForm = false, $mb }) => {
    if (isTable) {
      const rows = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]

      return (
        <>
          <StyledSkeletonCard $width={width} $height="60px" active $isTable />

          {rows.map((row) => (
            <StyledSkeletonCard key={row} $width={width} $height={height} active $isTable />
          ))}
        </>
      )
    }

    if (isForm) {
      return (
        <>
          <StyledSkeletonCard $width={width} $height={height} active />

          <Row>
            <StyledSkeletonCard $width={width} $height="80px" active />
          </Row>
        </>
      )
    }

    return (
      <SkeletonCardWrapper>
        <StyledSkeletonCard $width={width} $height={height} active $mb={$mb} />
      </SkeletonCardWrapper>
    )
  },
)
