import { MaterialsActionType, MaterialsActionTypes, MaterialsStateType } from '../actionTypes'

const initialState: MaterialsStateType = {
  materials: [],
  materialItem: undefined,
  tempParentIds: undefined,
  createdId: undefined,
  currencies: null,
  measurementUnits: null,
  history: null,
  metaHistory: null,
  materialsError: null,
  isMaterialsLoading: false,
}

export const materials = (
  state = initialState,
  action: MaterialsActionType,
): MaterialsStateType => {
  if (action) {
    switch (action.type) {
      case MaterialsActionTypes.START_MATERIALS_LOADING:
        return { ...state, isMaterialsLoading: true }

      case MaterialsActionTypes.LOAD_MATERIALS_SUCCESS:
        return {
          ...state,
          isMaterialsLoading: false,
          materialsError: null,
          history: null,
          materials: action.payload,
        }

      case MaterialsActionTypes.LOAD_MATERIAL_ITEM_SUCCESS:
        return {
          ...state,
          isMaterialsLoading: false,
          materialsError: null,
          materialItem: action.payload,
        }

      case MaterialsActionTypes.LOAD_MATERIAL_HISTORY_SUCCESS:
        return {
          ...state,
          isMaterialsLoading: false,
          materialsError: null,
          history: action.payload,
        }

      case MaterialsActionTypes.LOAD_MATERIAL_META_HISTORY_SUCCESS:
        return {
          ...state,
          metaHistory: action.payload,
        }

      case MaterialsActionTypes.LOAD_MATERIAL_CURRENCIES_SUCCESS:
        return {
          ...state,
          isMaterialsLoading: false,
          currencies: action.payload,
        }

      case MaterialsActionTypes.LOAD_MATERIAL_MEASUREMENT_UNITS_SUCCESS:
        return {
          ...state,
          isMaterialsLoading: false,
          measurementUnits: action.payload,
        }

      case MaterialsActionTypes.SET_CREATED_MATERIAL_ID:
        return {
          ...state,
          createdId: action.payload,
        }

      case MaterialsActionTypes.SAVE_TEMPORARY_PARENT_IDS:
        return { ...state, tempParentIds: action.payload }

      case MaterialsActionTypes.DELETE_MATERIAL_ITEM_FROM_STATE:
        return { ...state, materialItem: undefined }

      case MaterialsActionTypes.DELETE_MATERIAL_ITEM_SUCCESS:
        return { ...state, materials: state.materials.filter((i) => i.id !== action.payload) }

      case MaterialsActionTypes.DELETE_TEMPORARY_PARENT_IDS:
        return { ...state, tempParentIds: undefined }

      case MaterialsActionTypes.CREATE_MATERIAL_ITEM_SUCCESS:
        return { ...state, isMaterialsLoading: false, materialsError: null }

      case MaterialsActionTypes.LOAD_MATERIALS_ERROR:
        return { ...state, isMaterialsLoading: false, materialsError: action.payload }

      default:
        return state
    }
  } else {
    return state
  }
}
