import { AppDispatch } from '../../hooks/useTypedDispatch'
import { DataTypes } from '../../api'
import { FiltersActionTypes } from '../actionTypes'

export const setSearchValue =
  (dataType: DataTypes, searchValue: string) => (dispatch: AppDispatch) => {
    dispatch({
      type: FiltersActionTypes.SET_SEARCH_VALUE,
      payload: {
        dataType,
        searchValue,
      },
    })
  }
