import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { FC, ReactNode } from 'react'
import { Row, Typography } from 'antd'

const { Text } = Typography

type StyledContainerPropsType = {
  $width?: string
  $height?: string
  emptyText?: string
}

export const StyledContainer = styled(Row)<StyledContainerPropsType>`
  width: ${(props) => (props.$width ? props.$width : '100%')};
  height: ${(props) => (props.$height ? props.$height : '100%')};
`

type EmptyPropsType = {
  $width?: string
  $height?: string
  emptyText?: ReactNode
  isLoading?: boolean
  isError?: boolean
  isNoData?: boolean
}

export const Empty: FC<EmptyPropsType> = ({
  $height,
  isLoading,
  isError,
  emptyText,
  isNoData,
  $width = '100%',
}) => {
  const { t } = useTranslation()

  return (
    <StyledContainer justify="center" align="middle" $height={$height} $width={$width}>
      {!isError && !isLoading && isNoData ? emptyText : null}

      {isError ? <Text>{t('loading-error')}...</Text> : null}

      {isLoading ? '' : null}
    </StyledContainer>
  )
}
