import { RcFile } from 'antd/lib/upload'
import { t } from 'i18next'
import urlJoin from 'url-join'
import { AxiosError, AxiosInstance, AxiosResponse } from 'axios'

import { AppDispatch } from '../../hooks'
import { getEquipmentErrorActionType } from './utils/errorActionTypes'
import { handleLoadError } from './errors'
import { loadTags } from './tags'
import { RootState } from '../reducers/rootReducer'
import { sendNotification } from '../../components/common/notification'
import { sortEquipmentStatuses } from '../../api/converters'
import {
  CACHE_KEYS,
  CURRENT_LOCATION,
  DATA_TYPE,
  EQUIPMENT_SISTEM_NOTIFICATIONS,
  HISTORY_SISTEM_NOTIFICATIONS,
  INIT,
  MESSAGE_TYPES,
  TAB_PATHS,
} from '../../constants'
import { createObject, editObject } from '../../api/shortcuts'
import { deleteTemporaryImages, deleteTemporaryTags, resetTemporaryIds } from './temporaryData'
import {
  EQUIPMENT_ENDPOINT,
  EQUIPMENT_HISTORY_ENDPOINT,
  HISTORY_ENDPOINT,
  HISTORY_UPDATE_TIMEOUT,
  STATUSES_ENDPOINT,
} from '../../env'
import {
  EQUIPMENT_ERROR_TYPES,
  IEquipment,
  IEquipmentHistory,
  removeItemFromLocalStorage,
} from '../../api'
import { EquipmentActionTypes, TemporaryDataActionTypes } from '../actionTypes'
import { getBaseLocation, getCurrentTab } from '../../functions'
import { getEquipmentItemHistory, getEquipmentItemHistoryStats } from './history'
import { getJsonHeader, startDataLoading } from './utils'

export type GetEquipmentStateFunctionType = () => RootState

// Equipment

// Load equipment item success

export const loadEquipmentItemSuccess = (data: IEquipment) => (dispatch: AppDispatch) =>
  dispatch({
    type: EquipmentActionTypes.LOAD_EQUIPMENT_ITEM_SUCCESS,
    payload: data,
  })

// Delete temporary equipment ids

export const resetTemporaryEquipmentIds = () => (dispatch: AppDispatch) =>
  dispatch({
    type: TemporaryDataActionTypes.SET_TEMPORARY_IDS,
    payload: {
      dataType: DATA_TYPE.EQUIPMENT,
      tempIds: undefined,
    },
  })

// Delete Equipment item from state

export const deleteEquipmentItemFromState = () => (dispatch: AppDispatch) =>
  dispatch({
    type: EquipmentActionTypes.DELETE_EQUIPMENT_ITEM_FROM_STATE,
  })

// Set created equipment id

export const setCreatedEquipmentId = (value: string | undefined) => (dispatch: AppDispatch) =>
  dispatch({
    type: EquipmentActionTypes.SET_CREATED_EQUIPMENT_ID,
    payload: value,
  })

// Reset temporary equipment data

export const resetTemporaryEquipmentData = () => (dispatch: AppDispatch) => {
  dispatch(resetTemporaryEquipmentIds())

  dispatch(deleteTemporaryTags())

  dispatch(deleteTemporaryImages())

  dispatch(deleteEquipmentItemFromState())
}

// Get Equipment Item Status  GET

export const getEquipmentStatuses =
  () => (dispatch: AppDispatch, getState: GetEquipmentStateFunctionType, api: AxiosInstance) => {
    dispatch({
      type: EquipmentActionTypes.START_STATUSES_LOADING,
    })

    api
      .get(urlJoin(EQUIPMENT_ENDPOINT, STATUSES_ENDPOINT))
      .then((res: AxiosResponse) => {
        dispatch({
          type: EquipmentActionTypes.LOAD_EQUIPMENT_STATUSES_SUCCESS,
          payload: sortEquipmentStatuses(res.data.data),
        })
      })
      .catch((err: AxiosError) =>
        dispatch(handleLoadError(err, getEquipmentErrorActionType(EQUIPMENT_ERROR_TYPES.STATUSES))),
      )
  }

// Get All Equipment  GET

export const getAllEquipment =
  (init?: string) =>
  (dispatch: AppDispatch, getState: GetEquipmentStateFunctionType, api: AxiosInstance) => {
    dispatch(startDataLoading(EquipmentActionTypes.START_EQUIPMENT_LOADING))

    if (init && init === INIT) dispatch(resetTemporaryEquipmentData())

    api
      .get<IEquipment[]>(urlJoin(EQUIPMENT_ENDPOINT))
      .then((res: AxiosResponse) => {
        if (res.status === 200) {
          dispatch({
            type: EquipmentActionTypes.LOAD_EQUIPMENT_SUCCESS,
            payload: res.data.data,
          })
        }
      })
      .catch((err: AxiosError) =>
        dispatch(
          handleLoadError(err, getEquipmentErrorActionType(EQUIPMENT_ERROR_TYPES.EQUIPMENT)),
        ),
      )
  }

// Get Equipment Item   GET

export const getEquipmentItem =
  (equipmentId: string | undefined) =>
  (dispatch: AppDispatch, getState: GetEquipmentStateFunctionType, api: AxiosInstance) => {
    dispatch({
      type: EquipmentActionTypes.START_EQUIPMENT_ITEM_LOADING,
    })

    api
      .get(urlJoin(EQUIPMENT_ENDPOINT, equipmentId))
      .then((res: AxiosResponse) => {
        dispatch(loadEquipmentItemSuccess(res.data.data[0]))
      })
      .catch((err: AxiosError) =>
        dispatch(
          handleLoadError(err, getEquipmentErrorActionType(EQUIPMENT_ERROR_TYPES.EQUIPMENT_ITEM)),
        ),
      )
  }

//  Edit Equipment    PUT

export const editEquipmentItem =
  (equipmentId: string | undefined, data: IEquipment) =>
  (dispatch: AppDispatch, getState: GetEquipmentStateFunctionType, api: AxiosInstance) => {
    dispatch({
      type: EquipmentActionTypes.START_EQUIPMENT_ITEM_LOADING,
    })

    const currentLocation = getCurrentTab(window.location.pathname)
    const baseLocation = getBaseLocation(window.location.pathname)

    api
      .put(urlJoin(EQUIPMENT_ENDPOINT, equipmentId), editObject(data))
      .then((res: AxiosResponse) => {
        if (res.status === 200) {
          dispatch(resetTemporaryIds())
          dispatch(deleteTemporaryTags())

          dispatch(loadEquipmentItemSuccess(res.data.data[0]))

          dispatch(loadTags(DATA_TYPE.EQUIPMENT))

          if (currentLocation === TAB_PATHS.STATUSES) {
            dispatch(getEquipmentStatuses())
            dispatch(getEquipmentItemHistory(equipmentId))
            dispatch(getEquipmentItemHistoryStats(equipmentId))
          }

          if (baseLocation === CURRENT_LOCATION.EQUIPMENT) dispatch(getAllEquipment())

          sendNotification(
            MESSAGE_TYPES.SUCCESS,
            t(EQUIPMENT_SISTEM_NOTIFICATIONS.UPDATE_EQUIPMENT_DATA_SUCCESS),
          )
        }
      })
      .catch((err: AxiosError) =>
        dispatch(
          handleLoadError(err, getEquipmentErrorActionType(EQUIPMENT_ERROR_TYPES.EQUIPMENT_ITEM)),
        ),
      )
      .finally(() => {
        if (currentLocation === TAB_PATHS.STATUSES) {
          if (HISTORY_UPDATE_TIMEOUT && Number(HISTORY_UPDATE_TIMEOUT) >= 0) {
            setTimeout(() => {
              dispatch(getEquipmentItemHistory(equipmentId))
            }, Number(HISTORY_UPDATE_TIMEOUT))
          }
        }
      })
  }

// Create Equipment   POST

export const createEquipmentItem =
  (data: IEquipment, files?: RcFile[]) =>
  (dispatch: AppDispatch, getState: GetEquipmentStateFunctionType, api: AxiosInstance) => {
    dispatch({
      type: EquipmentActionTypes.START_EQUIPMENT_ITEM_LOADING,
    })

    api
      .post(urlJoin(EQUIPMENT_ENDPOINT), createObject(data, files))
      .then((res: AxiosResponse) => {
        if (res.status === 201) {
          const createdId = res.headers.location.split('/')[2]

          dispatch(setCreatedEquipmentId(createdId))

          dispatch({
            type: EquipmentActionTypes.CREATE_EQUIPMENT_ITEM_SUCCESS,
          })

          removeItemFromLocalStorage(CACHE_KEYS.CREATE_EQUIPMENT_FORM)

          sendNotification(
            MESSAGE_TYPES.SUCCESS,
            t(EQUIPMENT_SISTEM_NOTIFICATIONS.CREATE_EQUIPMENT_DATA_SUCCESS),
          )

          dispatch(resetTemporaryIds())
        }
      })
      .catch((err: AxiosError) =>
        dispatch(
          handleLoadError(err, getEquipmentErrorActionType(EQUIPMENT_ERROR_TYPES.EQUIPMENT_ITEM)),
        ),
      )
  }

// Delete Equipment Item   DELETE

export const deleteEquipmentItem =
  (equipmentId: string | undefined) =>
  (dispatch: AppDispatch, getState: GetEquipmentStateFunctionType, api: AxiosInstance) => {
    dispatch(startDataLoading(EquipmentActionTypes.START_EQUIPMENT_LOADING))

    api
      .delete(urlJoin(EQUIPMENT_ENDPOINT, equipmentId))
      .then((res: AxiosResponse) => {
        if (res.status === 204) {
          dispatch({
            type: EquipmentActionTypes.DELETE_EQUIPMENT_ITEM_SUCCESS,
            payload: Number(equipmentId),
          })

          dispatch(getAllEquipment(INIT))

          sendNotification(
            MESSAGE_TYPES.SUCCESS,
            t(EQUIPMENT_SISTEM_NOTIFICATIONS.DELETE_EQUIPMENT_ITEM_SUCCESS),
          )
        }
      })
      .catch((err: AxiosError) =>
        dispatch(
          handleLoadError(err, getEquipmentErrorActionType(EQUIPMENT_ERROR_TYPES.EQUIPMENT_ITEM)),
        ),
      )
  }

// Edit Equipment Item  History   PUT

export const editEquipmentItemHistory =
  (equipmentId: string, uuid: string, data: IEquipmentHistory) =>
  (dispatch: AppDispatch, getState: GetEquipmentStateFunctionType, api: AxiosInstance) => {
    dispatch(startDataLoading(EquipmentActionTypes.START_EQUIPMENT_HISTORY_LOADING))

    api
      .put(urlJoin(HISTORY_ENDPOINT, EQUIPMENT_HISTORY_ENDPOINT, uuid), data, getJsonHeader())
      .then((res: AxiosResponse) => {
        if (res.status === 200) {
          dispatch({
            type: EquipmentActionTypes.EDIT_EQUIPMENT_HISTORY_ITEM,
          })

          dispatch(getEquipmentItemHistory(equipmentId))
          dispatch(getEquipmentItemHistoryStats(equipmentId))

          sendNotification(
            MESSAGE_TYPES.SUCCESS,
            t(HISTORY_SISTEM_NOTIFICATIONS.UPDATE_HISTORY_DATA_SUCCESS),
          )
        }
      })
      .catch((err: AxiosError) =>
        dispatch(handleLoadError(err, getEquipmentErrorActionType(EQUIPMENT_ERROR_TYPES.HISTORY))),
      )
  }

// Delete Equipment Item  History   DELETE

export const deleteEquipmentItemHistory =
  (equipmentId: string, uuid: string) =>
  (dispatch: AppDispatch, getState: GetEquipmentStateFunctionType, api: AxiosInstance) => {
    dispatch(startDataLoading(EquipmentActionTypes.START_EQUIPMENT_HISTORY_LOADING))

    api
      .delete(urlJoin(HISTORY_ENDPOINT, EQUIPMENT_HISTORY_ENDPOINT, uuid))
      .then((res: AxiosResponse) => {
        if (res.status === 204) {
          dispatch({
            type: EquipmentActionTypes.DELETE_EQUIPMENT_HISTORY_ITEM,
          })

          dispatch(getEquipmentItemHistory(equipmentId))
          dispatch(getEquipmentItemHistoryStats(equipmentId))

          sendNotification(
            MESSAGE_TYPES.SUCCESS,
            t(HISTORY_SISTEM_NOTIFICATIONS.DELETE_HISTORY_ITEM_SUCCESS),
          )
        }
      })
      .catch((err: AxiosError) =>
        dispatch(handleLoadError(err, getEquipmentErrorActionType(EQUIPMENT_ERROR_TYPES.HISTORY))),
      )
  }
