import { FC } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Col, Row, Tooltip, Typography } from 'antd'
import { Link, useLocation } from 'react-router-dom'

import { Color } from '../../../styles'
import { getTemporaryEquipment } from '../../tables'
import { IEquipment } from '../../../api'
import { StyledCardText } from '../../common'
import { CREATE, CURRENT_LOCATION } from '../../../constants'
import { getEquipment, getTemporaryData } from '../../../store/selectors'
import { resetTemporaryEquipmentIds, resetTemporaryPlaceIds } from '../../../store/actions'
import { useTypedDispatch, useTypedSelector } from '../../../hooks'

const { Text } = Typography

export const StyledExtraText = styled(Row)`
  color: ${Color.blue};
  cursor: pointer;
  margin-left: 18px;
`

export const StyledCol = styled(Col)`
  display: flex;
  align-items: center;
  text-align: start;
  padding-left: 8px;
`
export const Bullet = styled.div`
  background-color: ${Color.blue};
  width: 5px;
  height: 5px;
  border-radius: 50%;
  margin-right: 6px;
`

export type EquipmentListPropsType = {
  prompt: string
  showModal?: () => void
  listItems?: IEquipment[]
}

export const EquipmentList: FC<EquipmentListPropsType> = ({ prompt, showModal, listItems }) => {
  const { equipment } = useTypedSelector(getEquipment)

  const { tempIds } = useTypedSelector(getTemporaryData)

  const { pathname } = useLocation()

  const { t } = useTranslation()

  const dispatch = useTypedDispatch()

  const isCreatePage = pathname.split('/')[2] === CREATE

  const getEquipmentList = () =>
    (isCreatePage && getTemporaryEquipment(equipment, tempIds.equipment)) || listItems || []

  const handleClick = () => {
    dispatch(resetTemporaryEquipmentIds())
    dispatch(resetTemporaryPlaceIds())
  }

  return getEquipmentList().length ? (
    <Row>
      {getEquipmentList().length > 1 &&
        getEquipmentList()
          .slice(0, 3)
          .map((item: IEquipment) => (
            <StyledCol xs={24} key={item.id}>
              <Bullet />

              {item.name.length > 18 ? (
                <Tooltip title={item.name}>
                  <Text>
                    <Link
                      key={item.id}
                      to={`/${CURRENT_LOCATION.EQUIPMENT}/${item.id}`}
                      onClick={handleClick}
                    >
                      {`${item.name.slice(0, 18)}...`}
                    </Link>
                  </Text>
                </Tooltip>
              ) : (
                <StyledCardText>
                  <Link
                    key={item.id}
                    to={`/${CURRENT_LOCATION.EQUIPMENT}/${item.id}`}
                    onClick={handleClick}
                  >
                    {item.name}
                  </Link>
                </StyledCardText>
              )}
            </StyledCol>
          ))}

      {getEquipmentList().length === 1 &&
        getEquipmentList()
          .slice(0, 3)
          .map((item: IEquipment) => (
            <Col xs={24} key={item.id}>
              <StyledCardText>
                <Link
                  key={item.id}
                  to={`/${CURRENT_LOCATION.EQUIPMENT}/${item.id}`}
                  onClick={handleClick}
                >
                  {item.name}
                </Link>
              </StyledCardText>
            </Col>
          ))}

      {getEquipmentList().length > 3 ? (
        <StyledCardText>
          <StyledExtraText onClick={showModal}>
            {t('another')} {` `}
            {getEquipmentList().length - 3} {t('short-items')}
          </StyledExtraText>
        </StyledCardText>
      ) : null}
    </Row>
  ) : (
    <StyledCardText>{prompt}</StyledCardText>
  )
}
