import { RootState } from '../reducers/rootReducer'

export const getEquipment = (state: RootState) => state.equipment
export const getPlaces = (state: RootState) => state.places
export const getImages = (state: RootState) => state.images
export const getTags = (state: RootState) => state.tags
export const getFiles = (state: RootState) => state.files
export const getFilters = (state: RootState) => state.filters
export const getMaterials = (state: RootState) => state.materials
export const getMeters = (state: RootState) => state.meters
export const getTemporaryData = (state: RootState) => state.temporaryData
export const getAuth = (state: RootState) => state.auth
export const getUser = (state: RootState) => state.user
export const getTasks = (state: RootState) => state.tasks
export const getUserServices = (state: RootState) => state.userServices
export const getErrors = (state: RootState) => state.errors
export const getWorkspaces = (state: RootState) => state.workspaces
export const getCompanies = (state: RootState) => state.companies
export const getNotifications = (state: RootState) => state.notifications
export const getPpwGraphics = (state: RootState) => state.ppwGraphics
export const getClients = (state: RootState) => state.clients
