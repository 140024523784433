import { t } from 'i18next'
import urlJoin from 'url-join'
import { AxiosError, AxiosInstance, AxiosResponse } from 'axios'

import { AppDispatch } from '../../hooks'
import { ClientsActionTypes } from '../actionTypes'
import { getCurrentTab } from '../../functions'
import { RootState } from '../reducers/rootReducer'
import { sendNotification } from '../../components/common/notification'
import { startDataLoading } from './utils'
import {
  CACHE_KEYS,
  CLIENTS_SISTEM_NOTIFICATIONS,
  CONTENT_TYPE_APPLICATION_JSON,
  MESSAGE_TYPES,
  TAB_PATHS,
} from '../../constants'
import {
  CLIENT_ENDPOINT,
  CLIENTS_ENDPOINT,
  DISTRIBUTION_ENDPOINT,
  HISTORY_UPDATE_TIMEOUT,
  PLANS_ENDPOINT,
  SUBSCRIPTION_ENDPOINT,
  SUBSCRIPTIONS_ENDPOINT,
} from '../../env'
import { ClientType, EditClientType, PlanType, removeItemFromLocalStorage } from '../../api'
import { getClientHistory, getClientHistoryStatistics } from './history'
import { handleLoadError, handleNotExistingUserError } from './errors'
import { transformKeysToCamelCase, transformKeysToSnakeCase } from '../../api/converters'

export type GetClientsStateFunctionType = () => RootState

// Set created client id

export const setCreatedClientId = (value: string) => (dispatch: AppDispatch) =>
  dispatch({
    type: ClientsActionTypes.SET_CREATED_ID,
    payload: value,
  })

// Get user GET

export const getAllClients =
  () => (dispatch: AppDispatch, getState: GetClientsStateFunctionType, api: AxiosInstance) => {
    dispatch(startDataLoading(ClientsActionTypes.START_CLIENTS_LOADING))

    api
      .get(urlJoin(DISTRIBUTION_ENDPOINT, CLIENTS_ENDPOINT))
      .then((res: AxiosResponse) => {
        dispatch({
          type: ClientsActionTypes.LOAD_CLIENTS_SUCCESS,
          payload: transformKeysToCamelCase(res.data),
        })
      })
      .catch((err: AxiosError) =>
        dispatch(handleLoadError(err, ClientsActionTypes.LOAD_CLIENTS_ERROR)),
      )
  }

// Get Client Item   GET

export const getClientItem =
  (clientId: string) =>
  (dispatch: AppDispatch, getState: GetClientsStateFunctionType, api: AxiosInstance) => {
    dispatch(startDataLoading(ClientsActionTypes.START_CLIENTS_LOADING))

    api
      .get(urlJoin(DISTRIBUTION_ENDPOINT, CLIENT_ENDPOINT, clientId))
      .then((res: AxiosResponse) => {
        dispatch({
          type: ClientsActionTypes.LOAD_CLIENT_SUCCESS,
          payload: transformKeysToCamelCase(res.data),
        })
      })
      .catch((err: AxiosError) =>
        dispatch(handleLoadError(err, ClientsActionTypes.LOAD_CLIENTS_ERROR)),
      )
  }

// Edit user PATCH

export const editClient =
  (clientId: string, data: EditClientType) =>
  (dispatch: AppDispatch, getState: GetClientsStateFunctionType, api: AxiosInstance) => {
    dispatch(startDataLoading(ClientsActionTypes.START_CLIENTS_LOADING))

    const headers = {
      headers: {
        'Content-Type': CONTENT_TYPE_APPLICATION_JSON,
      },
    }

    api
      .patch(
        urlJoin(DISTRIBUTION_ENDPOINT, CLIENT_ENDPOINT, clientId),
        transformKeysToSnakeCase(data),
        headers,
      )
      .then((res: AxiosResponse) => {
        if (res.status === 200) {
          dispatch({
            type: ClientsActionTypes.EDIT_CLIENT_SUCCESS,
            payload: transformKeysToCamelCase(res.data),
          })

          dispatch(getClientItem(clientId))

          removeItemFromLocalStorage(`${CACHE_KEYS.EDIT_CLIENT_FORM}:${clientId}`)

          sendNotification(
            MESSAGE_TYPES.SUCCESS,
            t(CLIENTS_SISTEM_NOTIFICATIONS.UPDATE_CLIENT_DATA_SUCCESS),
          )
        }
      })
      .catch((err: AxiosError) => {
        dispatch(handleLoadError(err, ClientsActionTypes.LOAD_CLIENTS_ERROR))
        dispatch(handleNotExistingUserError(err))
      })
  }

// Create subscription POST

export const createSubscription =
  (clientId: string, data: EditClientType) =>
  (dispatch: AppDispatch, getState: GetClientsStateFunctionType, api: AxiosInstance) => {
    dispatch(startDataLoading(ClientsActionTypes.START_CLIENTS_LOADING))

    const headers = {
      headers: {
        'Content-Type': CONTENT_TYPE_APPLICATION_JSON,
      },
    }

    api
      .post(
        urlJoin(DISTRIBUTION_ENDPOINT, SUBSCRIPTIONS_ENDPOINT),
        transformKeysToSnakeCase(data),
        headers,
      )
      .then((res: AxiosResponse) => {
        if (res.status === 200) {
          dispatch({
            type: ClientsActionTypes.EDIT_CLIENT_SUCCESS,
            payload: transformKeysToCamelCase(res.data),
          })

          dispatch(getClientItem(clientId))

          removeItemFromLocalStorage(`${CACHE_KEYS.EDIT_CLIENT_PLAN_FORM}:${clientId}`)

          if (getCurrentTab(window.location.pathname) === TAB_PATHS.HISTORY) {
            dispatch(getClientHistory(clientId))
            dispatch(getClientHistoryStatistics(clientId))
          }

          sendNotification(
            MESSAGE_TYPES.SUCCESS,
            t(CLIENTS_SISTEM_NOTIFICATIONS.UPDATE_CLIENT_DATA_SUCCESS),
          )
        }
      })
      .catch((err: AxiosError) =>
        dispatch(handleLoadError(err, ClientsActionTypes.LOAD_CLIENTS_ERROR)),
      )
      .finally(() => {
        if (getCurrentTab(window.location.pathname) === TAB_PATHS.HISTORY) {
          if (HISTORY_UPDATE_TIMEOUT && Number(HISTORY_UPDATE_TIMEOUT) >= 0) {
            setTimeout(() => {
              dispatch(getClientHistory(clientId))
              dispatch(getClientHistoryStatistics(clientId))
            }, Number(HISTORY_UPDATE_TIMEOUT))
          }
        }
        dispatch(getClientHistory(clientId))
        dispatch(getClientHistoryStatistics(clientId))
      })
  }

export const deleteClient =
  (clientId: string) =>
  (dispatch: AppDispatch, getState: GetClientsStateFunctionType, api: AxiosInstance) => {
    dispatch(startDataLoading(ClientsActionTypes.START_CLIENTS_LOADING))

    api
      .delete(urlJoin(DISTRIBUTION_ENDPOINT, CLIENT_ENDPOINT, clientId))
      .then((res: AxiosResponse) => {
        if (res.status === 204) {
          dispatch(getAllClients())

          sendNotification(
            MESSAGE_TYPES.SUCCESS,
            t(CLIENTS_SISTEM_NOTIFICATIONS.DELETE_CLIENT_ITEM_SUCCESS),
          )
        }
      })
      .catch((err: AxiosError) => {
        dispatch(handleLoadError(err, ClientsActionTypes.LOAD_CLIENTS_ERROR))
        dispatch(handleNotExistingUserError(err))
      })
  }

// Update user PATCH

export const updateSubscription =
  (clientId: string, subscriptionId: number, data: EditClientType) =>
  (dispatch: AppDispatch, getState: GetClientsStateFunctionType, api: AxiosInstance) => {
    dispatch(startDataLoading(ClientsActionTypes.START_CLIENTS_LOADING))

    const headers = {
      headers: {
        'Content-Type': CONTENT_TYPE_APPLICATION_JSON,
      },
    }

    api
      .patch(
        urlJoin(DISTRIBUTION_ENDPOINT, SUBSCRIPTION_ENDPOINT, subscriptionId.toString()),
        transformKeysToSnakeCase(data),
        headers,
      )
      .then((res: AxiosResponse) => {
        if (res.status === 200) {
          dispatch({
            type: ClientsActionTypes.EDIT_CLIENT_SUCCESS,
            payload: transformKeysToCamelCase(res.data),
          })

          dispatch(getClientItem(clientId))

          removeItemFromLocalStorage(`${CACHE_KEYS.EDIT_CLIENT_PLAN_FORM}:${clientId}`)

          if (getCurrentTab(window.location.pathname) === TAB_PATHS.HISTORY) {
            dispatch(getClientHistory(clientId))
            dispatch(getClientHistoryStatistics(clientId))
          }

          sendNotification(
            MESSAGE_TYPES.SUCCESS,
            t(CLIENTS_SISTEM_NOTIFICATIONS.UPDATE_CLIENT_DATA_SUCCESS),
          )
        }
      })
      .catch((err: AxiosError) =>
        dispatch(handleLoadError(err, ClientsActionTypes.LOAD_CLIENTS_ERROR)),
      )
      .finally(() => {
        if (getCurrentTab(window.location.pathname) === TAB_PATHS.HISTORY) {
          if (HISTORY_UPDATE_TIMEOUT && Number(HISTORY_UPDATE_TIMEOUT) >= 0) {
            setTimeout(() => {
              dispatch(getClientHistory(clientId))
              dispatch(getClientHistoryStatistics(clientId))
            }, Number(HISTORY_UPDATE_TIMEOUT))
          }
        }
        dispatch(getClientHistory(clientId))
        dispatch(getClientHistoryStatistics(clientId))
      })
  }

// Create client POST

export const createClient =
  (data: ClientType) =>
  (dispatch: AppDispatch, getState: GetClientsStateFunctionType, api: AxiosInstance) => {
    dispatch(startDataLoading(ClientsActionTypes.START_CLIENTS_LOADING))

    const headers = {
      headers: {
        'Content-Type': CONTENT_TYPE_APPLICATION_JSON,
      },
    }

    api
      .post(urlJoin(DISTRIBUTION_ENDPOINT, CLIENTS_ENDPOINT), data, headers)
      .then((res: AxiosResponse) => {
        if (res.status === 201) {
          dispatch({
            type: ClientsActionTypes.CREATE_CLIENT_SUCCESS,
            payload: transformKeysToCamelCase(res.data),
          })

          dispatch(setCreatedClientId(res.data?.id))

          removeItemFromLocalStorage(`${CACHE_KEYS.CREATE_CLIENT_FORM}`)

          sendNotification(
            MESSAGE_TYPES.SUCCESS,
            t(CLIENTS_SISTEM_NOTIFICATIONS.CREATE_CLIENT_DATA_SUCCESS),
          )
        }
      })
      .catch((err: AxiosError) => {
        dispatch(handleLoadError(err, ClientsActionTypes.LOAD_CLIENTS_ERROR))
        dispatch(handleNotExistingUserError(err))
      })
  }

// Get client plans GET

export const getPlans =
  () => (dispatch: AppDispatch, getState: GetClientsStateFunctionType, api: AxiosInstance) => {
    dispatch(startDataLoading(ClientsActionTypes.START_CLIENTS_LOADING))

    api
      .get<PlanType[]>(urlJoin(DISTRIBUTION_ENDPOINT, PLANS_ENDPOINT))
      .then((res: AxiosResponse) => {
        dispatch({
          type: ClientsActionTypes.LOAD_PLANS_SUCCESS,
          payload: transformKeysToCamelCase(res.data),
        })
      })
      .catch((err: AxiosError) =>
        dispatch(handleLoadError(err, ClientsActionTypes.LOAD_CLIENTS_ERROR)),
      )
  }
