import moment from 'moment'
import { RcFile } from 'antd/lib/upload'
import { t } from 'i18next'
import urlJoin from 'url-join'
import { AxiosError, AxiosInstance, AxiosResponse } from 'axios'

import { AppDispatch } from '../../hooks'
import { DATA_EXPORT_ENDPOINT } from '../../env'
import { DataTypes } from '../../api/types'
import { FilesActionTypes } from '../actionTypes'
import { getLanguage } from '../../functions/common'
import { handleLoadError } from './errors'
import { handleUpdateItem } from './utils/handleUpdateItem'
import { RootState } from '../reducers/rootReducer'
import { sendNotification } from '../../components/common/notification'
import { createFilesRecord, deleteFiles } from '../../api/shortcuts'
import {
  DATE_FORMAT,
  FILE_FORMAT,
  FILE_TYPE,
  FILES_SISTEM_NOTIFICATIONS,
  LANGUAGES,
  MESSAGE_TYPES,
} from '../../constants'
import { getBasePath, startDataLoading } from './utils'

type GetFilesStateFunctionType = () => RootState

moment.locale(getLanguage() === LANGUAGES.RU ? LANGUAGES.RU : LANGUAGES.EN_CA)

// Files

export const downloadFile = (
  res: AxiosResponse,
  dataType: DataTypes,
  fileType = FILE_FORMAT.XLSX,
) => {
  const blob = new Blob([res.data], {
    type: res.headers['content-type'],
  })

  const link = document.createElement('a')

  link.href = window.URL.createObjectURL(blob)
  link.download = `${moment().format(DATE_FORMAT)}_${dataType}.${fileType}`
  link.click()
}

// Add Files  PUT

export const createFileItems =
  (parentId: number | undefined, files: RcFile[], fileType: string) =>
  (dispatch: AppDispatch, getState: GetFilesStateFunctionType, api: AxiosInstance) => {
    dispatch(startDataLoading(FilesActionTypes.START_FILES_LOADING))

    api
      .put(
        urlJoin(getBasePath(fileType), parentId.toString()),
        createFilesRecord(files, FILE_TYPE.FILES),
      )
      .then((res: AxiosResponse) => {
        if (res.status === 200) {
          dispatch(handleUpdateItem(res.data.data[0], fileType))

          dispatch({
            type: FilesActionTypes.CREATE_FILES_SUCCESS,
          })

          sendNotification(
            MESSAGE_TYPES.SUCCESS,
            t(FILES_SISTEM_NOTIFICATIONS.CREATE_FILES_SUCCESS),
          )
        }
      })
      .catch((err: AxiosError) => dispatch(handleLoadError(err, FilesActionTypes.LOAD_FILES_ERROR)))
  }

// Delete File Item   PUT

export const deleteFileItem =
  (parentId: number, fileId: number, fileType: string) =>
  (dispatch: AppDispatch, getState: GetFilesStateFunctionType, api: AxiosInstance) => {
    dispatch(startDataLoading(FilesActionTypes.START_FILES_LOADING))

    const values = deleteFiles(fileId, FILE_TYPE.FILES)

    api
      .put(urlJoin(getBasePath(fileType), parentId.toString()), values)
      .then((res: AxiosResponse) => {
        dispatch(handleUpdateItem(res.data.data[0], fileType))

        dispatch({
          type: FilesActionTypes.CREATE_FILES_SUCCESS,
        })

        sendNotification(MESSAGE_TYPES.SUCCESS, t(FILES_SISTEM_NOTIFICATIONS.DELETE_FILE_SUCCESS))
      })
      .catch((err: AxiosError) => dispatch(handleLoadError(err, FilesActionTypes.LOAD_FILES_ERROR)))
  }

export const saveFiles = (file: RcFile) => (dispatch: AppDispatch) => {
  dispatch({
    type: FilesActionTypes.SAVE_TEMPORARY_FILES,
    payload: file,
  })
}

export const deleteTemporaryFiles = () => (dispatch: AppDispatch) => {
  dispatch({
    type: FilesActionTypes.DELETE_TEMPORARY_FILES,
  })
}

export const deleteTemporaryFileItem = (id: string) => (dispatch: AppDispatch) => {
  dispatch({
    type: FilesActionTypes.DELETE_TEMPORARY_FILE_ITEM,
    payload: id,
  })
}

export const exportData =
  (dataType: DataTypes, fileType = FILE_FORMAT.XLSX) =>
  (dispatch: AppDispatch, getState: GetFilesStateFunctionType, api: AxiosInstance) => {
    const BASE_PATH = getBasePath(dataType)

    api
      .get(urlJoin(BASE_PATH, DATA_EXPORT_ENDPOINT, fileType), {
        responseType: 'blob',
      })
      .then((res: AxiosResponse) => {
        if (res.status === 200) downloadFile(res, dataType, fileType)
      })
      .catch((err: AxiosError) => dispatch(handleLoadError(err, FilesActionTypes.LOAD_FILES_ERROR)))
  }
