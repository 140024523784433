import { FC } from 'react'
import LogoutIcon from '@mui/icons-material/Logout'
import PersonIcon from '@mui/icons-material/Person'
import { useTranslation } from 'react-i18next'
import { Button, Row, Tooltip, Typography } from 'antd'
import { Link, useNavigate } from 'react-router-dom'

import { getUser } from '../../../store/selectors'
import { logout } from '../../../store/actions'
import { BREAKPOINTS, USERS } from '../../../constants'
import { EmailWrapper, LogoutButtonWrapper } from '../../common'
import { useBreakpoint, useTypedDispatch, useTypedSelector } from '../../../hooks'

const { Text } = Typography

export const UserAccountHeaderActions: FC = () => {
  const { user } = useTypedSelector(getUser)

  const breakpoint = useBreakpoint()

  const { t } = useTranslation()

  const navigate = useNavigate()

  const dispatch = useTypedDispatch()

  const handleLogout = () => dispatch(logout())

  const handleNavigate = () => navigate(`${USERS}/${user.id}`)

  const isShowLogoutText = () => breakpoint === BREAKPOINTS.xxxl || breakpoint === BREAKPOINTS.xxl

  const isShowEmailText = () =>
    breakpoint === BREAKPOINTS.xxxl ||
    breakpoint === BREAKPOINTS.xxl ||
    breakpoint === BREAKPOINTS.xl

  return (
    <Row>
      <EmailWrapper>
        <PersonIcon onClick={handleNavigate} />

        {user && isShowEmailText() ? (
          <Link to={`${USERS}/${user.id}`}>
            {user.nickname && <Text strong>{user.nickname}</Text>}

            <Text className="email">{user.email}</Text>
          </Link>
        ) : null}
      </EmailWrapper>

      <LogoutButtonWrapper onClick={handleLogout}>
        <Tooltip title={!isShowLogoutText() ? t('logout-button') : ''}>
          <LogoutIcon />
        </Tooltip>

        {isShowLogoutText() ? <Button type="link">{t('logout-button')}</Button> : null}
      </LogoutButtonWrapper>
    </Row>
  )
}
