import { Row } from 'antd'
import urlJoin from 'url-join'
import { useTranslation } from 'react-i18next'
import { FC, memo } from 'react'
import { useLocation, useParams } from 'react-router-dom'

import { getUser } from '../../store/selectors'
import { Tab } from './Tab'
import {
  ClientIdParamType,
  EquipmentIdParamType,
  LocationParamType,
  MaterialIdParamType,
  MeterIdParamType,
  PpwGraphicsParamType,
  ServiceIdParamType,
  TaskIdParamType,
  UserIdParamType,
} from '../../api'
import {
  CLIENTS_URL,
  DASHBOARD_URL,
  EMPTY_STRING,
  EQUIPMENT_URL,
  LOCATIONS_URL,
  MATERIALS_URL,
  METERS_URL,
  PREVENTIVE_MAINTENANCE_URL,
  SCOPE_ID,
  TAB_PATHS,
  TASKS_URL,
  USER_SERVICES_URL,
  USERS,
} from '../../constants'
import { getBaseLocation, getCurrentTab, getScopeIdQuery } from '../../functions'
import { useQuery, useTypedSelector } from '../../hooks'

export type NavigationTabsPropsType = {
  materialsAmount?: number
  $isDashboardPage?: boolean
}

export const NavigationTabs: FC<NavigationTabsPropsType> = memo(
  ({ materialsAmount, $isDashboardPage }) => {
    const { user } = useTypedSelector(getUser)

    const { equipmentId } = useParams<EquipmentIdParamType>()
    const { locationId } = useParams<LocationParamType>()
    const { materialId } = useParams<MaterialIdParamType>()
    const { meterId } = useParams<MeterIdParamType>()
    const { taskId } = useParams<TaskIdParamType>()
    const { serviceId } = useParams<ServiceIdParamType>()
    const { userId } = useParams<UserIdParamType>()
    const { ppwGraphicsId } = useParams<PpwGraphicsParamType>()
    const { clientId } = useParams<ClientIdParamType>()

    const { pathname } = useLocation()

    const isTabHidden = () => {
      if (!user?.isDistributor) return false
      if (user?.isDistributor && getBaseLocation(pathname) === 'users') return true
      return false
    }

    const query = useQuery()

    const scopeId = query.get(SCOPE_ID)

    const { t } = useTranslation()

    const getCurrentPath = () => {
      if ($isDashboardPage) return pathname.split('/')[2]

      return getCurrentTab(pathname)
    }

    const getPath = () => {
      if (equipmentId) return urlJoin(EQUIPMENT_URL, equipmentId)
      if (materialId) return urlJoin(MATERIALS_URL, materialId)
      if (locationId) return urlJoin(LOCATIONS_URL, locationId)
      if (meterId) return urlJoin(METERS_URL, meterId)
      if (taskId) return urlJoin(TASKS_URL, taskId)
      if (serviceId) return urlJoin(USER_SERVICES_URL, serviceId)
      if (userId) return urlJoin(USERS, userId)
      if (ppwGraphicsId) return urlJoin(PREVENTIVE_MAINTENANCE_URL, ppwGraphicsId)
      if (clientId) return urlJoin(CLIENTS_URL, clientId)

      if ($isDashboardPage) return urlJoin(DASHBOARD_URL)

      return EMPTY_STRING
    }

    const getTabs = () => [
      {
        id: 1,
        title: t('main-tab'),
        link: urlJoin(getPath(), getScopeIdQuery(scopeId)),
        isActive: getCurrentPath() === undefined,
        isVisible: !isTabHidden(),
      },

      {
        id: 2,
        title: t('maintenance-tab'),
        link: urlJoin(getPath(), TAB_PATHS.MAINTENANCE, getScopeIdQuery(scopeId)),
        isActive: getCurrentPath() === TAB_PATHS.MAINTENANCE,
        isVisible: Boolean(equipmentId),
      },
      {
        id: 3,
        title: t('parameters-tab'),
        link: urlJoin(getPath(), TAB_PATHS.PARAMETERS, getScopeIdQuery(scopeId)),
        isActive: getCurrentPath() === TAB_PATHS.PARAMETERS,
        isVisible: Boolean(taskId),
        itemsAmount: undefined,
      },
      {
        id: 4,
        title: t('history-tab'),
        link: urlJoin(getPath(), TAB_PATHS.HISTORY, getScopeIdQuery(scopeId)),
        isActive: getCurrentPath() === TAB_PATHS.HISTORY,
        isVisible: Boolean(materialId || meterId || taskId || serviceId || clientId),
        itemsAmount: undefined,
      },
      {
        id: 5,
        title: t('condition-tab'),
        link: urlJoin(getPath(), TAB_PATHS.STATUSES, getScopeIdQuery(scopeId)),
        isActive: getCurrentPath() === TAB_PATHS.STATUSES,
        isVisible: Boolean(equipmentId),
        itemsAmount: undefined,
      },
      {
        id: 6,
        title: t('materials-tab'),
        link: urlJoin(getPath(), TAB_PATHS.MATERIALS, getScopeIdQuery(scopeId)),
        isActive: getCurrentPath() === TAB_PATHS.MATERIALS,
        isVisible: Boolean(equipmentId),
        itemsAmount: materialsAmount,
      },
      {
        id: 7,
        title: t('favorites-tab'),
        link: urlJoin(DASHBOARD_URL, TAB_PATHS.FAVORITES, getScopeIdQuery(scopeId)),
        isActive: getCurrentPath() === TAB_PATHS.FAVORITES,
        isVisible: $isDashboardPage,
        itemsAmount: undefined,
      },
      {
        id: 8,
        title: t('help-tab'),
        link: urlJoin(DASHBOARD_URL, TAB_PATHS.HELP, getScopeIdQuery(scopeId)),
        isActive: getCurrentPath() === TAB_PATHS.HELP,
        isVisible: $isDashboardPage,
        itemsAmount: undefined,
      },
      {
        id: 9,
        title: t('files-tab'),
        link: urlJoin(getPath(), TAB_PATHS.FILES, getScopeIdQuery(scopeId)),
        isActive: getCurrentPath() === TAB_PATHS.FILES,
        isVisible: Boolean(!$isDashboardPage && !userId && !ppwGraphicsId && !user?.isDistributor),
        itemsAmount: undefined,
      },
      {
        id: 10,
        title: t('organizations-tab'),
        link: urlJoin(getPath(), TAB_PATHS.ORGANIZATIONS, getScopeIdQuery(scopeId)),
        isActive: getCurrentPath() === TAB_PATHS.ORGANIZATIONS,
        isVisible: Boolean(userId) && !user?.isDistributor,
        itemsAmount: undefined,
      },
      {
        id: 11,
        title: t('subscriptions-tab'),
        link: urlJoin(getPath(), TAB_PATHS.SUBSCRIPTIONS, getScopeIdQuery(scopeId)),
        isActive: getCurrentPath() === TAB_PATHS.SUBSCRIPTIONS,
        isVisible: Boolean(userId) && !user?.isDistributor,
        itemsAmount: undefined,
      },
      {
        id: 12,
        title: t('graphics-tab'),
        link: urlJoin(getPath(), TAB_PATHS.GRAPHICS, getScopeIdQuery(scopeId)),
        isActive: getCurrentPath() === TAB_PATHS.GRAPHICS,
        isVisible: Boolean(ppwGraphicsId),
        itemsAmount: undefined,
      },
    ]

    return (
      <Row gutter={[60, 0]} align="middle">
        {getTabs().map(({ id, title, isActive, link, isVisible, itemsAmount }) => (
          <Tab
            key={id}
            title={title}
            link={link}
            $isActive={isActive}
            $isVisible={isVisible}
            itemsAmount={itemsAmount}
          />
        ))}
      </Row>
    )
  },
)
