import { AxiosError } from 'axios'

import { INotification } from '../../api'

export type NotificationsStateType = {
  isNotificationsLoading: boolean
  notifications: INotification[] | null
  loadNotificationsError: AxiosError | null
}

export enum NotificationsActionTypes {
  START_NOTIFICATIONS_LOADING = 'START_NOTIFICATIONS_LOADING',
  GET_NOTIFICATIONS_SUCCESS = 'GET_NOTIFICATIONS_SUCCESS',
  EDIT_NOTIFICATION_ITEM_SUCCESS = 'EDIT_NOTIFICATION_ITEM_SUCCESS',
  SET_LOAD_NOTIFICATIONS_ERROR = 'SET_LOAD_NOTIFICATIONS_ERROR',
}

export type StartNotificationsLoadingActionType = {
  type: NotificationsActionTypes.START_NOTIFICATIONS_LOADING
}

export type GetNotificationsSuccessActionType = {
  type: NotificationsActionTypes.GET_NOTIFICATIONS_SUCCESS
  payload: INotification[]
}

export type EditNotificationSuccessActionType = {
  type: NotificationsActionTypes.EDIT_NOTIFICATION_ITEM_SUCCESS
  payload: INotification[]
}

export type SetNotificationsErrorSuccessActionType = {
  type: NotificationsActionTypes.SET_LOAD_NOTIFICATIONS_ERROR
  payload: AxiosError
}

export type NotificationsActionType =
  | StartNotificationsLoadingActionType
  | GetNotificationsSuccessActionType
  | EditNotificationSuccessActionType
  | SetNotificationsErrorSuccessActionType
