import { FC } from 'react'
import { Row } from 'antd'
import styled from 'styled-components'

const MainLogo = styled(Row)`
  display: 'flex';
  justify-content: center;
  align-items: 'center';
  user-select: none;

  img {
    height: 120px;
    width: 120px;
    user-select: none;
  }
`

export const PlannyLogo: FC = ({ children }) => {
  return <MainLogo>{children}</MainLogo>
}
