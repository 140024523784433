import { CaretDownOutlined } from '@ant-design/icons'
import { Popover } from 'antd'
import { FC, useState } from 'react'

import { ActionButtonPropsType } from '../../../api/types'
import { UserActions } from './UserActions'

export type UserActionPopupPropsType = {
  actions: ActionButtonPropsType[]
  extraActions?: ActionButtonPropsType[]
}

export const UserActionPopup: FC<UserActionPopupPropsType> = ({ actions, extraActions }) => {
  const [isVisible, setIsVisible] = useState(false)

  const handleClose = () => setIsVisible(false)

  const handleOpen = () => setIsVisible(true)

  const handleVisibleChange = (visible: any) => {
    setIsVisible(visible)
  }

  return (
    <>
      <Popover
        visible={isVisible}
        content={
          <UserActions actions={actions} extraActions={extraActions} onPopoverClose={handleClose} />
        }
        onVisibleChange={handleVisibleChange}
      />

      <CaretDownOutlined onClick={handleOpen} />
    </>
  )
}
