import { FC } from 'react'
import styled from 'styled-components'
import urlJoin from 'url-join'

import { getQueryParam } from '../../../api'
import { Spacing } from '../../../styles'
import { useQuery } from '../../../hooks'
import {
  AUTH_ENDPOINT,
  AUTH_SSO_MAIL_ENDPOINT,
  PLANNY_BASE_URL,
  TELEGRAM_PARAMETER,
} from '../../../env'

const StyledLogo = styled.div`
  cursor: pointer;
  height: 50px;
  width: 50px;
  margin-right: ${Spacing.md20};

  :hover {
    opacity: 0.9;
  }
`

export const MailRuButton: FC = () => {
  const query = useQuery()

  const telegramToken = query.get(TELEGRAM_PARAMETER)

  const PARAMETR = getQueryParam(TELEGRAM_PARAMETER, telegramToken)

  const REDIRECT_LINK = urlJoin(PLANNY_BASE_URL, AUTH_ENDPOINT, AUTH_SSO_MAIL_ENDPOINT, PARAMETR)

  return (
    <StyledLogo>
      <a href={REDIRECT_LINK}>
        <img src="/images/oauth_mail.svg" alt="mailButton" />
      </a>
    </StyledLogo>
  )
}
