import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined'
import { useTranslation } from 'react-i18next'
import { FC, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { getUser } from '../store/selectors'
import { NavigationTabs } from '../components/navigation'
import { Page } from './page'
import { PlannyHeader } from '../components/header'
import { UserAccountHeaderActions } from '../components/actions/userActions'
import { useTypedSelector } from '../hooks'
import { CLIENTS_URL, TAB_PATHS } from '../constants'
import {
  DashboardFavoritesBlock,
  DashboardHelpBlock,
  DashboardMainBlock,
} from '../components/dashboard'

export const DashboardPage: FC = () => {
  const { user } = useTypedSelector(getUser)

  const { pathname } = useLocation()

  const navigate = useNavigate()

  const path = pathname?.split('/')[2]

  const { t } = useTranslation()

  useEffect(() => {
    if (user?.isDistributor) navigate(CLIENTS_URL)
  }, [user, navigate])

  return (
    <Page
      pageTitle={t('DashboardPageTitle')}
      header={
        <PlannyHeader
          pageTitle={t('DashboardPageTitle')}
          headerIcon={<DashboardOutlinedIcon />}
          navigationTabs={<NavigationTabs $isDashboardPage />}
          $isDashboardPage
          buttonGroup={<UserAccountHeaderActions />}
        />
      }
    >
      <>
        {!path && <DashboardMainBlock />}

        {path && path === TAB_PATHS.FAVORITES && <DashboardFavoritesBlock />}

        {path && path === TAB_PATHS.HELP && <DashboardHelpBlock />}
      </>
    </Page>
  )
}
