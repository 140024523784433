export enum Color {
  white = '#FFFFFF',
  black = '#000000',
  lightBlue = '#E8EDF2',
  blue = '#2196f3',
  darkBlue = '#273545',
  pastelBlue = '#CBEEF9',
  lightGray = '#E0E6EB',
  gray = '#929cb0',
  darkGray = '#7F8487',
  lightGreen = '#d5f0c0',
  pastelGreen = '#A5D6A7',
  green = '#4FB480',
  darkGreen = '#6EB03A',
  red = '#ff0000',
  pink = '#e99797',
  pastelPink = '#FAD3D3',
  orange = '#FFCD38',
  pastelOrange = '#FFE9D0',
  yellow = '#DEA434',
  purple = '#D9D9D9',
  darkPurple = '#94899b',
  siderLightBlue = '#e5f0ec',
}
