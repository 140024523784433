import { FC } from 'react'
import styled from 'styled-components'
import { Row, Typography } from 'antd'

import { PLANNY_TITLE } from '../../constants'
import { Color, FontSize, LineHeight, Spacing } from '../../styles'

const { Text } = Typography

const MainLogo = styled(Row)`
  display: 'flex';
  justify-content: center;
  align-items: 'center';

  span {
    font-size: 36px;
    color: ${Color.darkBlue};
    margin-left: ${Spacing.sm4};
    user-select: none;
  }

  img {
    display: block;
    width: 60px;
    height: 60px;
    user-select: none;
  }
`

const StyledSup = styled.sup`
  font-size: 10px;
  line-height: 16px;
`

const SiderLogo = styled.div`
  display: 'flex';
  justify-content: center;
  align-items: 'center';
  height: ${LineHeight.sm};
  cursor: pointer;

  span {
    font-size: ${FontSize.md};
    color: ${Color.darkBlue};
    margin-left: ${Spacing.sm4};
    user-select: none;
  }

  img {
    user-select: none;
  }
`

type LogoPropsType = {
  collapsed?: boolean
  onClick?: () => void
  $isSider?: boolean
}

export const Logo: FC<LogoPropsType> = ({ collapsed, onClick, $isSider = true }) => {
  return $isSider ? (
    <SiderLogo onClick={onClick}>
      <img src="/images/logo.svg" alt="logo" />

      {!collapsed && <Text strong>{PLANNY_TITLE}</Text>}
    </SiderLogo>
  ) : (
    <MainLogo onClick={onClick}>
      <img src="/images/logo.svg" alt="logo" />
      <Text strong>{PLANNY_TITLE}</Text>
      <StyledSup>beta</StyledSup>
    </MainLogo>
  )
}
