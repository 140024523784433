import { USER_ROLES } from '../../api'
import { LANGUAGES, METER_PARAMETERS, RELATION_TYPES, WEEK_DAYS } from '../../constants'

export const LANGUAGE_OPTIONS = [
  { id: 1, label: 'English', optionValue: LANGUAGES.EN },
  { id: 2, label: 'Русский', optionValue: LANGUAGES.RU },
]

export const WEEK_DAYS_OPTIONS = [
  { id: 1, label: 'monday', value: WEEK_DAYS.MONDAY },
  { id: 2, label: 'tuesday', value: WEEK_DAYS.TUESDAY },
  { id: 3, label: 'wednesday', value: WEEK_DAYS.WEDNESDAY },
  { id: 4, label: 'thursday', value: WEEK_DAYS.THURSDAY },
  { id: 5, label: 'friday', value: WEEK_DAYS.FRIDAY },
  { id: 6, label: 'saturday', value: WEEK_DAYS.SATURDAY },
  { id: 7, label: 'sunday', value: WEEK_DAYS.SUNDAY },
]

export const TIME_OPTIONS = ['09:00', '12:00', '14:00']

export const TIME_RADIO_BUTTON_OPTIONS = [
  { id: 1, label: '09:00', value: '09:00' },
  { id: 2, label: '12:00', value: '12:00' },
  { id: 3, label: '14:00', value: '14:00' },
]

export const NOTIFICATION_OPTIONS = [
  { id: 1, label: '1', value: 1 },
  { id: 2, label: '4', value: 4 },
  { id: 3, label: '24', value: 24 },
  { id: 4, label: '48', value: 48 },
]

export const METER_PARAMETERS_TYPE = [
  { id: 1, label: '', value: METER_PARAMETERS.VALUE },
  { id: 2, label: '', value: METER_PARAMETERS.INTERVAL },
]

export const RELATION_TYPE_OPTIONS = [
  { id: 1, label: 'input-label-great-then', optionValue: RELATION_TYPES.GREATER_THEN },
  { id: 2, label: 'input-label-low-then', optionValue: RELATION_TYPES.LESS_THEN },
  { id: 3, label: 'input-label-equal', optionValue: RELATION_TYPES.EQUAL },
]

export const USER_ROLE_OPTIONS = [
  { id: 1, label: 'admin-permissions', optionValue: USER_ROLES.ADMIN },
  { id: 2, label: 'guest-permissions', optionValue: USER_ROLES.GUEST },
  { id: 3, label: 'distributor', optionValue: USER_ROLES.DISTRIBUTOR },
]

export const LOCAL_STORAGE_CACHE_KEYS = [
  'createPlaceFormValues',
  'editPlaceFormValues',
  'editEquipmentFormValues',
  'createEquipmentFormValues',
  'editEquipmentStatusesFormValues',
  'createMeterFormValues',
  'editMeterFormValues',
  'editCoordinatesFormValues',
  'createMaterialFormValues',
  'editMaterialFormValues',
  'createTaskFormValues',
  'editTaskFormValues',
  'editTaskStatusesFormValues',
  'createServiceFormValues',
  'editServiceFormValues',
  'editUserServicesStatusesFormValues',
  'createClientFormValues',
  'editClientFormValues',
]

export const TIME_ZONES = [
  { id: 1, label: '+1 (UTC +1)', optionValue: '1' },
  { id: 2, label: '+2 (UTC +2)', optionValue: '2' },
  { id: 3, label: '+3 (UTC +3)', optionValue: '3' },
  { id: 4, label: '+4 (UTC +4)', optionValue: '4' },
  { id: 5, label: '+5 (UTC +5)', optionValue: '5' },
  { id: 6, label: '+6 (UTC +6)', optionValue: '6' },
  { id: 7, label: '+7 (UTC +7)', optionValue: '7' },
  { id: 8, label: '+8 (UTC +8)', optionValue: '8' },
  { id: 9, label: '+9 (UTC +9)', optionValue: '9' },
  { id: 10, label: '+10 (UTC +10)', optionValue: '10' },
  { id: 11, label: '+11 (UTC +11)', optionValue: '11' },
  { id: 12, label: '+12 (UTC +12)', optionValue: '12' },
  { id: 13, label: '-1 (UTC -1)', optionValue: '-1' },
  { id: 14, label: '-2 (UTC -2)', optionValue: '-2' },
  { id: 15, label: '-3 (UTC -3)', optionValue: '-3' },
  { id: 16, label: '-4 (UTC -4)', optionValue: '-4' },
  { id: 17, label: '-5 (UTC -5)', optionValue: '-5' },
  { id: 18, label: '-6 (UTC -6)', optionValue: '-6' },
  { id: 19, label: '-7 (UTC -7)', optionValue: '-7' },
  { id: 20, label: '-8 (UTC -8)', optionValue: '-8' },
  { id: 21, label: '-9 (UTC -9)', optionValue: '-9' },
  { id: 21, label: '-10 (UTC -10)', optionValue: '-10' },
  { id: 21, label: '-11 (UTC -11)', optionValue: '-11' },
  { id: 21, label: '-12 (UTC -12)', optionValue: '-12' },
  { id: 22, label: '-14 (UTC -14)', optionValue: '-14' },
]
