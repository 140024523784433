import { MetersActionType, MetersActionTypes, MetersStateType } from '../actionTypes'

const initialState: MetersStateType = {
  meters: [],
  meterItem: undefined,
  measurementUnits: null,
  readingPeriods: null,
  createdId: undefined,
  history: null,
  metaHistory: null,
  metersError: null,
  isMetersLoading: false,
}

export const meters = (state = initialState, action: MetersActionType): MetersStateType => {
  if (action) {
    switch (action.type) {
      case MetersActionTypes.START_METERS_LOADING:
        return { ...state, isMetersLoading: true }

      case MetersActionTypes.LOAD_METERS_SUCCESS:
        return {
          ...state,
          isMetersLoading: false,
          metersError: null,
          history: null,
          meters: action.payload,
        }

      case MetersActionTypes.LOAD_METER_ITEM_SUCCESS:
        return {
          ...state,
          isMetersLoading: false,
          metersError: null,
          meterItem: action.payload,
        }

      case MetersActionTypes.LOAD_METER_HISTORY_SUCCESS:
        return {
          ...state,
          isMetersLoading: false,
          metersError: null,
          history: action.payload,
        }

      case MetersActionTypes.LOAD_METER_META_HISTORY_SUCCESS:
        return {
          ...state,
          metaHistory: action.payload,
        }

      case MetersActionTypes.LOAD_METER_MEASUREMENT_UNITS_SUCCESS:
        return {
          ...state,
          isMetersLoading: false,
          measurementUnits: action.payload,
        }
      case MetersActionTypes.LOAD_METER_READING_PERIOD_SUCCESS:
        return {
          ...state,
          isMetersLoading: false,
          readingPeriods: action.payload,
        }

      case MetersActionTypes.SET_CREATED_METER_ID:
        return {
          ...state,
          createdId: action.payload,
        }

      case MetersActionTypes.DELETE_METER_ITEM_FROM_STATE:
        return { ...state, meterItem: undefined }

      case MetersActionTypes.DELETE_METER_ITEM_SUCCESS:
        return { ...state, meters: state.meters.filter((i) => i.id !== action.payload) }

      case MetersActionTypes.CREATE_METER_ITEM_SUCCESS:
        return { ...state, isMetersLoading: false, metersError: null }

      case MetersActionTypes.LOAD_METERS_ERROR:
        return { ...state, isMetersLoading: false, metersError: action.payload }

      default:
        return state
    }
  } else {
    return state
  }
}
