import { Link } from 'react-router-dom'

import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Col, Row, Tooltip } from 'antd'

import { CURRENT_LOCATION } from '../../../constants'
import { IUserServices } from '../../../api'
import { resetTemporaryServiceIds } from '../../../store/actions'
import { StyledCardText } from '../../common'
import { Bullet, StyledCol, StyledExtraText } from './EquipmentList'
import { getTemporaryData, getUserServices } from '../../../store/selectors'
import { useTypedDispatch, useTypedSelector } from '../../../hooks'

export type UserServiceListPropsType = {
  prompt: string
  showModal?: () => void
  selectedResponsibleId?: number
}

export const UserServiceList: FC<UserServiceListPropsType> = ({
  prompt,
  showModal,
  selectedResponsibleId,
}) => {
  const { userServices } = useTypedSelector(getUserServices)

  const { tempIds } = useTypedSelector(getTemporaryData)

  const { t } = useTranslation()

  const dispatch = useTypedDispatch()

  const getUser = () => {
    if (selectedResponsibleId)
      return userServices
        ? userServices.filter((item: IUserServices) => item.id === selectedResponsibleId)
        : []

    return []
  }

  const getResponsibleUsers = () => {
    if (tempIds.userServices && tempIds.userServices.length)
      return userServices ? userServices.filter((item) => item.id === tempIds.userServices[0]) : []

    return getUser()
  }

  const handleClick = () => dispatch(resetTemporaryServiceIds())

  return getResponsibleUsers().length && tempIds.userServices !== null ? (
    <Row>
      {getResponsibleUsers().length > 1 &&
        getResponsibleUsers()
          .slice(0, 3)
          .map((item: IUserServices) => (
            <StyledCol xs={24} key={item.id}>
              <Bullet />

              {item.name.length > 18 ? (
                <Tooltip title={item.name}>
                  <StyledCardText>
                    <Link
                      key={item.id}
                      to={`/${CURRENT_LOCATION.USER_SERVICES}/${item.id}`}
                      onClick={handleClick}
                    >
                      {`${item.name.slice(0, 18)}...`}
                    </Link>
                  </StyledCardText>
                </Tooltip>
              ) : (
                <StyledCardText>
                  <Link
                    key={item.id}
                    to={`/${CURRENT_LOCATION.USER_SERVICES}/${item.id}`}
                    onClick={handleClick}
                  >
                    {item.name}
                  </Link>
                </StyledCardText>
              )}
            </StyledCol>
          ))}

      {getResponsibleUsers().length === 1 &&
        getResponsibleUsers()
          .slice(0, 3)
          .map((item: IUserServices) => (
            <Col xs={24} key={item.id}>
              <StyledCardText>
                <Link
                  key={item.id}
                  to={`/${CURRENT_LOCATION.USER_SERVICES}/${item.id}`}
                  onClick={handleClick}
                >
                  {item.name}
                </Link>
              </StyledCardText>
            </Col>
          ))}

      {getResponsibleUsers().length > 3 ? (
        <StyledCardText>
          <StyledExtraText onClick={showModal}>
            {t('another')} {` `}
            {getResponsibleUsers().length - 3} {t('short-items')}
          </StyledExtraText>
        </StyledCardText>
      ) : null}
    </Row>
  ) : (
    <StyledCardText>{prompt}</StyledCardText>
  )
}
